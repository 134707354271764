import "./compact-trading-card.scss";
import { Currency } from "../../../interfaces/Currency";
import { ActionIndicator } from "../../backtest-center/currency-overview/backtest-trading-card/ActionIndicator";
import { CommonWalletReport } from "../../../interfaces/common-trading/CommonTradingReport";
import { CurrencyPriceData } from "../../../interfaces/PricesOverview";
import { motion } from "framer-motion";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import { Suspense, lazy, useMemo } from "react";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";
interface Props {
  activeTheme: string;
  currency: Currency;
  isHolding: boolean;
  tradingReport: CommonWalletReport | undefined;
  priceData: CurrencyPriceData | undefined;
  loadingCurrencies: boolean;
  focusedCurrency: Currency | undefined;
  onClick?: () => void;
}

const TradingCardChart = lazy(
  () =>
    import(
      "../../chart-components/interactive-chart/tradingcard-chart/TradingCardChart"
    )
);

export function CompactTradingCard(props: React.PropsWithChildren<Props>) {
  const relevantOrders = props.tradingReport?.report?.orders?.cases?.filter(
    (order) => order.currency_name === props.currency.currency_name
  );

  const theme = getTheme(props.activeTheme);

  const calcPriceDataChange = (_priceData: CurrencyPriceData) => {
    const firstPrice = _priceData.prices[0];
    const lastPrice = _priceData.prices[_priceData.prices.length - 1];
    const change = ((lastPrice - firstPrice) / firstPrice) * 100;
    return change.toFixed(2);
  };

  const priceDataChange = props.priceData
    ? calcPriceDataChange(props.priceData)
    : undefined;

  const currencyUrl = useMemo(() => {
    return getCurrencyUrl(props.currency);
  }, [props.currency]);

  const { data } = usePalette(currencyUrl);

  const isFocused = props.focusedCurrency === props.currency;

  return (
    <motion.div
      onClick={props.onClick}
      className={"compact-trading-card-container"}
      animate={{
        borderLeft: `2px solid ${data.vibrant}`,
        borderRight: isFocused
          ? `2px solid ${data.vibrant}`
          : `2px solid transparent`,
        borderTop: isFocused
          ? `2px solid ${data.vibrant}`
          : `2px solid transparent`,
        borderBottom: isFocused
          ? `2px solid ${data.vibrant}`
          : `2px solid transparent`,
      }}
    >
      <div
        className="compact-currency-theme-color"
        style={{
          backgroundColor: data.vibrant,
        }}
      />
      <Suspense
        fallback={
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NeotonSpinner activeTheme={props.activeTheme} />
          </div>
        }
      >
        <TradingCardChart
          showFirstAndLastDate
          activeTheme={props.activeTheme}
          priceData={props.priceData}
          filteredOrders={relevantOrders}
          currencyOverviewOrders={
            props.tradingReport?.report?.overview_orders?.[
              props.currency.currency_name
            ]
          }
          currentOverviewTimestamp={undefined}
          loading={props.loadingCurrencies}
        />
      </Suspense>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="compact-trading-card-bottom"
      >
        <ActionIndicator
          activeTheme={props.activeTheme}
          isHolding={props.isHolding}
          currency={props.currency}
          commonWalletReport={props.tradingReport}
          compact
        />
        <div className="compact-trading-card-bottom-column">
          <label className="dimmed-label">{props.currency.currency_name}</label>
          {priceDataChange && (
            <label
              className={
                "compact-pct-change-label" +
                (parseFloat(priceDataChange) === 0
                  ? " neutral"
                  : parseFloat(priceDataChange) > 0
                  ? " winning"
                  : " losing")
              }
            >
              {priceDataChange}%
            </label>
          )}
        </div>
        <div
          className={
            "holding-indicator-badge" + (props.isHolding ? " active" : "")
          }
        >
          holding
        </div>
        {props.isHolding && (
          <div
            className="compact-currency-theme-color holding"
            style={{
              backgroundColor: theme.buyOrderStrokeHover,
            }}
          />
        )}
      </motion.div>
    </motion.div>
  );
}
