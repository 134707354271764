import React, { Component, ReactNode } from "react";
import { ErrorPage } from "./ErrorPage";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactNode;
  appInsights: ApplicationInsights;
  activeTheme: string;
}

class GlobalErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    // Here, you can also add logic to report the error to a monitoring service
    this.props.appInsights.trackException({ exception: error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          activeTheme={this.props.activeTheme}
          appInsights={this.props.appInsights}
        />
      );
    }
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
