import axios, { AxiosInstance } from "axios";
import { CustomResponse } from "../interfaces/Response";
import {
  StrategyVersion,
  Body,
  Header,
} from "../interfaces/strategyInterfaces/Strategy";
import { StrategiesOverview } from "../interfaces/strategyInterfaces/StrategiesOverview";
import { createOptions } from "./CreateOptions";
import { StrategyInitialization } from "../interfaces/strategyInterfaces/StrategyInitialization";
import BlueprintService from "./BlueprintService";
import { Currency } from "../interfaces/Currency";
import { CriteriaGenerationPoint } from "../components/strategy-body/StrategyChartContainer";
import { CalculationProfile } from "../components/strategy-body/CalculationProfileMenu";
import { SuggestionResponse } from "../components/strategy-body/StrategyHelperModal";

const http = axios.create({
  baseURL: process.env.REACT_APP_STRATEGYMANAGER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class StrategyManagerServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public initStrategy = async (token: string, strategyName: string, fromStrategyId: string | undefined, fromStrategyVersionId: string | undefined) => {
    const options = createOptions(token);
    const body = {
      strategy_name: strategyName,
    };
    if (fromStrategyId) {
      body["init_from_strategy_id"] = fromStrategyId;
    }
    if (fromStrategyVersionId) {
      body["init_from_strategy_version_id"] = fromStrategyVersionId;
    }
    return await http.post<CustomResponse<StrategyInitialization>>(
      "/strategy/init",
      body,
      options
    );
  };

  public updateStrategy = async (
    token: string,
    strategyId: string,
    header: Header | null,
    strategyBody: Body | null,
    overwrite_version_id?: string
  ) => {
    const options = createOptions(token);
    const body = {
      strategy_id: strategyId,
      header: header,
      body: strategyBody,
      overwrite_version_id: overwrite_version_id,
    };
    try {
      const response = await http.post<CustomResponse<StrategyVersion>>(
        "/strategy/update",
        body,
        options
      );
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public deleteStrategy = async (
    token: string,
    strategyId: string,
    versionsToDelete?: string[]
  ) => {
    const options = createOptions(token);

    try {
      const body = {
        strategy_id: strategyId,
      };
      if (versionsToDelete) {
        body["versions_to_delete"] = versionsToDelete;
      }
      const response = await http.post<CustomResponse<StrategiesOverview>>(
        "/strategy/delete",
        body,
        options
      );
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public deleteStrategyVersion = async (
    token: string,
    strategyId: string,
    strategyVersion: string
  ) => {
    const options = createOptions(token);
    const body = {
      strategy_id: strategyId,
      version_id: strategyVersion,
    };
    const response = await http.post<CustomResponse<any>>(
      "/strategy/version/delete",
      body,
      options
    );
    if (response.data.error !== null) throw response.data.error;
    return response.data;
  };

  public generateSuggestions = async (
    token: string,
    calculationProfile: CalculationProfile,
    currency: Currency,
    criteriaGenerationPoints: CriteriaGenerationPoint[]
  ) => {
    const options = createOptions(token);
    const body = {
      calculation_profile: calculationProfile,
      currency_slug: currency.slug,
      criteria_generation_points: criteriaGenerationPoints,
    };
    try {
      const response = await http.post<CustomResponse<SuggestionResponse>>(
        "/strategy/critera-generator",
        body,
        options
      );
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public runMicrotest = async (
    token: string,
    microtestPayload: MicrotestPayload
  ) => {
    const options = createOptions(token);
    try {
      const response = await http.post<CustomResponse<MicrotestResults>>(
        "/strategy/microtest",
        microtestPayload,
        options
      );
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public toggleStrategyFromLeaderboard = async (
    token: string,
    strategyId: string,
    toggle: boolean
  ) => {
    const options = createOptions(token);
    const body = {
      strategy_id: strategyId,
      toggle: toggle,
    };
    try {
      const response = await http.post<CustomResponse<any>>(
        "/strategy/leaderboard-toggle",
        body,
        options
      );
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
export const StrategyManagerService = new StrategyManagerServiceClass(http);
export interface MicrotestPayload {
  currency_slug: string;
  candle_size: string;
  start: number;
  stop: number;
  microtest_dependencies: string[];
  entry_criteria?: string;
  exit_criteria?: string;
}
export interface MicrotestResults {
  entry_timestamps: number[];
  exit_timestamps: number[];
}
