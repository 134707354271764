import "./strategy-helper-modal.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { CriteriaGenerationPoint } from "./StrategyChartContainer";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { toTimestring } from "../../utils/FormattingUtils";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { Currency } from "../../interfaces/Currency";
import { useQuery } from "react-query";
import {
  CalculationProfile,
  CalculationProfileMenu,
} from "./CalculationProfileMenu";
import { NeotonLoader } from "../custom-loaders/NeotonLoader";
import CountUp from "react-countup";
import { ParsedCriteria } from "./ParsedCriteria";
import { IndicatorInfosContext } from "../../shared-service-contexts/IndicatorsInfoContext";
import { Badge, Tooltip } from "@mantine/core";
import { getTierColors } from "../../utils/ParseCriteriaUtil";
import { motion } from "framer-motion";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { CommonIconButton } from "../buttons/neoton-common-button/CommonIconButton";
import { FaUndo } from "react-icons/fa";
import { FiArrowLeft, FiPlayCircle, FiX } from "react-icons/fi";
import { CommonCandleSizeControl } from "../common/common-candle-size-control/CommonCandleSizeControl";
import { StrategyMicrotester } from "./StrategyMicrotester";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";

import { CaseSelection } from "./CaseSelection";
import { IoCodeWorking } from "react-icons/io5";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  criteriaGenerationPoints: CriteriaGenerationPoint[];
  buyPointIcon: React.ReactNode;
  sellPointIcon: React.ReactNode;
  currency: Currency;
  preSelectedCandleSize?: "1D" | "1h" | "5m";
}

export function StrategyHelperModal(props: React.PropsWithChildren<Props>) {
  const indicatorInfo = useContext(IndicatorInfosContext);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const [criteriaGenerationPoints, setCriteriaGenerationPoints] = useState<
    CriteriaGenerationPoint[]
  >(props.criteriaGenerationPoints.sort((a, b) => a.timestamp - b.timestamp));

  const oneDayCriteriaGenerationPoints = useMemo(() => {
    if (!criteriaGenerationPoints) return [];
    return criteriaGenerationPoints.filter(
      (point) => point.candle_size === "1D"
    );
  }, [criteriaGenerationPoints]);

  const oneHourCriteriaGenerationPoints = useMemo(() => {
    if (!criteriaGenerationPoints) return [];
    return criteriaGenerationPoints.filter(
      (point) => point.candle_size === "1h"
    );
  }, [criteriaGenerationPoints]);

  const fiveMinuteCriteriaGenerationPoints = useMemo(() => {
    if (!criteriaGenerationPoints) return [];
    return criteriaGenerationPoints.filter(
      (point) => point.candle_size === "5m"
    );
  }, [criteriaGenerationPoints]);

  const unfilteredOneDayCriteriaGenerationPoints = useMemo(() => {
    if (!props.criteriaGenerationPoints) return [];
    return props.criteriaGenerationPoints.filter(
      (point) => point.candle_size === "1D"
    );
  }, [props.criteriaGenerationPoints]);

  const unfilteredOneHourCriteriaGenerationPoints = useMemo(() => {
    if (!props.criteriaGenerationPoints) return [];
    return props.criteriaGenerationPoints.filter(
      (point) => point.candle_size === "1h"
    );
  }, [props.criteriaGenerationPoints]);

  const unfilteredFiveMinuteCriteriaGenerationPoints = useMemo(() => {
    if (!props.criteriaGenerationPoints) return [];
    return props.criteriaGenerationPoints.filter(
      (point) => point.candle_size === "5m"
    );
  }, [props.criteriaGenerationPoints]);

  const [selectedCandleSize, setSelectedCandleSize] = useState<CandleSize>(
    props.preSelectedCandleSize || "1D"
  );
  const [selectedCalculationProfile, setSelectedCalculationProfile] =
    useState<CalculationProfile>("TA");

  const [microtestMode, setMicrotestMode] = useState(false);
  const [addCriteriaMode, setAddCriteriaMode] = useState(false);

  const [collapseMap, setCollapseMap] = useState<CollapseMap>(
    {} as CollapseMap
  );

  const [entryCriteriaMap, setEntryCriteriaMap] = useState<{
    [candleSize: string]: string[];
  }>({
    "1D": [],
    "1h": [],
    "5m": [],
  });
  const [exitCriteriaMap, setExitCriteriaMap] = useState<{
    [candleSize: string]: string[];
  }>({
    "1D": [],
    "1h": [],
    "5m": [],
  });

  const [entryCriteriaPrecisionScore, setEntryCriteriaPrecisionScore] =
    useState<CriteriaPrecisionScore>();
  const [exitCriteriaPrecisionScore, setExitCriteriaPrecisionScore] =
    useState<CriteriaPrecisionScore>();

  const renderCriteriaGenerationPointContainer = useCallback(
    (
      criteriaGenerationPoints: CriteriaGenerationPoint[],
      unfilteredCriteriaGenerationPoints: CriteriaGenerationPoint[],
      candleSize: CandleSize,
      disableOnclick: boolean = false
    ) => {
      return (
        <div
          className={
            "criteria-generation-point-candle-size-container" +
            (selectedCandleSize === candleSize ? " selected" : "")
          }
        >
          <label className="dimmed-label">
            {candleSize} Criteria generation points
          </label>
          <div className={"criteria-generation-point-candle-size-row"}>
            {unfilteredCriteriaGenerationPoints
              .filter((point) => point.side === "entry")
              .map((point, idx) => {
                const active = criteriaGenerationPoints.includes(point);
                return (
                  <div
                    className={
                      "criteria-generator-chip buy " + (!active && "inactive")
                    }
                    onClick={() => {
                      if (disableOnclick) return;
                      if (criteriaGenerationPoints.includes(point)) {
                        setCriteriaGenerationPoints(
                          criteriaGenerationPoints.filter((p) => p !== point)
                        );
                      } else {
                        setCriteriaGenerationPoints([
                          ...criteriaGenerationPoints,
                          point,
                        ]);
                      }
                    }}
                    key={`buy-${idx}`}
                  >
                    {props.buyPointIcon}
                    <label className="dimmed-label">
                      {toTimestring(point.timestamp, point.candle_size)}
                    </label>
                  </div>
                );
              })}
          </div>
          <div className="criteria-generation-point-candle-size-row">
            {unfilteredCriteriaGenerationPoints
              .filter((point) => point.side === "exit")
              .map((point, idx) => {
                const active = criteriaGenerationPoints.includes(point);

                return (
                  <div
                    className={
                      "criteria-generator-chip sell " + (!active && "inactive")
                    }
                    onClick={() => {
                      if (disableOnclick) return;
                      if (criteriaGenerationPoints.includes(point)) {
                        setCriteriaGenerationPoints(
                          criteriaGenerationPoints.filter((p) => p !== point)
                        );
                      } else {
                        setCriteriaGenerationPoints([
                          ...criteriaGenerationPoints,
                          point,
                        ]);
                      }
                    }}
                    key={`sell-${idx}`}
                  >
                    {props.sellPointIcon}
                    <label className="dimmed-label">
                      {toTimestring(point.timestamp, point.candle_size)}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      );
    },
    [
      props.buyPointIcon,
      props.sellPointIcon,
      selectedCandleSize,
      setCriteriaGenerationPoints,
    ]
  );

  const microtestCriteriaGenerationPoints =
    selectedCandleSize === "1D"
      ? oneDayCriteriaGenerationPoints
      : selectedCandleSize === "1h"
      ? oneHourCriteriaGenerationPoints
      : fiveMinuteCriteriaGenerationPoints;

  const fetchStrategySuggestions = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || microtestCriteriaGenerationPoints.length === 0) return;
    try {
      setLoading(true);
      const response = await StrategyManagerService.generateSuggestions(
        token,
        selectedCalculationProfile,
        props.currency,
        microtestCriteriaGenerationPoints
      );
      return response.payload;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    selectedCalculationProfile,
    getAccessTokenSilently,
    props.currency,
    microtestCriteriaGenerationPoints,
    setLoading,
  ]);

  const strategySuggestionsQuery = useQuery(
    [
      "fetchStrategySuggestions",
      selectedCalculationProfile,
      selectedCandleSize,
      props.currency,
      microtestCriteriaGenerationPoints,
    ],
    fetchStrategySuggestions,
    {
      cacheTime: 60000,
      staleTime: 60000,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: microtestMode === false,
    }
  );

  const TASuggestions: CriteriaSuggestions | undefined = useMemo(() => {
    if (selectedCalculationProfile !== "TA") return undefined;
    if (!strategySuggestionsQuery.data) return undefined;
    const _collapseMap: CollapseMap = {} as CollapseMap;
    const openCap = 3;
    let openCount = 0;
    let entryCount = 0;
    let exitCount = 0;
    Object.keys(strategySuggestionsQuery.data.suggestions).forEach(
      (indicatorName) => {
        const suggestions =
          strategySuggestionsQuery.data?.suggestions[indicatorName]?.[
            selectedCandleSize
          ];
        if (suggestions) {
          const __entryCount = suggestions.entry?.length ?? 0;
          const __exitCount = suggestions.exit?.length ?? 0;
          const open = openCount < openCap;
          if (open) openCount++;
          _collapseMap[indicatorName] = open;
          entryCount += __entryCount;
          exitCount += __exitCount;
          suggestions.count = __entryCount + __exitCount;
        }
      }
    );
    _collapseMap.entry = entryCount > 0;
    _collapseMap.exit = exitCount > 0;
    setCollapseMap(_collapseMap);
    return {
      calculationProfile: "TA",
      entryCount: entryCount,
      exitCount: exitCount,
      suggestions: strategySuggestionsQuery.data.suggestions,
    };
  }, [
    strategySuggestionsQuery.data,
    selectedCalculationProfile,
    selectedCandleSize,
    setCollapseMap,
  ]);

  const PTSuggestions: CriteriaSuggestions | undefined = useMemo(() => {
    if (selectedCalculationProfile !== "PT") return undefined;
    if (!strategySuggestionsQuery.data) return undefined;
    const _collapseMap: CollapseMap = {} as CollapseMap;
    let entryCount = 0;
    let exitCount = 0;
    Object.keys(strategySuggestionsQuery.data.suggestions).forEach(
      (indicatorName) => {
        const suggestions =
          strategySuggestionsQuery.data?.suggestions[indicatorName]?.[
            selectedCandleSize
          ];
        if (suggestions) {
          const __entryCount = suggestions.entry?.length ?? 0;
          const __exitCount = suggestions.exit?.length ?? 0;
          _collapseMap[indicatorName] = true;
          entryCount += __entryCount;
          exitCount += __exitCount;
          suggestions.count = __entryCount + __exitCount;
        }
      }
    );
    _collapseMap.entry = entryCount > 0;
    _collapseMap.exit = exitCount > 0;
    setCollapseMap(_collapseMap);
    return {
      calculationProfile: "PT",
      entryCount: entryCount,
      exitCount: exitCount,
      suggestions: strategySuggestionsQuery.data.suggestions,
    };
  }, [
    strategySuggestionsQuery.data,
    selectedCalculationProfile,
    selectedCandleSize,
    setCollapseMap,
  ]);

  const AISuggestions: CriteriaSuggestions | undefined = useMemo(() => {
    if (selectedCalculationProfile !== "AI") return undefined;
    if (!strategySuggestionsQuery.data) return undefined;
    const _collapseMap: CollapseMap = {} as CollapseMap;
    let entryCount = 0;
    let exitCount = 0;
    Object.keys(strategySuggestionsQuery.data.suggestions).forEach(
      (indicatorName) => {
        const suggestions =
          strategySuggestionsQuery.data?.suggestions[indicatorName]?.[
            selectedCandleSize
          ];
        if (suggestions) {
          const __entryCount = suggestions.entry?.length ?? 0;
          const __exitCount = suggestions.exit?.length ?? 0;
          _collapseMap[indicatorName] = true;
          entryCount += __entryCount;
          exitCount += __exitCount;
          suggestions.count = __entryCount + __exitCount;
        }
      }
    );
    _collapseMap.entry = entryCount > 0;
    _collapseMap.exit = exitCount > 0;
    setCollapseMap(_collapseMap);
    return {
      calculationProfile: "AI",
      entryCount: entryCount,
      exitCount: exitCount,
      suggestions: strategySuggestionsQuery.data.suggestions,
    };
  }, [
    strategySuggestionsQuery.data,
    selectedCalculationProfile,
    selectedCandleSize,
    setCollapseMap,
  ]);

  const renderCriteriaSuggestions = useCallback(
    (criteriaSuggestions: CriteriaSuggestions) => {
      return (
        <>
          <div className="criteria-generator-row">
            <Badge
              radius={"xs"}
              variant="outline"
              color={getTierColors(criteriaSuggestions.calculationProfile)}
              size={"xs"}
              styles={{
                inner: {
                  fontFamily: "Montserrat-LightAlt",
                },
              }}
            >
              {criteriaSuggestions.calculationProfile}
            </Badge>
            {criteriaGenerationPoints.filter((point) => point.side === "entry")
              .length > 0 && (
              <div
                className={
                  "suggestion-criteria-row-chip" +
                  (collapseMap.entry ? " selected" : "")
                }
                onClick={() => {
                  if (collapseMap.exit) {
                    setCollapseMap({
                      ...collapseMap,
                      entry: true,
                      exit: !collapseMap.exit,
                    });
                  } else {
                    setCollapseMap({
                      ...collapseMap,
                      entry: !collapseMap.entry,
                    });
                  }
                }}
              >
                {props.buyPointIcon}
                <label className="dimmed-label">
                  Entry criterias:{" "}
                  <CountUp
                    end={criteriaSuggestions.exitCount}
                    preserveValue
                    duration={1}
                    decimals={0}
                  />
                </label>
              </div>
            )}

            {criteriaGenerationPoints.filter((point) => point.side === "exit")
              .length > 0 && (
              <div
                className={
                  "suggestion-criteria-row-chip" +
                  (collapseMap.exit ? " selected" : "")
                }
                onClick={() => {
                  if (collapseMap.entry) {
                    setCollapseMap({
                      ...collapseMap,
                      entry: !collapseMap.entry,
                      exit: true,
                    });
                  } else {
                    setCollapseMap({
                      ...collapseMap,
                      exit: !collapseMap.exit,
                    });
                  }
                }}
              >
                {props.sellPointIcon}
                <label className="dimmed-label">
                  Exit criterias:{" "}
                  <CountUp
                    end={criteriaSuggestions.exitCount}
                    preserveValue
                    duration={1}
                    decimals={0}
                  />
                </label>
              </div>
            )}

            {criteriaSuggestions.entryCount + criteriaSuggestions.exitCount >
              0 && (
              <div
                className={"suggestion-criteria-row-chip"}
                onClick={() => {
                  const collapse = Object.keys(collapseMap).some(
                    (key) => !collapseMap[key]
                  );
                  const _collapseMap: CollapseMap = {} as CollapseMap;
                  Object.keys(collapseMap).forEach((key) => {
                    _collapseMap[key] = collapse;
                  });
                  setCollapseMap(_collapseMap);
                }}
              >
                <label className="dimmed-label">Toggle all</label>
              </div>
            )}
          </div>
          {criteriaSuggestions.entryCount > 0 ||
          criteriaSuggestions.exitCount > 0 ? (
            <>
              {Object.keys(criteriaSuggestions.suggestions).map(
                (indicatorName, idx) => {
                  const suggestions =
                    criteriaSuggestions.suggestions?.[indicatorName]?.[
                      selectedCandleSize
                    ];
                  if (suggestions?.count === 0) return null;
                  const entrySuggestions = suggestions?.entry;
                  const exitSuggestions = suggestions?.exit;
                  const entryCount = entrySuggestions?.length ?? 0;
                  const exitCount = exitSuggestions?.length ?? 0;
                  return (
                    <div className="indicator-suggestions" key={idx}>
                      <div className="criteria-generator-row">
                        <div
                          className={
                            "suggestion-criteria-row-chip" +
                            (collapseMap[indicatorName] && (collapseMap.entry || collapseMap.exit) ? " selected" : "")
                          }
                          onClick={() =>
                            setCollapseMap({
                              ...collapseMap,
                              [indicatorName]: !collapseMap[indicatorName],
                              entry: !collapseMap.entry ? true : collapseMap.entry,
                              exit: !collapseMap.exit ? true : collapseMap.exit, 
                            })
                          }
                        >
                          <Badge
                            variant="outline"
                            size="xs"
                            radius={"sm"}
                            className="indicator-badge"
                            key={indicatorName}
                            color={getTierColors(selectedCalculationProfile)}
                          >
                            {indicatorName}
                          </Badge>

                          {criteriaSuggestions.entryCount > 0 && (
                            <>
                              {props.buyPointIcon}
                              <label className="dimmed-label">
                                <CountUp
                                  end={entryCount}
                                  preserveValue
                                  duration={1}
                                  decimals={0}
                                />
                              </label>
                            </>
                          )}
                          {criteriaSuggestions.exitCount > 0 && (
                            <>
                              {props.sellPointIcon}
                              <label className="dimmed-label">
                                <CountUp
                                  end={exitCount}
                                  preserveValue
                                  duration={1}
                                  decimals={0}
                                />
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                      {collapseMap[indicatorName] && (
                        <>
                          {collapseMap.entry &&
                            entrySuggestions?.map((entrySuggestion, idx) => {
                              return (
                                <motion.div
                                  key={entrySuggestion}
                                  initial={{ scale: 0, x: -3 }}
                                  animate={{ scale: 1, x: 0 }}
                                  transition={{
                                    duration: 0.15,
                                    delay: idx * 0.04,
                                  }}
                                  className={
                                    "suggestion" +
                                    (entryCriteriaMap[
                                      selectedCandleSize
                                    ]?.includes(entrySuggestion)
                                      ? " selected"
                                      : "")
                                  }
                                  onClick={() => {
                                    if (
                                      !entryCriteriaMap[
                                        selectedCandleSize
                                      ]?.includes(entrySuggestion)
                                    ) {
                                      setEntryCriteriaMap({
                                        ...entryCriteriaMap,
                                        [selectedCandleSize]: [
                                          ...entryCriteriaMap[
                                            selectedCandleSize
                                          ],
                                          entrySuggestion,
                                        ],
                                      });
                                    } else {
                                      setEntryCriteriaMap({
                                        ...entryCriteriaMap,
                                        [selectedCandleSize]: entryCriteriaMap[
                                          selectedCandleSize
                                        ].filter(
                                          (criteria) =>
                                            criteria !== entrySuggestion
                                        ),
                                      });
                                    }
                                  }}
                                >
                                  {props.buyPointIcon}
                                  <ParsedCriteria
                                    key={entrySuggestion}
                                    indicatorsInfo={indicatorInfo}
                                    activeTheme={props.activeTheme}
                                    criteria={entrySuggestion}
                                  />
                                </motion.div>
                              );
                            })}
                          {collapseMap.exit &&
                            exitSuggestions?.map((exitSuggestion, idx) => {
                              return (
                                <motion.div
                                  key={exitSuggestion}
                                  initial={{ scale: 0, x: -3 }}
                                  animate={{ scale: 1, x: 0 }}
                                  transition={{
                                    duration: 0.15,
                                    delay: idx * 0.04,
                                  }}
                                  className={
                                    "suggestion" +
                                    (exitCriteriaMap[
                                      selectedCandleSize
                                    ]?.includes(exitSuggestion)
                                      ? " selected"
                                      : "")
                                  }
                                  onClick={() => {
                                    if (
                                      !exitCriteriaMap[
                                        selectedCandleSize
                                      ]?.includes(exitSuggestion)
                                    ) {
                                      setExitCriteriaMap({
                                        ...exitCriteriaMap,
                                        [selectedCandleSize]: [
                                          ...exitCriteriaMap[
                                            selectedCandleSize
                                          ],
                                          exitSuggestion,
                                        ],
                                      });
                                    } else {
                                      setExitCriteriaMap({
                                        ...exitCriteriaMap,
                                        [selectedCandleSize]: exitCriteriaMap[
                                          selectedCandleSize
                                        ].filter(
                                          (criteria) =>
                                            criteria !== exitSuggestion
                                        ),
                                      });
                                    }
                                  }}
                                >
                                  {props.sellPointIcon}
                                  <ParsedCriteria
                                    key={exitSuggestion}
                                    indicatorsInfo={indicatorInfo}
                                    activeTheme={props.activeTheme}
                                    criteria={exitSuggestion}
                                  />
                                </motion.div>
                              );
                            })}
                        </>
                      )}
                    </div>
                  );
                }
              )}
            </>
          ) : (
            <label className="dimmed-label">
              No {criteriaSuggestions.calculationProfile} criterias could be
              generated to match all of the points
            </label>
          )}
        </>
      );
    },
    [
      props.sellPointIcon,
      props.buyPointIcon,
      selectedCandleSize,
      props.activeTheme,
      indicatorInfo,
      setCollapseMap,
      collapseMap,
      setEntryCriteriaMap,
      setExitCriteriaMap,
      entryCriteriaMap,
      exitCriteriaMap,
    ]
  );

  return (
    <div className="strategy-helper-modal-container">
      {addCriteriaMode ? (
        <CaseSelection
          activeTheme={props.activeTheme}
          strategy={props.strategy}
          candleSize={selectedCandleSize}
          entryCriteria={
            entryCriteriaMap[selectedCandleSize]
              ? entryCriteriaMap[selectedCandleSize].join(" ; ")
              : undefined
          }
          exitCriteria={
            exitCriteriaMap[selectedCandleSize]
              ? exitCriteriaMap[selectedCandleSize].join(" ; ")
              : undefined
          }
        />
      ) : (
        <>
          {!microtestMode && (
            <CommonCandleSizeControl
              selectedCandleSize={selectedCandleSize}
              onClick={(candleSize) =>
                setSelectedCandleSize(candleSize as CandleSize)
              }
              oneDayDisabled={oneDayCriteriaGenerationPoints.length === 0}
              oneHourDisabled={oneHourCriteriaGenerationPoints.length === 0}
              fiveMinuteDisabled={
                fiveMinuteCriteriaGenerationPoints.length === 0
              }
            />
          )}
          {criteriaGenerationPoints && microtestMode && (
            <div className="criteria-generation-points-container">
              {microtestCriteriaGenerationPoints.length > 0 && (
                <>
                  {renderCriteriaGenerationPointContainer(
                    microtestCriteriaGenerationPoints,
                    microtestCriteriaGenerationPoints,
                    selectedCandleSize,
                    true
                  )}
                </>
              )}
            </div>
          )}
          {criteriaGenerationPoints && !microtestMode && (
            <div className="criteria-generation-points-container">
              {unfilteredOneDayCriteriaGenerationPoints.length > 0 && (
                <>
                  {renderCriteriaGenerationPointContainer(
                    oneDayCriteriaGenerationPoints,
                    unfilteredOneDayCriteriaGenerationPoints,
                    "1D"
                  )}
                </>
              )}
              {unfilteredOneHourCriteriaGenerationPoints.length > 0 && (
                <>
                  {renderCriteriaGenerationPointContainer(
                    oneHourCriteriaGenerationPoints,
                    unfilteredOneHourCriteriaGenerationPoints,
                    "1h"
                  )}
                </>
              )}
              {unfilteredFiveMinuteCriteriaGenerationPoints.length > 0 && (
                <>
                  {renderCriteriaGenerationPointContainer(
                    fiveMinuteCriteriaGenerationPoints,
                    unfilteredFiveMinuteCriteriaGenerationPoints,
                    "5m"
                  )}
                </>
              )}
            </div>
          )}
          {!microtestMode && (
            <CalculationProfileMenu
              activeTheme={props.activeTheme}
              setSelectedCalculationProfile={setSelectedCalculationProfile}
              selectedCalculationProfile={selectedCalculationProfile}
            />
          )}
          <div className="suggestions-container">
            {microtestMode ? (
              <StrategyMicrotester
                activeTheme={props.activeTheme}
                currency={props.currency}
                candleSize={selectedCandleSize}
                start={
                  strategySuggestionsQuery.data?.candle_size_ranges[
                    selectedCandleSize
                  ].start
                }
                stop={
                  strategySuggestionsQuery.data?.candle_size_ranges[
                    selectedCandleSize
                  ].stop
                }
                criteriaGenerationPoints={microtestCriteriaGenerationPoints}
                entryCriteria={entryCriteriaMap[selectedCandleSize].join(" ; ")}
                exitCriteria={exitCriteriaMap[selectedCandleSize].join(" ; ")}
                setEntryCriteriaPrecisionScore={setEntryCriteriaPrecisionScore}
                setExitCriteriaPrecisionScore={setExitCriteriaPrecisionScore}
              />
            ) : (
              <>
                {loading ? (
                  <div className="suggestions-loading-container">
                    <NeotonLoader />
                    <label className="dimmed-label">
                      Generating {selectedCalculationProfile} criterias
                    </label>
                  </div>
                ) : (
                  <>
                    {selectedCalculationProfile === "TA" &&
                      TASuggestions &&
                      renderCriteriaSuggestions(TASuggestions)}
                    {selectedCalculationProfile === "PT" &&
                      PTSuggestions &&
                      renderCriteriaSuggestions(PTSuggestions)}
                    {selectedCalculationProfile === "AI" &&
                      AISuggestions &&
                      renderCriteriaSuggestions(AISuggestions)}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}

      {entryCriteriaMap[selectedCandleSize].length > 0 && (
        <>
          {!addCriteriaMode && (
            <div className="criteria-generator-row">
              <CommonIconButton
                icon={<FaUndo />}
                label="Undo"
                tooltipAbove
                style={{ fontSize: "smaller" }}
                onClick={() => {
                  // remove the last element from the array
                  setEntryCriteriaMap({
                    ...entryCriteriaMap,
                    [selectedCandleSize]: entryCriteriaMap[
                      selectedCandleSize
                    ].slice(0, -1),
                  });
                }}
                activeTheme={props.activeTheme}
              />
              <CommonIconButton
                icon={<FiX />}
                label={"Clear"}
                tooltipAbove
                style={{ fontSize: "smaller" }}
                onClick={() => {
                  setEntryCriteriaMap({
                    ...entryCriteriaMap,
                    [selectedCandleSize]: [],
                  });
                }}
                activeTheme={props.activeTheme}
              />

              {entryCriteriaPrecisionScore &&
                entryCriteriaPrecisionScore.criteria ===
                  entryCriteriaMap[selectedCandleSize].join(" ; ") &&
                entryCriteriaPrecisionScore.candleSize ===
                  selectedCandleSize && (
                  <Tooltip
                    label="Entry precision"
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <CountUp
                      className={"dimmed-label highlight"}
                      style={{
                        filter: `grayscale(${
                          1 - entryCriteriaPrecisionScore.precision
                        })`,
                      }}
                      end={entryCriteriaPrecisionScore.precision * 100}
                      duration={1}
                      decimals={2}
                      suffix="%"
                    />
                  </Tooltip>
                )}
            </div>
          )}

          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}
            style={{
              maxWidth: "none",
            }}
            className="criteria-result-container entry"
          >
            <ParsedCriteria
              activeTheme={props.activeTheme}
              indicatorsInfo={indicatorInfo}
              criteria={entryCriteriaMap[selectedCandleSize].join(" ; ")}
            />
          </motion.div>
        </>
      )}
      {exitCriteriaMap[selectedCandleSize].length > 0 && (
        <>
          {!addCriteriaMode && (
            <div className="criteria-generator-row">
              <CommonIconButton
                icon={<FaUndo />}
                label="Undo"
                style={{ fontSize: "smaller" }}
                onClick={() => {
                  // remove the last element from the array
                  setExitCriteriaMap({
                    ...exitCriteriaMap,
                    [selectedCandleSize]: exitCriteriaMap[
                      selectedCandleSize
                    ].slice(0, -1),
                  });
                }}
                activeTheme={props.activeTheme}
              />
              <CommonIconButton
                icon={<FiX />}
                style={{ fontSize: "smaller" }}
                label={"Clear"}
                onClick={() => {
                  setExitCriteriaMap({
                    ...exitCriteriaMap,
                    [selectedCandleSize]: [],
                  });
                }}
                activeTheme={props.activeTheme}
              />

              {exitCriteriaPrecisionScore &&
                exitCriteriaPrecisionScore.criteria ===
                  exitCriteriaMap[selectedCandleSize].join(" ; ") &&
                exitCriteriaPrecisionScore.candleSize ===
                  selectedCandleSize && (
                  <Tooltip
                    label="Exit precision"
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <CountUp
                      className="dimmed-label highlight"
                      style={{
                        filter: `grayscale(${
                          1 - exitCriteriaPrecisionScore.precision
                        })`,
                      }}
                      end={exitCriteriaPrecisionScore.precision * 100}
                      duration={1}
                      decimals={2}
                      suffix="%"
                    />
                  </Tooltip>
                )}
            </div>
          )}
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}
            style={{
              maxWidth: "none",
            }}
            className="criteria-result-container exit"
          >
            <ParsedCriteria
              activeTheme={props.activeTheme}
              indicatorsInfo={indicatorInfo}
              criteria={exitCriteriaMap[selectedCandleSize].join(" ; ")}
            />
          </motion.div>
        </>
      )}
      <div
        className="criteria-generator-row"
        style={{
          justifyContent: "center",
        }}
      >
        <>
          {microtestMode && (
            <CommonButton
              style={{
                alignSelf: "center",
              }}
              id="add-to-case"
              borderTheme="blue-accent"
              leftIcon={<IoCodeWorking />}
              label={"Add to case"}
              activeTheme={props.activeTheme}
              onClick={() => {
                setMicrotestMode(false);
                setAddCriteriaMode(true);
              }}
            />
          )}
          {(microtestMode || addCriteriaMode) && (
            <CommonButton
              style={{
                alignSelf: "center",
              }}
              leftIcon={<FiArrowLeft />}
              label={"Back to suggestions"}
              activeTheme={props.activeTheme}
              onClick={() => {
                setMicrotestMode(false);
                setAddCriteriaMode(false);
              }}
            />
          )}
          {!microtestMode && !addCriteriaMode && (
            <>
              {entryCriteriaMap[selectedCandleSize].length > 0 ||
              exitCriteriaMap[selectedCandleSize].length > 0 ? (
                <>
                  <CommonButton
                    style={{
                      alignSelf: "center",
                    }}
                    id="add-to-case"
                    borderTheme="blue-accent"
                    leftIcon={<IoCodeWorking />}
                    label={"Add to case"}
                    activeTheme={props.activeTheme}
                    onClick={() => {
                      setMicrotestMode(false);
                      setAddCriteriaMode(true);
                    }}
                  />
                  <CommonButton
                    style={{
                      alignSelf: "center",
                    }}
                    id="test-criteria-button"
                    borderTheme="green-accent"
                    leftIcon={<FiPlayCircle />}
                    label={"Test criteria"}
                    activeTheme={props.activeTheme}
                    onClick={() => {
                      setAddCriteriaMode(false);
                      setMicrotestMode(true);
                    }}
                  />
                </>
              ) : undefined}
            </>
          )}
        </>
      </div>
    </div>
  );
}
export type CandleSize = "1D" | "1h" | "5m";

export interface SuggestionResponse {
  calculationProfile: CalculationProfile;
  suggestions: IndicatorSuggestion;
  candle_size_ranges: CandleSizeRanges;
}

export interface CandleSizeRanges {
  [candleSize: string]: {
    start: number;
    stop: number;
  };
}

export interface CriteriaSuggestions {
  calculationProfile: CalculationProfile;
  entryCount: number;
  exitCount: number;
  suggestions: IndicatorSuggestion;
}

export interface IndicatorSuggestion {
  [indicatorName: string]: CandleSizeSuggestions;
}

export interface CandleSizeSuggestions {
  [candleSize: string]: Suggestion;
}
export interface Suggestion {
  count: number;
  entry: string[];
  exit: string[];
}
export interface CollapseMap {
  [key: string]: boolean;
  entry: boolean;
  exit: boolean;
}

export interface CriteriaPrecisionScore {
  candleSize: string;
  criteria: string;
  precision: number;
}
