import { Menu } from "@mantine/core";
import { motion } from "framer-motion";
import { FiCheck } from "react-icons/fi";
import { MdStackedLineChart, MdBarChart } from "react-icons/md";
import { RiGridLine } from "react-icons/ri";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { ChartSettings } from "../../interfaces/chartIndicators/ChartSettings";
import { EMASettings } from "../../interfaces/chartIndicators/EMASettings";
import { MACDSettings } from "../../interfaces/chartIndicators/MACDSettings";
import { RSISettings } from "../../interfaces/chartIndicators/RSISettings";
import { BBANDSettings } from "../../interfaces/chartIndicators/BBANDSettings";
import { useCallback } from "react";
import { MACDControls } from "./indicator-controls/MACDControls";
import { RSIControls } from "./indicator-controls/RSIControls";
import { BBANDControls } from "./indicator-controls/BBANDControls";
import { EMAControls } from "./indicator-controls/EMAControls";
import { ATRControls } from "./indicator-controls/ATRControls";
import { ATRSettings } from "../../interfaces/chartIndicators/ATRSettings";
import { LiaChartAreaSolid } from "react-icons/lia";

interface Props {
  activeTheme: string;
  chartSettings: ChartSettings;
  setChartSettings: (value: React.SetStateAction<ChartSettings>) => void;
  persistChartSettingsInLocalStorage: (_chartSettings: ChartSettings) => void;
  forceUpdate: () => void;
  hideOrders?: boolean;
}
export function ChartControls(props: React.PropsWithChildren<Props>) {
  const anyEmaEnabled =
    props.chartSettings.emaSettings.ema200enabled ||
    props.chartSettings.emaSettings.ema100enabled ||
    props.chartSettings.emaSettings.ema20enabled ||
    props.chartSettings.emaSettings.ema50enabled;

  const handleEmaSettingsChange = useCallback(
    (settings: EMASettings) => {
      props.setChartSettings({
        ...props.chartSettings,
        emaSettings: settings,
      });
      props.persistChartSettingsInLocalStorage(props.chartSettings);
      props.forceUpdate();
    },
    [
      props.forceUpdate,
      props.persistChartSettingsInLocalStorage,
      props.chartSettings,
      props.setChartSettings,
    ]
  );
  const handleMACDSettingsChange = useCallback(
    (settings: MACDSettings) => {
      props.setChartSettings({
        ...props.chartSettings,
        macdSettings: settings,
      });
      props.persistChartSettingsInLocalStorage(props.chartSettings);
      props.forceUpdate();
    },
    [
      props.forceUpdate,
      props.persistChartSettingsInLocalStorage,
      props.chartSettings,
      props.setChartSettings,
    ]
  );

  const handleATRSettingsChange = useCallback(
    (settings: ATRSettings) => {
      props.setChartSettings({
        ...props.chartSettings,
        atrSettings: settings,
      });
      props.persistChartSettingsInLocalStorage(props.chartSettings);
      props.forceUpdate();
    },
    [
      props.forceUpdate,
      props.persistChartSettingsInLocalStorage,
      props.chartSettings,
      props.setChartSettings,
    ]
  );
  const handleRSISettingsChange = useCallback(
    (settings: RSISettings) => {
      props.setChartSettings({
        ...props.chartSettings,
        rsiSettings: settings,
      });
      props.persistChartSettingsInLocalStorage(props.chartSettings);
      props.forceUpdate();
    },
    [
      props.forceUpdate,
      props.persistChartSettingsInLocalStorage,
      props.chartSettings,
      props.setChartSettings,
    ]
  );
  const handleBBANDSettingsChange = useCallback(
    (settings: BBANDSettings) => {
      props.setChartSettings({
        ...props.chartSettings,
        bbSettings: settings,
      });
      props.persistChartSettingsInLocalStorage(props.chartSettings);
      props.forceUpdate();
    },
    [
      props.forceUpdate,
      props.persistChartSettingsInLocalStorage,
      props.chartSettings,
      props.setChartSettings,
    ]
  );

  return (
    <>
      <Menu
        closeOnItemClick={false}
        control={
          <motion.div
            initial={{ opacity: 0.25 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="common-button-container compact"
          >
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
              className="left-icon-container"
            >
              <LiaChartAreaSolid />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
              className="label-container"
            >
              <label>Chart features</label>
            </motion.div>
          </motion.div>
        }
      >
        <Menu.Label>
          <label>Chart</label>
        </Menu.Label>
        {props.chartSettings.orderSettings && !props.hideOrders && (
          <Menu.Item
            onClick={(e: any) => {
              e.stopPropagation();
              if (!props.chartSettings.orderSettings) return;
              props.setChartSettings({
                ...props.chartSettings,
                orderSettings: {
                  enabled: !props.chartSettings.orderSettings.enabled,
                },
              });
            }}
            icon={<CgArrowsExchangeAlt />}
            rightSection={
              props.chartSettings.orderSettings.enabled && <FiCheck />
            }
          >
            <label>Orders</label>
          </Menu.Item>
        )}
        <Menu.Item
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              showVolume: !props.chartSettings.showVolume,
            });
          }}
          icon={<MdBarChart />}
          rightSection={props.chartSettings.showVolume && <FiCheck />}
        >
          <label>Volume</label>
        </Menu.Item>
        <Menu.Item
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              showGrid: !props.chartSettings.showGrid,
            });
          }}
          icon={<RiGridLine />}
          rightSection={props.chartSettings.showGrid && <FiCheck />}
        >
          <label>Grid</label>
        </Menu.Item>
        <Menu.Label>
          <label>TA Indicators</label>
        </Menu.Label>

        <Menu.Item
          icon={<MdStackedLineChart />}
          rightSection={
            props.chartSettings.atrSettings?.atrEnabled && <FiCheck />
          }
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              atrSettings: {
                ...props.chartSettings.atrSettings,
                atrEnabled: !props.chartSettings.atrSettings?.atrEnabled,
              },
            });
          }}
        >
          <label>ATR</label>
        </Menu.Item>
        <Menu.Item
          icon={<MdStackedLineChart />}
          rightSection={
            props.chartSettings.macdSettings.macdEnabled && <FiCheck />
          }
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              macdSettings: {
                ...props.chartSettings.macdSettings,
                macdEnabled: !props.chartSettings.macdSettings.macdEnabled,
              },
            });
          }}
        >
          <label>MACD</label>
        </Menu.Item>

        <Menu.Item
          icon={<MdStackedLineChart />}
          rightSection={
            props.chartSettings.bbSettings.bbandEnabled && <FiCheck />
          }
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              bbSettings: {
                ...props.chartSettings.bbSettings,
                bbandEnabled: !props.chartSettings.bbSettings.bbandEnabled,
              },
            });
          }}
        >
          <label>BBAND</label>
        </Menu.Item>

        <Menu.Item
          icon={<MdStackedLineChart />}
          rightSection={
            props.chartSettings.rsiSettings.rsiEnabled && <FiCheck />
          }
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              rsiSettings: {
                ...props.chartSettings.rsiSettings,
                rsiEnabled: !props.chartSettings.rsiSettings.rsiEnabled,
              },
            });
          }}
        >
          <label>RSI</label>
        </Menu.Item>

        <Menu.Item
          icon={<MdStackedLineChart />}
          rightSection={anyEmaEnabled && <FiCheck />}
          onClick={(e: any) => {
            e.stopPropagation();
            props.setChartSettings({
              ...props.chartSettings,
              emaSettings: {
                ...props.chartSettings.emaSettings,
                ema20enabled: anyEmaEnabled
                  ? false
                  : !props.chartSettings.emaSettings.ema20enabled,
                ema200enabled: anyEmaEnabled
                  ? false
                  : !props.chartSettings.emaSettings.ema200enabled,
                ema100enabled: anyEmaEnabled
                  ? false
                  : !props.chartSettings.emaSettings.ema100enabled,
                ema50enabled: anyEmaEnabled
                  ? false
                  : !props.chartSettings.emaSettings.ema50enabled,
              },
            });
          }}
        >
          <label>EMA</label>
        </Menu.Item>
      </Menu>
      {props.chartSettings.macdSettings.macdEnabled && (
        <MACDControls
          activeTheme={props.activeTheme}
          macdSettings={props.chartSettings.macdSettings}
          handleMACDSettingsChange={handleMACDSettingsChange}
        />
      )}
      {props.chartSettings.atrSettings.atrEnabled && (
        <ATRControls
          activeTheme={props.activeTheme}
          atrSettings={props.chartSettings.atrSettings}
          handleAtrSettingsChange={handleATRSettingsChange}
        />
      )}
      {props.chartSettings.rsiSettings.rsiEnabled && (
        <RSIControls
          activeTheme={props.activeTheme}
          rsiSettings={props.chartSettings.rsiSettings}
          handleRsiSettingsChange={handleRSISettingsChange}
        />
      )}
      {props.chartSettings.bbSettings.bbandEnabled && (
        <BBANDControls
          activeTheme={props.activeTheme}
          bbandSettings={props.chartSettings.bbSettings}
          handleBBANDSettingsChange={handleBBANDSettingsChange}
        />
      )}
      {anyEmaEnabled && (
        <EMAControls
          activeTheme={props.activeTheme}
          emaSettings={props.chartSettings.emaSettings}
          handleEmaSettingsChange={handleEmaSettingsChange}
        />
      )}
      {/* <motion.div layout className="indicator-controls-zone">
        
      </motion.div> */}
    </>
  );
}
