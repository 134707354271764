import jwt_decode from "jwt-decode";
import { UserPermissions } from "../interfaces/UserPermissions";

export const getPermissions = (token: string) => {
  const decodedToken: any = jwt_decode(token);
  const includedPermissions: string[] = decodedToken.permissions;
  return {
    includedPermissions: includedPermissions,
  } as UserPermissions;
};
