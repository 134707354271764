import "./neoton-loader.scss";
import { useContext, useEffect, useMemo } from "react";
import { ApplicationSettingsContext } from "../../App";
import { getTheme } from "../../utils/themeUtil";

interface Props {
  progressInput?: number;
}
export function NeotonLoader(props: React.PropsWithChildren<Props>) {
  const applicationSettings = useContext(ApplicationSettingsContext)
  const activeTheme = applicationSettings?.theme || "dark";
  const theme = useMemo(() => getTheme(activeTheme), [activeTheme]);
  const getRandomFloatBetween0And1 = () => {
    return Math.round(Math.random()) * 0.2;
  };

  const delays = useMemo(
    () =>
      Array(7)
        .fill(0)
        .map((_, i) => getRandomFloatBetween0And1() + 0.1 * ((i % 3) + 1)),
    []
  );

  useEffect(() => {
    const root = document.documentElement;
    if (root) {
      root?.style.setProperty("--color", theme.neoton);

      [
        "--s1-delay",
        "--s2-delay",
        "--s3-delay",
        "--s4-delay",
        "--s5-delay",
        "--s6-delay",
        "--s7-delay",
      ].forEach((property, i) => {
        root.style.setProperty(property, `${delays[i]}s`);
      });
    }
  }, [delays, theme]);

  const progress = props.progressInput || 100;

  return (
    <div className="loader">
      <div className="row">
        <div
          id="s1"
          style={{ filter: `grayscale(${100 - progress * 1}%)` }}
          className="square"
        ></div>
        <div
          id="s2"
          style={{ filter: `grayscale(${100 - progress * 0.8}%)` }}
          className="square"
        ></div>
        <div
          id="s3"
          style={{ filter: `grayscale(${100 - progress * 0.7}%)` }}
          className="square last"
        ></div>
      </div>
      <div className="row">
        <div
          id="s4"
          style={{ filter: `grayscale(${100 - progress * 1}%)` }}
          className="square"
        ></div>
        <div
          id="s5"
          style={{ filter: `grayscale(${100 - progress * 0.8}%)` }}
          className="square"
        ></div>
        <div
          id="s6"
          style={{ filter: `grayscale(${100 - progress * 0.7}%)` }}
          className="square last"
        ></div>
      </div>
      <div className="row">
        <div
          id="s7"
          style={{ filter: `grayscale(${100 - progress * 1}%)` }}
          className="square clear"
        ></div>
        <div
          id="s8"
          style={{ filter: `grayscale(${100 - progress * 0.8}%)` }}
          className="square "
        ></div>
        <div
          id="s9"
          style={{ filter: `grayscale(${100 - progress * 0.7}%)` }}
          className="square last"
        ></div>
      </div>
    </div>
  );
}
