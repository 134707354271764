interface Props {
  index: number;
  leftChild: React.ReactNode;
  rightChild: React.ReactNode;
}
export function NewsMarqueeItem(props: React.PropsWithChildren<Props>) {
  return (
    <div className="news-marquee-item-container">
      {props.leftChild}
      {props.rightChild}
      <div className="marquee-splitter" />
    </div>
  );
}
