import "./common-simulation-info.scss";
import "react-circular-progressbar/dist/styles.css";
import {
  IsBacktestingContext,
  IsStagingContext,
  SimulationInfo,
} from "../../../pages/backtester-page/BacktesterPage";
import { toTimestring } from "../../../utils/FormattingUtils";
import CountUp from "react-countup";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useContext, useEffect, useMemo, useState } from "react";
import { getTheme } from "../../../utils/themeUtil";
import { Tooltip } from "@mantine/core";
import { motion } from "framer-motion";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { TbReportOff } from "react-icons/tb";
interface Props {
  activeTheme: string;
  simulationInfo: SimulationInfo | undefined;
  flushReport?: () => void;
}
export function CommonSimulationInfo(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const isRunning = useContext(IsBacktestingContext);
  const isStaging = useContext(IsStagingContext);

  const executionCount = useMemo(() => {
    return props.simulationInfo?.execution_count ?? 0;
  }, [props.simulationInfo?.execution_count]);

  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const billingStart = useMemo(() => {
    return props.simulationInfo?.billing_start;
  }, [props.simulationInfo?.billing_start]);

  const billingStop = useMemo(() => {
    return props.simulationInfo?.billing_end;
  }, [props.simulationInfo?.billing_end]);

  useEffect(() => {
    if (billingStart && !billingStop) {
      const interval = setInterval(() => {
        setElapsedTime((Date.now() - billingStart) / 1000);
      }, 1000);
      return () => clearInterval(interval);
    }
    if (billingStart && billingStop) {
      setElapsedTime((billingStop - billingStart) / 1000);
    }
  }, [billingStart, billingStop]);

  const elapsedLabel = useMemo(() => {
    // convert seconds into a human readable format
    const hours = Math.floor(elapsedTime / 3600);
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = Math.floor(elapsedTime % 60);
    let label = "";
    if (hours > 0) label += `${hours}h `;
    if (minutes > 0) label += `${minutes}m `;
    if (seconds > 0) label += `${seconds}s`;
    return label;
  }, [elapsedTime]);

  const statusTitle = useMemo(() => {
    if (isStaging) return "Staging...";
    if (!props.simulationInfo?.status)
      return "Press the play button to start the backtest";
    return props.simulationInfo?.status;
  }, [props.simulationInfo?.status, isStaging]);

  const tooltipLabel = useMemo(() => {
    if (isStaging) return "Staging...";
    if (
      props.simulationInfo?.billing_end &&
      props.simulationInfo?.billing_start
    ) {
      return (
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div className="simulation-info-row">
            Started: {toTimestring(props.simulationInfo.billing_start)}
          </div>
          <div className="simulation-info-row">
            Finished: {toTimestring(props.simulationInfo.billing_end)}
          </div>
        </div>
      );
    }
    return "Running...";
  }, [
    props.simulationInfo?.billing_end,
    props.simulationInfo?.billing_start,
    isStaging,
  ]);

  const downloadLabel = useMemo(() => {
    if (!props.simulationInfo?.download_progress) return undefined;
    if (props.simulationInfo.download_progress === 100) return undefined;
    if (props.simulationInfo.download_progress === 0) return undefined;
    return `${props.simulationInfo.download_progress}%`;
  }, [props.simulationInfo]);

  return (
    <div className="simulation-info-container">
      <div className="simulation-info-row">
        <label>{statusTitle}</label>
      </div>
      <motion.div
        className="simulation-info-row"
        initial={{ opacity: 0 }}
        animate={{
          opacity: isStaging || !props.simulationInfo?.status_message ? 0 : 1,
        }}
        transition={{ delay: 1 }}
      >
        <label className="dimmed-label">
          {props.simulationInfo?.status_message}
        </label>
        {downloadLabel && (
          <label className="dimmed-label">{downloadLabel}</label>
        )}
      </motion.div>

      <div className="simulation-progress-container">
        {(isRunning || isStaging) &&
        (!props.simulationInfo?.progress ||
          !props.simulationInfo?.billing_start) ? (
          <NeotonLoader />
        ) : (
          <>
            {props.simulationInfo?.billing_start && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: isStaging ? 0 : 1 }}
                transition={{ delay: 1 }}
                className="simulation-info-row"
              >
                <label>Elapsed:</label>
                <label>{elapsedLabel}</label>
              </motion.div>
            )}

            {props.simulationInfo && props.simulationInfo.progress && (
              <Tooltip
                className="circular-progress-container"
                label={tooltipLabel}
                position="left"
              >
                <CircularProgressbarWithChildren
                  value={props.simulationInfo.progress}
                  styles={buildStyles({
                    strokeLinecap: theme.card,
                    trailColor: theme.cardInterior,
                    pathColor: theme.cardInteriorHover,
                  })}
                  children={
                    <CountUp
                      className={"wallet-stat-label papertrader-duration-label"}
                      end={props.simulationInfo.progress}
                      preserveValue
                      start={0}
                      duration={1}
                      suffix="%"
                      decimals={0}
                    />
                  }
                />
              </Tooltip>
            )}
          </>
        )}
        {props.simulationInfo?.execution_count && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isStaging ? 0 : 1 }}
            transition={{ delay: 1 }}
            className="simulation-info-row"
          >
            <label>Execution count:</label>
            <CountUp
              className={"wallet-stat-label papertrader-duration-label"}
              end={executionCount}
              preserveValue
              start={0}
              duration={2}
              decimals={0}
            />
          </motion.div>
        )}
      </div>
      {!isRunning &&
        !isStaging &&
        props.simulationInfo?.billing_start !== undefined &&
        props.flushReport && (
          <div className="simulation-info-row">
            <CommonButton
              activeTheme={props.activeTheme}
              label={"Clear report"}
              leftIcon={<TbReportOff />}
              onClick={props.flushReport}
            />
          </div>
        )}
    </div>
  );
}
