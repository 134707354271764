import { Chip, ColorSwatch, Modal, Popover, Space, Text } from "@mantine/core";
import { useCallback, useState } from "react";
import { VscSettings } from "react-icons/vsc";
import { MACDSettings } from "../../../interfaces/chartIndicators/MACDSettings";
import { CustomColorPicker } from "./CustomColorPicker";
import { MotionIndicatorButton } from "./MotionButton";

interface Props {
  activeTheme: string;
  macdSettings: MACDSettings;
  handleMACDSettingsChange: (macdSettings: MACDSettings) => void;
}
export function MACDControls(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);
  const [colorPickerOpened, setColorPickerOpened] = useState(false);

  const [currentColorToChangeValue, setCurrentColorToChangeValue] =
    useState<string>(props.macdSettings.appearance.strokeStyle.macd);

  const [currentFieldToChange, setCurrentFieldToChange] = useState<
    "macd" | "signal" | "divergence"
  >("macd");

  const color = props.activeTheme === "light" ? "dark" : "gray";

  const handleMacdToggle = useCallback(
    (toggled: boolean) => {
      props.macdSettings.macdEnabled = toggled;
      props.handleMACDSettingsChange(props.macdSettings);
    },
    [props]
  );

  const handleColorToChange = useCallback(
    (color: string) => {
      switch (currentFieldToChange) {
        case "macd":
          props.macdSettings.appearance.strokeStyle.macd = color;
          break;
        case "signal":
          props.macdSettings.appearance.strokeStyle.signal = color;
          break;
        case "divergence":
          props.macdSettings.appearance.fillStyle.divergence = color;
          break;
        default:
          break;
      }
      setColorPickerOpened(false);
      props.handleMACDSettingsChange(props.macdSettings);
    },
    [currentFieldToChange, props, setColorPickerOpened]
  );

  const handleColorPickerOpen = useCallback(
    (fieldToUpdate: "macd" | "signal" | "divergence") => {
      setColorPickerOpened(true);
      setCurrentFieldToChange(fieldToUpdate);
      switch (fieldToUpdate) {
        case "macd":
          setCurrentColorToChangeValue(
            props.macdSettings.appearance.strokeStyle.macd
          );
          break;
        case "signal":
          setCurrentColorToChangeValue(
            props.macdSettings.appearance.strokeStyle.signal
          );
          break;
        case "divergence":
          setCurrentColorToChangeValue(
            props.macdSettings.appearance.fillStyle.divergence
          );
          break;
        default:
          break;
      }
    },
    [
      props.macdSettings.appearance.strokeStyle.macd,
      props.macdSettings.appearance.strokeStyle.signal,
      props.macdSettings.appearance.fillStyle.divergence,
      setColorPickerOpened,
      setCurrentFieldToChange,
    ]
  );

  return (
    <>
      <Popover
        opened={opened}
        clickOutsideEvents={colorPickerOpened ? ["onClick"] : undefined}
        onClose={() => setOpened(false)}
        transition="slide-up"
        position="bottom"
        target={
          <MotionIndicatorButton
            onClick={(e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            }}
          >
            <VscSettings size={12} />

            <label>MACD</label>
          </MotionIndicatorButton>
        }
        className="indicator-popover"
      >
        <div className="indicator-popover-outer-container">
          <Text className="header" color={"dimmed"}>
            Moving Average Convergence Divergence
          </Text>
          <div className="indicator-popover-container">
            <Space h={"xs"} />
            <div className="indicator-popover-row">
              <Chip
                checked={props.macdSettings.macdEnabled}
                color={color}
                variant="outline"
                value="MACD Enabled"
                onChange={handleMacdToggle}
              >
                toggled
              </Chip>
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                MACD (26)
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("macd")}
                style={{ cursor: "pointer" }}
                color={props.macdSettings.appearance.strokeStyle.macd}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                Signal (9)
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("signal")}
                style={{ cursor: "pointer" }}
                color={props.macdSettings.appearance.strokeStyle.signal}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                Divergence (hist)
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("divergence")}
                style={{ cursor: "pointer" }}
                color={props.macdSettings.appearance.fillStyle.divergence}
                size={15}
                radius="sm"
              />
            </div>
          </div>
        </div>
      </Popover>
      <Modal
        opened={colorPickerOpened}
        onClose={() => setColorPickerOpened(false)}
      >
        <CustomColorPicker
          currentColor={currentColorToChangeValue}
          handleColorChange={handleColorToChange}
        />
      </Modal>
    </>
  );
}
