import { BBANDSettings } from "../../../interfaces/chartIndicators/BBANDSettings";
import { FiX } from "react-icons/fi";
import { MotionIndicatorButton } from "./MotionButton";

interface Props {
  activeTheme: string;
  bbandSettings: BBANDSettings;
  handleBBANDSettingsChange: (settings: BBANDSettings) => void;
}
export function BBANDControls(props: React.PropsWithChildren<Props>) {
  return (
    <MotionIndicatorButton
      onClick={(e: any) => {
        e.stopPropagation();
        const newSettings = props.bbandSettings;
        newSettings.bbandEnabled = !newSettings.bbandEnabled;
        props.handleBBANDSettingsChange(newSettings);
      }}
    >
      <FiX size={12} />

      <label>BBAND</label>
    </MotionIndicatorButton>
  );
}
