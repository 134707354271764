import "./common-strategy-select.scss";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { UseQueryResult } from "react-query";
import { CustomResponse } from "../../../interfaces/Response";
import {
  LiveStrategies,
  UserStrategyItem,
} from "../../../interfaces/common/UserStrategies";
import { motion } from "framer-motion";
import { toUTCTimestring } from "../../../utils/FormattingUtils";
import { Popover, Space, Tooltip } from "@mantine/core";
import { CommonVersionsSelect } from "./CommonVersionsSelect";
import { useCallback, useContext, useState } from "react";
import { IoIosCodeDownload } from "react-icons/io";
import { UserLimitationsContext } from "../../../App";

import { Limitation } from "../../../interfaces/common/UserLimitations";
interface Props {
  activeTheme: string;
  traderType: TraderType;
  compact?: boolean;
  toggleBottom?: () => void;
  strategiesQuery: UseQueryResult<
    | CustomResponse<{
        [key: string]: UserStrategyItem[];
      }>
    | undefined,
    unknown
  >;
  liveStrategiesQuery: UseQueryResult<
    CustomResponse<LiveStrategies[]> | undefined,
    unknown
  >;
  selectedStrategyVersion: string | undefined;
  setSelectedStrategyVersion?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  attachStrategy: (strategyId: string, versionId: string) => void;
  tradingAccountSetup?: boolean;
  newStrategyPopupMode?: boolean;
  height?: number;
  disableButtons?: boolean;
  closeExistingDrawer?: () => void;
}
export function CommonStrategySelectBody(
  props: React.PropsWithChildren<Props>
) {
  const [selectedVersionId, setSelectedVersionId] = useState<
    string | undefined
  >();

  const limitation: Limitation | undefined = useContext(UserLimitationsContext);

  const getDetailsFromFirstEntry = (entry: UserStrategyItem) => {
    return {
      created_on: entry.created_on,
      name: entry.name,
    } as StrategyOverviewDetails;
  };
  const sortStrategiesByLatestVersion = (strategies: {
    [key: string]: UserStrategyItem[];
  }) => {
    const strategyDates: { [key: string]: number } = {};
    Object.keys(strategies).forEach((key) => {
      if (strategies[key].length === 0) return;
      const firstEntry = getDetailsFromFirstEntry(strategies[key][0]);
      strategyDates[key] = firstEntry.created_on;
    });
    const sortedKeys = Object.keys(strategyDates).sort((a, b) => {
      return strategyDates[a] - strategyDates[b];
    });
    const sortedStrategies: { [key: string]: UserStrategyItem[] } = {};
    sortedKeys.reverse().forEach((key) => {
      sortedStrategies[key] = strategies[key];
    });
    return sortedStrategies;
  };

  const liveStrategyIds =
    props.liveStrategiesQuery.data?.payload?.map((x) => x.strategy_id) || [];

  const sortedStrategies = props.strategiesQuery.data?.payload
    ? sortStrategiesByLatestVersion(props.strategiesQuery.data?.payload)
    : undefined;

  const getLivetradingVersionIds = useCallback(
    (strategyId: string) => {
      if (!props.liveStrategiesQuery.data?.payload) return [];
      return (
        props.liveStrategiesQuery.data?.payload
          ?.filter((x) => x.strategy_id === strategyId)
          .map((x) => x.version_id) || []
      );
    },
    [props.liveStrategiesQuery]
  );

  return (
    <div
      className="common-strategy-select-container"
      style={{
        height: props.height ? props.height : undefined,
        maxWidth:
          props.tradingAccountSetup || props.disableButtons
            ? "100px"
            : undefined,
      }}
    >
      <div
        style={{
          maxWidth:
            props.tradingAccountSetup || props.disableButtons
              ? "400px"
              : undefined,
        }}
        className="common-strategy-select-container-header"
      >
        {props.compact && props.toggleBottom && (
          <>
            <IoIosCodeDownload
              className="dimmed-label rotatable-icon"
              size={30}
              onClick={() => {
                props.toggleBottom?.();
              }}
            />
            <label className="dimmed-label">Hide</label>
          </>
        )}
        <Space w={"xl"} />
      </div>
      {sortedStrategies &&
        Object.keys(sortedStrategies).map((key, index) => {
          const version_count = sortedStrategies[key].length || 0;
          if (version_count === 0) return undefined;
          let details: StrategyOverviewDetails | undefined = undefined;

          if (sortedStrategies) {
            details = getDetailsFromFirstEntry(sortedStrategies[key][0]);
          }

          return (
            <Popover
              width={400}
              key={index}
              opened={props.selectedStrategyVersion === key}
              onClose={() => {
                setSelectedVersionId(undefined);
                if (props.selectedStrategyVersion !== key) {
                  return;
                }
                props.setSelectedStrategyVersion &&
                  props.setSelectedStrategyVersion(undefined);
              }}
              withArrow
              position={
                props.compact
                  ? "bottom"
                  : props.tradingAccountSetup
                  ? "left"
                  : "right"
              }
              placement={props.compact ? undefined : "start"}
              transition="pop-top-left"
              classNames={{
                popover: "versions-popup-container",
                body: "versions-popup-body",
                wrapper: "versions-popup-wrapper",
                inner: "versions-popup-inner",
                arrow: "versions-popup-arrow",
                root: "versions-popup-root",
                target: "versions-popup-target",
              }}
              target={
                <div className="common-strategy-select-item-outer">
                  <motion.div
                    key={index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.01 }}
                    className={
                      "common-strategy-select-item" +
                      (props.selectedStrategyVersion === key ? " selected" : "")
                    }
                    onClick={() => {
                      setSelectedVersionId(undefined);
                      if (props.selectedStrategyVersion === key) {
                        props.setSelectedStrategyVersion &&
                          props.setSelectedStrategyVersion(undefined);
                        return;
                      }
                      props.setSelectedStrategyVersion &&
                        props.setSelectedStrategyVersion(key);
                    }}
                  >
                    {details && (
                      <label className="common-strategy-item-name">
                        {details.name}
                      </label>
                    )}
                    <label className="common-strategy-version-label">
                      {"<"}
                      {version_count}
                      {">"}
                    </label>
                    {details && (
                      <label className="dimmed-label">
                        Edited: {toUTCTimestring(details.created_on, "1h")}
                      </label>
                    )}
                    {
                      <Tooltip label="This strategy is currently in use for live trading">
                        <motion.div
                          className="common-strategy-live-trading-indicator"
                          id={
                            liveStrategyIds.includes(key)
                              ? ""
                              : "hidden-indicator"
                          }
                        ></motion.div>
                      </Tooltip>
                    }
                  </motion.div>
                </div>
              }
            >
              <CommonVersionsSelect
                activeTheme={props.activeTheme}
                versionItems={sortedStrategies[key]}
                strategyId={key}
                traderType={props.traderType}
                selectedVersionId={selectedVersionId}
                setSelectedVersionId={setSelectedVersionId}
                refetch={() => props.strategiesQuery.refetch()}
                livetradingVersionIds={getLivetradingVersionIds(key)}
                attachStrategy={props.attachStrategy}
                tradingAccountSetup={props.tradingAccountSetup}
                newStrategyPopupMode={props.newStrategyPopupMode}
                strategyName={sortedStrategies[key][0].name}
                strategyIsLive={liveStrategyIds.includes(key)}
                limitations={limitation}
              />
            </Popover>
          );
        })}
      {sortedStrategies && Object.keys(sortedStrategies).length < 1 && (
        <label className="dimmed-label">
          It appears that you do not have any trading strategies yet...
        </label>
      )}
    </div>
  );
}

interface StrategyOverviewDetails {
  name: string;
  created_on: number;
  versionCount: number;
}
