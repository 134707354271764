import { getTheme } from "../../utils/themeUtil";
import "./pixel-loader.scss";

interface Props {
  activeTheme: string;
  gray?: boolean;
  dark?: boolean;
}
export function NeotonSpinner(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const root = document.documentElement;

  root?.style.setProperty("--loader-color", props.dark ? theme.background2 : theme.neoton);
  return (
    <div className="circle-loader-container" id={props.gray ? "gray" : ""}>
      <div className="loader-18"></div>
    </div>
  );
}
