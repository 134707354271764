import { ChartSettings } from "../interfaces/chartIndicators/ChartSettings";
import { EMASettings } from "../interfaces/chartIndicators/EMASettings";
import { MACDSettings } from "../interfaces/chartIndicators/MACDSettings";
import { StrategyVersion } from "../interfaces/strategyInterfaces/Strategy";
import { ApplicationSettings } from "../types/ApplicationSettings";

/**
 *
 *
 * @param {string} storageName
 * @return {*}  {(string | null)}
 */
function readFromLocalStorage(storageName: string): string | null {
  const item = localStorage.getItem(storageName);
  if (item === null) {
    // only log when in development
    if (process.env.NODE_ENV === "development")
      console.debug(`Item ${storageName} does not exist in local storage`);
  }
  return item;
}

/**
 * Returns cached payload from Application Storage
 *
 * @param {string} storageName
 * @return {*}  {*}
 */
export const getCachedPayload = (storageName: string): any => {
  const cachedData: string | null = readFromLocalStorage(storageName);
  if (cachedData) {
    return JSON.parse(cachedData)["payload"];
  }
};

// holds all of the card ids
export const homepageCardIds: { [key: string]: string } = {
  news: "News",
  membership: "Account",
  tickers: "Tickers",
  get_started: "Tutorials",
  strategies: "Strategies",
  papertrader_leaderboard: "Papertrader leaderboard",
  trading_accounts: "Trading Accounts",
};
export const noRolesHomepageCardIds: { [key: string]: string } = {
  news: "News",
  membership: "Account",
  tickers: "Tickers",
  get_started: "Tutorials",
};

/**
 *
 *
 * @return {*}  {ApplicationSettings}
 */
export const tryGetApplicationSettings = (
  user_id?: string
): ApplicationSettings => {
  const applicationSettingsKey = user_id
    ? `ApplicationSettings_${user_id}`
    : "ApplicationSettings";
  const storedApplicationSettings = readFromLocalStorage(
    applicationSettingsKey
  );
  if (storedApplicationSettings) {
    const _applicationSettings = JSON.parse(
      storedApplicationSettings
    ) as ApplicationSettings;
    if (_applicationSettings.dashboardLayout === undefined) {
      _applicationSettings.dashboardLayout = {
        left: ["news", "membership", "tickers"],
        middle: ["get_started", "strategies"],
        right: ["papertrader_leaderboard", "trading_accounts"],
      };
    }
    if (_applicationSettings.dashboardLayoutIsLocked === undefined) {
      _applicationSettings.dashboardLayoutIsLocked = false;
    }
    if (_applicationSettings.particlesSettings === undefined) {
      _applicationSettings.particlesSettings = {
        amount: 50,
        enabled: false,
      };
    }
    if (_applicationSettings.enableTutorial === undefined) {
      _applicationSettings.enableTutorial = true;
    }
    return _applicationSettings;
  }
  const applicationSettings: ApplicationSettings = {
    theme: "dark",
    particlesSettings: {
      amount: 0,
      enabled: false,
    },
    dashboardLayout: {
      left: ["news", "membership", "tickers"],
      middle: ["get_started", "strategies"],
      right: ["papertrader_leaderboard", "trading_accounts"],
    },
    dashboardLayoutIsLocked: false,
    enableTutorial: true,
  };
  localStorage.setItem(
    applicationSettingsKey,
    JSON.stringify(applicationSettings)
  );
  return applicationSettings;
};

const getDefaultEMASettings = (): EMASettings => {
  return {
    ema20Color: "#00ffb3",
    ema20enabled: false,
    ema50Color: "#f2ff00",
    ema50enabled: false,
    ema100Color: "#ff4400",
    ema100enabled: false,
    ema200Color: "#e61e60",
    ema200enabled: false,
  };
};

const getDefaultMACDSettings = (): MACDSettings => {
  return {
    macdEnabled: false,
    appearance: {
      strokeStyle: {
        macd: "#0093FF",
        signal: "#D84315",
        zero: "rgba(0, 0, 0, 0.3)",
      },
      fillStyle: {
        divergence: "#4682B4",
      },
    },
  };
};

const getDefaultRSISettings = () => {
  return {
    rsiEnabled: false,
    outsideThresholdColor: "#e61e60",
    insideThresholdColor: "#b6456a",
  };
};

const getDefaultATRSettings = () => {
  return {
    atrEnabled: false,
    atrColor: "#ff203d",
  };
};

export const tryGetStoredChartSettings = (): ChartSettings => {
  const storedChartSettings = readFromLocalStorage("ChartSettings");
  if (!storedChartSettings) {
    const emaSettings = getDefaultEMASettings();
    const macdSettings = getDefaultMACDSettings();
    const rsiSettings = getDefaultRSISettings();
    const atrSettings = getDefaultATRSettings();
    const chartSettings: ChartSettings = {
      emaSettings: emaSettings,
      macdSettings: macdSettings,
      showVolume: true,
      showGrid: false,
      rsiSettings: rsiSettings,
      atrSettings: atrSettings,
      bbSettings: {
        bbandEnabled: false,
      },
    };
    localStorage.setItem("ChartSettings", JSON.stringify(chartSettings));
    return chartSettings;
  }
  // ensure that all of the fields are present
  const parsedChartSettings = JSON.parse(storedChartSettings);
  const emaSettings = parsedChartSettings.emaSettings
    ? parsedChartSettings.emaSettings
    : getDefaultEMASettings();
  const macdSettings = parsedChartSettings.macdSettings
    ? parsedChartSettings.macdSettings
    : getDefaultMACDSettings();
  const rsiSettings = parsedChartSettings.rsiSettings
    ? parsedChartSettings.rsiSettings
    : getDefaultRSISettings();
  const atrSettings = parsedChartSettings.atrSettings
    ? parsedChartSettings.atrSettings
    : getDefaultATRSettings();
  const bbSettings = parsedChartSettings.bbSettings
    ? parsedChartSettings.bbSettings
    : { bbandEnabled: false };
  const chartSettings: ChartSettings = {
    emaSettings: emaSettings,
    macdSettings: macdSettings,
    showVolume: parsedChartSettings.showVolume,
    showGrid: parsedChartSettings.showGrid,
    rsiSettings: rsiSettings,
    atrSettings: atrSettings,
    bbSettings: bbSettings,
  };
  localStorage.setItem("ChartSettings", JSON.stringify(chartSettings));
  return chartSettings;
};

/**
 *
 *
 * @param {ApplicationSettings} applicationSettings
 */
export const persistApplicationSettings = (
  applicationSettings: ApplicationSettings
): void => {
  localStorage.setItem(
    "ApplicationSettings",
    JSON.stringify(applicationSettings)
  );
};

export const persistChartSettings = (chartSettings: ChartSettings): void => {
  localStorage.setItem("ChartSettings", JSON.stringify(chartSettings));
};

export const tryGetStoredStrategy = (): StrategyVersion | undefined => {
  const storedStrategy = readFromLocalStorage("Strategy");
  if (!storedStrategy) {
    localStorage.removeItem("Strategy");
    return;
  }
  return JSON.parse(storedStrategy) as StrategyVersion;
};
export const tryGetUnsavedStrategy = (): StrategyVersion | undefined => {
  const storedStrategy = readFromLocalStorage("unsavedStrategy");
  if (!storedStrategy) {
    localStorage.removeItem("unsavedStrategy");
    return;
  }
  return JSON.parse(storedStrategy) as StrategyVersion;
};

export const tryDeleteUnsavedStrategy = (): void => {
  localStorage.removeItem("unsavedStrategy");
};
