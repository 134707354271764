import { Currency } from "../interfaces/Currency";

export const getCurrencyUrl = (currency: Currency): string => {
  return `https://neoton-media.azureedge.net/currency-assets/${currency.slug}.png`;
};

export const getProfilePictureUrl = (userHash: string): string => {
  return `https://neoton-media.azureedge.net/profile-pics/${userHash}.jpg`;
};

export const getExchangeLogoUrl = (
  exchange_id: string,
  activeTheme: string = "dark"
) => {
  const theme = activeTheme === "light" ? "light" : "dark"; // night mode is also using dark theme
  const neotonPrefix = 'NTexchange'
  return `https://neoton-media.azureedge.net/currency-assets/${neotonPrefix}-${exchange_id}-${theme}.png`;
};

export const getBaseCurrencyUrl = (baseCurrency: BaseCurrencyType | undefined) => {
  if (!baseCurrency) return '';
  const baseCurrencySlug = baseCurrency === 'USD' ? 'fiat-USD' : 'tether';
  return `https://neoton-media.azureedge.net/currency-assets/${baseCurrencySlug}.png`;
}

export const getStarterProfilePicUrl = (name: string) => {
  return `https://neoton-media.azureedge.net/starter-profile-pics/${name}`;
}

export type BaseCurrencyType = 'USD' | 'USDT';