import axios, { AxiosInstance } from "axios";
import { createOptions } from "./CreateOptions";
import BlueprintService from "./BlueprintService";
import {
  StrategyESResults,
  UserESResults,
} from "../interfaces/elasticsearch/ElasticSearchResults";

const http = axios.create({
  baseURL: process.env.REACT_APP_ELASTICMANAGER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class ElasticManagerServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getFeaturedStrategies = async (token: string) => {
    const options = createOptions(token);
    return await http.get<StrategyESResults>(`/featured-strategies`, options);
  };

  public searchUsernames = async (token: string, username: string) => {
    const options = createOptions(token);
    const body = {
      query: username,
    };
    return await http.post<UserESResults>(`/search-user`, body, options);
  };

  public searchUserStrategies = async (token: string, strategyName: string) => {
    const options = createOptions(token);
    const body = {
      query: strategyName,
    };
    return await http.post<StrategyESResults>(
      `/search-user-strategies`,
      body,
      options
    );
  };

  public searchPublicStrategies = async (
    token: string,
    strategyName: string
  ) => {
    const options = createOptions(token);
    const body = {
      query: strategyName,
    };
    return await http.post<StrategyESResults>(
      `/search-public-strategies`,
      body,
      options
    );
  };
}

export const ElasticManagerService = new ElasticManagerServiceClass(http);
