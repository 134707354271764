import { useState, useCallback, useEffect } from "react";
import {
  StrategyCaseState,
  CaseCriteria,
  StrategyCaseActionLevel,
} from "../../../../interfaces/backtester/CaseState";
import { CommonWalletReport } from "../../../../interfaces/common-trading/CommonTradingReport";
import { Currency } from "../../../../interfaces/Currency";
import { Avatar, Tooltip } from "@mantine/core";
import { ActionIndicatorTooltip } from "./ActionIndicatorTooltip";
import { motion } from "framer-motion";
import { getTheme } from "../../../../utils/themeUtil";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { getCurrencyUrl } from "../../../../utils/cdnUtil";

interface Props {
  activeTheme: string;
  isHolding: boolean;
  currency: Currency;
  commonWalletReport?: CommonWalletReport;
  compact?: boolean;
}

export function ActionIndicator(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const [strategyCaseActionLevel, setStrategyCaseActionLevel] = useState<
    StrategyCaseActionLevel[] | undefined
  >();

  const [strategyCaseStates, setStrategyCaseStates] = useState<
    StrategyCaseState[] | undefined
  >();

  const [actionIndicatorLevel, setActionIndicatorLevel] = useState<number>(0);

  const calculateActionIndicatorLevel = useCallback(
    (StrategyCaseStates: StrategyCaseState[], isHolding: boolean) => {
      const _caseActionLevelStates: StrategyCaseActionLevel[] = [];

      StrategyCaseStates.forEach((strategyCaseState) => {
        let actionIndicatorLevel = 0;
        let division = 1;
        if (isHolding) {
          if (strategyCaseState.exit_criteria_1D?.caseCriterias.length !== 0) {
            division += 1;
          }
          if (strategyCaseState.exit_criteria_1h?.caseCriterias.length !== 0) {
            division += 1;
          }
          if (strategyCaseState.exit_criteria_5m?.caseCriterias.length !== 0) {
            division += 1;
          }
          division = division > 0 ? division : 1;

          let multiplier = 100 / (division - 1);

          if (strategyCaseState.exit_criteria_1D?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
          if (strategyCaseState.exit_criteria_1h?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
          if (strategyCaseState.exit_criteria_5m?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
        } else {
          if (strategyCaseState.entry_criteria_1D?.caseCriterias.length !== 0) {
            division += 1;
          }
          if (strategyCaseState.entry_criteria_1h?.caseCriterias.length !== 0) {
            division += 1;
          }
          if (strategyCaseState.entry_criteria_5m?.caseCriterias.length !== 0) {
            division += 1;
          }
          division = division > 0 ? division : 1;
          let multiplier = 100 / (division - 1);

          if (strategyCaseState.entry_criteria_1D?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
          if (strategyCaseState.entry_criteria_1h?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
          if (strategyCaseState.entry_criteria_5m?.hasFullfilled) {
            actionIndicatorLevel += multiplier;
          }
        }
        _caseActionLevelStates.push({
          caseName: strategyCaseState.caseName,
          actionLevel: actionIndicatorLevel,
        });
      });

      setStrategyCaseActionLevel(_caseActionLevelStates);
      return Math.max.apply(
        Math,
        _caseActionLevelStates.map(function (o) {
          return o.actionLevel;
        })
      );
    },
    [setStrategyCaseActionLevel]
  );

  const setActionIndicatorLevelFromTradingReport = useCallback(
    (_tradingReport?: CommonWalletReport) => {
      if (!_tradingReport) return undefined;
      const criteriaType = props.isHolding ? "exit_criteria" : "entry_criteria";
      const _strategyCaseStates: StrategyCaseState[] = [];
      if (_tradingReport === undefined || _tradingReport === null) return;
      try {
        const CaseNames = Object.keys(
          _tradingReport?.report?.actionState[props.currency.currency_name]
        );
        CaseNames.forEach((caseName) => {
          if (
            _tradingReport.report?.actionState[props.currency.currency_name][
              caseName
            ]
          ) {
            if (props.isHolding) {
              const currentHolding = _tradingReport.report?.holdings
                ?.holding_list
                ? _tradingReport.report.holdings.holding_list.find(
                    (holding) =>
                      holding.currency_name === props.currency.currency_name
                  )
                : undefined;
              if (currentHolding && currentHolding.case !== caseName) return;
            }
            const strategyCaseState: StrategyCaseState = {
              caseName: caseName,
            };
            const candleSizes = Object.keys(
              _tradingReport.report?.actionState[props.currency.currency_name][
                caseName
              ][criteriaType]
            ).filter((key) => !key.includes("pending"));
            candleSizes.forEach((candleSize) => {
              const caseStateItems: CaseCriteria[] = [];
              let isFullfilled = false;
              const criterias = Object.keys(
                _tradingReport?.report?.actionState[
                  props.currency.currency_name
                ][caseName][criteriaType][candleSize]
              );
              criterias.forEach((criterion) => {
                const isFulfilled =
                  _tradingReport.report?.actionState[
                    props.currency.currency_name
                  ][caseName][criteriaType][candleSize][criterion];
                caseStateItems.push({
                  criteria: criterion,
                  fulfilled: isFulfilled,
                });
                if (isFulfilled) {
                  isFullfilled = true;
                }
              });
              if (candleSize === "candle_size_1D") {
                if (props.isHolding) {
                  strategyCaseState.exit_criteria_1D = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                } else {
                  strategyCaseState.entry_criteria_1D = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                }
              }
              if (candleSize === "candle_size_1h") {
                if (props.isHolding) {
                  strategyCaseState.exit_criteria_1h = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                } else {
                  strategyCaseState.entry_criteria_1h = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                }
              }
              if (candleSize === "candle_size_5m") {
                if (props.isHolding) {
                  strategyCaseState.exit_criteria_5m = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                } else {
                  strategyCaseState.entry_criteria_5m = {
                    caseCriterias: caseStateItems,
                    hasFullfilled: isFullfilled,
                  };
                }
              }
            });
            _strategyCaseStates.push(strategyCaseState);
          }
        });
      } catch (err: any) {}
      setStrategyCaseStates(_strategyCaseStates);

      setActionIndicatorLevel(
        calculateActionIndicatorLevel(_strategyCaseStates, props.isHolding)
      );
    },
    [
      props.currency.currency_name,
      props.isHolding,
      setStrategyCaseStates,
      calculateActionIndicatorLevel,
      setActionIndicatorLevel,
    ]
  );

  useEffect(() => {
    if (props.commonWalletReport?.report?.actionState) {
      setActionIndicatorLevelFromTradingReport(props.commonWalletReport);
    }
    if (!props.commonWalletReport?.report?.actionState) {
      setActionIndicatorLevel(0);
    }
  }, [
    setActionIndicatorLevelFromTradingReport,
    setActionIndicatorLevel,
    props.commonWalletReport,
  ]);

  const barCount = 6;

  // convert actionIndicatorLevel to barCount
  // 0-100 -> 0-6
  const barCountFromActionIndicatorLevel = Math.round(
    (actionIndicatorLevel / 100) * barCount
  );

  const color = props.isHolding ? theme.deficitLabel : theme.profitLabel;

  return (
    <>
      <Tooltip
        key={props.currency.currency_name}
        className="action-indicator-tooltip"
        label={
          strategyCaseActionLevel &&
          strategyCaseStates && (
            <ActionIndicatorTooltip
              key={props.currency.currency_name}
              activeTheme={props.activeTheme}
              criteriaType={props.isHolding ? "exit" : "entry"}
              strategyCaseActionLevels={strategyCaseActionLevel}
              strategyCaseStates={strategyCaseStates}
              currencyName={props.currency.currency_name}
            />
          )
        }
        classNames={{
          root: "action-indicator-root-class",
          arrow: "action-indicator-arrow-class",
          body: "action-indicator-body-class",
        }}
        withArrow
        width={"auto"}
        disabled={actionIndicatorLevel === 0}
        position={props.compact ? "top" : "right"}
      >
        {props.compact ? (
          <div className="compact-action-indicator">
            <CircularProgressbarWithChildren
              value={actionIndicatorLevel}
              counterClockwise={true}
              styles={buildStyles({
                strokeLinecap: theme.card,
                trailColor: "transparent",
                pathColor: props.isHolding
                  ? theme.sellOrderStrokeHover
                  : theme.buyOrderStrokeHover,
              })}
              children={
                <Avatar
                  size={"sm"}
                  src={getCurrencyUrl(props.currency)}
                  radius={"xl"}
                />
              }
            />
          </div>
        ) : (
          <div className="new-action-indicator-container">
            {
              // place 6 bars
              Array.from(Array(barCount).keys()).map((barIndex) => {
                return (
                  <motion.div
                    key={barIndex}
                    className={
                      "new-action-indicator-bar" +
                      (barIndex < barCountFromActionIndicatorLevel
                        ? props.isHolding
                          ? " indicating-exit"
                          : " indicating-entry"
                        : "")
                    }
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      boxShadow:
                        barIndex < barCountFromActionIndicatorLevel
                          ? `.1px .2px ${
                              barIndex + 2
                            }px ${color}, .1px .1px .01px ${color}`
                          : "0px 0px 0px rgba(0, 0, 0, 0)", // Updated value
                    }}
                    exit={{ opacity: 0 }}
                  />
                );
              })
            }
          </div>
        )}
      </Tooltip>
    </>
  );
}
