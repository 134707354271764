import { motion } from "framer-motion";
import { useCallback, useContext, useState } from "react";
import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { Identicon } from "../../common/identicon/Identicon";
import { TbReportAnalytics, TbShare } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { GiBackwardTime } from "react-icons/gi";
import { AiOutlineCode } from "react-icons/ai";
import { IoIosMore } from "react-icons/io";
import { StrategyManagerService } from "../../../services/StrategyManagerService";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { BiHide, BiShow } from "react-icons/bi";
import { ToastContext } from "../../../App";
import { StrategyVersionIcon } from "../../common/identicon/StrategyVersionIcon";

interface Props {
  activeTheme: string;
  strategyId: string;
  selectedVersionIds: string[];
  setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  refetchStrategies?: () => void;
  featuredButton?: FeaturedButton;
  setExpandedId?: (id: string | undefined) => void;
}
export function StrategyOwnerMenu(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const triggerToast = useContext(ToastContext);
  const [toggling, setToggling] = useState(false);

  const navigate = useNavigate();
  const iconSize = 22;

  const fetchStrategyMetadata = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || !props.strategyId) return;
    const response = await StrategyManagerService.blueprint.getStrategyMetadata(
      token,
      props.strategyId
    );
    return response.data;
  }, [props.strategyId]);

  const strategyMetadataQuery = useQuery(
    ["StrategyMetadata", props.strategyId],
    fetchStrategyMetadata,
    {
      cacheTime: 60000,
      staleTime: 0,
      enabled: props.strategyId ? true : false,
    }
  );

  const handleHideFromLeaderboard = useCallback(
    async (toggle: boolean) => {
      if (!props.strategyId) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      try {
        setToggling(true);
        const response =
          await StrategyManagerService.toggleStrategyFromLeaderboard(
            token,
            props.strategyId,
            toggle
          );
        triggerToast(response.message, "success", null);
        strategyMetadataQuery.refetch();
      } finally {
        setToggling(false);
      }
    },
    [
      props.strategyId,
      getAccessTokenSilently,
      strategyMetadataQuery,
      triggerToast,
      setToggling,
    ]
  );

  const handleEditPress = useCallback(
    (e: any) => {
      e?.stopPropagation();
      navigate(`/strategy/${props.strategyId}/${props.selectedVersionIds[0]}`);
    },
    [props, navigate]
  );

  const handleBacktestPress = useCallback(
    (e: any) => {
      e?.stopPropagation();
      navigate("/backtester", {
        state: {
          strategyId: props.strategyId,
          versionId: props.selectedVersionIds[0],
        },
      });
    },
    [props, navigate]
  );
  const handlePapertraderPress = useCallback(
    (e: any) => {
      e?.stopPropagation();
      navigate("/papertrader", {
        state: {
          strategyId: props.strategyId,
          versionId: props.selectedVersionIds[0],
        },
      });
    },
    [props, navigate]
  );

  const renderClearButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Clear"}
        leftIcon={<FiX size={iconSize} />}
        onClick={() => {
          props.setSelectedVersionIds([]);
        }}
        compact
      />
    );
  }, [props]);

  const renderShareButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Share"}
        leftIcon={<TbShare size={iconSize} />}
        onClick={() => {}}
        compact
        disabled
      />
    );
  }, [props]);

  const renderMoreButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"More"}
        leftIcon={<IoIosMore size={iconSize} />}
        onClick={() => {}}
        compact
        disabled
      />
    );
  }, [props]);

  const renderAnalyzeButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Analyze"}
        leftIcon={<TbReportAnalytics size={iconSize} />}
        onClick={() => {}}
        compact
        disabled
      />
    );
  }, [props]);

  const renderEditButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Edit strategy"}
        //borderTheme="blue-accent"
        leftIcon={<AiOutlineCode size={iconSize} />}
        onClick={handleEditPress}
        compact
      />
    );
  }, [props, handleEditPress]);

  const renderBacktestButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Backtester"}
        leftIcon={<GiBackwardTime size={iconSize} />}
        onClick={handleBacktestPress}
        compact
      />
    );
  }, [props, handleBacktestPress]);
  const renderPapertraderButton = useCallback(() => {
    return (
      <CommonButton
        activeTheme={props.activeTheme}
        label={"Papertrader"}
        leftIcon={<BsGraphUpArrow size={iconSize} />}
        onClick={handlePapertraderPress}
        compact
      />
    );
  }, [props, handlePapertraderPress]);

  const identiconSize =
    props.selectedVersionIds.length < 7
      ? 40
      : props.selectedVersionIds.length < 9
      ? 32
      : 30;

  return (
    <motion.div
      className="strategy-result-owner-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, delay: 1.4 }}
    >
      <>
        {props.selectedVersionIds.length === 1 && (
          <>
            {props.featuredButton ? (
              <CommonButton
                activeTheme={props.activeTheme}
                borderTheme="green-accent"
                onClick={() =>
                  props.featuredButton?.onClick(
                    props.strategyId,
                    props.selectedVersionIds[0]
                  )
                }
                leftIcon={
                  props.featuredButton.icon ?? (
                    <motion.div
                      initial={{ opacity: 0, rotate: 90 }}
                      animate={{ opacity: 1, rotate: 0 }}
                      exit={{ opacity: 0, rotate: 90 }}
                      transition={{ duration: 0.2, delay: 0.2 }}
                    >
                      <Identicon size={50} guid={props.selectedVersionIds[0]} />
                    </motion.div>
                  )
                }
                label={props.featuredButton.label}
              />
            ) : (
              <StrategyVersionIcon
                activeTheme={props.activeTheme}
                included_currencies={[]}
                strategy_id={props.strategyId}
                version_id={props.selectedVersionIds[0]}
                disableMenu
                hideLabel
              />
            )}

            {props.featuredButton?.mode === "papertrader"
              ? undefined
              : renderPapertraderButton()}
            {props.featuredButton?.mode === "backtester"
              ? undefined
              : renderBacktestButton()}
            {renderEditButton()}
            {renderClearButton()}
          </>
        )}

        {props.selectedVersionIds.length > 1 ? (
          <>
            <div className="selected-versions-wrapper">
              {props.selectedVersionIds.map((versionId) => {
                return (
                  <motion.div
                    key={versionId}
                    initial={{ opacity: 0, rotate: 90 }}
                    animate={{ opacity: 1, rotate: 0 }}
                    transition={{ duration: 0.2, delay: 0.4 }}
                    className="selected-version-wrapper-item"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      props.setSelectedVersionIds([versionId]);
                    }}
                  >
                    <Identicon size={identiconSize} guid={versionId} />
                  </motion.div>
                );
              })}
            </div>
            {renderClearButton()}
          </>
        ) : (
          <>
            {props.selectedVersionIds.length === 0 &&
              strategyMetadataQuery.data?.payload && (
                <>
                  <label className="dimmed-label">
                    Click on a version to select
                  </label>
                  <label className="dimmed-label">
                    {!strategyMetadataQuery.data.payload?.hide_from_leaderboard
                      ? "Included in leaderboard"
                      : "Excluded from leaderboard"}
                  </label>
                  {!strategyMetadataQuery.data.payload
                    ?.hide_from_leaderboard ? (
                    <CommonButton
                      activeTheme={props.activeTheme}
                      label={"Hide from public"}
                      leftIcon={<BiHide size={iconSize} />}
                      onClick={() => handleHideFromLeaderboard(true)}
                      loading={toggling}
                      compact
                    />
                  ) : (
                    <CommonButton
                      activeTheme={props.activeTheme}
                      label={"Include in public"}
                      leftIcon={<BiShow size={iconSize} />}
                      onClick={() => handleHideFromLeaderboard(false)}
                      loading={toggling}
                      compact
                    />
                  )}
                  {props.setExpandedId && (
                    <CommonButton
                      activeTheme={props.activeTheme}
                      label={"Close"}
                      leftIcon={<FiX size={iconSize} />}
                      onClick={() => props.setExpandedId?.(undefined)}
                      loading={toggling}
                      compact
                    />
                  )}
                </>
              )}
          </>
        )}
      </>
    </motion.div>
  );
}

export interface FeaturedButton {
  mode: "papertrader" | "backtester";
  label: string;
  onClick: (strategyId: string, versionId: string) => void;
  icon?: JSX.Element;
}
