import { FiCheck } from "react-icons/fi";
import { Badge, Space, Text } from "@mantine/core";
import {
  StrategyCaseState,
  StrategyCaseActionLevel,
} from "../../../../interfaces/backtester/CaseState";
import { ParsedCriteria } from "../../../strategy-body/ParsedCriteria";
import { useContext } from "react";
import { IndicatorInfosContext } from "../../../../shared-service-contexts/IndicatorsInfoContext";

interface Props {
  activeTheme: string;
  criteriaType: string;
  strategyCaseStates: StrategyCaseState[];
  strategyCaseActionLevels: StrategyCaseActionLevel[];
  currencyName: string;
}
export function ActionIndicatorTooltip(props: React.PropsWithChildren<Props>) {
  const indicatorsInfo = useContext(IndicatorInfosContext);

  const sortedStrategyCaseActionLevels = props.strategyCaseActionLevels.sort(
    (a, b) => (a.actionLevel > b.actionLevel ? 1 : -1)
  );

  const sortStrategyCaseStates = (
    sortedStrategyCaseActionLevels: StrategyCaseActionLevel[],
    strategyCaseStates: StrategyCaseState[]
  ) => {
    const _sortedStrategyCaseStates: StrategyCaseState[] = [];
    for (var i = 0; i < sortedStrategyCaseActionLevels.length; i++) {
      for (var j = 0; j < strategyCaseStates.length; j++) {
        if (
          sortedStrategyCaseActionLevels[i].caseName ===
          strategyCaseStates[j].caseName
        ) {
          _sortedStrategyCaseStates.push(strategyCaseStates[j]);
        }
      }
    }
    return _sortedStrategyCaseStates;
  };

  const sortedStrategyCaseStates: StrategyCaseState[] = sortStrategyCaseStates(
    sortedStrategyCaseActionLevels,
    props.strategyCaseStates
  );

  return (
    <div className="action-indicator-tooltip-container">
      <>
        {sortedStrategyCaseStates
          .slice(0, 3)
          .map((strategyCaseState, index) => {
            if (
              strategyCaseState[`${props.criteriaType}_criteria_1D`]
                ?.hasFullfilled ||
              strategyCaseState[`${props.criteriaType}_criteria_1h`]
                ?.hasFullfilled ||
              strategyCaseState[`${props.criteriaType}_criteria_5m`]
                ?.hasFullfilled
            )
              return (
                <div
                  key={`${index}-${props.currencyName}`}
                  className="action-indicator-tooltip-item"
                >
                  <div className="action-indicator-tooltip-item-name">
                    <Text size="sm" color={"dimmed"}>
                      Case:{" "}
                    </Text>
                    <Space w={"md"} />
                    <Badge color="gray" radius="xs" variant="outline">
                      {strategyCaseState.caseName}
                    </Badge>
                  </div>
                  {strategyCaseState[`${props.criteriaType}_criteria_1D`] ? (
                    <>
                      {strategyCaseState[
                        `${props.criteriaType}_criteria_1D`
                      ].caseCriterias.map((caseCriteria, idx) => {
                        return (
                          <div key={idx}>
                            {idx === 0 ? (
                              <>
                                <Space h={"xs"} />
                                <Badge
                                  color="gray"
                                  radius="xl"
                                  variant="outline"
                                >
                                  1D
                                </Badge>
                                <Space h={"xs"} />
                              </>
                            ) : undefined}
                            <div
                              key={`div-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1D`}
                              className={`action-indicator-tooltip-item-value-${
                                caseCriteria.fulfilled ? "valid" : "invalid"
                              }`}
                            >
                              <FiCheck
                                key={`check-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1D`}
                              />
                              <ParsedCriteria
                                key={`parsed-criteria-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1D`}
                                activeTheme={props.activeTheme}
                                criteria={caseCriteria.criteria}
                                indicatorsInfo={indicatorsInfo}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : undefined}
                  {strategyCaseState[`${props.criteriaType}_criteria_1h`] ? (
                    <>
                      {strategyCaseState[
                        `${props.criteriaType}_criteria_1h`
                      ].caseCriterias.map((caseCriteria, idx) => {
                        return (
                          <div key={idx}>
                            {idx === 0 ? (
                              <>
                                <Space h={"xs"} />
                                <Badge
                                  color="gray"
                                  radius="xl"
                                  variant="outline"
                                >
                                  1h
                                </Badge>
                                <Space h={"xs"} />
                              </>
                            ) : undefined}

                            <div
                              key={`div-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1h`}
                              className={`action-indicator-tooltip-item-value-${
                                caseCriteria.fulfilled ? "valid" : "invalid"
                              }`}
                            >
                              <FiCheck
                                key={`check-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1h`}
                              />
                              <ParsedCriteria
                                key={`parsed-criteria-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-1h`}
                                activeTheme={props.activeTheme}
                                criteria={caseCriteria.criteria}
                                indicatorsInfo={indicatorsInfo}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : undefined}
                  {strategyCaseState[`${props.criteriaType}_criteria_5m`] ? (
                    <>
                      {strategyCaseState[
                        `${props.criteriaType}_criteria_5m`
                      ].caseCriterias.map((caseCriteria, idx) => {
                        return (
                          <div key={idx}>
                            {idx === 0 ? (
                              <>
                                <Space h={"xs"} />
                                <Badge
                                  color="gray"
                                  radius="xl"
                                  variant="outline"
                                >
                                  5m
                                </Badge>
                                <Space h={"xs"} />
                              </>
                            ) : undefined}

                            <div
                              key={`div-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-5m`}
                              className={`action-indicator-tooltip-item-value-${
                                caseCriteria.fulfilled ? "valid" : "invalid"
                              }`}
                            >
                              <FiCheck
                                key={`parsed-criteria-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-5m`}
                              />
                              <ParsedCriteria
                                key={`parsed-criteria-${props.currencyName}-${caseCriteria.criteria}-${index}-${idx}-5m`}
                                activeTheme={props.activeTheme}
                                criteria={caseCriteria.criteria}
                                indicatorsInfo={indicatorsInfo}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : undefined}
                  <Space h={"sm"} />
                </div>
              );
          })}
      </>
    </div>
  );
}
