import "./common-interval-picker.scss";
import { FiX } from "react-icons/fi";
import { motion } from "framer-motion";
import { RangeCalendar } from "@mantine/dates";
import { useCallback, useMemo, useState } from "react";
import { CommonDateChip } from "./CommonDateChip";
import { DateInterval } from "../../../interfaces/DateInterval";
import { Button, Space } from "@mantine/core";
import { CommonDateSubChip } from "./CommonDateSubChip";
import { isEqual } from "../../../utils/ObjectEqualsUtil";
import { useAuth0 } from "@auth0/auth0-react";
import { BacktesterService } from "../../../services/BacktesterService";
import { PricesOverview } from "../../../interfaces/PricesOverview";
import { useQuery } from "react-query";
import { RangePreviewChart } from "../../chart-components/interactive-chart/range-preview-chart/RangePreviewChart";
import { capitalize } from "../../../utils/CapitalizeString";
import { PapertraderSeasonsMenu } from "../../paperwallet-center/PapertraderSeasonsMenu";
import { getPapertraderSeasonStartPadding } from "../../../utils/FormattingUtils";

interface Props {
  activeTheme: string;
  dateInterval: DateInterval;
  previewCurrencyName: string;
  handleClose?: () => void;
  formatDateIntervalToLabel: (_dateInterval: DateInterval) => string;
  setNewDateInterval: (_dateInterval: DateInterval) => void;
}
export function BacktestIntervalPicker(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();

  const minDate = useMemo(() => new Date(2020, 0, 1), []);
  const now = useMemo(() => new Date(), []);
  const maxDate = useMemo(() => {
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      0,
      0
    );
  }, [now]);

  const [value, setValue] = useState<[Date | null, Date | null]>([
    new Date(props.dateInterval.startTimestamp),
    new Date(props.dateInterval.stopTimestamp),
  ]);

  const getSeasons = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await BacktesterService.blueprint.getSeasons(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const seasonsQuery = useQuery(["Seasons"], getSeasons, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const getCurrentDateInterval = useCallback(() => {
    if (!value) return;
    if (!value[0] || !value[1]) return;
    return {
      startTimestamp: value[0].getTime(),
      stopTimestamp: value[1].getTime(),
    } as DateInterval;
  }, [value]);

  const selectedDateInterval = getCurrentDateInterval();
  const [previousDateInterval, setPreviousDateInterval] =
    useState<DateInterval>();

  const ensureTimestampIsInBounds = useCallback(
    (_timestamp: number) => {
      if (_timestamp < minDate.getTime()) return minDate.getTime();
      if (_timestamp > maxDate.getTime()) return maxDate.getTime();
      return _timestamp;
    },
    [minDate, maxDate]
  );

  const handleDateIntervalChange = useCallback(
    (_dateInteval: DateInterval) => {
      setPreviousDateInterval(selectedDateInterval);
      setValue([
        new Date(ensureTimestampIsInBounds(_dateInteval.startTimestamp)),
        new Date(ensureTimestampIsInBounds(_dateInteval.stopTimestamp)),
      ]);
    },
    [
      setValue,
      setPreviousDateInterval,
      ensureTimestampIsInBounds,
      selectedDateInterval,
    ]
  );

  const handleDateIntervalExtend = useCallback(
    (_dateInteval: DateInterval) => {
      // if the click interval stopTimetamp is greater than the current interval stopTimestamp, extend the current interval
      if (selectedDateInterval) {
        setPreviousDateInterval({
          startTimestamp: ensureTimestampIsInBounds(
            selectedDateInterval.startTimestamp
          ),
          stopTimestamp: ensureTimestampIsInBounds(
            selectedDateInterval.stopTimestamp
          ),
        });
      }
      if (
        previousDateInterval &&
        isEqual(previousDateInterval, selectedDateInterval)
      ) {
        setPreviousDateInterval(undefined);
        setValue([
          new Date(ensureTimestampIsInBounds(_dateInteval.startTimestamp)),
          new Date(ensureTimestampIsInBounds(_dateInteval.stopTimestamp)),
        ]);
        return;
      }
      if (
        selectedDateInterval &&
        _dateInteval.stopTimestamp > selectedDateInterval.stopTimestamp
      ) {
        setPreviousDateInterval(_dateInteval);
        setValue([
          new Date(
            ensureTimestampIsInBounds(selectedDateInterval.startTimestamp)
          ),
          new Date(ensureTimestampIsInBounds(_dateInteval.stopTimestamp)),
        ]);
        return;
      }
      // if the click interval startTimetamp is less than the current interval startTimestamp, extend the current interval
      if (
        selectedDateInterval &&
        _dateInteval.startTimestamp < selectedDateInterval.startTimestamp
      ) {
        setPreviousDateInterval(_dateInteval);
        setValue([
          new Date(ensureTimestampIsInBounds(_dateInteval.startTimestamp)),
          new Date(
            ensureTimestampIsInBounds(selectedDateInterval.stopTimestamp)
          ),
        ]);
        return;
      }
    },
    [
      setValue,
      ensureTimestampIsInBounds,
      setPreviousDateInterval,
      selectedDateInterval,
      previousDateInterval,
    ]
  );

  const [selectedSeasonKey, setSelectedSeasonKey] = useState<string>(
    seasonsQuery.data?.current_season ?? "season_0"
  );

  const getSubChipsForYear = useCallback(
    (_year: number) => {
      const subChips: React.ReactNode[] = [];
      const q1Interval: DateInterval = {
        startTimestamp: new Date(_year, 0, 1).getTime(),
        stopTimestamp: new Date(_year, 2, 31).getTime(),
      };
      const q2Interval: DateInterval = {
        startTimestamp: new Date(_year, 3, 1).getTime(),
        stopTimestamp: new Date(_year, 5, 30).getTime(),
      };
      const q3Interval: DateInterval = {
        startTimestamp: new Date(_year, 6, 1).getTime(),
        stopTimestamp: new Date(_year, 8, 30).getTime(),
      };
      const q4Interval: DateInterval = {
        startTimestamp: new Date(_year, 9, 1).getTime(),
        stopTimestamp: new Date(_year, 11, 31).getTime(),
      };

      const maxTimestamp = maxDate.getTime();
      if (q1Interval.startTimestamp < maxTimestamp) {
        subChips.push(
          <CommonDateSubChip
            currentInterval={selectedDateInterval}
            key={`${_year}-q1`}
            handleDateIntervalExtend={handleDateIntervalExtend}
            quarterLabel="Q1"
            dateInterval={q1Interval}
          />
        );
      }
      if (q2Interval.startTimestamp <= maxTimestamp) {
        subChips.push(
          <CommonDateSubChip
            currentInterval={selectedDateInterval}
            key={`${_year}-q2`}
            handleDateIntervalExtend={handleDateIntervalExtend}
            quarterLabel="Q2"
            dateInterval={q2Interval}
          />
        );
      }
      if (q3Interval.startTimestamp <= maxTimestamp) {
        subChips.push(
          <CommonDateSubChip
            currentInterval={selectedDateInterval}
            key={`${_year}-q3`}
            handleDateIntervalExtend={handleDateIntervalExtend}
            quarterLabel="Q3"
            dateInterval={q3Interval}
          />
        );
      }
      if (q4Interval.startTimestamp <= maxTimestamp) {
        subChips.push(
          <CommonDateSubChip
            currentInterval={selectedDateInterval}
            key={`${_year}-q4`}
            handleDateIntervalExtend={handleDateIntervalExtend}
            quarterLabel="Q4"
            dateInterval={q4Interval}
          />
        );
      }
      return subChips;
    },
    [maxDate, selectedDateInterval, handleDateIntervalExtend]
  );

  const getChips = useCallback(() => {
    const dateChips: { dateChipYear: number; subChips: any[] }[] = [];
    let _year = maxDate.getFullYear();

    while (_year >= minDate.getFullYear()) {
      dateChips.push({
        dateChipYear: _year,
        subChips: getSubChipsForYear(_year),
      });
      _year--;
    }
    return dateChips;
  }, [minDate, maxDate, getSubChipsForYear]);

  const fetchPreviewCurrencyData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await BacktesterService.blueprint.getPricesOverview(
      token,
      {
        currencies: [props.previewCurrencyName],
        start: minDate.getTime(),
        stop: maxDate.getTime(),
        candle_size: "1D",
      }
    );
    return response.data as PricesOverview;
  }, [getAccessTokenSilently, props.previewCurrencyName, minDate, maxDate]);

  const previewCurrencyDataQuery = useQuery(
    ["previewCurrencyData", props.previewCurrencyName],
    fetchPreviewCurrencyData,
    { cacheTime: 120000, staleTime: 0 }
  );

  const dateChips = getChips();

  const getCurrencyPrice = (currencyName: string) => {
    return previewCurrencyDataQuery?.data?.currencyPrices?.filter(
      (currencyPrice) => currencyPrice.currencyName === capitalize(currencyName)
    )[0];
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0.5 }}
      exit={{ opacity: 0 }}
      layout
      className="interval-picker-container"
    >
      <motion.div className="interval-picker-popup-header">
        <label>Pick a range for backtesting</label>
        {props.handleClose && <FiX size={25} onClick={props.handleClose} />}
      </motion.div>

      {previewCurrencyDataQuery.data && selectedDateInterval && (
        <motion.div className="interval-picker-popup-preview">
          <RangePreviewChart
            activeTheme={props.activeTheme}
            dateInterval={selectedDateInterval}
            priceData={getCurrencyPrice(props.previewCurrencyName)}
          />
        </motion.div>
      )}
      <Space h={"xs"} />
      <motion.div className="interval-picker-popup-footer">
        {selectedDateInterval && (
          <>
            <label>
              {props.formatDateIntervalToLabel(selectedDateInterval)}
            </label>
            <br />
            <Button
              className="green-button-fill"
              radius={"xs"}
              onClick={() => {
                props.setNewDateInterval(selectedDateInterval);
              }}
              disabled={
                props.dateInterval &&
                props.dateInterval.startTimestamp ===
                  selectedDateInterval.startTimestamp &&
                props.dateInterval.stopTimestamp ===
                  selectedDateInterval.stopTimestamp
              }
            >
              Apply
            </Button>
          </>
        )}
      </motion.div>
      <Space h={"xs"} />

      <motion.div className="interval-picker-popup-body">
        <label>Interval shortcuts</label>
        <div className="chips-container">
          {seasonsQuery.data && (
            <PapertraderSeasonsMenu
              activeTheme={props.activeTheme}
              currentSeasonKey={seasonsQuery.data.current_season}
              seasonTimestamps={seasonsQuery.data.season_timestamps}
              selectedSeasonKey={selectedSeasonKey}
              setSelectedSeasonKey={(_seasonKey: string) => {
                setSelectedSeasonKey(_seasonKey);
                const timestamps =
                  seasonsQuery.data?.season_timestamps[_seasonKey];
                const _seasonDateInterval: DateInterval = {
                  startTimestamp:
                    timestamps![0] - getPapertraderSeasonStartPadding(),
                  stopTimestamp: timestamps![1],
                };
                handleDateIntervalChange(_seasonDateInterval);
              }}
            />
          )}
          {dateChips.reverse().map((dateChip, index) => {
            const startTimestamp = new Date(
              dateChip.dateChipYear,
              0,
              1
            ).getTime();
            const stopTimestamp = new Date(
              dateChip.dateChipYear,
              11,
              31
            ).getTime();
            return (
              <CommonDateChip
                onYearClick={(dateInterval: DateInterval) =>
                  handleDateIntervalChange(dateInterval)
                }
                key={"date-chip-" + index + startTimestamp.toString()}
                label={dateChip.dateChipYear.toString()}
                currentInterval={selectedDateInterval}
                subChips={dateChip.subChips}
                dateInterval={{
                  startTimestamp: startTimestamp,
                  stopTimestamp:
                    stopTimestamp <= maxDate.getTime()
                      ? stopTimestamp
                      : maxDate.getTime(),
                }}
              />
            );
          })}
          <CommonDateChip
            key={"date-chip-ytd"}
            dateInterval={{
              startTimestamp: new Date(maxDate.getFullYear(), 0, 1).getTime(),
              stopTimestamp: maxDate.getTime(),
            }}
            setYTD={(dateInterval: DateInterval) => {
              setValue([
                new Date(dateInterval.startTimestamp),
                new Date(dateInterval.stopTimestamp),
              ]);
            }}
            label="YTD"
            currentInterval={selectedDateInterval}
            onYearClick={(dateInterval: DateInterval) =>
              handleDateIntervalChange(dateInterval)
            }
          />
        </div>

        <label>Custom interval</label>

        <RangeCalendar
          size={"xs"}
          value={value}
          onChange={setValue}
          minDate={minDate}
          maxDate={maxDate}
        />
      </motion.div>
    </motion.div>
  );
}
