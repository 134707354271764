import { BarChart, XAxis, YAxis, Bar } from "recharts";
import { getTheme } from "../../../utils/themeUtil";
import { useMemo } from "react";

interface Props {
  activeTheme: string;
  fakeLeftData: number[][];
  fakeRightData: number[][];
  fakeMidData: number[][];
}
export function PatternHelperChart(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);

  const Candlestick = (props) => {
    const {
      fill,
      x,
      y,
      width,
      height,
      low,
      high,
      focus,
      openClose: [open, close],
    } = props;
    const isGrowing = open < close;
    const color = isGrowing
      ? theme.buyOrderStrokeHover
      : theme.sellOrderStrokeHover;
    const ratio = Math.abs(height / (open - close));
    return (
      <g stroke={color} fill={color} opacity={focus ? 1 : 0.2} strokeWidth="2">
        <path
          d={`
            M ${x},${y}
            L ${x},${y + height}
            L ${x + width},${y + height}
            L ${x + width},${y}
            L ${x},${y}
          `}
        />
        {/* bottom line */}
        {isGrowing ? (
          <path
            d={`
              M ${x + width / 2}, ${y + height}
              v ${(open - low) * ratio}
            `}
          />
        ) : (
          <path
            d={`
              M ${x + width / 2}, ${y}
              v ${(close - low) * ratio}
            `}
          />
        )}
        {/* top line */}
        {isGrowing ? (
          <path
            d={`
              M ${x + width / 2}, ${y}
              v ${(close - high) * ratio}
            `}
          />
        ) : (
          <path
            d={`
              M ${x + width / 2}, ${y + height}
              v ${(open - high) * ratio}
            `}
          />
        )}
      </g>
    );
  };

  const data = useMemo(() => {
    const _collectedData: any[] = [];
    props.fakeLeftData.forEach((leftDataItem, index) => {
      return _collectedData.push({
        open: leftDataItem[0],
        high: leftDataItem[1],
        low: leftDataItem[2],
        close: leftDataItem[3],
        openClose: [leftDataItem[0], leftDataItem[3]],
        focus: false,
        ts: 10 * index,
      });
    });
    props.fakeMidData.forEach((midDataItem, index) => {
      return _collectedData.push({
        open: midDataItem[0],
        high: midDataItem[1],
        low: midDataItem[2],
        close: midDataItem[3],
        openClose: [midDataItem[0], midDataItem[3]],
        focus: true,
        ts: 20 * index,
      });
    });
    props.fakeRightData.forEach((rightDataItem, index) => {
      return _collectedData.push({
        open: rightDataItem[0],
        high: rightDataItem[1],
        low: rightDataItem[2],
        close: rightDataItem[3],
        openClose: [rightDataItem[0], rightDataItem[3]],
        focus: false,
        ts: 30 * index,
      });
    });

    return _collectedData;
  }, [props.fakeLeftData, props.fakeMidData, props.fakeRightData]);

  const minValue = data.reduce(
    (minValue, { low, openClose: [open, close] }) => {
      const currentMin = Math.min(low, open, close);
      return minValue === null || currentMin < minValue ? currentMin : minValue;
    },
    null
  );
  const maxValue = data.reduce(
    (maxValue, { high, openClose: [open, close] }) => {
      const currentMax = Math.max(high, open, close);
      return currentMax > maxValue ? currentMax : maxValue;
    },
    minValue
  );

  return (
    <>
      <BarChart
        width={300}
        height={100}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="ts" hide />
        <YAxis domain={[minValue, maxValue]} hide />
        <Bar dataKey="openClose" shape={<Candlestick />}></Bar>
      </BarChart>
    </>
  );
}
