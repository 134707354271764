import axios, { AxiosInstance } from "axios";
import { CommonWalletReport } from "../interfaces/common-trading/CommonTradingReport";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";
import { createOptions } from "./CreateOptions";
import { Leaderboard } from "../interfaces/season/Leaderboard";

const http = axios.create({
  baseURL: process.env.REACT_APP_PAPERTRADER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class PapertraderServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getPapertraderReport = async (
    token: string,
    versionId: string,
    season?: string
  ) => {
    const options = createOptions(token);
    const body = {
      version_id: versionId,
    };
    body["season"] = season ? season : undefined;
    return await http.post<CustomResponse<CommonWalletReport | null>>(
      `/papertrader-report`,
      body,
      options
    );
  };

  public getPapertraderLeaderboard = async (token: string, season: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<Leaderboard | undefined>>(
      `/papertrader-leaderboard/${season}`,
      options
    );
  };
}

export const PapertraderService = new PapertraderServiceClass(http);
