import ReactJoyride, { CallBackProps, Step } from "react-joyride";
import { getTheme } from "../../utils/themeUtil";
import { useCallback, useContext, useState } from "react";
import { motion } from "framer-motion";
import "./tutorial.scss";
import { Modal, Progress } from "@mantine/core";
import { CommonIconButton } from "../buttons/neoton-common-button/CommonIconButton";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IconMenuButton } from "../sidemenu/lord-icon/IconMenuButton";
import checkIntro from "../Animations/check-intro.json";
import check from "../Animations/check.json";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import {
  ApplicationSettingsContext,
  SetApplicationSettingsContext,
} from "../../App";
import { IoMdInformation } from "react-icons/io";

interface Props {
  activeTheme: string;
}
export function StrategyTutorial(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const applicationSettings = useContext(ApplicationSettingsContext);
  const setApplicationSettings = useContext(SetApplicationSettingsContext);

  const hints: { [key: string]: string } = {
    "Add a new case": "Click the 'Add Case' button",
    "Currency pools": "Select the currencies you want to trade with",
    "Funds distribution 3/3": "Use the slider to adjust the funds distribution",
    "Criteria editor 2/4": "Click on 'Add criteria' button",
    "Criteria generator 3/6": "'Double-click' on the chart to place points",
    "Criteria generator 4/6": "Click on 'Generate criteria'",
    "Criteria generator 6/6": "Select one or more criteria suggestions",
    "Criteria tester 2/3": "Click on 'Test criteria'",
    "Add criteria to case 3/3": "Click on 'Insert Criteria'",
    Simulation: "Save your strategy in order to simulate it",
  };

  const steps: Step[] = [
    {
      target: ".strategy-controls-container",
      title: "Welcome to the strategy creator",
      content: "This is where you develop your trading strategies.",
      placement: "center",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".tutorial-tracker",
      title: "Tutorial tracker",
      content: "You can use this tracker to navigate through the tutorial.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".add-case-container",
      title: "Add a new case",
      content:
        "Add a new case. Use the Generate button to randomize a name or make up your own.",
      placement: "right",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".target-currencies-container",
      title: "Target currencies",
      content: "Pick the currencies that you want the case to target.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".fixed-currency-array-item",
      title: "Currency pools",
      content:
        "When you select a currency, it will appear here. These are your currency pools. You can move currencies between the pools by clicking on them.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#funds-distribution-slider",
      title: "Funds distribution 1/3",
      content:
        "Use the Funds distribution slider to adjust the amount of funds that is allocated to each pool. If a pool is red, it means that either funds are allocated to an empty pool or the pool is not empty and no funds are allocated to it.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".pool-display.remarks",
      title: "Funds distribution 2/3",
      content:
        "This pool is empty, but funds are allocated to it. These funds will not be used in trading. You can fix this by adjusting the Funds distribution slider.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".pool-display.empty",
      title: "Funds distribution 3/3",
      content: "This pool is now empty and no funds are allocated to it.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#pool-composition-switch",
      title: "Pool composition",
      content: "Choose between dynamic and fixed pool composition.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#pool-composition-switch",
      title: "Dynamic Pool composition",
      content:
        "Dynamic pool composition will automatically place the currencies in the high, medium and low pools based on their current market cap. You can use the Rank Distribution slider to adjust the threshold for each pool. This is recommended if you plan on trading with a large number of currencies.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#pool-composition-switch",
      title: "Fixed Pool composition",
      content:
        "Fixed pool composition will allow you to manually place the currencies in the high, medium and low pools. This is recommended if you plan on trading with one or a few specific currencies.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".extras-column.take-profit",
      title: "Take profit",
      content:
        "On each case, you can configure a take profit rule. This rule will automatically sell a currency when it reaches a certain profit percentage.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".extras-column.stoploss",
      title: "Stoploss",
      content:
        "You can also configure a stoploss rule. This rule will automatically sell a currency when it reaches a certain loss percentage.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".extras-column.order-options",
      title: "Order options",
      content: "You can configure the order options for each case.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#order-weight-input",
      title: "Order weight",
      content:
        "Order weight determines the % of available pool funds that will be used to place an order on a given currency.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#order-frequency-input",
      title: "Order frequency",
      content:
        "Order frequency determines how often a new buy order can be placed for a given currency.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".case-logic-container",
      title: "Case criteria",
      content:
        "This is where you define the rules for your case. Entry criteria are the conditions that must be met for a buy order to be placed. Exit criteria are the conditions that must be met for a sell order to be placed.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#add-criteria-button",
      title: "Criteria editor 1/4",
      content:
        "There are 3 timeframes to choose from. This is how often the criteria will be checked.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
      
    },
    {
      target: ".case-editor-header",
      title: "Criteria editor 2/4",
      content:
        "This is where you can create your trading criteria. Notice the selected timeframe. This is how often the criteria will be checked.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".case-editor-header-menu",
      title: "Criteria editor 3/4",
      content:
        "This is the different types of trading signals that you can use to define your case criteria. You can use Technical Indicators, Pattern Tracking and AI Signals. The trading signals can be combined or used in isolation.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".case-editor-result-container",
      title: "Criteria editor 4/4",
      content: "Your case criteria will be displayed here.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#close-case-popup",
      title: "Close the case popup",
      content: "Press the 'X' button to close the case popup.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".chart-controls-button",
      title: "Chart features",
      content:
        "Press the 'Chart features' button to open the chart features. Here you and toggle the chart features on and off.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#strategy-creator-candle-size-control",
      title: "Timeframe",
      content: "You can change the timeframe of the chart here.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".chart-navigation-container",
      title: "Chart navigation",
      content:
        "On smaller timeframes, you can use the chart navigation to scroll through the chart.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".currency-selection-button",
      title: "Currency selection",
      content:
        "You can change the currency that is displayed on the chart here.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".criteria-generator-container",
      title: "Criteria generator 1/6",
      content:
        "You can double click on the chart to place buy and sell points. You can automatically generate criteria based on these points.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".stonk-chart-container",
      title: "Criteria generator 2/6",
      content:
        "Find a good point on the chart where you would like to place a buy order and 'double-click' on it. Similarly, find a good point where you would like to place a sell order and 'double-click' on it.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#criteria-generator-button",
      title: "Criteria generator 3/6",
      content:
        "Once you have placed your buy and/or sell points, open the criteria generator.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".strategy-helper-modal-container",
      title: "Criteria generator 4/6",
      content:
        "This is the criteria generator. This will generate code suggestions that will place buy and sell orders based on the points you have selected on the chart. You can combine the different trading signals to enhance precision..",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".suggestions-container",
      title: "Criteria generator 5/6",
      content: "Select one or more criteria suggestions",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".criteria-result-container",
      title: "Criteria generator 6/6",
      content: "Your criteria will be displayed here.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#test-criteria-button",
      title: "Criteria tester 1/2",
      content:
        "You can test your criteria to see when they would have placed buy and sell orders in the past.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".microtest-chart-container",
      title: "Criteria tester 2/2",
      content:
        "The graph will show you when your criteria would have placed buy and sell orders in the past. The dots represent the buy and sell points, that you've placed. The lines represent the buy and sell orders that would have been placed based on your criteria.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#add-to-case",
      title: "Add criteria to case 1/3",
      content:
        "Once you are satisfied with your criteria, you can add it to your case.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".case-selection-container",
      title: "Add criteria to case 2/3",
      content:
        "Pick the case that you want to add the criteria to. Or create a new case.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".case-features-container",
      title: "Add criteria to case 3/3",
      content: "Insert the criteria into the case.",
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: ".strategy-button-column",
      title: "Save your strategy",
      content:
        "When you've made all the changes you want, press the 'Save' button to save your strategy. This will save a new version of your strategy, meaning that you can always go back to previous versions. Each strategy can hold up to 20 versions.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
    {
      target: "#simulate-button",
      title: "Simulation",
      content:
        "Once you've saved your strategy, you can simulate it in the Backtester. This will show you how your strategy would have performed in the past.",
      placement: "auto",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
    },
  ];

  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [finalized, setFinalized] = useState(false);
  const [expandedTracker, setExpandedTracker] = useState(false);

  const checkAndResumeTour = useCallback((targetSelector, callback) => {
    const intervalId = setInterval(() => {
      if (document.querySelector(targetSelector)) {
        clearInterval(intervalId);
        callback(); // Resume the tour
      }
    }, 200); // Check every 200ms
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type, step } = data;

    if (status === "finished" || (status === "skipped" && action === "close")) {
      setRun(false);
      if (index === steps.length - 1) {
        setFinalized(true);
      }
    } else if (type === "step:after" && action === "next") {
      setStepIndex(index + 1);
    } else if (type === "error:target_not_found") {
      // Stop the tour temporarily
      setRun(false);

      // Wait for the target to become available
      checkAndResumeTour(step.target, () => {
        // Once the target is available, resume the tour
        setRun(true);
        setStepIndex(index); // Resume from the current step
      });
    }
  };

  const handleFinalize = useCallback(() => {
    if (!applicationSettings) return;
    setApplicationSettings({
      ...applicationSettings,
      enableTutorial: false,
    });
    setFinalized(false);
    setStepIndex(0);
  }, [setApplicationSettings, applicationSettings, setFinalized, setStepIndex]);

  const progressValue = (stepIndex / steps.length) * 100;

  return (
    <>
      <ReactJoyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous={true}
        showProgress={true}
        styles={{
          options: {
            arrowColor: theme.popup,
            backgroundColor: theme.customTooltip2,
            overlayColor: theme.cardTransparent,
            primaryColor: theme.menuItemHover,
            textColor: theme.text,

            zIndex: 1000,
          },
          overlay: {
            fontWeight: 100,
          },
        }}
      />
      <Modal
        title={<label>Tutorial completed</label>}
        centered
        size={"lg"}
        opened={finalized}
        onClose={handleFinalize}
      >
        <div className="tutorial-complete-container">
          <label>
            Well done! You now know the basics of creating a trading strategy on
            Neoton.io
          </label>
          <div className="tutorial-icon-container">
            <IconMenuButton
              animationData={check}
              introAnimationData={checkIntro}
            />
          </div>
          <label>What's next?</label>
          <label className="dimmed-label">
            Fine-tune, optimize and explore new trading tactics using the{" "}
            <strong className="highlight">Strategy Creator</strong>.
          </label>
          <label className="dimmed-label">
            Simulate your trading strategy in the{" "}
            <strong className="highlight">Backtester</strong>.
          </label>
          <label className="dimmed-label">
            Compete against other traders in our seasonal{" "}
            <strong className="highlight">Papertrader</strong>.
          </label>
          <label>Or</label>
          <label
            className="dimmed-label"
            style={{
              whiteSpace: "wrap",
            }}
          >
            Attach your trading strategy to the{" "}
            <strong className="highlight">Livetrader</strong>.
          </label>
          <CommonButton
            activeTheme={props.activeTheme}
            label="Got it!"
            onClick={handleFinalize}
          />
        </div>
      </Modal>
      {!finalized && (
        <motion.div
          initial={{ scale: 0, bottom: "2%", right: "2%" }}
          animate={{ scale: 1, bottom: "7%", right: "2%" }}
          transition={{ duration: 0.2, delay: 0.3 }}
          className="tutorial-tracker"
        >
          <div className="tutorial-tracker-row">
            <label>{steps[stepIndex]?.title}</label>
            <CommonIconButton
              borderTheme={expandedTracker ? "gray-accent" : undefined}
              activeTheme={props.activeTheme}
              icon={<IoMdInformation />}
              onClick={() => {
                setExpandedTracker(!expandedTracker);
              }}
            />
          </div>
          {expandedTracker && (
            <motion.label
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.2 }}
              style={{
                textWrap: "wrap",
                marginBottom: 10,
              }}
              className="dimmed-label"
            >
              {steps[stepIndex]?.content}
            </motion.label>
          )}
          {hints[steps[stepIndex]?.title as string] && (
            <label className="dimmed-label highlight">
              {hints[steps[stepIndex]?.title as string]}
            </label>
          )}
          <Progress
            value={finalized ? 100 : progressValue}
            color={"cyan"}
            size="sm"
            radius="xl"
            style={{ width: "100%" }}
          />
          <div className="tutorial-tracker-row">
            <CommonIconButton
              activeTheme={props.activeTheme}
              icon={<AiOutlineArrowLeft />}
              onClick={() => {
                if (stepIndex > 0) {
                  const nextStepIndex = stepIndex - 1;
                  setStepIndex(nextStepIndex);
                  const step = steps[nextStepIndex];
                  checkAndResumeTour(step.target, () => {
                    // Once the target is available, resume the tour
                    setRun(true);
                    setStepIndex(nextStepIndex); // Resume from the current step
                  });
                }
              }}
            />
            <label className="dimmed-label">
              {stepIndex + 1} of {steps.length}
            </label>
            <CommonIconButton
              activeTheme={props.activeTheme}
              icon={
                <AiOutlineArrowLeft style={{ transform: "rotate(180deg)" }} />
              }
              onClick={() => {
                if (stepIndex < steps.length - 1) {
                  const nextStepIndex = stepIndex + 1;
                  setStepIndex(nextStepIndex);
                  const step = steps[nextStepIndex];
                  checkAndResumeTour(step.target, () => {
                    // Once the target is available, resume the tour
                    setRun(true);
                    setStepIndex(nextStepIndex); // Resume from the current step
                  });
                }
              }}
            />
          </div>
        </motion.div>
      )}
    </>
  );
}
