import "./strategy-helper-modal.scss";
import { CriteriaTestResource } from "../../pages/strategy-page/StrategyPage";
import { ChartNavigation } from "./ChartNavigation";
import { Currency } from "../../interfaces/Currency";
import { useCallback, useContext, useMemo, useState } from "react";
import { getAllowedTimeInterval } from "../../utils/CandleCountUtil";
import { getTranslatedCriteriaDependencies } from "../../utils/ParseCriteriaUtil";
import { IndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { StrategyMicrotester } from "./StrategyMicrotester";
import { motion } from "framer-motion";
import { ParsedCriteria } from "./ParsedCriteria";
import { IndicatorInfosContext } from "../../shared-service-contexts/IndicatorsInfoContext";

interface Props {
  activeTheme: string;
  criteriaTestResource: CriteriaTestResource;
  selectedCurrency: Currency;
}
export function CriteriaTesterModal(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(IndicatorsContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const currencies = useContext(CurrencyContext);

  const now = useMemo(() => new Date().getTime(), []);
  const minDate = useMemo(() => 1507840400000, []);
  const maxCandles = useMemo(() => {
    return props.criteriaTestResource.candleSize === "1D" ? (365 * 2) : 1200;
  }, [props.criteriaTestResource.candleSize]);

  const initialTimeInterval = useMemo(() => {
    const allowedInterval = getAllowedTimeInterval(
      now,
      maxCandles,
      props.criteriaTestResource.candleSize,
      now
    );
    return allowedInterval;
  }, [props.criteriaTestResource, minDate, now]);

  const isAI = useMemo(() => {
    if (!applicationIndicators) return false;
    const combinedCriteria = `${props.criteriaTestResource.entryCriteria} ; ${props.criteriaTestResource.exitCriteria}`;
    const deps = getTranslatedCriteriaDependencies(
      combinedCriteria,
      applicationIndicators
    );
    return deps.calculationProfiles.includes("AI");
  }, [props.criteriaTestResource, applicationIndicators]);

  const supportedCurrencies = useMemo(() => {
    if (!currencies || !applicationIndicators) return [];
    if (isAI) {
      return currencies.filter((currency) =>
        applicationIndicators.ai_supported_currencies.includes(
          currency.currency_name
        )
      );
    }
    return currencies;
  }, [currencies, applicationIndicators, isAI]);

  const [timeInterval, setTimeInterval] = useState(initialTimeInterval);
  const [selectedCurrency, setSelectedCurrency] = useState(
    supportedCurrencies.includes(props.selectedCurrency)
      ? props.selectedCurrency
      : supportedCurrencies[0]
  );

  return (
    <div className="strategy-helper-modal-container">
      {supportedCurrencies && (
        <>
          <ChartNavigation
            candleSize={props.criteriaTestResource.candleSize}
            activeTheme={props.activeTheme}
            selectedCurrency={selectedCurrency}
            enable1DChartClick
            handleNavigationChartClick={(candleSize, centerTimestamp) => {
              const allowedInterval = getAllowedTimeInterval(
                now,
                maxCandles,
                candleSize,
                centerTimestamp ?? now
              );
              setTimeInterval(allowedInterval);
            }}
            currencySelectionOverride={supportedCurrencies}
            onCurrencySelection={(currency) => setSelectedCurrency(currency)}
            currentInterval={[timeInterval[0], timeInterval[1]]}
          />
          <div className="suggestions-container">
            <StrategyMicrotester
              activeTheme={props.activeTheme}
              candleSize={props.criteriaTestResource.candleSize}
              currency={selectedCurrency}
              start={timeInterval[0]}
              stop={timeInterval[1]}
              entryCriteria={props.criteriaTestResource.entryCriteria}
              exitCriteria={props.criteriaTestResource.exitCriteria}
            />
          </div>
          {props.criteriaTestResource.entryCriteria && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
              }}
              className="criteria-result-container entry"
            >
              <ParsedCriteria
                indicatorsInfo={indicatorsInfo}
                activeTheme={props.activeTheme}
                criteria={props.criteriaTestResource.entryCriteria}
              />
            </motion.div>
          )}
          {props.criteriaTestResource.exitCriteria && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
              }}
              className="criteria-result-container exit"
            >
              <ParsedCriteria
                indicatorsInfo={indicatorsInfo}
                activeTheme={props.activeTheme}
                criteria={props.criteriaTestResource.exitCriteria}
              />
            </motion.div>
          )}
        </>
      )}
    </div>
  );
}
