import { motion } from "framer-motion";
import { DateInterval } from "../../../interfaces/DateInterval";
import { useCallback } from "react";

interface Props {
  label: string;
  dateInterval: DateInterval;
  currentInterval: DateInterval | undefined;
  subChips?: React.ReactNode[];
  onYearClick: (dateInterval: DateInterval) => void;
  setYTD?: (dateInterval: DateInterval) => void;
}
export function CommonDateChip(props: React.PropsWithChildren<Props>) {
  const checkIfActive = useCallback(() => {
    if (!props.currentInterval) return false;
    const yearsRange: string[] = [];
    let currentStartYear = new Date(
      props.currentInterval?.startTimestamp
    ).getFullYear();
    const currentStopYear = new Date(
      props.currentInterval?.stopTimestamp
    ).getFullYear();
    while (currentStartYear <= currentStopYear) {
      yearsRange.push(currentStartYear.toString());
      currentStartYear++;
    }

    return yearsRange.includes(props.label);
  }, [props.currentInterval, props.label]);

  const checkIfYTDIsActive = useCallback(() => {
    if (!props.currentInterval) return false;
    return (
      props.currentInterval.startTimestamp ===
        props.dateInterval.startTimestamp &&
      props.dateInterval.stopTimestamp === props.currentInterval.stopTimestamp
    );
  }, [props.currentInterval, props.dateInterval]);

  const isActive =
    props.label === "YTD" ? checkIfYTDIsActive() : checkIfActive();

  return (
    <motion.div className="common-date-chip-outer">
      <motion.div
        className={"common-date-chip" + (isActive ? " active" : "")}
        onClick={() =>
          props.setYTD
            ? props.setYTD(props.dateInterval)
            : props.onYearClick(props.dateInterval)
        }
      >
        <label>{props.label}</label>
      </motion.div>
      {props.subChips && props.subChips.length > 0 && (
        <motion.div className="subchips-container">
          {props.subChips.map((subChip, idx) => {
            return (
              <div
                style={{
                  flex: 1,
                }}
                key={idx}
              >
                {subChip}
              </div>
            );
          })}
        </motion.div>
      )}
    </motion.div>
  );
}
