import "./compact-trading-overview.scss";
import { Currency } from "../../../interfaces/Currency";
import { useContext, useMemo, useState } from "react";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { StrategyVersion } from "../../../interfaces/strategyInterfaces/Strategy";
import { CompactTradingCard } from "./CompactTradingCard";
import { CommonWalletReport } from "../../../interfaces/common-trading/CommonTradingReport";
import { CompactMarketCapPoolHeader } from "../compact-strategy-select/CompactMarketCapPoolHeader";
import { PricesOverview } from "../../../interfaces/PricesOverview";
import { capitalize } from "../../../utils/CapitalizeString";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  commonWalletReport: CommonWalletReport | undefined;
  pricesOverviewQuery: PricesOverview | undefined;
  loadingCurrencies: boolean;
  focusedCurrency: Currency | undefined;
  setFocusedCurrency: (currency: Currency | undefined) => void;
}
export function CompactTradingOverview(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);

  const poolLabels = useMemo(() => {
    const isFixed = props.strategy.header.pool_composition_type === "fixed";
    const _poolLabels: { [key: string]: string } = {
      high: isFixed ? "High pool" : "High market cap",
      medium: isFixed ? "Medium pool" : "Medium market cap",
      low: isFixed ? "Low pool" : "Low market cap",
    };
    return _poolLabels;
  }, [props.strategy]);

  const [poolsExpanded, setPoolsExpanded] = useState<{
    [key: string]: boolean;
  }>({
    high: true,
    medium: true,
    low: true,
  });

  const highMCCurrencies = useMemo(() => {
    const _filteredCurrencies =
      props.strategy.header.pool_composition_type !== "fixed"
        ? currencies?.filter(
            (currency) =>
              currency.cmc_rank <
                props.strategy.header.rank_distribution.high &&
              props.strategy.included_currencies.includes(
                currency.currency_name
              )
          )
        : currencies?.filter((currency) =>
            props.strategy.header.fixed_pool_composition?.high.includes(
              currency.currency_name
            )
          );
    if (props.focusedCurrency !== undefined) {
      return _filteredCurrencies?.filter(
        (currency) =>
          currency.currency_name === props.focusedCurrency!.currency_name
      );
    }
    return _filteredCurrencies;
  }, [currencies, props.strategy, props.focusedCurrency]);

  const mediumMCCurrencies = useMemo(() => {
    const _filteredCurrencies =
      props.strategy.header.pool_composition_type !== "fixed"
        ? currencies?.filter(
            (currency) =>
              currency.cmc_rank >=
                props.strategy.header.rank_distribution.high &&
              currency.cmc_rank <
                props.strategy.header.rank_distribution.medium &&
              props.strategy.included_currencies.includes(
                currency.currency_name
              )
          )
        : currencies?.filter((currency) =>
            props.strategy.header.fixed_pool_composition?.medium.includes(
              currency.currency_name
            )
          );
    if (props.focusedCurrency !== undefined) {
      return _filteredCurrencies?.filter(
        (currency) =>
          currency.currency_name === props.focusedCurrency!.currency_name
      );
    }
    return _filteredCurrencies;
  }, [currencies, props.strategy, props.focusedCurrency]);

  const lowMCCurrencies = useMemo(() => {
    const _filteredCurrencies =
      props.strategy.header.pool_composition_type !== "fixed"
        ? currencies?.filter(
            (currency) =>
              currency.cmc_rank >=
                props.strategy.header.rank_distribution.medium &&
              props.strategy.included_currencies.includes(
                currency.currency_name
              )
          )
        : currencies?.filter((currency) =>
            props.strategy.header.fixed_pool_composition?.low.includes(
              currency.currency_name
            )
          );
    if (props.focusedCurrency !== undefined) {
      return _filteredCurrencies?.filter(
        (currency) =>
          currency.currency_name === props.focusedCurrency!.currency_name
      );
    }
    return _filteredCurrencies;
  }, [currencies, props.strategy, props.focusedCurrency]);

  const getCurrencyPrice = (currencyName: string) => {
    return props.pricesOverviewQuery?.currencyPrices?.filter(
      (currencyPrice) => currencyPrice.currencyName === capitalize(currencyName)
    )[0];
  };

  return (
    <div className="compact-trading-overview-container">
      {highMCCurrencies && highMCCurrencies.length > 0 && (
        <>
          <CompactMarketCapPoolHeader
            poolCurrencies={highMCCurrencies}
            poolsExpanded={poolsExpanded}
            pool="high"
            setPoolsExpanded={setPoolsExpanded}
            label={poolLabels.high}
            poolFunds={props.commonWalletReport?.wallet?.funds.high}
          />
          {poolsExpanded.high && (
            <div className={"compact-market-cap-pool-container"}>
              {poolsExpanded.high &&
                highMCCurrencies?.map((currency: Currency) => {
                  const isHolding =
                    props.commonWalletReport?.report?.holdings?.currencies?.includes(
                      currency.currency_name
                    ) ?? false;
                  return (
                    <CompactTradingCard
                      isHolding={isHolding}
                      focusedCurrency={props.focusedCurrency}
                      onClick={() => {
                        props.setFocusedCurrency(
                          props.focusedCurrency?.currency_name ===
                            currency.currency_name
                            ? undefined
                            : currency
                        );
                      }}
                      priceData={getCurrencyPrice(currency.currency_name)}
                      tradingReport={props.commonWalletReport}
                      key={currency.currency_name}
                      activeTheme={props.activeTheme}
                      currency={currency}
                      loadingCurrencies={props.loadingCurrencies}
                    />
                  );
                })}
            </div>
          )}
        </>
      )}
      {mediumMCCurrencies && mediumMCCurrencies.length > 0 && (
        <>
          <CompactMarketCapPoolHeader
            poolCurrencies={mediumMCCurrencies}
            poolsExpanded={poolsExpanded}
            pool="medium"
            setPoolsExpanded={setPoolsExpanded}
            label={poolLabels.medium}
            poolFunds={props.commonWalletReport?.wallet?.funds.medium}
          />
          {poolsExpanded.medium && (
            <div className={"compact-market-cap-pool-container"}>
              {poolsExpanded.medium &&
                mediumMCCurrencies?.map((currency: Currency) => {
                  const isHolding =
                    props.commonWalletReport?.report?.holdings?.currencies?.includes(
                      currency.currency_name
                    ) ?? false;
                  return (
                    <CompactTradingCard
                      isHolding={isHolding}
                      focusedCurrency={props.focusedCurrency}
                      onClick={() => {
                        props.setFocusedCurrency(
                          props.focusedCurrency?.currency_name ===
                            currency.currency_name
                            ? undefined
                            : currency
                        );
                      }}
                      priceData={getCurrencyPrice(currency.currency_name)}
                      tradingReport={props.commonWalletReport}
                      key={currency.currency_name}
                      activeTheme={props.activeTheme}
                      currency={currency}
                      loadingCurrencies={props.loadingCurrencies}
                    />
                  );
                })}
            </div>
          )}
        </>
      )}
      {lowMCCurrencies && lowMCCurrencies.length > 0 && (
        <>
          <CompactMarketCapPoolHeader
            poolCurrencies={lowMCCurrencies}
            poolsExpanded={poolsExpanded}
            pool="low"
            setPoolsExpanded={setPoolsExpanded}
            label={poolLabels.low}
            poolFunds={props.commonWalletReport?.wallet?.funds.low}
          />
          {poolsExpanded.low && (
            <div className={"compact-market-cap-pool-container"}>
              {poolsExpanded.low &&
                lowMCCurrencies?.map((currency: Currency) => {
                  const isHolding =
                    props.commonWalletReport?.report?.holdings?.currencies?.includes(
                      currency.currency_name
                    ) ?? false;
                  return (
                    <CompactTradingCard
                      isHolding={isHolding}
                      focusedCurrency={props.focusedCurrency}
                      onClick={() => {
                        props.setFocusedCurrency(
                          props.focusedCurrency?.currency_name ===
                            currency.currency_name
                            ? undefined
                            : currency
                        );
                      }}
                      priceData={getCurrencyPrice(currency.currency_name)}
                      tradingReport={props.commonWalletReport}
                      key={currency.currency_name}
                      activeTheme={props.activeTheme}
                      currency={currency}
                      loadingCurrencies={props.loadingCurrencies}
                    />
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
}
