import "./common-event-log.scss";
import {
  NewPoolCompositionEvent,
  TradingEvent,
} from "../../../interfaces/common-trading/CommonTradingReport";
import { useCallback, useContext, useMemo } from "react";
import { getTheme } from "../../../utils/themeUtil";
import { MdCurrencyExchange } from "react-icons/md";
import { toTimestring } from "../../../utils/FormattingUtils";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";

interface Props {
  activeTheme: string;
  events: TradingEvent[] | undefined;
}
export function CommonEventLog(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const getEventIcon = useCallback(
    (eventLabel: string) => {
      switch (eventLabel) {
        case "pool_composition_initialized":
          return <MdCurrencyExchange color={theme.buyOrderStroke} />;
        case "pool_composition_changed":
          return <MdCurrencyExchange color={theme.orderOptionsTheme} />;
        default:
          return undefined;
      }
    },
    [theme]
  );

  const getEventLabel = useCallback((eventLabel: string) => {
    switch (eventLabel) {
      case "pool_composition_initialized":
        return "Pool composition initialized";
      case "pool_composition_changed":
        return "Pool composition changed";
      default:
        return undefined;
    }
  }, []);

  const renderPoolCompositionEvent = useCallback(
    (poolCompositionEvent: NewPoolCompositionEvent) => {
      if (!currencies) return;
      const highPoolCurrencies = currencies.filter((currency) => {
        return poolCompositionEvent.high.includes(currency.currency_name);
      });
      const mediumPoolCurrencies = currencies.filter((currency) => {
        return poolCompositionEvent.medium.includes(currency.currency_name);
      });
      const lowPoolCurrencies = currencies.filter((currency) => {
        return poolCompositionEvent.low.includes(currency.currency_name);
      });
      return (
        <div className="pool-composition-event">
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">High pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={highPoolCurrencies}
                size="xs"
                expandable
                previewAmount={8}
              />
            </div>
          </div>
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">Medium pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={mediumPoolCurrencies}
                size="xs"
                expandable
                previewAmount={8}
              />
            </div>
          </div>
          <div className="pool-event-container">
            <div className="pool-event-header">
              <label className="dimmed-label">Low pool</label>
            </div>
            <div className="pool-event-body">
              <CurrencyLogoArray
                currencies={lowPoolCurrencies}
                size="xs"
                expandable
                previewAmount={8}
              />
            </div>
          </div>
        </div>
      );
    },
    [currencies]
  );

  return (
    <div className="common-event-log">
      {props.events &&
        props.events.map((tradingEvent, idx) => {
          return (
            <div key={idx} className="common-trading-event-container">
              <div className="common-trading-event-header-container">
                {getEventIcon(tradingEvent.event)}
                <label>{getEventLabel(tradingEvent.event)}</label>
                {tradingEvent.timestamp && (
                  <label
                    className="dimmed-label"
                    style={{ marginLeft: "auto", marginRight: "5px" }}
                  >
                    {toTimestring(tradingEvent.timestamp)}
                  </label>
                )}
              </div>
              {tradingEvent.event.includes("pool_composition") &&
                tradingEvent.new_pool_composition && (
                  <>
                    {renderPoolCompositionEvent(
                      tradingEvent.new_pool_composition
                    )}
                  </>
                )}
            </div>
          );
        })}
    </div>
  );
}
