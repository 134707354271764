import { motion } from "framer-motion";
import { HoldingItem } from "../../../interfaces/backtester/BacktestTradingReport";
import { memo, useContext, useMemo, useRef, useState } from "react";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { Avatar } from "@mantine/core";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";
import CountUp from "react-countup";
import {
  calcPctChange,
  determineDecimals,
} from "../../../utils/FormattingUtils";
import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import { getCurrencyUrl } from "../../../utils/cdnUtil";

interface Props {
  activeTheme: string;
  pctWidth: number;
  holdingItem?: HoldingItem;
  poolValue?: number;
  poolFunds?: PoolFunds;
  label?: string;
}

const CommonMarketCapPoolHeaderProgress = memo(function CommonTradingCard(
  props: React.PropsWithChildren<Props>
) {
  const theme = getTheme(props.activeTheme);
  const containerRef = useRef<HTMLDivElement>(null); // Create a ref

  const currencies = useContext(CurrencyContext);
  const currency = props.holdingItem
    ? currencies?.find(
        (currency) =>
          currency.currency_name === props.holdingItem?.currency_name
      )
    : undefined;

  const currencyUrl = useMemo(() => {
    if (currency) return getCurrencyUrl(currency);
  }, [currency]);

  const logoSize = 20;

  const { data } = usePalette(currencyUrl ?? "/assets/neoton/neotonLogo.png");
  const [clicked, setClicked] = useState(false);

  const fillColor = useMemo(() => {
    if (data && currencyUrl) return data;
  }, [data, currencyUrl]);

  const initialValue = useMemo(() => {
    if (props.holdingItem?.buy_price && props.holdingItem?.quantity) {
      return props.holdingItem?.buy_price * props.holdingItem?.quantity;
    }
    return undefined;
  }, [props.holdingItem?.buy_price, props.holdingItem?.quantity]);

  const pnlChangePct = useMemo(() => {
    if (initialValue && props.holdingItem?.current_value) {
      return calcPctChange(initialValue, props.holdingItem?.current_value);
    }
    return undefined;
  }, [initialValue, props.holdingItem?.current_value]);

  const pnlDiff = useMemo(() => {
    if (initialValue && props.holdingItem?.current_value) {
      return props.holdingItem?.current_value - initialValue;
    }
    return undefined;
  }, [initialValue, props.holdingItem?.current_value]);

  return (
    <div
      ref={containerRef}
      style={{ width: `${props.pctWidth}%`, minWidth: "fit-content" }}
      className="common-market-cap-pool-progress"
      onClick={() => setClicked(!clicked)}
    >
      {currencyUrl && <Avatar src={currencyUrl} size={logoSize} radius="xl" />}
      <motion.div
        initial={{ borderColor: theme.text }}
        animate={{
          borderColor: fillColor?.vibrant ?? theme.cardInterior,
          backgroundColor: fillColor?.lightVibrant ?? "rgba(0, 0, 0, 0)",
        }}
        transition={{ duration: 0.4, delay: 0.5 }}
        className={
          "common-market-cap-pool-progress-filler" +
          (!currencyUrl ? " base-currency" : "")
        }
      >
        {props.children}
      </motion.div>
      {clicked && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            y: "60%",
            borderColor: currencyUrl ? data.vibrant : theme.text,
          }}
          transition={{ duration: 0.2 }}
          className={"common-market-cap-pool-progress-tooltip"}
        >
          {currencyUrl ? (
            <>
              {props.holdingItem?.currency_name && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Currency: </label>
                  <label>{props.holdingItem?.currency_name}</label>
                </div>
              )}
              {props.holdingItem?.buy_price && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Buy price: </label>
                  <CountUp
                    className={"wallet-stat-label"}
                    end={props.holdingItem.buy_price}
                    start={0}
                    preserveValue
                    duration={1}
                    prefix="$ "
                    decimals={determineDecimals(props.holdingItem.buy_price)}
                  />
                </div>
              )}
              {initialValue && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Initial value: </label>
                  <CountUp
                    className={"wallet-stat-label"}
                    end={initialValue}
                    preserveValue
                    start={0}
                    duration={1}
                    prefix="$ "
                    decimals={determineDecimals(initialValue)}
                  />
                </div>
              )}
              {props.holdingItem?.current_value && initialValue && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Current value: </label>
                  <CountUp
                    className={
                      initialValue === props.holdingItem.current_value
                        ? "wallet-stat-label"
                        : initialValue < props.holdingItem.current_value
                        ? "wallet-stat-label-profit"
                        : "wallet-stat-label-deficit"
                    }
                    end={props.holdingItem?.current_value}
                    preserveValue
                    start={0}
                    duration={1}
                    prefix="$ "
                    decimals={determineDecimals(
                      props.holdingItem.current_value ?? initialValue
                    )}
                  />
                </div>
              )}
              {pnlDiff && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">P/L: </label>
                  <CountUp
                    className={
                      pnlDiff === 0
                        ? "wallet-stat-label"
                        : pnlDiff > 0
                        ? "wallet-stat-label-profit"
                        : "wallet-stat-label-deficit"
                    }
                    end={pnlDiff}
                    preserveValue
                    start={0}
                    duration={1}
                    prefix="$ "
                    decimals={2}
                  />
                </div>
              )}
              {pnlChangePct && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">P/L pct: </label>
                  <CountUp
                    className={
                      pnlChangePct === 0
                        ? "wallet-stat-label"
                        : pnlChangePct > 0
                        ? "wallet-stat-label-profit"
                        : "wallet-stat-label-deficit"
                    }
                    end={pnlChangePct}
                    preserveValue
                    start={0}
                    duration={1}
                    decimals={2}
                    suffix="%"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {props.label && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">{props.label}</label>
                </div>
              )}
              {props.poolValue && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Pool value: </label>
                  <CountUp
                    className={"wallet-stat-label"}
                    end={props.poolValue}
                    start={0}
                    preserveValue
                    duration={1}
                    prefix="$ "
                    decimals={2}
                  />
                </div>
              )}
              {props.poolFunds && (
                <div className="common-market-cap-pool-progress-tooltip-row">
                  <label className="dimmed-label">Available funds: </label>
                  <CountUp
                    className={"wallet-stat-label"}
                    end={props.poolFunds.available_amount}
                    start={0}
                    preserveValue
                    duration={1}
                    prefix="$ "
                    decimals={2}
                  />
                </div>
              )}
            </>
          )}
        </motion.div>
      )}
    </div>
  );
});

export default CommonMarketCapPoolHeaderProgress;
