import axios, { AxiosInstance } from "axios";
import { createOptions } from "./CreateOptions";
import { BacktestStageRequest } from "../interfaces/backtester/BacktestStageRequest";
import { BacktestTradingReport } from "../interfaces/backtester/BacktestTradingReport";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";
import { BacktestStageResponse } from "../interfaces/backtester/BacktestStageResponse";
import { Tournament } from "../interfaces/backtester/Tournament";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKTESTER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class BacktesterServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public stageBacktest = async (
    token: string,
    stageRequest: BacktestStageRequest
  ) => {
    const options = createOptions(token);
    const body = {
      start: stageRequest.start,
      stop: stageRequest.stop,
      strategy_id: stageRequest.strategyId,
      version_id: stageRequest.versionId,
      initial_amount: stageRequest.initialBalance,
      fee: stageRequest.fee,
      tournament_mode: stageRequest.tournamentMode ?? false,
    };
    return await http.post<BacktestStageResponse>(
      `/backtest/stage`,
      body,
      options
    );
  };

  public joinGroup = async (token: string) => {
    const options = createOptions(token);

    return await http.get<{ pubsub_url: string }>(`/join-group`, options);
  };

  public cancelBacktest = async (token: string) => {
    const options = createOptions(token);
    return await http.post<CustomResponse<any>>(
      `/cancel-backtest`,
      {},
      options
    );
  };
  public getBacktestTradingReport = async (token: string) => {
    const options = createOptions(token);
    return await http.get<BacktestTradingReport | null>(
      `/backtest-trading-report`,
      options
    );
  };

  public getTournament = async (token: string) => {
    const options = createOptions(token);
    return await http.get<Tournament>(`/tournament`, options);
  };
}

export const BacktesterService = new BacktesterServiceClass(http);
