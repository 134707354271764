import { useAuth0 } from "@auth0/auth0-react";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState, useCallback, useEffect } from "react";
import { ToastContext } from "../../../App";
import { StrategyManagerService } from "../../../services/StrategyManagerService";
import { FiTrash2, FiX } from "react-icons/fi";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";

interface Props {
  activeTheme: string;
  strategyId: string;
  selectedVersionIds: string[];
  expanded: boolean;
  setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  refetchStrategies?: () => void;
  versionCount: number;
}
export function StrategyOwnerFooter(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const triggerToast = useContext(ToastContext);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (!props.expanded && deleteMode) {
      setDeleteMode(false);
    }
  }, [props.expanded, deleteMode]);

  const handleStrategyDelete = useCallback(async () => {
    if (deleting) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setDeleting(true);
      const response = await StrategyManagerService.deleteStrategy(
        token,
        props.strategyId,
        props.selectedVersionIds.length > 0
          ? props.selectedVersionIds
          : undefined
      );
      triggerToast(response.message, "success", null);
      props.refetchStrategies && props.refetchStrategies();
    } finally {
      setDeleting(false);
      setDeleteMode(false);
    }
  }, [getAccessTokenSilently, triggerToast, props, setDeleting, deleting]);

  const deleteButton = useCallback(() => {
    return (
      <CommonIconButton
        activeTheme={props.activeTheme}
        onClick={() => {
          setDeleteMode(true);
        }}
        borderTheme="red-accent"
        style={{fontSize: 'smaller'}}
        loading={deleting}
        icon={<FiTrash2 />}
      />
    );
  }, [props.activeTheme, setDeleteMode, deleting]);

  const deleteConfirmation = useCallback(() => {
    return (
      <motion.div className="strategy-delete-confirmation-container">
        <label className="dimmed-label">
          Delete{" "}
          {props.selectedVersionIds.length === 0
            ? "strategy?"
            : `${props.selectedVersionIds.length} version${
                props.selectedVersionIds.length > 1 ? "s" : ""
              }?`}
        </label>
        <CommonButton
          activeTheme={props.activeTheme}
          onClick={() => {
            handleStrategyDelete();
          }}
          loading={deleting}
          customLoadingText="Deleting"
          borderTheme="red-accent"
          leftIcon={<FiTrash2 />}
          label={"Yes, delete"}
          compact
        />
        {!deleting && (
          <CommonButton
            activeTheme={props.activeTheme}
            onClick={() => {
              setDeleteMode(false);
            }}
            leftIcon={<FiX />}
            label={"Cancel"}
            compact
          />
        )}
      </motion.div>
    );
  }, [
    props.selectedVersionIds,
    props.activeTheme,
    handleStrategyDelete,
    deleting,
    setDeleteMode,
  ]);

  const [showShiftLabel, setShowShiftLabel] = useState(false);

  useEffect(() => {
    if (props.selectedVersionIds.length > 1) return;
    const interval = setInterval(() => {
      setShowShiftLabel((prev) => !prev);
    }, 8000); // Change every 8 seconds

    return () => clearInterval(interval);
  }, [props.selectedVersionIds.length]);

  return (
    <motion.div className="strategy-result-owner-footer-container">
      {deleteMode ? (
        deleteConfirmation()
      ) : (
        <>
          <AnimatePresence exitBeforeEnter>
            <motion.span
              key={showShiftLabel ? "shiftLabel" : "countLabel"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <label className="dimmed-label">
                {showShiftLabel && props.selectedVersionIds.length <= 1
                  ? "Hold shift to select multiple"
                  : `${props.selectedVersionIds.length} of ${props.versionCount} versions selected`}
              </label>
            </motion.span>
          </AnimatePresence>

          {deleteButton()}
        </>
      )}
      <label className="dimmed-label">{props.versionCount} versions</label>
    </motion.div>
  );
}
