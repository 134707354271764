import "./currency-logo-array.scss";
import { Avatar, MantineSize, Popover, Tooltip } from "@mantine/core";
import { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { Currency } from "../../../interfaces/Currency";
import { getCurrencyUrl } from "../../../utils/cdnUtil";

interface Props {
  currencies: Currency[] | undefined;
  previewAmount: number;
  size: MantineSize;
  expandable: boolean;

  id?: string;
}

export function CurrencyLogoArray(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);

  return (
    <div
      className="currency-array-container"
      id={props.id}
      style={{
        gap: props.size === "xs" ? "0.2rem" : "0.4rem",
      }}
    >
      {props.currencies?.slice(0, props.previewAmount).map((currency, idx) => {
        const url = getCurrencyUrl(currency);
        const tooltipLabel = `#${currency.cmc_rank} ${currency.currency_name}`;
        return (
          <Tooltip
            key={idx}
            className="currency-array-item"
            label={tooltipLabel}
          >
            <Avatar src={url} size={props.size} radius={"lg"} />
          </Tooltip>
        );
      })}
      {props.currencies && props.currencies.length > props.previewAmount && props.expandable ? (
        <Popover
          withArrow
          onMouseOver={() => setOpened(true)}
          onMouseLeave={() => setOpened(false)}
          position="right"
          width={
            props.currencies.length - props.previewAmount > 5 ? 260 : "auto"
          }
          opened={opened}
          onClose={() => setOpened(false)}
          target={<FiPlus onClick={() => setOpened((o) => !o)} />}
        >
          <div className="currency-array-popover">
            {props.currencies
              .slice(props.previewAmount)
              .map((currency, idx) => {
                const url = getCurrencyUrl(currency);
                const tooltipLabel = `#${currency.cmc_rank} ${currency.currency_name}`;

                return (
                  <Tooltip
                    key={idx}
                    className="currency-array-item"
                    style={{ padding: "3px" }}
                    label={tooltipLabel}
                  >
                    <Avatar src={url} size={props.size} radius={"lg"} />
                  </Tooltip>
                );
              })}
          </div>
        </Popover>
      ) : (
        <></>
      )}
    </div>
  );
}
