import { NeotonLoader } from "../../components/custom-loaders/NeotonLoader";
import "./loading-page.scss";

interface Props {}
export function LoadingPage(props: React.PropsWithChildren<Props>) {
  return (
    <div className="loading-page-container">
      <NeotonLoader />
    </div>
  );
}
