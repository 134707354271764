export const lightTheme = {
  brandLogoText: "#0f0e0f",
  brandLogoTextOpen: "#ffffff",
  body: "#DFE2DF",
  card: "#dadada",
  cardTransparent: "rgb(218, 218, 218, .7)",
  ferrisCardTransparent: "rgb(218, 218, 218, .1)",
  walletStatFont: "#363537",
  popup: "#E8EBED",
  customTooltip: "rgba(247, 251, 255, .3)",
  customTooltip2: "rgba(247, 251, 255, .8)",
  customTooltip3: "rgb(236, 236, 236, .5)",
  cardInterior: '#a9aeb3',
  cardInteriorAlt: 'rgba(186, 189, 187, .8)',
  cardInteriorHover: '#018567',
  segmentedControl: '#cdd3d9',
  walletDistributionHeader: "#363537",
  tradingCard1: '#e3e7ea',
  tradingCard2: '#A3A3A3',
  actionIndicator1: '#02B98F',
  actionIndicator2: '#0F7173',
  profitLabel: '#0F7173',
  deficitLabel: "#f92672",
  actionIndicatorHolding1: '#E23C47',
  actionIndicatorHolding2: '#DF2935',
  subtleBuyOrderStroke: "rgb(15, 113, 115, .2)",
  buyOrderStroke: "rgb(15, 113, 115, .4)",
  buyOrderStrokeHover: "rgb(15, 113, 115, 1.0)",
  buyOrderAreaY: "rgb(15, 113, 115, .2)",
  buyOrderAreaX: "rgb(15, 113, 115, .6)",
  subtleSellOrderStroke: "rgb(249, 38, 114, .2)",
  sellOrderStroke: "rgb(249, 38, 114, .4)",
  sellOrderStrokeHover: "rgb(249, 38, 114, 1.0)",
  sellOrderAreaY: "rgb(249, 38, 114, 0.2)",
  sellOrderAreaX: "rgb(249, 38, 114, 0.6)",
  combinedOrder: "rgba(231, 208, 54, .4)",
  background1: '#b4b8b9',
  background2: '#c0c1c1',
  PlayButtonbackground1: '#ced4da',
  PlayButtonbackground2: '#dfe2df',
  PlayButtonbackground3: 'rgba(186, 189, 187, .8)',
  PlayButtonbackground4: 'rgba(186, 189, 187, .8)',
  PlayButtonbackground5: '#d7dad7',
  chronoCardBackground1: 'rgba(186, 189, 187)',
  chronoCardBackground2: '#ced4da',
  componentContainer: '#ababab',
  scrollTrack: "#ced4da",
  text: "#131313",
  textInverted: "#fff",
  marketReturnsLine: "rgb(54, 53, 55, .5)",
  // sidemenu
  menu: "#28262A",
  menuInterior: "#3d3a40",
  menuText: "#DFE2DF",
  menuItem: "#333036",
  menuItemHover: "#0f9e98",
  menuItemSelected: "#7f807e",
  menuItemSelectedAlt: "rgb(13, 12, 13, .7)",
  themeToggle: "#DFE2DF",
  //button
  validButton: "#333036",
  invalidButton: "#ced4da",
  invalidButtonHover: "#3399ef",
  //checkbox
  checkboxBackground: "#ececec",
  checkboxBorder: "#ced4da",
  //date select
  dateSelectInput: "#ced4da",
  //fragments
  fragmentConnector: "#d8d8d8",
  fragmentOperator: "#afafaf",
  avdfragmentOperator: "#4f60cc",
  fragmentNumber: "#9cabec",
  fragmentVariable: "#8deb99",
  fragmentVariableTa: "#6379ff",
  fragmentVariableAi: "#04f0e2",
  fragmentPriceHead: '#038C83',
  fragmentCandle: "#8deb99",
  fragmentPattern: "#BC3BCB",
  highMc: "#04F0E2",
  mediumMC: "#03B5A9",
  lowMC: "#027971",
  neoton: "#0f9e98",
  neotonTransparent: "rgb(15, 158, 152, .5)",
  ciSignals: "#f7931a",

  strategyOverlayFilter: "blur(10px)",
  //status colors
  warning: "rgb(255, 162, 13)",
  error: "rgb(146, 22, 66)",
  success: "rgb(15, 113, 115, .6)",
  //chart
  rsiLine: "#4C1552",
  rsiThreshold: "#8D3696",
  btcPairBadgeColor: "#f7931a",
  usdPairBadgeColor: "#00a668",
  mediumWarningColor: "#CC8707",
  subtleOverlayBackground: "rgb(192, 193, 192, .02)",
  orderOptionsTheme: "#5a6dc2",

  //strategyControlButtons
  backtestColor: '#02B98F',
  publishColor: '#4A9CBF',
  saveColor: '#00959d',
  restoreColor: '#67A1A9',

  //shadow
  commonBoxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
  commonInsetShadow: "rgba(0, 0, 0, 0.01) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.05) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  commonSubtleInsetShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.02) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.01) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  //stripe plans
  basicPlan: "rgb(64, 90, 191, .3)",
  basicPlanHover: "#4f60cc",
  proPlan: "rgb(1, 253, 238, .3)",
  proPlanHover: "#01fdee",

};


export const darkTheme = {
  brandLogoText: "#ffffff",
  brandLogoTextOpen: "#ffffff",
  body: "#2C333E",
  card: "#201D23",
  cardTransparent: "rgb(32, 29, 35, .5)",
  ferrisCardTransparent: "rgb(32, 29, 35, .1)",
  walletStatFont: "#fff",
  popup: "#232027",
  customTooltip: "rgba(32, 29, 35, .3)",
  customTooltip2: "rgba(32, 29, 35, .8)",
  customTooltip3: "rgb(26, 27, 30, .5)",
  cardInterior: 'rgba(87, 81, 92, .7)',
  cardInteriorAlt: '#27232a',
  cardInteriorHover: '#02b98f',
  segmentedControl: 'rgba(87, 81, 92, .7)',
  walletDistributionHeader: "#211e23",
  tradingCard1: '#28242C',
  tradingCard2: '#28242C',
  profitLabel: '#02B98F',
  deficitLabel: "#f92672",
  actionIndicator1: '#02B98F',
  actionIndicator2: '#16F4D0',
  actionIndicatorHolding1: '#CB0E01',
  actionIndicatorHolding2: '#FE1C0B',
  subtleBuyOrderStroke: "rgb(2, 185, 143, .2)",
  buyOrderStroke: "rgb(2, 185, 143, .4)",
  buyOrderStrokeHover: "rgb(2, 185, 143, 1.0)",
  buyOrderAreaY: "rgb(2, 185, 143, .2)",
  buyOrderAreaX: "rgb(2, 185, 143, .6)",
  subtleSellOrderStroke: "rgb(249, 38, 114, .2)",
  sellOrderStroke: "rgb(249, 38, 114, .4)",
  sellOrderStrokeHover: "rgb(249, 38, 114, 1.0)",
  sellOrderAreaY: "rgb(249, 38, 114, .2)",
  sellOrderAreaX: "rgb(249, 38, 114, .6)",
  combinedOrder: "rgba(231, 208, 54, .4)",
  background1: '#0A090B',
  background2: '#1b1a1c',
  PlayButtonbackground1: '#1b1a1c',
  PlayButtonbackground2: '#0A090B',
  PlayButtonbackground3: '#19171c',
  PlayButtonbackground4: 'rgba(20, 21, 23, .8)',
  PlayButtonbackground5: '#19171c',
  chronoCardBackground1: '#161619',
  chronoCardBackground2: '#1b1a1c',
  componentContainer: '#1c1c1c',
  scrollTrack: "#333036",
  text: "#B2B3B1",
  textInverted: "#080811",
  marketReturnsLine: "rgb(122, 134, 185, .5)",
  // sidemenu
  menu: "#201D23",
  menuInterior: "#27232a",
  menuText: "#DFE2DF",
  menuItem: "#29252D",
  menuItemHover: "#0f9e98",
  menuItemSelected: "#7f807e",
  menuItemSelectedAlt: "rgb(13, 12, 13, .3)",
  themeToggle: "#02b98f",
  //button
  validButton: "#39af8c",
  invalidButton: "#2c2e33",
  invalidButtonHover: "#02b98f",
  //checkbox
  checkboxBackground: "#1A1B1E",
  checkboxBorder: "#5c5f66",
  //date select
  dateSelectInput: "#2c2e33",
  //fragments
  fragmentConnector: "#d8d8d8",
  fragmentOperator: "#afafaf",
  avdfragmentOperator: "#4f60cc",
  fragmentNumber: "#9cabec",
  fragmentVariable: "#8deb99",
  fragmentVariableTa: "#6379ff",
  fragmentVariableAi: "#04f0e2",
  fragmentPriceHead: '#038C83',
  fragmentCandle: "#8deb99",
  fragmentPattern: "#BC3BCB",
  highMc: "#04F0E2",
  mediumMC: "#03B5A9",
  lowMC: "#027971",
  neoton: "#04f0e2",
  neotonTransparent: "rgb(4, 240, 226, .5)",
  ciSignals: "#f7931a",
  // filter
  strategyOverlayFilter: "blur(50px)",

  //status colors
  warning: "rgb(255, 162, 13, .6)",
  error: "rgb(146, 22, 66)",
  success: "rgb(2, 185, 143, .6)",
  //chart
  rsiLine: "#D580DF",
  rsiThreshold: "#d209e9",
  btcPairBadgeColor: "#f7931a",
  usdPairBadgeColor: "#03DD62",
  mediumWarningColor: "#E3F09B",
  subtleOverlayBackground: "rgb(5, 5, 14, .3)",
  orderOptionsTheme: "#5a6dc2",

  //strategyControlButtons
  backtestColor: '#02B98F',
  publishColor: '#4A9CBF',
  saveColor: '#00959d',
  restoreColor: '#67A1A9',
  //shadow
  commonBoxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
  commonInsetShadow: "rgba(0, 0, 0, 0.3) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  commonSubtleInsetShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.02) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.01) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  //stripe plans
  basicPlan: "rgb(64, 90, 191, .3)",
  basicPlanHover: "#4f60cc",
  proPlan: "rgb(1, 253, 238, .3)",
  proPlanHover: "#01fdee",
};


export const nightTheme = {
  brandLogoText: "#ffffff",
  brandLogoTextOpen: "#ffffff",
  body: "#2C333E",
  card: "#080811",
  cardTransparent: "rgb(8, 8, 17, .7)",
  ferrisCardTransparent: "rgb(8, 8, 17, .1)",
  walletStatFont: "#fff",
  popup: "#0b0b17",
  customTooltip: "rgb(5, 5, 14, .5)",
  customTooltip2: "rgba(32, 29, 35, .8)",
  customTooltip3: "rgb(5, 5, 14, .5)",
  cardInterior: 'rgba(87, 81, 92, .2)',
  cardInteriorAlt: '#0e0e1e',
  cardInteriorHover: '#02b98f',
  segmentedControl: 'rgb(11, 11, 26, .2)',
  walletDistributionHeader: "#211e23",
  tradingCard1: '#0b0b1a',
  tradingCard2: '#0b0b1a',
  profitLabel: '#02B98F',
  deficitLabel: "#f92672",
  actionIndicator1: '#02B98F',
  actionIndicator2: '#16F4D0',
  actionIndicatorHolding1: '#CB0E01',
  actionIndicatorHolding2: '#FE1C0B',
  subtleBuyOrderStroke: "rgb(2, 185, 143, .2)",
  buyOrderStroke: "rgb(2, 185, 143, .4)",
  buyOrderStrokeHover: "rgb(2, 185, 143, 1.0)",
  buyOrderAreaY: "rgb(2, 185, 143, .2)",
  buyOrderAreaX: "rgb(2, 185, 143, .6)",
  subtleSellOrderStroke: "rgb(249, 38, 114, .2)",
  sellOrderStroke: "rgb(249, 38, 114, .4)",
  sellOrderStrokeHover: "rgb(249, 38, 114, 1.0)",
  sellOrderAreaY: "rgb(249, 38, 114, .2)",
  sellOrderAreaX: "rgb(249, 38, 114, .6)",
  combinedOrder: "rgba(231, 208, 54, .4)",
  background1: '#020205',
  background2: '#05050e',
  PlayButtonbackground1: '#05050e',
  PlayButtonbackground2: '#0A090B',
  PlayButtonbackground3: '#05050e',
  PlayButtonbackground4: '#05050e',
  PlayButtonbackground5: '#05050e',
  chronoCardBackground1: '#161619',
  chronoCardBackground2: '#1b1a1c',
  componentContainer: 'rgb(25, 25, 33)',
  scrollTrack: "rgb(11, 11, 26, .5)",
  text: "#B2B3B1",
  textInverted: "#080811",
  marketReturnsLine: "rgb(122, 134, 185, .5)",
  // sidemenu
  menu: "#080811",
  menuInterior: "#0e0e1e",
  menuText: "#DFE2DF",
  menuItem: "#101023",
  menuItemHover: "#0f9e98",
  menuItemSelected: "#54516e",
  menuItemSelectedAlt: "rgb(8, 8, 21, .7)",
  themeToggle: "#02b98f",
  //button
  validButton: "#39af8c",
  invalidButton: "#2c2e33",
  invalidButtonHover: "#02b98f",
  //checkbox
  checkboxBackground: "#05050e",
  checkboxBorder: "#5c5f66",
  //date select
  dateSelectInput: "#2c2e33",
  //fragments
  fragmentConnector: "#d8d8d8",
  fragmentOperator: "#afafaf",
  avdfragmentOperator: "#4f60cc",
  fragmentNumber: "#9cabec",
  fragmentVariable: "#8deb99",
  fragmentVariableTa: "#6379ff",
  fragmentVariableAi: "#04f0e2",
  fragmentPriceHead: '#038C83',
  fragmentCandle: "#8deb99",
  fragmentPattern: "#BC3BCB",
  highMc: "#04F0E2",
  mediumMC: "#03B5A9",
  lowMC: "#027971",
  neoton: "#04f0e2",
  neotonTransparent: "rgb(4, 240, 226, .5)",
  ciSignals: "#f7931a",
  // filter
  strategyOverlayFilter: "blur(50px)",

  //status colors
  warning: "rgb(255, 162, 13, .6)",
  error: "rgb(146, 22, 66)",
  success: "rgb(2, 185, 143, .6)",
  //chart
  rsiLine: "#D580DF",
  rsiThreshold: "#d209e9",
  btcPairBadgeColor: "#f7931a",
  usdPairBadgeColor: "#04F16B",
  mediumWarningColor: "#E3F09B",
  subtleOverlayBackground: "rgb(5, 5, 14, .3)",
  orderOptionsTheme: "#5a6dc2",

  //strategyControlButtons
  backtestColor: '#02B98F',
  publishColor: '#4A9CBF',
  saveColor: '#00959d',
  restoreColor: '#67A1A9',
  //shadow
  commonBoxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
  commonInsetShadow: "rgba(0, 0, 0, 0.3) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  commonSubtleInsetShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.02) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.01) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px",
  //stripe plans
  basicPlan: "rgb(64, 90, 191, .3)",
  basicPlanHover: "#4f60cc",
  proPlan: "rgb(1, 253, 238, .3)",
  proPlanHover: "#01fdee",
};
