import { FiPlus } from "react-icons/fi";
import { ApplicationIndicators } from "../../../interfaces/Indicators";
import { useCallback } from "react";
import { IndicatorsInfo } from "../../../shared-service-contexts/IndicatorsInfoContext";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import {
  getTierColors,
  getTranslatedCriteriaDependencies,
} from "../../../utils/ParseCriteriaUtil";
import { Badge, Tooltip } from "@mantine/core";
import { TierBadges } from "./TierBadges";

interface Props {
  activeTheme: string;
  candleSize: string;
  expanded: boolean;
  onClick: () => void;
  label: string;
  applicationIndicators: ApplicationIndicators | undefined;
  criterias: string[];
  addNewCriteria: () => void;
  readonly?: boolean;
  indicatorsInfo?: IndicatorsInfo;
  criteriaType: "entry" | "exit";
}
export function CriteriaCandleSizeHeader(
  props: React.PropsWithChildren<Props>
) {
  const criteriasDependencies =
    props.applicationIndicators &&
    getTranslatedCriteriaDependencies(
      props.criterias.join(" ; "),
      props.applicationIndicators
    );

  const renderCriteriaDependencies = useCallback(() => {
    if (!criteriasDependencies) {
      return <label className="dimmed-label">Empty</label>;
    }
    const maxDisplayCount = 2;
    const badgeRadius = "sm";

    const renderCriteriaBadges = (calculationProfile: "TA" | "AI" | "PT") => {
      const criteriaDeps =
        criteriasDependencies[`${calculationProfile.toLowerCase()}Indicators`];
      return criteriaDeps.map((indicator) => {
        return (
          <Badge
            variant="outline"
            size="xs"
            radius={badgeRadius}
            className="indicator-badge"
            key={indicator}
            color={getTierColors(calculationProfile)}
          >
            {indicator}
          </Badge>
        );
      });
    };

    return (
      <div className="criteria-dependencies-container">
        {criteriasDependencies.aiIndicators.length > maxDisplayCount && (
          <Tooltip
            withArrow
            classNames={{
              root: "action-indicator-root-class",
              arrow: "action-indicator-arrow-class",
              body: "action-indicator-body-class",
            }}
            label={
              <div className="criteria-badges-column">
                {renderCriteriaBadges("AI")}
              </div>
            }
          >
            <TierBadges collectedCriterias={[]} tiersList={["AI"]} />
          </Tooltip>
        )}
        {criteriasDependencies.taIndicators.length > maxDisplayCount && (
          <Tooltip
            withArrow
            classNames={{
              root: "action-indicator-root-class",
              arrow: "action-indicator-arrow-class",
              body: "action-indicator-body-class",
            }}
            label={
              <div className="criteria-badges-column">
                {renderCriteriaBadges("TA")}
              </div>
            }
          >
            <TierBadges collectedCriterias={[]} tiersList={["TA"]} />
          </Tooltip>
        )}

        {criteriasDependencies.ptIndicators.length > maxDisplayCount && (
          <Tooltip
            withArrow
            classNames={{
              root: "action-indicator-root-class",
              arrow: "action-indicator-arrow-class",
              body: "action-indicator-body-class",
            }}
            label={
              <div className="criteria-badges-column">
                {renderCriteriaBadges("PT")}
              </div>
            }
          >
            <TierBadges collectedCriterias={[]} tiersList={["PT"]} />
          </Tooltip>
        )}
        {criteriasDependencies.aiIndicators.length <= maxDisplayCount &&
          renderCriteriaBadges("AI")}
        {criteriasDependencies.taIndicators.length <= maxDisplayCount &&
          renderCriteriaBadges("TA")}
        {criteriasDependencies.ptIndicators.length <= maxDisplayCount &&
          renderCriteriaBadges("PT")}
      </div>
    );
  }, [criteriasDependencies]);

  return (
    <div
      onClick={props.onClick}
      className={
        props.expanded
          ? `criteria-candle-size-header-container-expanded ${props.criteriaType}`
          : "criteria-candle-size-header-container"
      }
    >
      <div
        className={
          `candle-size-control-item ${props.criteriaType}` +
          (props.expanded ? " selected" : "")
        }
        style={{
          maxWidth: "fit-content",
        }}
      >
        <label>{props.candleSize}</label>
      </div>
      {props.criterias.length > 0 ? (
        renderCriteriaDependencies()
      ) : (
        <label className="dimmed-label">Empty</label>
      )}
      {!props.readonly && (
        <CommonButton
          style={{ marginLeft: "auto", marginRight: "5px" }}
          activeTheme={props.activeTheme}
          onClick={props.addNewCriteria}
          id="add-criteria-button"
          compact
          leftIcon={<FiPlus />}
          label={`Add ${props.candleSize} criteria`}
        />
      )}
    </div>
  );
}
