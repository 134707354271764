import "./wallet-amount.scss";
import CountUp from "react-countup";
import { Tooltip } from "@mantine/core";
import { calcPctChange, determineDecimals, determinePctDecimals, roundToTwo } from "../../../utils/FormattingUtils";
import { WalletTooltipLabel } from "./WalletTooltipLabel";
import { MarketStatistics } from "../../../interfaces/common/MarketStatisticsPayload";

interface Props {
  walletValue: number | undefined;
  initialBalance: number;
  marketStatistics: MarketStatistics | undefined;
}

export function WalletAmount(props: React.PropsWithChildren<Props>) {
  const calculateMarketChange = (
    marketStatistics: MarketStatistics | undefined
  ) => {
    if (!marketStatistics) return 0;
    const initialMarketValue = marketStatistics.valueSnapshots[0];
    const currentMarketValue =
      marketStatistics.valueSnapshots[
        marketStatistics.valueSnapshots.length - 1
      ];
    return calcPctChange(initialMarketValue, currentMarketValue);
  };

  const calculateMarketPL = (
    marketStatistics: MarketStatistics | undefined
  ) => {
    if (!marketStatistics) return 0;
    const initialMarketValue = marketStatistics.valueSnapshots[0];
    const currentMarketValue =
      marketStatistics.valueSnapshots[
        marketStatistics.valueSnapshots.length - 1
      ];
    return currentMarketValue - initialMarketValue;
  };

  const marketPL = calculateMarketPL(props.marketStatistics);
  const marketChange = calculateMarketChange(props.marketStatistics);
  const growth =
    props.walletValue &&
    ((props.walletValue - props.initialBalance) / props.initialBalance) * 100;
  const walletVsMarket = growth && growth - marketChange;
  const walletPL =
    props.walletValue && props.walletValue - props.initialBalance;

  return (
    <div className="wallet-amount-container">
      <Tooltip
        className="wallet-value-container"
        position="bottom"
        withArrow
        label={
          <WalletTooltipLabel
            currentValue={props.walletValue}
            initialValue={props.initialBalance}
          />
        }
      >
        <CountUp
          className="wallet-stat-label"
          decimals={props.walletValue ? determineDecimals(
            props.walletValue
          ) : 0}
          end={props.walletValue ?? props.initialBalance}
          start={props.initialBalance}
          duration={1}
          preserveValue
          prefix="$ "
        />

        <div className="initial-wallet-value-label-container">
          <label className="dimmed-label">Initial balance: </label>
          <label className="wallet-stat-label-minor">
            $ {roundToTwo(props.initialBalance)}
          </label>
        </div>
      </Tooltip>

      {props.walletValue && walletPL && growth ? (
        <Tooltip
          className="wallet-growth"
          label={
            <>
              <label>
                Wallet P/L: <br />
                <strong>$ {walletPL.toFixed(2)}</strong>
              </label>
            </>
          }
          position="right"
          withArrow
        >
          <div className="wallet-growth-tooltip-target">
            <label className="dimmed-label">Wallet return: </label>
            <CountUp
              className={
                !growth
                  ? "wallet-stat-label"
                  : growth > 0
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              end={growth}
              start={0}
              preserveValue
              suffix=" %"
              duration={1}
              decimals={determinePctDecimals(growth)}
            />
          </div>
        </Tooltip>
      ) : undefined}
      {props.marketStatistics && (
        <Tooltip
          className="wallet-growth"
          label={
            <>
              <label>
                Market P/L: <br />
                <strong>$ {marketPL.toFixed(2)}</strong>
              </label>
            </>
          }
          position="right"
          withArrow
        >
          <div className="wallet-growth-tooltip-target">
            <label className="dimmed-label">Market return: </label>
            <CountUp
              className={
                !marketChange
                  ? "wallet-stat-label"
                  : marketChange > 0
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              end={marketChange}
              preserveValue
              start={0}
              suffix=" %"
              duration={1}
              decimals={determinePctDecimals(marketChange)}
            />
          </div>
        </Tooltip>
      )}
      {props.walletValue && walletPL && walletVsMarket ? (
        <Tooltip
          className="wallet-growth"
          label={
            <>
              <label>
                Excess P/L: <br />
                <strong>$ {(walletPL - marketPL).toFixed(2)}</strong>
              </label>
            </>
          }
          position="right"
          withArrow
        >
          <div className="wallet-growth-tooltip-target">
            <label className="dimmed-label">Excess return: </label>
            <CountUp
              className={
                !walletVsMarket
                  ? "wallet-stat-label"
                  : walletVsMarket > 0
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              end={walletVsMarket}
              preserveValue
              start={0}
              suffix=" %"
              duration={1}
              decimals={determinePctDecimals(walletVsMarket)}
            />
          </div>
        </Tooltip>
      ) : undefined}
    </div>
  );
}
