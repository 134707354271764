import { motion } from "framer-motion";
import "./common-candle-size-control.scss";

interface Props {
  selectedCandleSize: string;
  onClick: (candleSize: string) => void;
  hideLabel?: boolean;
  themeColor?: "buy" | "sell";
  oneDayDisabled?: boolean;
  oneHourDisabled?: boolean;
  fiveMinuteDisabled?: boolean;
  id?: string;
}
export function CommonCandleSizeControl(props: React.PropsWithChildren<Props>) {
  const candleSizes = ["1D", "1h", "5m"];

  return (
    <div id={props.id} className="common-candle-size-control-container">
      {!props.hideLabel && (
        <div className="upper-candle-size-control-container">
          <label>Timeframe</label>
        </div>
      )}
      <div className="lower-candle-size-control-container">
        {candleSizes.map((candleSize, idx) => {
          if (
            (candleSize === "1D" && props.oneDayDisabled) ||
            (candleSize === "1h" && props.oneHourDisabled) ||
            (candleSize === "5m" && props.fiveMinuteDisabled)
          ) {
            return null;
          }
          return (
            <motion.div
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              transition={{ duration: 0.2, delay: idx * 0.1 }}
              key={candleSize}
              onClick={() => props.onClick(candleSize)}
              className={
                "candle-size-control-item" +
                (props.selectedCandleSize === candleSize ? " selected" : "") +
                (props.themeColor ? ` ${props.themeColor}` : "")
              }
            >
              <label>{candleSize}</label>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
