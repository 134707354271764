import { Space } from "@mantine/core";
import {
  Heading,
  List,
  ListItem,
  Paragraph,
  Section,
} from "../../pages/legal/Typegraphy";
import "./legal-component.scss";

interface Props {
  printRef?: React.LegacyRef<HTMLDivElement> | undefined;
}
export function PrivacyPolicy(props: React.PropsWithChildren<Props>) {
  const effectiveDate = "April 17, 2024";

  return (
    <div className="privacy-policy-container" ref={props.printRef}>
      <Section>
        <Heading>Privacy Policy</Heading>
        <Paragraph>Effective Date: {effectiveDate}</Paragraph>
        <Paragraph>Welcome to Neoton Crypto Trading Software.</Paragraph>
        <Paragraph>
          Please read this Privacy Policy carefully before using the Neoton
          Crypto Trading Software (referred to as "Neoton," "we," "us," or
          "our"). By accessing or using our trading Software, you ("you" or
          "user") agree to the practices described in this Privacy Policy. If
          you do not agree to this Privacy Policy, please do not use our
          services.
        </Paragraph>
        <Paragraph>
          This Privacy Policy delineates how we gather, utilize, share, and
          safeguard your personal information when you access or utilize our
          trading software. By employing Neoton, you explicitly consent to the
          practices elucidated in this Privacy Policy.
        </Paragraph>
        <Paragraph>
          Please dedicate the necessary time to scrutinize these Terms
          meticulously, as they regulate your utilization of the Neoton Crypto
          Trading Software. By accessing or utilizing our software, you indicate
          that you have perused, comprehended, and assented to these Terms, as
          well as our Privacy Policy. These Terms establish a legally binding
          agreement between you and Neoton.
        </Paragraph>
        <Paragraph>
          Thank you for selecting Neoton. We are thrilled to have you join us
          and eagerly anticipate assisting you in accomplishing your trading
          objectives.
        </Paragraph>
      </Section>
      <Section>
        <Heading>1. Personal Information</Heading>
        <List>
          <ListItem>
            1.1 When you visit Neoton.io, we automatically collect information
            about you and your use of Neoton.io, such as the type of browser you
            use, the search terms you use on Neoton.io, your IP address
            including your network location, and information about your
            computer. In addition, Neoton’s Cookie Policy applies when you use
            Neoton.io.
            <List>
              <Space h={"sm"} />
              <ListItem>
                1.1.1 The purpose is to optimize the user experience and
                functionality of Neoton.io, as well as to conduct targeted
                marketing, including retargeting via platforms such as Facebook
                and Google. This processing of information is necessary for us
                to pursue our interests in improving Neoton.io and showing you
                relevant offers.
              </ListItem>
              <ListItem>
                1.1.2 The legal basis for the processing is Article 6(1)(f) of
                the EU General Data Protection Regulation.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            1.2 When you purchase a subscription or communicate with us, we
            collect the information you provide, such as name, email address,
            phone number, payment method, information about which subscription
            plans you purchase, and information about the IP address from which
            the order is placed when the order is placed on Neoton.io.
            <List>
              <Space h={"sm"} />
              <ListItem>
                1.2.1 The purpose is for us to be able to deliver the
                subscription plans you have ordered and otherwise fulfill our
                agreement with you, including being able to manage your rights
                to complain, as well as if necessary, to contact you regarding
                relevant information about the subscription you have purchased.
                We may also process information about your purchases to comply
                with legal requirements, including those regarding product
                withdrawals, bookkeeping, and accounting. When purchasing on
                Neoton.io, the IP address is collected for the purpose of
                pursuing our interest in preventing fraud.
              </ListItem>
              <ListItem>
                1.2.2 The legal basis for the processing is Article 6(1)(b), (c)
                and (f) of the EU General Data Protection Regulation.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            1.3 When you subscribe to our newsletter, we collect information
            about your name and email address.
            <List>
              <Space h={"sm"} />
              <ListItem>
                1.3.1 The purpose is to pursue our interest in being able to
                deliver newsletters to you.
              </ListItem>
              <ListItem>
                1.3.2 The legal basis for the processing is Article 6(1)(f) of
                the EU General Data Protection Regulation.
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>2. Sharing of Information</Heading>
        <List>
          <ListItem>
            2.1 Third-Party Service Providers: We may share your information
            with third-party service providers, such as Stripe, to facilitate
            payments, conduct data analysis, and deliver customer support.
          </ListItem>
          <ListItem>
            2.2 Legal Requirements: We may disclose your information to comply
            with legal obligations, respond to lawful requests, protect our
            rights, or ensure the safety and security of our users.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>3. Security Measures</Heading>
        <Paragraph>
          We employ industry-standard security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction.
        </Paragraph>
      </Section>
      <Section>
        <Heading>4. Your Choices</Heading>
        <List>
          <ListItem>
            4.1 Access and Correction: You can access and update your account
            information through your Neoton account settings.
          </ListItem>
          <ListItem>
            4.2 Communication Preferences: You can manage your communication
            preferences by adjusting your account settings or following the
            instructions in our emails.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>5. Cookies and Tracking</Heading>
        <List>
          <ListItem>
            5.1 Cookies: We may use cookies and similar technologies to collect
            information about your interactions with our software and to enhance
            your user experience.
          </ListItem>
          <ListItem>
            5.2 Data Collection and Usage: In order to provide a personalized
            and secure experience, Neoton collects various types of data from
            users when they interact with our website, neoton.io. This
            information helps us to improve our platform and deliver a service
            that meets our users' needs. Below are the types of data we collect:
            <List>
              <Space h={"sm"} />
              <ListItem>
                5.2.1 Browser Type: We collect information about the type of
                browser you are using (e.g., Chrome, Firefox, Safari) to
                optimize our website's performance and ensure compatibility with
                the various technologies used by our users.
              </ListItem>
              <ListItem>
                5.2.2 Search Terms: When you use our platform's search features,
                we collect the search terms you enter. This data is used to
                improve the relevance of results provided by Neoton and to
                understand what users are most interested in. IP Address and
                Network Location: Your IP address and network location are
                collected when you visit neoton.io. This information is used for
                diagnostic purposes, to prevent fraud, and to manage geographic
                access and features in accordance with legal requirements.
              </ListItem>
              <ListItem>
                5.2.3 Information About User's Computer: We collect information
                about the computing device you use to access our service, such
                as the operating system, hardware version, device settings, file
                and software names and types, battery and signal strength, and
                device identifiers. This data is used to ensure that Neoton
                functions correctly on your device and to provide assistance in
                the event of technical difficulties.
              </ListItem>
              <ListItem>
                5.2.4 Usage Data: Information on how you interact with
                neoton.io, such as the pages you visit, the time spent on those
                pages, the links clicked, and the frequency of access, may also
                be collected. This helps us understand user behavior within our
                platform, allowing us to improve the user experience. Please
                note that we take your privacy seriously and implement a variety
                of security measures to protect your personal information.
                However, we encourage you to review our full Privacy Policy to
                understand our data protection practices and your rights
                regarding your personal data.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            5.3 Improving Neoton.io Through Data Use: At Neoton, we believe in
            constantly enhancing our platform to deliver an exceptional
            experience to our users. The information we collect plays a vital
            role in this ongoing improvement process. Here's how we use the data
            to better Neoton.io:
            <List>
              <Space h={"sm"} />
              <ListItem>
                5.3.1 Optimization and Compatibility: By understanding the types
                of browsers our users operate, we can tailor Neoton.io to
                function seamlessly across a wide range of web browsers and
                versions, ensuring accessibility for all users.
              </ListItem>
              <ListItem>
                5.3.2 Search Relevance: Analyzing search terms entered by our
                users allows us to refine our search algorithms. This leads to
                more accurate and relevant search results, helping users find
                the information they need faster.
              </ListItem>
              <ListItem>
                5.3.3 Security and Access: IP addresses and network location
                data are critical for identifying and mitigating potential
                security risks. They help us maintain the integrity of our
                platform and ensure that Neoton.io is accessible to users in
                compliance with geographic and legal restrictions.
              </ListItem>
              <ListItem>
                5.3.4 Device Compatibility: Information about the users'
                computers, including operating systems and hardware
                configurations, aids us in optimizing Neoton.io to deliver the
                best performance on a variety of devices, from desktops to
                mobile phones.
              </ListItem>
              <ListItem>
                5.3.5 User Experience Enhancement: By analyzing how users
                interact with our platform, we can identify popular features and
                those that may need improvement. This enables us to focus our
                development efforts where they matter most to our community.
              </ListItem>
              <ListItem>
                5.3.6 Personalization: Usage data helps us to personalize the
                user experience on Neoton.io. We can provide recommendations and
                content tailored to individual user preferences and behaviors,
                making the platform more intuitive and user-friendly.
              </ListItem>
            </List>
          </ListItem>
        </List>
        <Paragraph>
          Every piece of data collected is a step towards a more efficient,
          secure, and user-centric Neoton.io. We commit to transparency in our
          data practices and pledge to use this information responsibly and
          ethically, with the sole purpose of elevating the user experience on
          our platform.
        </Paragraph>
      </Section>
      <Section>
        <Heading>6. Your Rights</Heading>
        <List>
          <ListItem>
            6.1 Right of access
            <List>
              <ListItem>
                6.1.1 You have the right to request information from us at any
                time about, among other things, what information we have
                recorded about you, the purpose of the registration, which
                categories of personal data and recipients of information there
                may be, and information about where the information comes from.
              </ListItem>
              <ListItem>
                6.1.2 You have the right to receive a copy of the personal
                information that we process about you. If you wish to receive a
                copy of your personal information, you must send a written
                request to neoton@neoton.io. You will be asked to prove that you
                are who you claim to be.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.2 Right of rectification
            <List>
              <ListItem>
                6.2.1 You have the right to have inaccurate personal information
                about yourself rectified by us. If you become aware that there
                are errors in the information we have registered about you, you
                are encouraged to contact us in writing so that the information
                can be corrected. You will be asked to prove that you are who
                you claim to be.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.3 Right to erasure
            <List>
              <ListItem>
                6.3.1 In certain cases, you have the right to have all or some
                of your personal information deleted by us, for example, if you
                revoke your consent and we do not have another legal basis for
                continuing the processing. To the extent that continued
                processing of your information is necessary, for example for us
                to comply with our legal obligations or for the establishment,
                exercise, or defense of legal claims, we are not obliged to
                delete your personal information. You will be asked to prove
                that you are who you claim to be.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.4 Right to restriction of processing for storage only
            <List>
              <ListItem>
                6.4.1 In certain cases, you have the right to have the
                processing of your personal information restricted to storage
                only, for example if you believe that the information we process
                about you is not correct.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.5 Right to data portability
            <List>
              <ListItem>
                6.5.1 In certain cases, you have the right to receive personal
                information that you have provided to us in a structured,
                commonly used, and machine-readable format and have the right to
                transfer this information to another data controller.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.6 Right to object
            <List>
              <ListItem>
                6.6.1 You have the right to object at any time to our processing
                of your personal information for the purposes of direct
                marketing, including the profiling that is carried out in order
                to target our direct marketing, as this processing is based on
                our legitimate interests, as stated in sections 2.1 and 2.3. If
                you do not want us to use your personal information for direct
                marketing and profiling, you should delete your cookies, as
                mentioned in section 5.1 below.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.7 Right to withdraw consent
            <List>
              <ListItem>
                6.7.1 You have the right at any time to withdraw a consent you
                have given us for a given processing of personal information,
                including for the sending of newsletters.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            6.8 Right to lodge a complaint: You have the right at any time to
            lodge a complaint with the Danish Data Protection Agency, Carl
            Jacobsens Vej 35, 2500 Copenhagen, about our processing of your
            personal information. Complaints can, among other things, be
            submitted by email to dt@datatilsynet.dk or by phone at +45 33 19 32
            00.
          </ListItem>
        </List>
      </Section>
      <Section>
        <Heading>7. Changes to this Privacy Policy</Heading>
        <Paragraph>
          We may update this Privacy Policy as our services evolve. Any changes
          will be posted on our website, and your continued use of Neoton after
          such changes signifies your acceptance of the updated policy.
        </Paragraph>
      </Section>

      <Section>
        <Heading>8. Contact Information</Heading>
        <Paragraph>
          If you have questions or concerns about this Privacy Policy, please
          contact us at{" "}
          <a href="mailto:neoton@neoton.io">
            <strong>neoton@neoton.io</strong>
          </a>
          .
        </Paragraph>
      </Section>
      <Section>
        <Heading>9. Conclusion and Acknowledgement</Heading>
        <Paragraph>
          This Privacy Policy is part of the Neoton Crypto Trading Software's
          agreement. By using the software, you agree to abide by the Terms of
          Service and this Privacy Policy. Thank you for choosing Neoton!
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>Neoton © 2024. All rights reserved.</Paragraph>
      </Section>
      <Section>
        <Space h={"xl"} />
        <Space h={"xl"} />
        <Space h={"xl"} />
      </Section>
    </div>
  );
}
