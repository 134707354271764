import { useCallback } from "react";
import "./common-dashboard-button.scss";
import { motion } from "framer-motion";

interface Props {
  label: string;
  onClick?: () => void;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  labelClassName?: string;
}
export function CommonDashboardSelectButton(
  props: React.PropsWithChildren<Props>
) {
  return (
    <>
      {!props.disabled ? (
        <motion.div
          className="common-dashboard-select-button"
          onClick={props.onClick}
          whileTap={{ scale: !props.disabled ? 0.9 : 1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {props.leftIcon && <div className="left-icon">{props.leftIcon}</div>}
          <motion.label
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className={props.labelClassName}
          >
            {props.label}
          </motion.label>
          {props.rightIcon && (
            <div className="right-icon">{props.rightIcon}</div>
          )}
        </motion.div>
      ) : (
        <motion.div
          className="common-dashboard-select-button"
          whileTap={{ scale: !props.disabled ? 0.9 : 1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: .7 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {props.leftIcon && <div className="left-icon">{props.leftIcon}</div>}
          <motion.label
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.2, delay: 0.2 }}
            className={props.labelClassName}
          >
            {props.label}
          </motion.label>
          {props.rightIcon && (
            <div className="right-icon">{props.rightIcon}</div>
          )}
        </motion.div>
      )}
    </>
  );
}
