import { MotionIndicatorButton } from "./MotionButton";
import { useCallback, useState } from "react";
import { Chip, ColorSwatch, Modal, Popover, Space, Text } from "@mantine/core";
import { VscSettings } from "react-icons/vsc";
import { CustomColorPicker } from "./CustomColorPicker";
import { ATRSettings } from "../../../interfaces/chartIndicators/ATRSettings";

interface Props {
  activeTheme: string;
  atrSettings: ATRSettings;
  handleAtrSettingsChange: (settings: ATRSettings) => void;
}
export function ATRControls(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);
  const [colorPickerOpened, setColorPickerOpened] = useState(false);
  const color = props.activeTheme === "light" ? "dark" : "gray";

  const [currentColorToChangeValue, setCurrentColorToChangeValue] =
    useState<string>(props.atrSettings.atrColor);

  const handleColorToChange = useCallback(
    (color: string) => {
      props.atrSettings.atrColor = color;
      setColorPickerOpened(false);
      props.handleAtrSettingsChange(props.atrSettings);
    },
    [props, setColorPickerOpened]
  );

  const handleColorPickerOpen = useCallback(
    (fieldToUpdate: "inside" | "outside") => {
      setColorPickerOpened(true);
      setCurrentColorToChangeValue(props.atrSettings.atrColor);
    },
    [props.atrSettings, setColorPickerOpened]
  );

  return (
    <>
      <Popover
        opened={opened}
        clickOutsideEvents={colorPickerOpened ? ["onClick"] : undefined}
        onClose={() => setOpened(false)}
        transition="slide-up"
        position="bottom"
        target={
          <MotionIndicatorButton
            onClick={(e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            }}
          >
            <VscSettings size={12} />
            <label>ATR</label>
          </MotionIndicatorButton>
        }
        className="indicator-popover"
      >
        <div className="indicator-popover-outer-container">
          <Text className="header" color={"dimmed"}>
            Average True Range
          </Text>
          <div className="indicator-popover-container">
            <Space h={"xs"} />
            <div className="indicator-popover-row">
              <Chip
                checked={props.atrSettings.atrEnabled}
                color={color}
                variant="outline"
                value="RSI Enabled"
                onChange={() => {
                  const newSettings = props.atrSettings;
                  newSettings.atrEnabled = !newSettings.atrEnabled;
                  props.handleAtrSettingsChange(newSettings);
                }}
              >
                toggled
              </Chip>
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                ATR Color
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("inside")}
                style={{ cursor: "pointer" }}
                color={props.atrSettings.atrColor}
                size={15}
                radius="sm"
              />
            </div>
          </div>
        </div>
      </Popover>
      <Modal
        opened={colorPickerOpened}
        onClose={() => setColorPickerOpened(false)}
      >
        <CustomColorPicker
          currentColor={currentColorToChangeValue}
          handleColorChange={handleColorToChange}
        />
      </Modal>
    </>
  );
}
