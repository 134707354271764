import "./common-button.scss";
import { motion } from "framer-motion";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";

interface Props {
  activeTheme: string;
  label?: string | React.ReactNode;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  forcePropagate?: boolean;
  loading?: boolean;
  compact?: boolean;
  ellipsis?: boolean;
  customLoadingText?: string;
  forceTextColor?: string;
  borderTheme?:
    | "red-accent"
    | "green-accent"
    | "blue-accent"
    | "yellow-accent"
    | "purple-accent"
    | "gray-accent"
    | "neoton-accent"
    | "orange-accent";
  keepWidth?: boolean;
  noMinWidth?: boolean;
  flat?: boolean;
  style?: React.CSSProperties;
  onlyFitContent?: boolean;
  dimmedLabel?: boolean;
  labelStyle?: React.CSSProperties;
  id?: string;
}
export function CommonButton(props: React.PropsWithChildren<Props>) {
  return (
    <div
      className={
        "common-button-container" +
        (props.borderTheme ? " " + props.borderTheme : "") +
        (props.compact ? " compact" : "") +
        (props.keepWidth ? " keep-width" : "") +
        (props.flat ? " flat" : "") +
        (props.onlyFitContent ? " only-fit-content" : "") +
        (props.noMinWidth ? " no-min-width" : "")
      }
      id={props.disabled ? "disabled-common-button" : props.id}
      onClick={(e: any) => {
        e?.stopPropagation();
        !props.disabled && !props.loading && props.onClick && props.onClick();
      }}
      style={props.style}
    >
      {props.leftIcon && !props.loading && (
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.2 }}
          className="left-icon-container"
        >
          {props.leftIcon}
        </motion.div>
      )}
      {props.loading && (
        <motion.div
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.2 }}
          className="loading-container"
        >
          {!props.compact && (
            <NeotonSpinner activeTheme={props.activeTheme} gray />
          )}
        </motion.div>
      )}
      {props.loading ? (
        <motion.div
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.2 }}
          className="label-container"
        >
          {props.customLoadingText ?? "Loading"}{" "}
          <strong className="dot-1">.</strong>
          <strong className="dot-2">.</strong>
          <strong className="dot-3">.</strong>
        </motion.div>
      ) : (
        <>
          {!props.loading && (
            <motion.div
              initial={{ opacity: 0, x: 10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className={
                "label-container" + (props.ellipsis ? " ellipsis" : "")
              }
              style={{
                color: props.forceTextColor ?? undefined,
              }}
            >
              {props.dimmedLabel ? (
                <label className="dimmed-label" style={props.labelStyle}>
                  {props.label}
                </label>
              ) : (
                props.label
              )}
            </motion.div>
          )}
        </>
      )}
    </div>
  );
}
