import { AxiosInstance } from "axios";
import { Candles } from "../interfaces/Candle";
import { TierLimitations } from "../interfaces/common/UserLimitations";
import { Currency } from "../interfaces/Currency";
import { ApplicationIndicators } from "../interfaces/Indicators";
import { PricesOverview } from "../interfaces/PricesOverview";
import {
  PublicStrategiesOverview,
  StrategiesOverview,
} from "../interfaces/strategyInterfaces/StrategiesOverview";
import { createOptions } from "./CreateOptions";
import { CustomResponse } from "../interfaces/Response";
import { StrategyVersion } from "../interfaces/strategyInterfaces/Strategy";
import { CurrencyInfo } from "../interfaces/common-trading/CurrencyInfo";
import {
  MarketStatistics,
  MarketStatisticsPayload,
} from "../interfaces/common/MarketStatisticsPayload";
import {
  LiveStrategies,
  StrategyMetadata,
  StrategyVersionPaperwalletItem,
  UserStrategyItem,
} from "../interfaces/common/UserStrategies";
import { TickerResponse } from "../interfaces/common-trading/Tickers";
import { PricesOverviewRequestPayload } from "../pages/common/TradingDashboard";
import {
  CommonTradingReport,
  CommonWallet,
} from "../interfaces/common-trading/CommonTradingReport";
import { FeedItem } from "../interfaces/common/Feed";
import { Metrics } from "../interfaces/metrics/Metrics";
import { Seasons } from "../interfaces/season/Seasons";

class BlueprintService {
  public axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  public getIndicatorsInfo = async (token: string) => {
    const options = createOptions(token);

    try {
      return await this.axiosInstance.get<any>("/indicators-info", options);
    } catch (error) {
      throw error;
    }
  };

  public getSeasons = async (token: string) => {
    const options = createOptions(token);

    try {
      return await this.axiosInstance.get<Seasons>("/seasons", options);
    } catch (error) {
      throw error;
    }
  };

  public getUserStrategiesDeprecated = async (token: string) => {
    const options = createOptions(token);
    try {
      const response = await this.axiosInstance.get<
        CustomResponse<StrategiesOverview>
      >("/strategies", options);
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public getAllPublicStrategies = async (token: string) => {
    const options = createOptions(token);
    try {
      const response = await this.axiosInstance.get<
        CustomResponse<PublicStrategiesOverview>
      >("/get-published-test-strategies", options);
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public loadStrategy = async (
    token: string,
    strategyId: string,
    versionId: string
  ) => {
    const options = createOptions(token);
    const body = {
      strategy_id: strategyId,
      version_id: versionId,
    };
    try {
      const response = await this.axiosInstance.post<
        CustomResponse<StrategyVersion>
      >("/strategy/load", body, options);
      if (response.data.error !== null) throw response.data.error;
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  public getLimitations = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.get<TierLimitations>(
        "/limitations",
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getCurrencies = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      const response = await this.axiosInstance.get<{
        [key: string]: Currency;
      }>("/currencies", options);
      const _currencies = Object.values(response.data) as Currency[];
      // lets sort them by cmc_rank
      _currencies.sort((a, b) => a.cmc_rank - b.cmc_rank);
      return _currencies;
    }
    throw new Error("Access Token is required");
  };

  public getIndicators = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.get<ApplicationIndicators>(
        "/indicators",
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getCandles = async (
    token: string,
    selectedCurrency: string,
    candleSize: string,
    startTimestamp: number,
    stopTimestamp: number
  ) => {
    const options = createOptions(token);

    const body = {
      currency_name: selectedCurrency,
      candle_size: candleSize,
      start: startTimestamp,
      stop: stopTimestamp,
    };
    if (options && token !== null) {
      return await this.axiosInstance.post<Candles>(
        "/tradingdata2/prices/candles",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getPricesOverview = async (
    token: string,
    payload: PricesOverviewRequestPayload
  ) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.post<PricesOverview>(
        "/tradingdata2/prices/overview",
        payload,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getCurrencyInfo = async (token: string, currencyName: string) => {
    const options = createOptions(token);

    const body = {
      currency_name: currencyName,
    };
    if (options && token !== null) {
      return await this.axiosInstance.post<CurrencyInfo>(
        "/currency-info",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getMarketStatistics = async (
    token: string,
    marketStatisticsPayload: MarketStatisticsPayload
  ) => {
    const options = createOptions(token);
    const body = {
      ...marketStatisticsPayload,
    };

    if (options && token !== null) {
      return await this.axiosInstance.post<MarketStatistics>(
        "/statistics/market",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getStrategyMetadata = async (token: string, strategyId: string) => {
    const options = createOptions(token);

    if (options && token !== null) {
      const body = {
        strategy_id: strategyId,
      };
      return await this.axiosInstance.post<CustomResponse<StrategyMetadata>>(
        "/strategy-metadata",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getUserStrategies = async (token: string) => {
    const options = createOptions(token);

    if (options && token !== null) {
      return await this.axiosInstance.get<
        CustomResponse<{ [key: string]: UserStrategyItem[] }>
      >("/user-strategies", options);
    }
    throw new Error("Access Token is required");
  };

  public getLiveStrategies = async (token: string) => {
    const options = createOptions(token);

    if (options && token !== null) {
      return await this.axiosInstance.get<CustomResponse<LiveStrategies[]>>(
        "/live-strategies",
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getStrategyPaperwallets = async (
    token: string,
    versionIds: string[],
    season?: string
  ) => {
    const options = createOptions(token);
    const body = {
      version_ids: versionIds,
    };
    if (season) {
      body["season"] = season;
    }
    if (options && token !== null) {
      return await this.axiosInstance.post<
        CustomResponse<{ [key: string]: StrategyVersionPaperwalletItem | null }>
      >("/strategy-paperwallets", body, options);
    }
    throw new Error("Access Token is required");
  };

  public getTickers = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.get<TickerResponse>("/tickers", options);
    }
    throw new Error("Access Token is required");
  };

  public getMetrics = async (
    token: string,
    report?: CommonTradingReport,
    wallet?: CommonWallet,
    livewalletId?: string,
    fetch_from_blob: boolean = false
  ) => {
    //DEPRECATED
    const options = createOptions(token);
    const body = {
      fetch_from_blob: fetch_from_blob,
    };
    if (report) {
      body["report"] = report;
    }
    if (wallet) {
      body["wallet"] = wallet;
    }
    if (livewalletId) {
      body["livewallet_id"] = livewalletId;
    }
    if (options && token !== null) {
      return await this.axiosInstance.post<Metrics>(
        "/statistics/metrics",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };

  public getFeed = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.get<FeedItem[]>("/feed", options);
    }
    throw new Error("Access Token is required");
  };

  //admin
  public getDatabaseSizes = async (token: string) => {
    const options = createOptions(token);
    if (options && token !== null) {
      return await this.axiosInstance.get<{ [key: string]: string }>(
        "/admin/database-sizes",
        options
      );
    }
    throw new Error("Access Token is required");
  };
  public getCandleDatabaseOverview = async (
    token: string,
    currencyName: string
  ) => {
    const options = createOptions(token);
    const body = {
      currency_name: currencyName,
    };
    if (options && token !== null) {
      return await this.axiosInstance.post<CandleDatabaseOverview>(
        "/admin/candle-database-overview",
        body,
        options
      );
    }
    throw new Error("Access Token is required");
  };
}

export default BlueprintService;

export interface CandleDatabaseOverview {
  candle_amount_1D: number;
  candle_amount_1h: number;
  candle_amount_5m: number;
  first_candle_size_1D: number;
  first_candle_size_1h: number;
  first_candle_size_5m: number;
  latest_candle_size_1D: number;
  latest_candle_size_1h: number;
  latest_candle_size_5m: number;
}
