export function getAllowedTimeInterval(
  timestampNow: number,
  allowedCandleCount: number,
  candleSize: string,
  centerPointTimestamp: number,
  lowerBound: number = Number.MIN_SAFE_INTEGER,
  upperBound: number = Number.MAX_SAFE_INTEGER
): number[] {
  let incrementCount = 0;
  let stopTimestamp = centerPointTimestamp;
  let startTimestamp = centerPointTimestamp;
  const candleSizeIncrement =
    candleSize === "1D"
      ? incrementDay()
      : candleSize === "1h"
      ? incrementHour()
      : increment5Min();
  //future
  for (let i = 0; i < allowedCandleCount / 2; i++) {
    const incrementedTimestamp = stopTimestamp + candleSizeIncrement;
    if (
      incrementedTimestamp <= timestampNow &&
      incrementedTimestamp <= upperBound
    ) {
      stopTimestamp = incrementedTimestamp;
      incrementCount += 1;
    }
  }
  //past
  for (let i = 0; i < allowedCandleCount - incrementCount; i++) {
    const incrementedTimestamp = startTimestamp - candleSizeIncrement;
    if (incrementedTimestamp >= lowerBound) {
      startTimestamp = incrementedTimestamp;
    }
  }

  // Adjusting the start and stop timestamps to not exceed the bounds
  startTimestamp = Math.max(startTimestamp, lowerBound);
  stopTimestamp = Math.min(stopTimestamp, upperBound);

  return [startTimestamp, stopTimestamp];
}

// count candles in the interval
export function getCandleCount(
  candleSize: string,
  startTimestamp: number,
  stopTimestamp: number
): number {
  let count = 0;
  if (candleSize === "1D") {
    count = Math.floor((stopTimestamp - startTimestamp) / incrementDay());
  }
  if (candleSize === "1h") {
    count = Math.floor((stopTimestamp - startTimestamp) / incrementHour());
  }
  if (candleSize === "5m") {
    count = Math.floor((stopTimestamp - startTimestamp) / increment5Min());
  }
  return count;
}

export function incrementDay() {
  return 60 * 60 * 24 * 1000;
}
export function incrementHour() {
  return 60 * 60 * 1000;
}
export function increment5Min() {
  return 60 * 5 * 1000;
}

export const offsetTimestamp = (
  candle_size: string,
  timestamp: number,
  n: number,
  direction: "left" | "right"
) => {
  if (candle_size === "1D") {
    if (direction === "left") {
      return timestamp - n * incrementDay();
    }
    return timestamp + n * incrementDay();
  }
  if (candle_size === "1h") {
    if (direction === "left") {
      return timestamp - n * incrementHour();
    }
    return timestamp + n * incrementHour();
  }
  if (candle_size === "5m") {
    if (direction === "left") {
      return timestamp - n * increment5Min();
    }
    return timestamp + n * increment5Min();
  }
  return timestamp;
};
