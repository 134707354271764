import { motion } from "framer-motion";

interface Props {
  onClick: (e: any) => void;
}
export function MotionIndicatorButton(props: React.PropsWithChildren<Props>) {
  return (
    <motion.div
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.2, delay: 0.1 }}
      exit={{ scale: 0.5 }}
      className="indicator-settings-button"
      onClick={props.onClick}
    >
      {props.children}
    </motion.div>
  );
}
