import "./strategy-search-result.scss";
import { motion } from "framer-motion";
import { ElasticManagerService } from "../../../services/ElasticManagerService";
import { UserProfileComponent } from "../../community/user-profile-component/UserProfileComponent";
import { useCallback, useContext, useMemo, useState } from "react";
import { TierBadges } from "../../strategy-body/case-control/TierBadges";
import CountUp from "react-countup";
import {
  calcPctChange,
  roundToTwo,
  toUTCTimestring,
} from "../../../utils/FormattingUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyVersionPaperwalletItem } from "../../../interfaces/common/UserStrategies";
import { useQuery } from "react-query";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { useMediaQuery } from "@mantine/hooks";
import { WalletValueChart } from "../../chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import { AiOutlineLineChart } from "react-icons/ai";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { Currency } from "../../../interfaces/Currency";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { FeaturedButton, StrategyOwnerMenu } from "./StrategyOwnerMenu";
import { StrategyOwnerFooter } from "./StrategyOwnerFooter";
import { CurrencyLogoArray } from "../../common/common-currencies/CurrencyLogoArray";
import { getTheme } from "../../../utils/themeUtil";
import { StrategyVersionIcon } from "../../common/identicon/StrategyVersionIcon";
import { Tooltip } from "@mantine/core";
import { ApplicationSettingsContext } from "../../../App";
interface Props {
  expanded: boolean;
  index: number;
  strategyId: string;
  strategyResult: CommonStrategyItem | undefined;
  setExpandedId: (id: string | undefined) => void;
  searchQuery?: string;
  paperwalletStats?: { [key: string]: StrategyVersionPaperwalletItem | null };
  traderType?: TraderType;
  refetchStrategies?: () => void;
  featuredButton?: FeaturedButton;
  loadingPaperwalletStats?: boolean;
}
export function StrategySearchResultComponent(
  props: React.PropsWithChildren<Props>
) {
  const { getAccessTokenSilently } = useAuth0();
  const currencies = useContext(CurrencyContext);
  const applicationSettings = useContext(ApplicationSettingsContext);
  const activeTheme = applicationSettings?.theme ?? "dark";
  const compact = useMediaQuery("(max-width: 768px)");

  const maxCurrencyDisplay = useMemo(() => (compact ? 3 : 6), [compact]);
  const theme = useMemo(() => getTheme(activeTheme), [activeTheme]);
  const user = useMemo(
    () => props.strategyResult?.user,
    [props.strategyResult]
  );

  const [loadingPaperwalletStats, setLoadingPaperwalletStats] = useState(false);
  const [sortMethod, setSortMethod] = useState<"performance" | "latest">(
    "performance"
  );
  const [animating, setAnimating] = useState(false);
  const [selectedVersionIds, setSelectedVersionIds] = useState<string[]>(
    props.strategyResult?.versions?.[0]?.version_id
      ? [props.strategyResult?.versions?.[0]?.version_id]
      : []
  );

  const versionIds = useMemo(() => {
    return (
      props.strategyResult?.versions?.map((version) => version.version_id) ?? []
    );
  }, [props.strategyResult]);

  const fetchPaperwalletStats = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!props.strategyResult) return;
    try {
      setLoadingPaperwalletStats(true);
      const blueprintService = props.traderType
        ? getBlueprintService(props.traderType)
        : ElasticManagerService.blueprint;

      const response = await blueprintService.getStrategyPaperwallets(
        token,
        versionIds
      );
      return response.data;
    } finally {
      setLoadingPaperwalletStats(false);
    }
  }, [
    getAccessTokenSilently,
    setLoadingPaperwalletStats,
    props.strategyResult,
    props.traderType,
    versionIds,
  ]);

  const paperwalletStatsQuery = useQuery(
    ["paperwalletStats", props.strategyId, props.expanded],
    fetchPaperwalletStats,
    {
      enabled:
        props.expanded && !props.paperwalletStats && versionIds.length > 0,
      cacheTime: 60000,
      staleTime: 0,
    }
  );

  const paperwalletStats = useMemo(() => {
    return props.paperwalletStats ?? paperwalletStatsQuery?.data?.payload ?? {};
  }, [paperwalletStatsQuery?.data?.payload, props.paperwalletStats]);

  const expandedBoxShadow =
    "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px";
  const subtleInsetBoxShadow =
    "rgba(0, 0, 0, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.02) 0px 18px 36px -18px inset, rgba(0, 0, 0, 0.01) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px";

  const sortedVersions = useMemo(() => {
    if (!props.expanded)
      return props.strategyResult?.versions?.sort((a, b) => {
        return b.current_balance - a.current_balance;
      });

    return sortMethod === "performance"
      ? props.strategyResult?.versions?.sort((a, b) => {
          return b.current_balance - a.current_balance;
        })
      : props.strategyResult?.versions?.sort((a, b) => {
          return b.created_on - a.created_on;
        });
  }, [props.expanded, sortMethod, props.strategyResult?.versions]);

  const CompactVersionRow = useCallback(
    (
      strategyId: string,
      idx: number,
      version: CommonStrategyVersionItem,
      pctChange: number
    ): JSX.Element | undefined => {
      const versionCurrencies = currencies?.filter((currency) =>
        version.currencies.includes(currency.currency_name)
      );

      return (
        <motion.div
          key={idx}
          className="strategy-search-result-strategy-version"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.2, delay: 0.1 }}
          exit={{ opacity: 0 }}
        >
          <Tooltip
            position="right"
            classNames={{
              root: "action-indicator-root-class",
              arrow: "action-indicator-arrow-class",
              body: "action-indicator-body-class",
            }}
            label={
              <TierBadges tiersList={version.tier} collectedCriterias={[]} />
            }
          >
            <StrategyVersionIcon
              version_id={version.version_id}
              strategy_id={strategyId}
              included_currencies={version.currencies}
              activeTheme={activeTheme}
              size={20}
              disableMenu
            />
          </Tooltip>
          <CountUp
            className={
              roundToTwo(pctChange) === 0.0
                ? "wallet-stat-label"
                : pctChange > 0
                ? "wallet-stat-label-profit"
                : "wallet-stat-label-deficit"
            }
            style={{
              opacity: pctChange === 0 ? 0.5 : 1,
            }}
            decimals={pctChange > 0 && pctChange < 100 ? 2 : 0}
            suffix="%"
            duration={props.expanded ? 2 : 0}
            end={pctChange}
            start={0}
          />
          <div className="version-row-tier-badges-container">
            {versionCurrencies && (
              <CurrencyLogoArray
                currencies={versionCurrencies}
                previewAmount={3}
                size={"xs"}
                expandable
              />
            )}
          </div>
        </motion.div>
      );
    },
    [activeTheme, props.expanded, currencies]
  );

  const ExpandedVersionRow = useCallback(
    (
      version: CommonStrategyVersionItem,
      versionCurrencies: Currency[] | undefined,
      paperwalletItem: StrategyVersionPaperwalletItem | undefined,
      isWinning: boolean,
      pctChange: number,
      selectedVersionIds: string[],
      setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>
    ): JSX.Element | undefined => {
      const selected = selectedVersionIds.includes(version?.version_id);
      const colorLess =
        !paperwalletItem || !paperwalletItem?.wallet_value_snapshots
          ? true
          : paperwalletItem.wallet_value_snapshots?.valueSnapshots[0] ===
            paperwalletItem.wallet_value_snapshots?.valueSnapshots[
              paperwalletItem.wallet_value_snapshots?.valueSnapshots.length - 1
            ];
      return (
        <motion.div
          key={version?.version_id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.3 }}
          className={
            "search-result-paper-wallet-stats" + (selected ? " selected" : "")
          }
          onClick={(e: any) => {
            e.stopPropagation();
            if (!e.shiftKey) {
              setSelectedVersionIds([version?.version_id]);
              return;
            }
            if (selectedVersionIds.includes(version?.version_id)) {
              setSelectedVersionIds(
                selectedVersionIds.filter((id) => id !== version?.version_id)
              );
            } else {
              setSelectedVersionIds([
                ...selectedVersionIds,
                version?.version_id,
              ]);
            }
          }}
        >
          <label className="search-result-date-label">
            {toUTCTimestring(version.created_on, "1h")}
          </label>
          <StrategyVersionIcon
            version_id={version.version_id}
            strategy_id={props.strategyId}
            included_currencies={version.currencies}
            activeTheme={activeTheme}
            size={30}
            disableMenu
          />
          <div className="search-result-paper-wallet-stats-inner">
            <div className="search-result-currencies-container">
              <CurrencyLogoArray
                currencies={versionCurrencies ?? []}
                previewAmount={maxCurrencyDisplay}
                size={"xs"}
                expandable
              />
              <TierBadges
                style={{ marginLeft: "auto" }}
                tiersList={version.tier}
                collectedCriterias={[]}
                size="xs"
                noMargin
              />
            </div>
            {!paperwalletItem ? (
              <label className="dimmed-label">
                This version is missing a report
              </label>
            ) : (
              <>
                {paperwalletItem?.wallet_value_snapshots?.valueSnapshots
                  .length > 1 ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="version-paperwallet-chart-container"
                    >
                      <WalletValueChart
                        currencyPair={paperwalletItem.currency_pair}
                        colorLess={colorLess}
                        isWinning={isWinning}
                        walletValueSnapshots={
                          paperwalletItem.wallet_value_snapshots
                        }
                        activeTheme={activeTheme}
                        marketStatistics={undefined}
                        animate={false}
                        compact
                        heightOverride={45}
                        disableTooltip
                      />
                    </motion.div>
                    <div
                      className="change-container"
                      style={{
                        height: 40,
                        alignSelf: "flex-start",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        minWidth: 40,
                      }}
                    >
                      <CountUp
                        className={
                          roundToTwo(pctChange) === 0.0
                            ? "wallet-stat-label"
                            : pctChange > 0
                            ? "wallet-stat-label-profit"
                            : "wallet-stat-label-deficit"
                        }
                        style={{
                          opacity: pctChange === 0 ? 0.5 : 1,
                        }}
                        decimals={pctChange !== 0 && pctChange < 100 ? 2 : 0}
                        suffix="%"
                        duration={0}
                        end={pctChange}
                        start={0}
                      />
                    </div>
                  </>
                ) : (
                  <div className="data-unavailable-container">
                    <AiOutlineLineChart size={25} />
                    <label className="dimmed-label">Report unavailable</label>
                  </div>
                )}
              </>
            )}
          </div>
        </motion.div>
      );
    },
    [props.strategyId, activeTheme, maxCurrencyDisplay]
  );

  return (
    <motion.div
      id={props.expanded ? "expanded-search-result" : ""}
      className="strategy-search-result-container"
      onClick={(e: any) => {
        e.stopPropagation();
        props.setExpandedId(props.strategyId ?? undefined);
      }}
      onAnimationStart={() => {
        setAnimating(true);
      }}
      onAnimationComplete={() => {
        setAnimating(false);
      }}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        width: props.expanded ? "400px" : "auto",
        maxWidth: !props.expanded ? "200px" : "400px",
        minWidth: props.expanded ? "99%" : "auto",
        flex: props.expanded ? "0 1 99%" : "140px",
        height: props.expanded ? "250px" : "auto",
        maxHeight: "250px",
        borderBottomLeftRadius: props.expanded ? "60px" : "10px",
        borderTopLeftRadius: props.expanded ? "60px" : "10px",
        boxShadow: props.expanded ? expandedBoxShadow : subtleInsetBoxShadow,
        border: props.expanded
          ? `1px solid transparent`
          : `1px solid ${theme.cardInteriorAlt}
        `,
      }}
      transition={{
        duration: 0.2,
      }}
      exit={{ opacity: 0 }}
    >
      {user && !props.strategyResult?.isOwner && (
        <div className="strategy-search-result-user-container">
          {!animating && (
            <UserProfileComponent
              activeTheme={activeTheme}
              userHash={user.user_hash}
              username={user.username}
              userTitle={user.title ?? undefined}
              size={props.expanded ? 160 : 50}
              tiny={!props.expanded}
              clickable={props.expanded}
            />
          )}
        </div>
      )}
      {props.strategyResult?.isOwner && props.expanded && (
        <StrategyOwnerMenu
          activeTheme={activeTheme}
          strategyId={props.strategyId}
          selectedVersionIds={selectedVersionIds}
          setSelectedVersionIds={setSelectedVersionIds}
          refetchStrategies={props.refetchStrategies}
          featuredButton={props.featuredButton}
          setExpandedId={props.setExpandedId}
        />
      )}
      {!animating && sortedVersions && (
        <div
          className={
            "strategy-search-result-strategy-container" +
            (props.expanded ? " expanded" : "")
          }
        >
          {props.expanded &&
            props.strategyResult &&
            props.strategyResult?.versions &&
            props.strategyResult?.versions?.length > 1 && (
              <div className="strategy-search-result-strategy-sort-menu">
                <label
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setSortMethod("performance");
                  }}
                  className={
                    "sort-label-button" +
                    (sortMethod === "performance" ? " selected" : "")
                  }
                >
                  Papertrader performance
                </label>
                <label
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setSortMethod("latest");
                  }}
                  className={
                    "sort-label-button" +
                    (sortMethod === "latest" ? " selected" : "")
                  }
                >
                  Latest
                </label>
              </div>
            )}

          <div
            className={
              "expanded-search-result-content" +
              (props.expanded ? " expanded" : "")
            }
          >
            {props.expanded && !animating ? (
              <>
                {(loadingPaperwalletStats && props.expanded) ||
                (props.loadingPaperwalletStats && props.expanded) ? (
                  <div className="loading-stats-container">
                    <NeotonLoader />
                  </div>
                ) : (
                  <>
                    {paperwalletStats &&
                      props.strategyResult?.versions &&
                      props.strategyResult?.versions.map((version) => {
                        const paperwalletItem =
                          paperwalletStats[version?.version_id] ?? undefined;
                        const versionCurrencies = currencies
                          ?.filter((currency) =>
                            version?.currencies.includes(currency.currency_name)
                          )
                          .slice(0, maxCurrencyDisplay);
                        const isWinning =
                          version?.current_balance && version?.initial_balance
                            ? version.current_balance > version?.initial_balance
                            : false;
                        const pctChange =
                          version?.current_balance && version?.initial_balance
                            ? calcPctChange(
                                version?.initial_balance,
                                version?.current_balance
                              )
                            : 0;
                        return ExpandedVersionRow(
                          version,
                          versionCurrencies,
                          paperwalletItem,
                          isWinning,
                          pctChange,
                          selectedVersionIds,
                          setSelectedVersionIds
                        );
                      })}
                  </>
                )}
              </>
            ) : (
              <>
                {sortedVersions.map((version, idx) => {
                  if (!props.expanded && idx > 1) return;
                  if (!version.current_balance || !version.initial_balance)
                    return;
                  const pctChange = calcPctChange(
                    version?.initial_balance,
                    version?.current_balance
                  );
                  return CompactVersionRow(
                    props.strategyId,
                    idx,
                    version,
                    pctChange
                  );
                })}
              </>
            )}
          </div>
          {props.strategyResult && props.strategyResult?.versions && (
            <>
              <label
                className={
                  "strategy-search-result-strategy-title" +
                  (props.expanded
                    ? props.strategyResult.name.length > 20
                      ? " expanded-smaller"
                      : " expanded"
                    : props.strategyResult.name.length > 20 && !props.expanded
                    ? " scroll"
                    : "")
                }
              >
                {props.strategyResult.name}
              </label>
              {props.expanded ? (
                <>
                  {props.strategyResult.isOwner ? (
                    <StrategyOwnerFooter
                      activeTheme={activeTheme}
                      strategyId={props.strategyId}
                      selectedVersionIds={selectedVersionIds}
                      setSelectedVersionIds={setSelectedVersionIds}
                      refetchStrategies={props.refetchStrategies}
                      expanded={props.expanded}
                      versionCount={props.strategyResult.versions.length}
                    />
                  ) : (
                    <label
                      className={
                        "strategy-search-result-strategy-versions-label expanded"
                      }
                    >
                      {props.strategyResult.versions.length} version
                      {props.strategyResult.versions.length > 1 ? "s" : ""}
                    </label>
                  )}
                </>
              ) : (
                <label
                  className={"strategy-search-result-strategy-versions-label"}
                >
                  {"<"} {props.strategyResult.versions.length} {">"}
                </label>
              )}
            </>
          )}
        </div>
      )}
    </motion.div>
  );
}

export interface CommonStrategyItem {
  isOwner: boolean;
  name: string;
  user: CommonUserItem;
  versions?: CommonStrategyVersionItem[];
  match?: number;
  _ts?: number; // cosmos timestamp in seconds
}

export interface CommonUserItem {
  user_hash: string;
  username: string;
  title?: string;
}

export interface CommonStrategyVersionItem {
  currencies: string[];
  currency_pair: string;
  current_balance: number;
  initial_balance: number;
  min_permission: string;
  tags: string[];
  tier: string[];
  version_id: string;
  created_on: number;
}
