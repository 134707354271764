import "./new-strategy-popup.scss";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Input, Divider, Checkbox } from "@mantine/core";
import { FiArrowRight } from "react-icons/fi";
import {
  AppInsightsContext,
  ApplicationSettingsContext,
  SetApplicationSettingsContext,
  ToastContext,
  UserLimitationsContext,
} from "../../App";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { StrategyManagerService } from "../../services/StrategyManagerService";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { useQuery } from "react-query";
import { CommonStrategySelect } from "../common/common-dashboard/CommonStrategySelect";
import { StrategyVersionIcon } from "../common/identicon/StrategyVersionIcon";
import { BiFileBlank } from "react-icons/bi";

interface Props {
  activeTheme: string;
  setNewStrategyDrawerOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NewStrategyPopupContent(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const triggerToast = useContext(ToastContext);
  const appInsights = useContext(AppInsightsContext);
  const limitations = useContext(UserLimitationsContext);
  const applicationSettings = useContext(ApplicationSettingsContext);
  const setApplicationSettings = useContext(SetApplicationSettingsContext);

  const [strategyName, setStrategyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [existingStrategyId, setExistingStrategyId] = useState<string>();
  const [existingVersionId, setExistingVersionId] = useState<string>();
  const [enableTutorial, setEnableTutorial] = useState(false);

  const handleStrategyNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStrategyName(event.target.value);
  };
  const navigate = useNavigate();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      initStrategy();
    }
  };

  const getUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await StrategyManagerService.blueprint.getUserStrategies(
        token
      );
      return response.data;
    } catch (error: any) {
      appInsights?.trackException({ exception: error });
      triggerToast(error, "error", null);
    }
  }, [getAccessTokenSilently, appInsights, triggerToast]);

  const userStrategiesQuery = useQuery("userStrategies", getUserStrategies, {
    keepPreviousData: false,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      if (!data?.payload) return;
      const length = Object.keys(data.payload).length;
      setEnableTutorial(length === 0);
    },
  });

  const existingStrategyNames = useMemo(() => {
    const _excistingStrategyNames: string[] = [];
    if (userStrategiesQuery?.data?.payload) {
      Object.keys(userStrategiesQuery.data.payload).forEach((key) => {
        userStrategiesQuery.data?.payload?.[key].forEach((strategy) => {
          _excistingStrategyNames.push(strategy.name);
        });
      });
    }
    return _excistingStrategyNames;
  }, [userStrategiesQuery]);

  const strategyLimitReached =
    userStrategiesQuery?.data?.payload &&
    limitations &&
    Object.keys(userStrategiesQuery.data.payload).length >=
      limitations.strategies
      ? true
      : false;

  const validInput = useMemo(() => {
    return (
      strategyName.length > 2 &&
      strategyName.length < 29 &&
      !existingStrategyNames.includes(strategyName) &&
      !strategyLimitReached
    );
  }, [strategyName, existingStrategyNames, strategyLimitReached]);

  const initStrategy = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;

    if (!validInput) return;
    try {
      setLoading(true);
      if (applicationSettings) {
        setApplicationSettings({
          ...applicationSettings,
          enableTutorial: enableTutorial,
        });
      }
      const initializationResponse = await StrategyManagerService.initStrategy(
        token,
        strategyName,
        existingStrategyId,
        existingVersionId
      );
      const initializationPayload = initializationResponse.data.payload!;
      props.setNewStrategyDrawerOpened(false);
      triggerToast(initializationResponse.data.message, "success", null);
      navigate(
        `/strategy/${initializationPayload.strategy_id}/${initializationPayload.version_id}`
      );
    } catch (error: any) {
      const message = error["response"]["data"]["error"];
      triggerToast(message, "error", null);
      appInsights?.trackException({ exception: error });
    } finally {
      setLoading(false);
    }
  }, [
    strategyName,
    triggerToast,
    navigate,
    getAccessTokenSilently,
    validInput,
    props,
    appInsights,
    existingStrategyId,
    existingVersionId,
    enableTutorial,
    applicationSettings,
    setApplicationSettings,
  ]);

  return (
    <div className="new-strategy-content">
      <label className="dimmed-label">Enter a strategy name</label>
      <Input
        placeholder="Strategy Name"
        id="strategyNameInput"
        radius="xs"
        size="md"
        onChange={handleStrategyNameInput}
        onKeyPress={handleKeyPress}
        maxLength={28}
      />
      <Divider />
      {existingStrategyNames.length > 0 && (
        <>
          <div className="common-strategy-select-section">
            <CommonStrategySelect
              disableButtons
              activeTheme={props.activeTheme}
              newStrategyPopupMode
              refetchCallback={userStrategiesQuery.refetch}
              attachStrategy={(strategyId, versionId) => {
                setExistingStrategyId(strategyId);
                setExistingVersionId(versionId);
              }}
              traderType={{
                traderType: "strategymanager",
              }}
            />
          </div>
          <Divider />
        </>
      )}
      {strategyLimitReached && (
        <label className="dimmed-label">
          You reached the limited amount of strategies
        </label>
      )}
      <div className="new-strategy-content-row">
        {existingStrategyId && existingVersionId ? (
          <>
            <div
              className="starter-template-container"
              onClick={() => {
                setExistingStrategyId(undefined);
                setExistingVersionId(undefined);
              }}
            >
              <StrategyVersionIcon
                activeTheme={props.activeTheme}
                included_currencies={[]}
                strategy_id={existingStrategyId}
                version_id={existingVersionId}
                size={30}
                disableMenu
                hideLabel
              />
            </div>
            <label
              className="dimmed-label"
              onClick={() => {
                setExistingStrategyId(undefined);
                setExistingVersionId(undefined);
              }}
            >
              Starting from existing strategy. click to remove
            </label>
          </>
        ) : (
          <>
            <label className={"blank-template-container"}>
              Empty strategy
              <BiFileBlank />
            </label>
            {existingStrategyNames.length > 0 && (
              <label className="dimmed-label">
                Pick a strategy to start from
              </label>
            )}
          </>
        )}
      </div>

      <div className="new-strategy-content-row">
        <Checkbox
          size="md"
          checked={enableTutorial}
          onChange={() => setEnableTutorial(!enableTutorial)}
          color="cyan"
          label={
            <label
              onClick={() => setEnableTutorial(!enableTutorial)}
              className="dimmed-label"
            >
              Enable tutorial
            </label>
          }
        />
      </div>
      <CommonButton
        style={{
          alignSelf: "center",
        }}
        activeTheme={props.activeTheme}
        label="Create"
        leftIcon={<FiArrowRight size={20} />}
        onClick={initStrategy}
        loading={loading}
        disabled={!validInput}
      />
    </div>
  );
}
