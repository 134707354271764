import "./livetrader-control.scss";
import { motion } from "framer-motion";
import { CommonDashboardSelectButton } from "../CommonStrategySelectButton";
import { FiArrowUp } from "react-icons/fi";
import { Identicon } from "../../identicon/Identicon";
import { getPreviewName } from "../../../../utils/FormattingUtils";
import { TierBadges } from "../../../strategy-body/case-control/TierBadges";
import { LivetraderReport } from "../../../../interfaces/livetrader/LivewalletReport";
import { ExecutionUpdateCircularProgressBar } from "../../../livetrader-wallet/ExeuctionUpdateProgressBar";
import { useMemo } from "react";
import { getExchangeLogoUrl } from "../../../../utils/cdnUtil";
import { Image } from "@mantine/core";

interface Props {
  activeTheme: string;
  livetraderReport: LivetraderReport | undefined;
  onClick?: () => void;
}
export function LivetraderControlCompact(
  props: React.PropsWithChildren<Props>
) {
  const exchangeUrl = useMemo(() => {
    if (!props.livetraderReport?.common_wallet_report.wallet?.exchange_id)
      return undefined;
    return getExchangeLogoUrl(
      props.livetraderReport.common_wallet_report.wallet.exchange_id,
      props.activeTheme
    );
  }, [
    props.livetraderReport?.common_wallet_report.wallet?.exchange_id,
    props.activeTheme,
  ]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0, y: 10 }}
      className="compact-livetrader-control-container"
    >
      {props.livetraderReport ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0, y: 10 }}
          onClick={props.onClick}
          className="compact-livetrader-control"
        >
          <div className="strategy-section">
            <div className="strategy-section-row">
              <Identicon
                guid={props.livetraderReport.strategy.version_id}
                size={30}
              />
              <label>
                {getPreviewName(props.livetraderReport.strategy.name)}
              </label>
            </div>
            <div className="strategy-section-row">
              <TierBadges
                collectedCriterias={[]}
                tiersList={props.livetraderReport.strategy.tier.split("/")}
              />
            </div>
          </div>

          {props.livetraderReport.execution_status_message && (
            <ExecutionUpdateCircularProgressBar
              activeTheme={props.activeTheme}
              executionTimestamp={
                props.livetraderReport.execution_status_message?.timestamp
              }
              executionStatusMessage={
                props.livetraderReport.execution_status_message
              }
              compact
            />
          )}

          {exchangeUrl && (
            <div className="livetrader-compact-account-container">
              <div className="compact-livetrader-exchange-icon">
                <Image radius="xs" src={exchangeUrl} alt="missing exchange" />
              </div>
            </div>
          )}
        </motion.div>
      ) : (
        <CommonDashboardSelectButton
          label="Attach Trading Account"
          onClick={props.onClick}
          leftIcon={<FiArrowUp />}
        />
      )}
    </motion.div>
  );
}
