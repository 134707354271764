import { lightTheme, darkTheme, nightTheme } from "../style/Themes";

export const getTheme = (activeTheme: string) => {
  switch (activeTheme) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    case "night":
      return nightTheme;
    default:
      return lightTheme;
  }
};
