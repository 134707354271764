import { Space } from "@mantine/core";
import { FiX } from "react-icons/fi";
import { IoIosCodeDownload } from "react-icons/io";
import { MdFormatListNumberedRtl } from "react-icons/md";
import { WalletValueChart } from "../../components/chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import NeotonCard from "../../components/common/common-dashboard/Card";
import { BacktestIntervalPicker } from "../../components/common/common-dashboard/CommonIntervalPicker";
import { CommonOrders } from "../../components/common/common-dashboard/CommonOrders";
import { CommonDashboardSelectButton } from "../../components/common/common-dashboard/CommonStrategySelectButton";
import { CompactTradingOverview } from "../../components/common/common-dashboard/CompactTradingOverview";
import { CompactWalletValue } from "../../components/common/common-dashboard/CompactWalletValue";
import { BacktesterBottomControlCompact } from "../../components/common/common-dashboard/trader-controls/BacktesterBottomControlCompact";
import { LivetraderControlCompact } from "../../components/common/common-dashboard/trader-controls/CompactLivetraderControl";
import { CompactStrategySelectPopup } from "../../components/common/compact-strategy-select/CompactStrategySelectPopup";
import { Identicon } from "../../components/common/identicon/Identicon";
import { NeotonSpinner } from "../../components/custom-loaders/PixelLoader";
import { PapertraderControlCompact } from "../../components/papertader-compact/PapertraderControlCompact";
import { getPreviewName } from "../../utils/FormattingUtils";
import { isEqual } from "../../utils/ObjectEqualsUtil";
import { useContext, useEffect, useMemo, useState } from "react";
import { TraderType } from "./TradingDashboard";
import { CommonWalletReport } from "../../interfaces/common-trading/CommonTradingReport";
import { Currency } from "../../interfaces/Currency";
import { IsBacktestingContext } from "../backtester-page/BacktesterPage";
import { UseQueryResult } from "react-query";
import { MarketStatistics } from "../../interfaces/common/MarketStatisticsPayload";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { PricesOverview } from "../../interfaces/PricesOverview";
import { DateInterval } from "../../interfaces/DateInterval";
import { LivetraderReport } from "../../interfaces/livetrader/LivewalletReport";
interface Props {
  activeTheme: string;
  attachedStrategy: StrategyVersion | undefined;
  traderType: TraderType;
  commonWalletReport: CommonWalletReport | undefined;
  focusedCurrency: Currency | undefined;
  setFocusedCurrency: React.Dispatch<
    React.SetStateAction<Currency | undefined>
  >;
  loadingInitialReport?: boolean;
  marketReturnsQuery: UseQueryResult<MarketStatistics | undefined, unknown>;
  pricesOverviewQuery: UseQueryResult<PricesOverview | undefined, unknown>;
  listContainerChild?: React.ReactNode;
  leaderboardContainerChild?: React.ReactNode;
  YTDInterval: DateInterval;
  dateInterval: DateInterval | undefined;
  setDateInterval: React.Dispatch<
    React.SetStateAction<DateInterval | undefined>
  >;
  livetraderReport?: LivetraderReport | undefined;
  handleAttachStrategy?: (strategyId: string, versionId: string) => void;
  getCurrencyPreviewName: () => string;
  formatDateIntervalToLabel: (_dateInterval: DateInterval) => string;
}
function CompactTradingDashboard(props: React.PropsWithChildren<Props>) {
  const isRunning = useContext(IsBacktestingContext);
  const [bottomToggled, setBottomToggled] = useState(false);
  const [selectMode, setBottomMode] = useState<
    "strategies" | "interval" | "leaderboards" | undefined
  >();
  const [ordersToggled, setOrdersToggled] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (props.focusedCurrency && !ordersToggled) {
      setOrdersToggled(true);
    }
    if (props.loadingInitialReport && bottomToggled) {
      setBottomToggled(false);
    }
    if (
      props.traderType.traderType === "livetrader" &&
      !bottomToggled &&
      !props.loadingInitialReport &&
      initialLoad
    ) {
      setInitialLoad(false);
      setBottomToggled(true);
      setBottomMode("strategies");
    }
  }, [
    props.focusedCurrency,
    ordersToggled,
    setOrdersToggled,
    props.loadingInitialReport,
    bottomToggled,
    props.traderType,
    initialLoad,
  ]);

  const showCompactOverview = useMemo(() => {
    if (!ordersToggled) return true;
    if (ordersToggled && !props.focusedCurrency) return false;
    if (props.focusedCurrency) return true;
    if (ordersToggled && props.focusedCurrency) return true;
    return false;
  }, [ordersToggled, props.focusedCurrency]);

  return (
    <>
      <NeotonCard className="small-screen-header">
        <CompactWalletValue
          activeTheme={props.activeTheme}
          commonWalletReport={props.commonWalletReport}
          traderType={props.traderType}
        />
        <label className="page-title-label">
          {props.traderType.traderType}
        </label>
      </NeotonCard>
      {!bottomToggled && (
        <>
          <NeotonCard
            className={
              "small-screen-orders" + (ordersToggled ? " toggled" : "")
            }
          >
            {!props.loadingInitialReport && props.commonWalletReport && (
              <CommonOrders
                compact
                focusedCurrency={props.focusedCurrency}
                toggle={() => setOrdersToggled(!ordersToggled)}
                activeTheme={props.activeTheme}
                orders={props.commonWalletReport.report?.orders.cases}
                toggled={ordersToggled}
              />
            )}
            {props.loadingInitialReport && (
              <>
                <Space w={"sm"} />
                <NeotonSpinner activeTheme={props.activeTheme} />
                <label>Loading...</label>
              </>
            )}
          </NeotonCard>
          {!ordersToggled && (
            <>
              <NeotonCard className="small-screen">
                {props.commonWalletReport?.report?.current_balance &&
                  props.commonWalletReport?.report?.initial_balance && (
                    <WalletValueChart
                      activeTheme={props.activeTheme}
                      currencyPair="usd"
                      isWinning={
                        props.commonWalletReport.report.current_balance >
                        props.commonWalletReport.report.initial_balance
                      }
                      marketStatistics={props.marketReturnsQuery?.data}
                      walletValueSnapshots={
                        props.commonWalletReport.report.wallet_value_snapshots
                      }
                      compact={true}
                    />
                  )}
              </NeotonCard>
            </>
          )}
          {showCompactOverview && (
            <NeotonCard
              className={
                "small-screen-overview" +
                (props.focusedCurrency ? " focused" : "")
              }
            >
              {props.attachedStrategy && (
                <CompactTradingOverview
                  setFocusedCurrency={(currency) => {
                    props.setFocusedCurrency(currency);
                    if (!currency) {
                      setOrdersToggled(false);
                    }
                  }}
                  focusedCurrency={props.focusedCurrency}
                  activeTheme={props.activeTheme}
                  strategy={props.attachedStrategy}
                  commonWalletReport={props.commonWalletReport}
                  pricesOverviewQuery={props.pricesOverviewQuery?.data}
                  loadingCurrencies={props.pricesOverviewQuery?.isLoading}
                />
              )}
            </NeotonCard>
          )}
        </>
      )}

      <NeotonCard
        className={"small-screen-bottom" + (bottomToggled ? " toggled" : "")}
      >
        {bottomToggled && selectMode === "strategies" && !isRunning && (
          <>
            {props.listContainerChild ? (
              <div className="compact-strategies-popup-container">
                <div className="compact-strategies-popup-header">
                  <label>Trading Accounts</label>
                  <FiX
                    onClick={() => {
                      setBottomToggled(false);
                      setBottomMode(undefined);
                    }}
                  />
                </div>
                <>{props.listContainerChild}</>
              </div>
            ) : (
              <CompactStrategySelectPopup
                toggleBottom={() => {
                  setBottomToggled(false);
                  setBottomMode(undefined);
                }}
                activeTheme={props.activeTheme}
                traderType={props.traderType}
                attachStrategy={(strategyId, versionId) => {
                  if (!props.handleAttachStrategy) return;
                  props.handleAttachStrategy(strategyId, versionId);
                  setBottomToggled(false);
                }}
              />
            )}
          </>
        )}
        {bottomToggled &&
          selectMode === "leaderboards" &&
          props.leaderboardContainerChild && (
            <div className="papertrader-compact-leaderboard-parent">
              <FiX
                size={20}
                onClick={() => {
                  setBottomToggled(false);
                  setBottomMode(undefined);
                }}
              />
              {props.leaderboardContainerChild}
            </div>
          )}
        {props.traderType.traderType === "backtester" &&
          !isRunning &&
          bottomToggled &&
          props.dateInterval &&
          selectMode === "interval" && (
            <BacktestIntervalPicker
              previewCurrencyName={props.getCurrencyPreviewName()}
              formatDateIntervalToLabel={props.formatDateIntervalToLabel}
              dateInterval={props.dateInterval}
              handleClose={() => {
                setBottomToggled(false);
                setBottomMode(undefined);
              }}
              setNewDateInterval={(newDateInterval) => {
                props.setDateInterval(newDateInterval);
                setBottomToggled(false);
                setBottomMode(undefined);
              }}
              activeTheme={props.activeTheme}
            />
          )}
        {!bottomToggled && props.traderType.traderType === "backtester" && (
          <BacktesterBottomControlCompact
            activeTheme={props.activeTheme}
            showBacktestButton={!ordersToggled}
            attachedStrategy={props.attachedStrategy}
            leftSlotOnClicked={() => {
              if (isRunning) return;
              setBottomToggled(true);
              setBottomMode("strategies");
            }}
            rightSlotOnClicked={() => {
              if (isRunning) return;
              setBottomToggled(!bottomToggled);
              setBottomMode("interval");
            }}
            leftSlot={
              props.attachedStrategy ? (
                <CommonDashboardSelectButton
                  disabled={isRunning}
                  leftIcon={
                    <Identicon
                      guid={props.attachedStrategy.version_id}
                      size={30}
                    />
                  }
                  label={getPreviewName(props.attachedStrategy.name)}
                />
              ) : (
                <CommonDashboardSelectButton
                  disabled={isRunning}
                  leftIcon={
                    <IoIosCodeDownload className="rotate-icon" size={25} />
                  }
                  label="Strategies"
                  labelClassName="interval-button-label"
                />
              )
            }
            rightSlot={
              props.dateInterval && (
                <CommonDashboardSelectButton
                  disabled={isRunning}
                  label={
                    isEqual(props.dateInterval, props.YTDInterval)
                      ? "Year to date"
                      : props.formatDateIntervalToLabel(props.dateInterval)
                  }
                  labelClassName="interval-button-label"
                  onClick={() => {
                    setBottomToggled(!bottomToggled);
                    setBottomMode("interval");
                  }}
                />
              )
            }
          />
        )}
        {!bottomToggled && props.traderType.traderType === "livetrader" && (
          <LivetraderControlCompact
            activeTheme={props.activeTheme}
            onClick={() => {
              setBottomToggled(true);
              setBottomMode("strategies");
            }}
            livetraderReport={props.livetraderReport}
          />
        )}
        {!bottomToggled && props.traderType.traderType === "papertrader" && (
          <PapertraderControlCompact
            activeTheme={props.activeTheme}
            attachedStrategy={props.attachedStrategy}
            leftSlotOnClicked={() => {
              setBottomToggled(true);
              setBottomMode("strategies");
            }}
            leftSlot={
              props.attachedStrategy ? (
                <CommonDashboardSelectButton
                  leftIcon={
                    <Identicon
                      guid={props.attachedStrategy.version_id}
                      size={30}
                    />
                  }
                  label={getPreviewName(props.attachedStrategy.name)}
                />
              ) : (
                <CommonDashboardSelectButton
                  leftIcon={
                    <IoIosCodeDownload className="rotate-icon" size={25} />
                  }
                  label="Strategies"
                  labelClassName="interval-button-label"
                />
              )
            }
            rightSlot={
              <CommonDashboardSelectButton
                rightIcon={<MdFormatListNumberedRtl size={25} />}
                label="Leaderboards"
                labelClassName="interval-button-label"
              />
            }
            rightSlotOnClicked={() => {
              setBottomToggled(true);
              setBottomMode("leaderboards");
            }}
          />
        )}
      </NeotonCard>
    </>
  );
}

export default CompactTradingDashboard;
