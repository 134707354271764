import { motion } from "framer-motion";
import { PatternInfo } from "../../../../interfaces/Indicators";
import { PatternHelperChart } from "../../../chart-components/pattern-helper-chart/PatternHelperChart";

interface Props {
  activeTheme: string;
  patternInfo: PatternInfo | undefined;
}
export function PTCodeFragmentsExpandedContainer(
  props: React.PropsWithChildren<Props>
) {
  return (
    <div
      className="code-fragments-details-container"
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      {props.patternInfo && (
        <>
          <motion.label
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="dimmed-label description"
          >
            {props.patternInfo.description}
          </motion.label>
          <PatternHelperChart
            fakeLeftData={props.patternInfo.fake_data_left}
            fakeRightData={props.patternInfo.fake_data_right}
            fakeMidData={props.patternInfo.fake_data}
            activeTheme={props.activeTheme}
          />
        </>
      )}
    </div>
  );
}
