import { motion } from "framer-motion";
import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import { Currency } from "../../../interfaces/Currency";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";

interface Props {
  label: string;
  poolFunds: PoolFunds | undefined;
  poolCurrencies: Currency[];
  setPoolsExpanded: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  poolsExpanded: {
    [key: string]: boolean;
  };
  pool: "high" | "medium" | "low";
}
export function CompactMarketCapPoolHeader(
  props: React.PropsWithChildren<Props>
) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, delay: 0.2 }}
      className={
        "compact-market-cap-pool-header-container" +
        (props.poolsExpanded[props.pool] ? " expanded" : "")
      }
      onClick={() => {
        props.setPoolsExpanded({
          ...props.poolsExpanded,
          [props.pool]: !props.poolsExpanded[props.pool],
        });
      }}
    >
      <label>{props.label}</label>
      {props.poolFunds && (
        <label className="dimmed-label">
          | Available funds: {props.poolFunds.available_amount.toFixed(2)} $
        </label>
      )}
      {!props.poolsExpanded[props.pool] && (
        <motion.div
          className="pool-currency-array-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.2 }}
          exit={{ opacity: 0 }}
        >
          <CurrencyLogoArray
            currencies={props.poolCurrencies}
            size="xs"
            expandable={true}
            previewAmount={10}
          />
        </motion.div>
      )}
    </motion.div>
  );
}
