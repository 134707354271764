import "./error-page.scss";
import { useEffect } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useDocumentTitle } from "@mantine/hooks";
import { CommonButton } from "../../components/buttons/neoton-common-button/CommonButton";
import { AiOutlineReload } from "react-icons/ai";
interface Props {
  activeTheme: string;
  appInsights: ApplicationInsights;
}
export function ErrorPage(props: React.PropsWithChildren<Props>) {
  useDocumentTitle(`Neoton | Error`);
  useEffect(() => {
    if (props.appInsights) {
      props.appInsights.trackEvent({ name: "ErrorPageShown" });
    }
  }, [props.appInsights]);

  return (
    <div className="error-page-container">
      <h1 className="error-header">
        <span className="error-highlight">E</span>rror
      </h1>
      <label>Looks like something went wrong.</label>
      <label>The error has automatically been reported.</label>
      <CommonButton
        onClick={() => window.location.reload()}
        activeTheme={props.activeTheme}
        label="Reload page"
        leftIcon={<AiOutlineReload />}
      />
    </div>
  );
}
