import * as jdenticon from "jdenticon";
import { useEffect, useRef } from "react";

interface Props {
  guid: string;
  size: number;
  onClick?: () => void;
  className?: string;
}
export function Identicon(props: React.PropsWithChildren<Props>) {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      jdenticon.update(svgRef.current, props.guid);
    }
  }, [props.guid, svgRef]);
  return (
    <svg
      style={{
        zIndex: 4,
      }}
      className={props.className}
      onClick={props.onClick}
      ref={svgRef}
      width={props.size}
      height={props.size}
      viewBox="0 0 40 40"
    />
  );
}
