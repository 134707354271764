export const createOptions = (token: string) => {
  const options = {
    headers: {
      authorization: `Bearer ${token}`,
      //fix the cors issue so the frontend pod can talk to the backend pods
      "Access-Control-Allow-Origin": "*",
    },
  };
  return options;
};
