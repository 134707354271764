import "./trading-dashboard.scss";
import { AnimatePresence, motion } from "framer-motion";
import { WalletValueChart } from "../../components/chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CommonOrders } from "../../components/common/common-dashboard/CommonOrders";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { DateInterval } from "../../interfaces/DateInterval";
import { toUTCTimestring } from "../../utils/FormattingUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { capitalize } from "../../utils/CapitalizeString";
import { PricesOverview } from "../../interfaces/PricesOverview";
import { isEqual } from "../../utils/ObjectEqualsUtil";
import { AppInsightsContext } from "../../App";
import { Currency } from "../../interfaces/Currency";
import { CommonWalletReport } from "../../interfaces/common-trading/CommonTradingReport";
import {
  GetBacktestStageRequestContext,
  SetBacktestStageRequestContext,
} from "../backtester-page/context/BacktestStageRequestContext";
import { useDocumentTitle, useMediaQuery } from "@mantine/hooks";
import { WalletAmount } from "../../components/common/wallet-amount/WalletAmount";
import { CommonOverviewHeader } from "../../components/common/common-dashboard/CommonOverviewHeader";
import { CommonTradingOverview } from "../../components/common/common-dashboard/CommonTradingOverview";
import { BacktesterControl } from "../../components/common/common-dashboard/trader-controls/BacktesterControl";
import {
  IsBacktestingContext,
  SimulationInfo,
} from "../backtester-page/BacktesterPage";
import { getBlueprintService } from "../../utils/serviceUtil";
import { ChartSettings } from "../../interfaces/chartIndicators/ChartSettings";
import { tryGetStoredChartSettings } from "../../utils/CachedDataUtil";
import { offsetTimestamp } from "../../utils/CandleCountUtil";
import { MarketStatisticsPayload } from "../../interfaces/common/MarketStatisticsPayload";
import {
  ExecutionStatusMessages,
  LivetraderReport,
  TradingAccountMetadata,
} from "../../interfaces/livetrader/LivewalletReport";
import { FaChartLine } from "react-icons/fa";
import { SetListChildToggledContext } from "../../components/shared-context/ListChildToggledContext";
import { CommonStrategyOverview } from "../../components/common/common-strategy-overview/CommonStrategyOverview";
import { CommonClock } from "../../components/common/common-dashboard/common-clock/CommonClock";
import { CgArrowsExchange } from "react-icons/cg";
import { TbStatusChange, TbTimelineEventPlus } from "react-icons/tb";
import CountUp from "react-countup";
import NeotonCard from "../../components/common/common-dashboard/Card";
import useWindowDimensions from "../../hooks/UseWIndowsDimensions";
import { FeaturedButton } from "../../components/sidemenu/search-results/StrategyOwnerMenu";
import { CommonEventLog } from "../../components/common/common-dashboard/CommonEventLog";
import { LivetraderLogs } from "../../components/common/common-dashboard/LivetraderLogs";
import { BsJournalText } from "react-icons/bs";
import { LivetraderService } from "../../services/LivetraderService";
import CommonNewsMarquee from "../../components/common/common-news-marquee/CommonNewsMarquee";
import CompactTradingDashboard from "./CompactTradingDashboard";
import { CommonSimulationInfo } from "../../components/common/common-dashboard/CommonSimulationInfo";

interface Props {
  activeTheme: string;
  traderType: TraderType;
  commonWalletReport: CommonWalletReport | undefined;
  attachedStrategy: StrategyVersion | undefined;
  loadingInitialReport?: boolean;
  listContainerChild?: React.ReactNode;
  controlContainerChild?: React.ReactNode;
  livetraderReport?: LivetraderReport | undefined;
  leaderboardContainerChild?: React.ReactNode;
  walletContainerChild?: React.ReactNode;
  liveTradingAccountMetaData?: TradingAccountMetadata;
  livetraderReportStart?: number;
  executionStatusMessage?: ExecutionStatusMessages;
  dateInterval?: DateInterval;
  listChildToggledOverride?: boolean;
  livewalletId?: string;
  featuredButton?: FeaturedButton;
  simulationInfo?: SimulationInfo;
  flushReport?: () => void;
  handleAttachStrategy?: (strategyId: string, versionId: string) => void;
}
export function TradingDashboard(props: React.PropsWithChildren<Props>) {
  const mobile = useMediaQuery("(max-width: 768px)");
  const useMiddleContainer = useMediaQuery("(max-width: 950px)");
  const appInsights = useContext(AppInsightsContext);

  const setListChildOverride = useContext(SetListChildToggledContext);

  //If used by backtester
  const setBacktestStageRequest = useContext(SetBacktestStageRequestContext);
  const backtestStageRequest = useContext(GetBacktestStageRequestContext);
  const isRunning = useContext(IsBacktestingContext);

  const pageTitle = capitalize(props.traderType.traderType);
  useDocumentTitle(`Neoton | ${pageTitle}`);
  const maxCandles = 600;
  const maxDate = useMemo(() => {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      0
    );
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  const [headerMenuMode, setHeaderMenuMode] = useState<
    "Tickers" | "Wallet" | "Metrics"
  >("Wallet");

  const [infoMenuMode, setInfoMenuMode] = useState<
    "Orders" | "Events" | "Logs" | "Status"
  >("Orders");
  const [ordersToggled, setOrdersToggled] = useState(false);

  const [listChildToggled, setListChildToggled] = useState(
    props.listChildToggledOverride ?? false
  );
  const [focusedCurrency, setFocusedCurrency] = useState<Currency>();

  const [focusedOrderIds, setFocusedOrderIds] = useState<
    [string | null, string | null]
  >([null, null]);
  const [orderFilters, setOrderFilters] = useState<string[]>([]);

  const filteredOrderIds = useMemo(() => {
    if (!props.commonWalletReport?.report?.orders?.cases) return [];
    if (orderFilters.length === 0) return [];
    const sellOrders = props.commonWalletReport.report.orders.cases.filter(
      (order) => order.id.includes("sell")
    );
    const _filteredSellOrders = sellOrders.filter((order) => {
      return orderFilters.includes("wins")
        ? order.buy_price < order.sell_price! ?? 0
        : order.buy_price > order.sell_price! ?? 0;
    });

    const _filteredSellOrderIds = _filteredSellOrders.map((order) => {
      return order.id;
    });
    const _filteredBuyOrderIds = _filteredSellOrderIds.map((orderId) => {
      return orderId.replace("sell", "buy");
    });

    return [..._filteredSellOrderIds, ..._filteredBuyOrderIds];
  }, [orderFilters, props.commonWalletReport?.report?.orders?.cases]);

  const [didLoadStrategyFromReport, setDidLoadStrategyFromReport] =
    useState(false);
  const storedChartSettings = tryGetStoredChartSettings();
  const [candleSize, setCandleSize] = useState<string>("1D"); // "1D" | "1h" | "5m"
  const [windowedInterval, setWindowedInterval] = useState<
    DateInterval | undefined
  >();

  const handleToggleListChild = useCallback(
    (state: boolean) => {
      setListChildToggled(state);
      if (setListChildOverride) {
        setListChildOverride(state);
      }
    },
    [setListChildOverride, setListChildToggled]
  );

  useEffect(() => {
    if (
      props.traderType.traderType === "backtester" &&
      props.attachedStrategy &&
      !props.commonWalletReport
    ) {
      setInfoMenuMode("Status");
    }
  }, [
    props.attachedStrategy,
    props.traderType,
    props.commonWalletReport,
    setInfoMenuMode,
  ]);

  useEffect(() => {
    if (props.listChildToggledOverride !== undefined) {
      if (listChildToggled !== props.listChildToggledOverride) {
        setListChildToggled(props.listChildToggledOverride);
      }
    }
    if (listChildToggled && focusedCurrency) {
      setFocusedCurrency(undefined);
    }
  }, [
    listChildToggled,
    props.listChildToggledOverride,
    setListChildToggled,
    setFocusedCurrency,
    focusedCurrency,
  ]);

  const [chartSettings, setChartSettings] =
    useState<ChartSettings>(storedChartSettings);
  // convert to useMemo
  const YTDInterval: DateInterval = useMemo(() => {
    return {
      startTimestamp: new Date(maxDate.getFullYear(), 0, 1).getTime(),
      stopTimestamp: maxDate.getTime(),
    };
  }, [maxDate]);

  const tryGetPersistedInterval = useCallback(() => {
    if (props.traderType.traderType === "backtester") {
      const possibleIntervalItem = localStorage.getItem("backtestInterval");
      if (possibleIntervalItem) {
        const parsedInterval = JSON.parse(possibleIntervalItem) as DateInterval;
        return parsedInterval;
      }
    }
    if (
      props.traderType.traderType === "livetrader" &&
      props.livetraderReportStart
    ) {
      return undefined;
    }

    // fallback to YTD
    return YTDInterval;
  }, [YTDInterval, props.traderType.traderType, props.livetraderReportStart]);

  const [dateInterval, setDateInterval] = useState<DateInterval | undefined>(
    props.dateInterval ?? tryGetPersistedInterval()
  );
  const [sessionInterval, setSessionInterval] = useState<
    DateInterval | undefined
  >(dateInterval);

  const formatDateIntervalToLabel = useCallback(
    (_dateInterval: DateInterval) => {
      return `${toUTCTimestring(
        _dateInterval.startTimestamp,
        "1D"
      )} -> ${toUTCTimestring(_dateInterval.stopTimestamp, "1D")}`;
    },
    []
  );

  const trySetDateIntervalFromReport = useCallback(() => {
    if (!props.commonWalletReport) return;
    if (
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps
        .length === 0
    )
      return;
    const firstTimestamp =
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps[0];
    const lastTimestamp =
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps[
        props.commonWalletReport.report?.wallet_value_snapshots?.timestamps
          .length - 1
      ];

    if (!firstTimestamp || !lastTimestamp) return;

    const startTimestamp = firstTimestamp
      ? parseInt(firstTimestamp) - 60 * 60 * 24 * 1000
      : undefined;
    const stopTimestamp = parseInt(lastTimestamp);

    if (!startTimestamp || !stopTimestamp) return;

    setDateInterval({ startTimestamp, stopTimestamp });
    setSessionInterval({ startTimestamp, stopTimestamp });
  }, [setDateInterval, setSessionInterval, props.commonWalletReport]);

  const getCurrencyPreviewName = useCallback(() => {
    const fallback = "Bitcoin";
    if (!props.attachedStrategy) return fallback;
    if (props.attachedStrategy.included_currencies.length === 0)
      return fallback;
    const currencyName = props.attachedStrategy.included_currencies[0];
    return currencyName;
  }, [props.attachedStrategy]);

  const priceDataInterval = useMemo(() => {
    if (candleSize === "1D") return dateInterval;
    return windowedInterval ?? dateInterval;
  }, [candleSize, dateInterval, windowedInterval]);

  const fetchPricesOverviewData = useCallback(
    async (payload: PricesOverviewRequestPayload | undefined) => {
      if (!payload) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      try {
        const blueprintService = getBlueprintService(props.traderType);
        const response = await blueprintService.getPricesOverview(
          token,
          payload
        );
        return response.data as PricesOverview;
      } finally {
      }
    },
    [getAccessTokenSilently, props.traderType]
  );

  const fetchCandleData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!focusedCurrency || !dateInterval) return;

    try {
      let startTimestamp =
        candleSize === "1D"
          ? offsetTimestamp(
              candleSize,
              dateInterval.startTimestamp,
              300,
              "left"
            )
          : priceDataInterval?.startTimestamp ?? dateInterval.startTimestamp;

      let stopTimestamp =
        candleSize === "1D"
          ? dateInterval.stopTimestamp
          : priceDataInterval?.stopTimestamp ?? dateInterval.stopTimestamp;

      if (candleSize === "5m" || candleSize === "1h") {
        //check length of interval - we should max allow 1000 candles
        const intervalLength = stopTimestamp - startTimestamp;
        const candleSizeMultiplier = candleSize === "5m" ? 5 : 60;
        const maxIntervalLength = maxCandles * candleSizeMultiplier * 60 * 1000;
        if (intervalLength > maxIntervalLength) {
          const potentialWindowedStartTimestamp =
            stopTimestamp - maxIntervalLength;
          const adjustedDateIntervalStartTimestamp =
            dateInterval.stopTimestamp - maxIntervalLength;

          startTimestamp =
            potentialWindowedStartTimestamp < dateInterval.startTimestamp
              ? adjustedDateIntervalStartTimestamp
              : potentialWindowedStartTimestamp;
        }
      }

      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getCandles(
        token,
        focusedCurrency.currency_name,
        candleSize,
        startTimestamp,
        stopTimestamp
      );

      return response.data;
    } finally {
    }
  }, [
    focusedCurrency,
    getAccessTokenSilently,
    candleSize,
    priceDataInterval,
    dateInterval,
    props.traderType,
    maxCandles,
  ]);

  const candlesQuery = useQuery(
    [
      "candles",
      focusedCurrency,
      props.attachedStrategy,
      candleSize,
      priceDataInterval,
    ],
    fetchCandleData,
    {
      enabled: !!focusedCurrency && !!dateInterval && !!props.attachedStrategy,
      cacheTime: 60000,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess(data) {
        if (!data) return;
        setWindowedInterval({
          startTimestamp: data?.candleData.timestamps[0],
          stopTimestamp:
            data?.candleData.timestamps[data.candleData.timestamps.length - 1],
        });
      },
    }
  );

  const pricesOverviewQuery = useQuery(
    ["PricesOverview", priceDataInterval, props.attachedStrategy, candleSize],
    () =>
      fetchPricesOverviewData(
        props.attachedStrategy && dateInterval
          ? {
              currencies: props.attachedStrategy.header.currencies,
              start:
                priceDataInterval?.startTimestamp ??
                dateInterval.startTimestamp,
              stop:
                priceDataInterval?.stopTimestamp ?? dateInterval.stopTimestamp,
              candle_size: candleSize,
            }
          : undefined
      ),
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess(data) {
        if (!data || data.candleSize === "1D") return;
        setWindowedInterval({
          startTimestamp: data.initialTimestamp,
          stopTimestamp: data.finalTimestamp,
        });
      },
    }
  );

  const fetchLivetraderLogs = useCallback(async () => {
    if (!props.livewalletId) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await LivetraderService.getLivetraderLogs(
        token,
        props.livewalletId
      );
      return response.data.payload;
    } catch (error: any) {
      appInsights?.trackException({ error });
    }
  }, [getAccessTokenSilently, props.livewalletId, appInsights]);

  const livetraderLogsQuery = useQuery(
    ["LivetraderLogs", props.livewalletId],
    fetchLivetraderLogs,
    {
      refetchInterval: 2 * 60 * 1000, // 2 minutes
      cacheTime: 60000,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: props.livewalletId !== undefined,
    }
  );

  const [loadingMarketStatistics, setLoadingMarketStatistics] = useState(false);

  const getStartStopTimestampsFromReport = useCallback(
    (_report: CommonWalletReport) => {
      const startTimestamp =
        _report.report?.wallet_value_snapshots?.timestamps[0];
      const stopTimestamp =
        _report.report?.wallet_value_snapshots?.timestamps[
          _report.report?.wallet_value_snapshots?.timestamps.length - 1
        ];
      return { startTimestamp, stopTimestamp };
    },
    []
  );

  const fetchMarketStatistics = useCallback(async () => {
    if (!props.attachedStrategy) return;
    if (!dateInterval && props.traderType.traderType === "backtester") return;
    const token = await getAccessTokenSilently();
    if (!token || loadingMarketStatistics) return;
    try {
      let startTimestamp;
      let stopTimestamp;
      if (props.traderType.traderType === "backtester") {
        if (!props.commonWalletReport) {
          startTimestamp = dateInterval!.startTimestamp;
          stopTimestamp = dateInterval!.stopTimestamp;
        } else {
          const {
            startTimestamp: _startTimestamp,
            stopTimestamp: _stopTimestamp,
          } = getStartStopTimestampsFromReport(props.commonWalletReport!);
          startTimestamp = _startTimestamp;
          stopTimestamp = _stopTimestamp;
        }
      } else {
        const {
          startTimestamp: _startTimestamp,
          stopTimestamp: _stopTimestamp,
        } = getStartStopTimestampsFromReport(props.commonWalletReport!);
        startTimestamp = _startTimestamp;
        stopTimestamp = _stopTimestamp;
      }
      setLoadingMarketStatistics(true);
      const payload: MarketStatisticsPayload = {
        currency_pair: props.attachedStrategy.header.currency_pair,
        initial_wallet_value:
          props.commonWalletReport?.report?.initial_balance ?? 1000,
        mc_distribution: props.attachedStrategy.header.mc_distribution,
        rank_distribution: props.attachedStrategy.header.rank_distribution,
        start: startTimestamp,
        stop: stopTimestamp,
        trading_currencies: props.attachedStrategy.included_currencies,
        fixed_pool_composition:
          props.attachedStrategy.header.pool_composition_type === "fixed" &&
          props.attachedStrategy.header.fixed_pool_composition
            ? props.attachedStrategy.header.fixed_pool_composition
            : undefined,
      };
      const blueprintService = getBlueprintService(props.traderType);

      const response = await blueprintService.getMarketStatistics(
        token,
        payload
      );
      return response.data;
    } catch (error: any) {
      appInsights?.trackException({ error });
    } finally {
      setLoadingMarketStatistics(false);
    }
  }, [
    props.attachedStrategy,
    getAccessTokenSilently,
    props.commonWalletReport,
    dateInterval,
    props.traderType,
    appInsights,
    setLoadingMarketStatistics,
    loadingMarketStatistics,
    getStartStopTimestampsFromReport,
  ]);

  const sharedMarketReturns = useQuery(
    ["marketStatistics", props.commonWalletReport],
    fetchMarketStatistics,
    {
      keepPreviousData: true,
      cacheTime: 60 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: props.traderType.traderType !== "backtester",
    }
  );
  const backtesterMarketReturns = useQuery(
    [
      "marketStatistics",
      props.attachedStrategy,
      dateInterval,
      props.commonWalletReport,
    ],
    fetchMarketStatistics,
    {
      keepPreviousData: true,
      cacheTime: 60 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !isRunning && props.traderType.traderType === "backtester",
    }
  );

  const marketReturnsQuery =
    props.traderType.traderType === "backtester"
      ? backtesterMarketReturns
      : sharedMarketReturns;

  useEffect(() => {
    if (
      props.traderType.traderType === "backtester" &&
      isRunning &&
      infoMenuMode !== "Status"
    ) {
      setInfoMenuMode("Status");
    }
  }, [isRunning, props.traderType.traderType, infoMenuMode, setInfoMenuMode]);

  useEffect(() => {
    if (
      props.loadingInitialReport &&
      listChildToggled &&
      props.listChildToggledOverride === undefined
    ) {
      handleToggleListChild(false);
    }
    if (
      props.traderType.traderType === "livetrader" &&
      props.livetraderReportStart &&
      dateInterval?.startTimestamp !== props.livetraderReportStart
    ) {
      const now = new Date().getTime();
      setDateInterval({
        startTimestamp: props.livetraderReportStart,
        stopTimestamp: now,
      });
      setSessionInterval({
        startTimestamp: props.livetraderReportStart,
        stopTimestamp: now,
      });
    }
    if (props.dateInterval && !isEqual(props.dateInterval, dateInterval)) {
      setDateInterval(props.dateInterval);
      setSessionInterval(props.dateInterval);
    }
  }, [
    listChildToggled,
    props.loadingInitialReport,
    props.traderType.traderType,
    dateInterval,
    props.livetraderReportStart,
    props.dateInterval,
    handleToggleListChild,
    props.listChildToggledOverride,
  ]);

  useEffect(() => {
    if (
      props.commonWalletReport &&
      !props.attachedStrategy &&
      !didLoadStrategyFromReport
    ) {
      setDidLoadStrategyFromReport(true);
      if (props.traderType.traderType === "backtester") {
        setInfoMenuMode("Orders");
        trySetDateIntervalFromReport();
      }
    }
    if (!useMiddleContainer && !ordersToggled && !mobile) {
      setOrdersToggled(true);
    }
    if (useMiddleContainer && focusedCurrency && !mobile) {
      setFocusedCurrency(undefined);
    }
  }, [
    setInfoMenuMode,
    props.commonWalletReport,
    setDidLoadStrategyFromReport,
    props.attachedStrategy,
    didLoadStrategyFromReport,
    trySetDateIntervalFromReport,
    props.traderType.traderType,
    useMiddleContainer,
    ordersToggled,
    setOrdersToggled,
    focusedCurrency,
    setFocusedCurrency,
    mobile,
  ]);

  useEffect(() => {
    if (props.traderType.traderType !== "backtester") return;
    if (!dateInterval || isRunning) return;
    if (
      dateInterval.startTimestamp !== backtestStageRequest?.start ||
      dateInterval.stopTimestamp !== backtestStageRequest?.stop
    ) {
      setBacktestStageRequest({
        ...backtestStageRequest,
        start: dateInterval.startTimestamp,
        stop: dateInterval.stopTimestamp,
      });
      setSessionInterval(dateInterval);
    }
  }, [
    backtestStageRequest,
    setBacktestStageRequest,
    dateInterval,
    props.traderType.traderType,
    isRunning,
  ]);

  useMemo(() => {
    if (
      !chartSettings.orderSettings &&
      props.commonWalletReport?.report?.orders?.cases
    ) {
      setChartSettings({
        ...chartSettings,
        orderSettings: {
          enabled: true,
        },
      });
    }
    if (
      chartSettings.orderSettings &&
      !props.commonWalletReport?.report?.orders?.cases
    ) {
      setChartSettings({
        ...chartSettings,
        orderSettings: undefined,
      });
    }
  }, [setChartSettings, chartSettings, props.commonWalletReport]);

  const renderWalletValueChart = useMemo(() => {
    return (
      <WalletValueChart
        activeTheme={props.activeTheme}
        currencyPair="usd"
        isWinning={
          !props.commonWalletReport?.report
            ? false
            : props.commonWalletReport.report.current_balance >
              props.commonWalletReport.report.initial_balance
        }
        marketStatistics={marketReturnsQuery.data}
        walletValueSnapshots={
          props.commonWalletReport?.report?.wallet_value_snapshots
        }
        animate={!isRunning}
        compact={true}
        heightOverride={"85%"}
      />
    );
  }, [
    props.commonWalletReport,
    props.activeTheme,
    marketReturnsQuery.data,

    isRunning,
  ]);

  const renderCommonOrders = useMemo(() => {
    return (
      <CommonOrders
        focusedCurrency={focusedCurrency}
        toggle={() =>
          useMiddleContainer
            ? setOrdersToggled(!ordersToggled)
            : setOrdersToggled(true)
        }
        activeTheme={props.activeTheme}
        orders={props.commonWalletReport?.report?.orders.cases}
        toggled={ordersToggled}
      />
    );
  }, [
    focusedCurrency,
    useMiddleContainer,
    ordersToggled,
    setOrdersToggled,
    props.activeTheme,
    props.commonWalletReport?.report?.orders.cases,
  ]);

  const renderSimulationInfo = useMemo(() => {
    return (
      <CommonSimulationInfo
        activeTheme={props.activeTheme}
        simulationInfo={props.simulationInfo}
        flushReport={props.flushReport}
      />
    );
  }, [props.activeTheme, props.simulationInfo, props.flushReport]);

  const renderEventLog = useMemo(() => {
    return (
      <CommonEventLog
        activeTheme={props.activeTheme}
        events={
          isRunning ? undefined : props.commonWalletReport?.report?.event_log
        }
      />
    );
  }, [
    props.activeTheme,
    props.commonWalletReport?.report?.event_log,
    isRunning,
  ]);

  const renderLivetraderLogs = useMemo(() => {
    return (
      <LivetraderLogs
        activeTheme={props.activeTheme}
        livetraderLogs={livetraderLogsQuery.data}
        refetchLogs={livetraderLogsQuery.refetch}
      />
    );
  }, [
    props.activeTheme,
    livetraderLogsQuery.data,
    livetraderLogsQuery.refetch,
  ]);

  const renderWalletValue = useMemo(() => {
    return (
      <NeotonCard className="dashboard-wallet-value-container">
        <WalletAmount
          initialBalance={
            props.commonWalletReport?.report?.initial_balance ?? 1000
          }
          walletValue={props.commonWalletReport?.report?.current_balance}
          marketStatistics={marketReturnsQuery.data}
        />
      </NeotonCard>
    );
  }, [props.commonWalletReport?.report, marketReturnsQuery.data]);

  const renderCommonNewsMarquee = useMemo(() => {
    return (
      <CommonNewsMarquee
        activeTheme={props.activeTheme}
        traderType={props.traderType}
      />
    );
  }, [props.activeTheme, props.traderType]);

  const renderCommonOverviewHeader = useMemo(() => {
    return (
      <CommonOverviewHeader
        listChildToggled={listChildToggled}
        setFocusedCurrency={setFocusedCurrency}
        toggleListChild={() => handleToggleListChild(!listChildToggled)}
        strategyLoading={false}
        activeTheme={props.activeTheme}
        traderType={props.traderType}
        attachedStrategy={props.attachedStrategy}
        reportLoading={props.loadingInitialReport}
        chartSettings={chartSettings}
        focusedCurrency={focusedCurrency}
        setChartSettings={setChartSettings}
        getCurrencyPreviewName={getCurrencyPreviewName}
      />
    );
  }, [
    listChildToggled,
    handleToggleListChild,
    setFocusedCurrency,
    props.activeTheme,
    props.traderType,
    props.attachedStrategy,
    props.loadingInitialReport,
    chartSettings,
    focusedCurrency,
    setChartSettings,
    getCurrencyPreviewName,
  ]);
  const iconSize = 25;

  const headerMenuButtons = [
    {
      label: "Wallet",
      icon: <FaChartLine size={iconSize} />,
      onClick: () => {
        setHeaderMenuMode("Wallet");
      },
      enabled: props.attachedStrategy !== undefined,
    },
    /* {
      label: "Metrics",
      icon: <MdSpeed size={iconSize} />,
      onClick: () => {
        setHeaderMenuMode("Metrics");
      },
      enabled:
        props.commonWalletReport !== undefined &&
        props.commonWalletReport.report?.orders?.cases &&
        props.commonWalletReport.report?.orders?.cases?.length > 0 &&
        !isRunning,
    }, */
  ];

  const infoMenuButtons = [
    {
      label: "Orders",
      icon: <CgArrowsExchange size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Orders");
      },
      enabled: props.commonWalletReport !== undefined && !isRunning,
    },
    {
      label: "Status",
      icon: <TbStatusChange size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Status");
      },
      enabled: props.traderType.traderType === "backtester",
      hide: props.traderType.traderType !== "backtester",
    },
    {
      label: "Logs",
      icon: <BsJournalText size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Logs");
      },
      enabled: props.traderType.traderType === "livetrader",
      hide: props.traderType.traderType !== "livetrader",
    },
    {
      label: "Events",
      icon: <TbTimelineEventPlus size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Events");
      },
      enabled: props.commonWalletReport !== undefined,
    },
  ];

  const infoItemCount = useMemo(() => {
    const infoItem: { [key: string]: number } = {};
    infoItem["Orders"] = props.commonWalletReport?.report?.order_count ?? 0;
    infoItem["Events"] = isRunning
      ? props.commonWalletReport?.report?.event_count ?? 0
      : props.commonWalletReport?.report?.event_log?.length ?? 0;
    infoItem["Logs"] = livetraderLogsQuery?.data?.length ?? 0;
    return infoItem;
  }, [props.commonWalletReport?.report, isRunning, livetraderLogsQuery.data]);

  const handleFocusTrade = useCallback(
    (orderId: string | undefined) => {
      if (!orderId) {
        setFocusedOrderIds([null, null]);
        return;
      }
      setInfoMenuMode("Orders");
      const orderType = orderId.includes("sell") ? "sell" : "buy";
      const relatedOrderId =
        orderType === "buy"
          ? orderId.replace("buy", "sell")
          : orderId.replace("sell", "buy");
      setFocusedOrderIds([orderId, relatedOrderId]);
    },
    [setFocusedOrderIds, setInfoMenuMode]
  );

  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const upperRowRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowDimensions();
  const lowerRowHeight = upperRowRef.current
    ? height - upperRowRef.current.clientHeight - 20
    : 0;

  return (
    <AnimatePresence>
      <CandleSizeContext.Provider value={candleSize}>
        <SetCandleSizeContext.Provider value={setCandleSize}>
          <GetSessionIntervalContext.Provider value={sessionInterval}>
            <GetWindowedIntervalContext.Provider value={windowedInterval}>
              <SetWindowedIntervalContext.Provider value={setWindowedInterval}>
                <FilteredOrderIdsContext.Provider value={filteredOrderIds}>
                  <SetOrderFiltersContext.Provider value={setOrderFilters}>
                    <OrderFiltersContext.Provider value={orderFilters}>
                      <FocusedOrderIdsContext.Provider value={focusedOrderIds}>
                        <SetFocusedOrderIdsContext.Provider
                          value={handleFocusTrade}
                        >
                          <div className="trading-dashboard-page-container">
                            {mobile ? (
                              <CompactTradingDashboard
                                activeTheme={props.activeTheme}
                                YTDInterval={YTDInterval}
                                attachedStrategy={props.attachedStrategy}
                                commonWalletReport={props.commonWalletReport}
                                dateInterval={dateInterval}
                                focusedCurrency={focusedCurrency}
                                formatDateIntervalToLabel={
                                  formatDateIntervalToLabel
                                }
                                getCurrencyPreviewName={getCurrencyPreviewName}
                                marketReturnsQuery={marketReturnsQuery}
                                pricesOverviewQuery={pricesOverviewQuery}
                                setDateInterval={setDateInterval}
                                setFocusedCurrency={setFocusedCurrency}
                                traderType={props.traderType}
                                handleAttachStrategy={
                                  props.handleAttachStrategy
                                }
                                leaderboardContainerChild={
                                  props.leaderboardContainerChild
                                }
                                listContainerChild={props.listContainerChild}
                                livetraderReport={props.livetraderReport}
                                loadingInitialReport={
                                  props.loadingInitialReport
                                }
                              />
                            ) : (
                              <>
                                <div
                                  className="dashboard-upper"
                                  ref={upperRowRef}
                                >
                                  {props.attachedStrategy && renderWalletValue}
                                  {!useMiddleContainer && (
                                    <NeotonCard className="dashboard-wallet-value-chart-container">
                                      <div className="wallet-value-chart-header">
                                        {headerMenuButtons.map(
                                          (button, idx) => {
                                            if (!button.enabled) return null;
                                            return (
                                              <label
                                                key={idx}
                                                className={
                                                  "header-button" +
                                                  (headerMenuMode ===
                                                  button.label
                                                    ? " active"
                                                    : "")
                                                }
                                                onClick={() => {
                                                  button.enabled &&
                                                    button.onClick();
                                                }}
                                              >
                                                {button.icon} {button.label}
                                              </label>
                                            );
                                          }
                                        )}
                                        {renderCommonNewsMarquee}
                                        <CommonClock />
                                      </div>
                                      {/* {headerMenuMode === "Metrics" &&
                                    !isRunning &&
                                    props.commonWalletReport && (
                                      <CommonMetrics
                                        activeTheme={props.activeTheme}
                                        traderType={props.traderType}
                                        commonWalletReport={
                                          props.commonWalletReport
                                        }
                                        livewalletId={props.livewalletId}
                                      />
                                    )} */}

                                      {headerMenuMode === "Wallet" &&
                                        renderWalletValueChart}
                                    </NeotonCard>
                                  )}
                                  <NeotonCard className="dashboard-control-container">
                                    {props.traderType.traderType ===
                                      "backtester" &&
                                      dateInterval && (
                                        <BacktesterControl
                                          activeTheme={props.activeTheme}
                                          attachedStrategy={
                                            props.attachedStrategy
                                          }
                                          showBacktestButton={true}
                                          formatDateIntervalToLabel={
                                            formatDateIntervalToLabel
                                          }
                                          YTDInterval={YTDInterval}
                                          dateInterval={dateInterval}
                                          setNewDateInterval={(
                                            _newDateInterval
                                          ) => {
                                            setDateInterval(_newDateInterval);
                                            props.flushReport?.();
                                            marketReturnsQuery.remove();
                                          }}
                                          getCurrencyPreviewName={
                                            getCurrencyPreviewName
                                          }
                                        />
                                      )}
                                    {props.controlContainerChild && (
                                      <>{props.controlContainerChild}</>
                                    )}
                                  </NeotonCard>
                                </div>
                                {useMiddleContainer && (
                                  <div className="dashboard-middle">
                                    <NeotonCard className="dashboard-wallet-value-chart-container middle">
                                      {props.commonWalletReport?.report &&
                                        renderWalletValueChart}
                                    </NeotonCard>
                                  </div>
                                )}
                                <motion.div
                                  className="dashboard-lower"
                                  animate={{
                                    height: lowerRowHeight,
                                  }}
                                  transition={{
                                    duration: 0,
                                  }}
                                >
                                  <NeotonCard
                                    className="dashboard-overview-container"
                                    ref={dashboardContainerRef}
                                  >
                                    <StrategyPopupParentContainerRefContext.Provider
                                      value={dashboardContainerRef}
                                    >
                                      {renderCommonOverviewHeader}

                                      {!listChildToggled &&
                                        props.attachedStrategy && (
                                          <CommonTradingOverview
                                            setFocusedCurrency={(currency) =>
                                              setFocusedCurrency(currency)
                                            }
                                            activeTheme={props.activeTheme}
                                            strategy={props.attachedStrategy}
                                            commonWalletReport={
                                              props.commonWalletReport
                                            }
                                            pricesOverviewQuery={
                                              pricesOverviewQuery.data
                                            }
                                            loadingCurrencies={
                                              pricesOverviewQuery.isLoading
                                            }
                                            focusedCurrency={focusedCurrency}
                                            candlesQuery={candlesQuery}
                                            chartSettings={chartSettings}
                                          />
                                        )}
                                      {listChildToggled && (
                                        <div className="list-child-container">
                                          {props.listContainerChild ?? (
                                            <div className="trading-dashboard-strategies-overview">
                                              <CommonStrategyOverview
                                                activeTheme={props.activeTheme}
                                                traderType={props.traderType}
                                                maxHeight={
                                                  dashboardContainerRef?.current
                                                    ? dashboardContainerRef
                                                        .current.clientHeight -
                                                      140
                                                    : undefined
                                                }
                                                featuredButton={
                                                  props.featuredButton ?? {
                                                    label: "Attach Strategy",
                                                    onClick(
                                                      strategyId,
                                                      versionId
                                                    ) {
                                                      if (
                                                        !props.handleAttachStrategy
                                                      )
                                                        return;
                                                      props.handleAttachStrategy(
                                                        strategyId,
                                                        versionId
                                                      );
                                                      handleToggleListChild(
                                                        false
                                                      );
                                                      setInfoMenuMode("Status");
                                                    },
                                                    mode: "backtester",
                                                  }
                                                }
                                              />
                                            </div>
                                          )}
                                          {props.leaderboardContainerChild}
                                        </div>
                                      )}
                                    </StrategyPopupParentContainerRefContext.Provider>
                                  </NeotonCard>
                                  {props.attachedStrategy && (
                                    <>
                                      {props.listContainerChild &&
                                      listChildToggled ? undefined : (
                                        <NeotonCard
                                          className={
                                            "dashboard-info-container" +
                                            (ordersToggled ? " toggled" : "")
                                          }
                                        >
                                          <div className="dashboard-info-header">
                                            {infoMenuButtons.map(
                                              (button, idx) => {
                                                const itemCount =
                                                  infoItemCount[button.label];
                                                const itemCountComponent =
                                                  itemCount > 0 ? (
                                                    <>
                                                      (
                                                      <CountUp
                                                        end={itemCount}
                                                        duration={2}
                                                        start={0}
                                                        preserveValue
                                                      />
                                                      )
                                                    </>
                                                  ) : undefined;

                                                if (button.hide) return null;
                                                return (
                                                  <label
                                                    key={idx}
                                                    id={button.label.toLowerCase()+"-header-button"}
                                                    className={
                                                      "header-button" +
                                                      " info" +
                                                      (button.enabled
                                                        ? ""
                                                        : " disabled") +
                                                      (infoMenuMode ===
                                                      button.label
                                                        ? " active"
                                                        : "")
                                                    }
                                                    onClick={() =>
                                                      button.enabled &&
                                                      button.onClick()
                                                    }
                                                  >
                                                    {button.icon} {button.label}{" "}
                                                    {itemCountComponent}
                                                  </label>
                                                );
                                              }
                                            )}
                                          </div>

                                          {!props.loadingInitialReport &&
                                          props.commonWalletReport &&
                                          infoMenuMode === "Orders"
                                            ? renderCommonOrders
                                            : undefined}
                                          {!props.loadingInitialReport &&
                                          props.commonWalletReport &&
                                          infoMenuMode === "Events"
                                            ? renderEventLog
                                            : undefined}
                                          {props.simulationInfo &&
                                          infoMenuMode === "Status"
                                            ? renderSimulationInfo
                                            : undefined}
                                          {!props.loadingInitialReport &&
                                          props.commonWalletReport &&
                                          props.traderType.traderType ===
                                            "livetrader" &&
                                          props.livewalletId !== undefined &&
                                          livetraderLogsQuery.data &&
                                          infoMenuMode === "Logs"
                                            ? renderLivetraderLogs
                                            : undefined}
                                        </NeotonCard>
                                      )}
                                    </>
                                  )}
                                </motion.div>
                              </>
                            )}
                          </div>
                        </SetFocusedOrderIdsContext.Provider>
                      </FocusedOrderIdsContext.Provider>
                    </OrderFiltersContext.Provider>
                  </SetOrderFiltersContext.Provider>
                </FilteredOrderIdsContext.Provider>
              </SetWindowedIntervalContext.Provider>
            </GetWindowedIntervalContext.Provider>
          </GetSessionIntervalContext.Provider>
        </SetCandleSizeContext.Provider>
      </CandleSizeContext.Provider>
    </AnimatePresence>
  );
}

export interface TraderType {
  traderType:
    | "backtester"
    | "papertrader"
    | "livetrader"
    | "usermanager"
    | "strategymanager";
}
export interface PricesOverviewRequestPayload {
  start: number;
  stop: number;
  currencies: string[];
  candle_size: string;
}

export const StrategyPopupParentContainerRefContext = createContext<
  React.RefObject<HTMLDivElement> | undefined
>(undefined);

export const FocusedOrderIdsContext = createContext<
  [string | null, string | null]
>([null, null]);
export const SetFocusedOrderIdsContext = createContext<
  (id: string | undefined) => void
>((id) => {});
export const CandleSizeContext = createContext<string>("1D");
export const SetCandleSizeContext = createContext<(candleSize: string) => void>(
  (candleSize) => {}
);
export const SetWindowedIntervalContext = createContext<
  (windowedInterval: DateInterval | undefined) => void
>((windowedInterval) => {});
export const GetWindowedIntervalContext = createContext<
  DateInterval | undefined
>(undefined);
export const GetSessionIntervalContext = createContext<
  DateInterval | undefined
>(undefined);

export const FilteredOrderIdsContext = createContext<string[]>([]);
export const OrderFiltersContext = createContext<string[]>([]);
export const SetOrderFiltersContext = createContext<
  (filters: string[]) => void
>((filters) => {});
