import "./backtester-control-compact.scss";
import { motion } from "framer-motion";
import { StrategyVersion } from "../../../../interfaces/strategyInterfaces/Strategy";
import { BacktestButton } from "../../../buttons/backtest-button/BacktestButton";
interface Props {
  activeTheme: string;
  rightSlotOnClicked?: () => void;
  leftSlotOnClicked?: () => void;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  attachedStrategy: StrategyVersion | undefined;
  showBacktestButton: boolean;
}
export function BacktesterBottomControlCompact(
  props: React.PropsWithChildren<Props>
) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0, y: 10 }}
      className="backtester-control-bottom-container"
    >
      {props.leftSlot && (
        <motion.div
          onClick={props.leftSlotOnClicked}
          className="backtester-control-slot left"
        >
          {props.leftSlot}
        </motion.div>
      )}
      {props.attachedStrategy && props.showBacktestButton && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: -30 }}
          transition={{ duration: 0.2 }}
          className="backtester-control-center"
        >
          <BacktestButton
            isRunning={false}
            activeTheme={props.activeTheme}
            currencyNamesToBeTested={props.attachedStrategy.included_currencies}
          />
        </motion.div>
      )}
      {props.rightSlot && (
        <motion.div
          onClick={props.rightSlotOnClicked}
          className="backtester-control-slot right"
        >
          {props.rightSlot}
        </motion.div>
      )}
    </motion.div>
  );
}
