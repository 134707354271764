import './beta-signup-form.scss';
import { useState, useMemo, useCallback, useContext } from "react";
import {
  UsermanagerService,
  BetaSignupForm as BetaSignupFormType,
} from "../../services/UserManagerService";
import "./beta-signup-form.scss";
import countryList from "react-select-country-list";
import {
  TextInput,
  Select as MantineSelect,
  Button,
  Checkbox,
} from "@mantine/core";
import { ToastContext } from "../../App";

interface Props {
  closeModal: () => void;
  email?: string;
}

export function BetaSignupForm(props: React.PropsWithChildren<Props>) {
  const triggerToast = useContext(ToastContext);
  const countries: { value: string; label: string }[] = useMemo(
    () => countryList().getData(),
    []
  );

  const [form, setForm] = useState<BetaSignupFormType>({
    nonce: "",
    email: props.email ?? "",
    first_name: "",
    last_name: "",
    country: "",
  });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const checkForm = useCallback(() => {
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (
      !form.email.match(emailPattern) ||
      !form.first_name ||
      !form.last_name ||
      !form.country
    ) {
      return false;
    }
    return true;
  }, [form]);

  const formIsValid = checkForm();

  const handleSubmit = useCallback(async () => {
    if (!formIsValid || !checked) return;
    try {
      setLoading(true);
      // get Nonce
      const nonceResponse = await UsermanagerService.getNonce();
      const nonce = nonceResponse.data.nonce;
      const submitResponse = await UsermanagerService.submitBetaSignupForm({
        ...form,
        nonce: nonce,
      });
      triggerToast(submitResponse.data.message, "info", null);
      props.closeModal();
    } catch (error: any) {
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setForm({
        nonce: "",
        email: "",
        first_name: "",
        last_name: "",
        country: "",
      });
      setLoading(false);
    }
  }, [formIsValid, checked, form, setForm, props, setLoading, triggerToast]);

  return (
    <div className="beta-signup-form">
      <div className="form-block">
        <label>First name</label>
        <TextInput
          required
          placeholder="First name"
          value={form.first_name}
          onChange={(event) =>
            setForm({ ...form, first_name: event.currentTarget.value })
          }
        />
      </div>

      <div className="form-block">
        <label>Last name</label>
        <TextInput
          required
          placeholder="Last name"
          value={form.last_name}
          onChange={(event) =>
            setForm({ ...form, last_name: event.currentTarget.value })
          }
        />
      </div>

      <div className="form-block">
        <label>Email</label>
        <TextInput
          required
          placeholder="Email@email.com"
          value={form.email}
          onChange={(event) =>
            setForm({ ...form, email: event.currentTarget.value })
          }
        />
      </div>

      <div className="form-block">
        <label>Country/residence</label>
        <MantineSelect
          required
          searchable
          data={countries}
          placeholder="Country"
          color="cyan"
          value={form.country}
          onChange={(value) => value && setForm({ ...form, country: value })}
        />
      </div>
      <div className="submit-container">
        <Checkbox
          checked={checked}
          onChange={() => setChecked(!checked)}
          color="cyan"
          label="I would like to receive news from Neoton.io"
        />
        <br />
        <Button
          onClick={handleSubmit}
          radius={"xs"}
          color="cyan"
          disabled={!formIsValid || !checked}
          loading={loading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
