import { TraderType } from "../../../pages/common/TradingDashboard";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback, useContext } from "react";
import { useQuery } from "react-query";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { CommonStrategySelectBody } from "../common-strategy-select/CommonStrategySelectBody";
import { UserLimitationsContext } from "../../../App";
import { Limitation } from "../../../interfaces/common/UserLimitations";

interface Props {
  activeTheme: string;
  traderType: TraderType;
  attachStrategy: (strategyId: string, versionId: string) => void;
  tradingAccountSetup?: boolean;
  newStrategyPopupMode?: boolean;
  compact?: boolean;
  height?: number;
  disableButtons?: boolean;
  closeExistingDrawer?: () => void;
  refetchCallback?: () => void;
}
export function CommonStrategySelect(props: React.PropsWithChildren<Props>) {
  const limitation: Limitation | undefined = useContext(UserLimitationsContext);

  const { getAccessTokenSilently } = useAuth0();
  const [selectedStrategyVersion, setSelectedStrategyVersion] =
    useState<string>();

  const fetchUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const blueprintService = getBlueprintService(props.traderType);
    const response = await blueprintService.getUserStrategies(token);
    return response.data;
  }, [getAccessTokenSilently, props.traderType]);
  const fetchLiveUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;

    const blueprintService = getBlueprintService(props.traderType);
    const response = await blueprintService.getLiveStrategies(token);
    return response.data;
  }, [getAccessTokenSilently, props.traderType]);

  const strategiesQuery = useQuery(["Strategies"], fetchUserStrategies, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      props.refetchCallback && props.refetchCallback();
    }
  });

  const liveStrategiesQuery = useQuery(
    ["LiveStrategies"],
    fetchLiveUserStrategies,
    {
      cacheTime: 60000,
      staleTime: 60000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      {limitation && strategiesQuery.data?.payload && (
        <label className="dimmed-label">
          {Object.keys(strategiesQuery.data?.payload).length} /{" "}
          {limitation.strategies} strategies
        </label>
      )}
      {strategiesQuery.data && (
        <CommonStrategySelectBody
          disableButtons={props.disableButtons}
          height={props.height}
          activeTheme={props.activeTheme}
          traderType={props.traderType}
          strategiesQuery={strategiesQuery}
          liveStrategiesQuery={liveStrategiesQuery}
          selectedStrategyVersion={selectedStrategyVersion}
          setSelectedStrategyVersion={setSelectedStrategyVersion}
          attachStrategy={props.attachStrategy}
          tradingAccountSetup={props.tradingAccountSetup}
          newStrategyPopupMode={props.newStrategyPopupMode}
          compact={props.compact}
        />
      )}
    </>
  );
}
