import "./tradingcard-chart.scss";
import {
  HoldingItem,
  Order,
} from "../../../../interfaces/backtester/BacktestTradingReport";
import { CurrencyPriceData } from "../../../../interfaces/PricesOverview";
import {
  getPriceDecimalCount,
  toUTCTimestring,
} from "../../../../utils/FormattingUtils";

import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  YAxis,
  ReferenceDot,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import { getTheme } from "../../../../utils/themeUtil";
import { AiOutlineLineChart } from "react-icons/ai";
import { motion } from "framer-motion";
import { NeotonSpinner } from "../../../custom-loaders/PixelLoader";
import {
  ReactNode,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IsBacktestingContext } from "../../../../pages/backtester-page/BacktesterPage";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  FilteredOrderIdsContext,
  SetFocusedOrderIdsContext,
} from "../../../../pages/common/TradingDashboard";
import { HoldingDetailsToggle } from "../../../common/common-trading-card/CommonTradingCard";
import { MicrotestResults } from "../../../../services/StrategyManagerService";

interface Props {
  activeTheme: string;
  priceData: CurrencyPriceData | undefined;
  filteredOrders: Order[] | undefined;
  currencyOverviewOrders: any[] | undefined;
  currentOverviewTimestamp: number | undefined;
  candleSize?: string;
  progress?: number;
  loading?: boolean;
  hideBrush?: boolean;
  xContraints?: [number | undefined, number | undefined];
  setXContraints?: (
    xContraints: [number | undefined, number | undefined]
  ) => void;
  isHolding?: boolean;
  holding?: HoldingItem | undefined;
  showHoldingDetails?: boolean;
  holdingDetailsToggle?: HoldingDetailsToggle;
  hideOrders?: boolean;
  microtester?: boolean;
  microtestResults?: MicrotestResults;
  showFirstAndLastDate?: boolean;
}
export default function TradingCardChart(
  props: React.PropsWithChildren<Props>
) {
  const itemCap = 3;
  const theme = getTheme(props.activeTheme);
  const isRunning = useContext(IsBacktestingContext);
  const filteredOrderIds = useContext(FilteredOrderIdsContext);
  const setFocusedOrderId = useContext(SetFocusedOrderIdsContext);
  const [enableAnnotation, setEnableAnnotation] = useState(false);
  const decimalCount = useMemo(() => {
    return props.priceData ? getPriceDecimalCount(props.priceData.prices) : 2;
  }, [props.priceData]);

  const [baseLeft, setBaseLeft] = useState<string | undefined>();
  const [baseRight, setBaseRight] = useState<string | undefined>();

  const [zoomLeft, setZoomLeft] = useState<string | undefined>();
  const [zoomRight, setZoomRight] = useState<string | undefined>();

  const currencyOverviewOrders: any[] | undefined = useMemo(() => {
    if (filteredOrderIds.length === 0) return props.currencyOverviewOrders;
    const filteredCurrencyOverviewOrders: any = {};
    Object.entries(props.currencyOverviewOrders ?? {}).forEach(
      ([timestamp, orderIds]) => {
        const filteredOrderIdsForTimestamp = orderIds.filter(
          (orderId: string) => filteredOrderIds.includes(orderId)
        );
        if (filteredOrderIdsForTimestamp.length > 0)
          filteredCurrencyOverviewOrders[timestamp] =
            filteredOrderIdsForTimestamp;
      }
    );
    return filteredCurrencyOverviewOrders;
  }, [props.currencyOverviewOrders, filteredOrderIds]);

  const { dataset, timestampMap, priceMap } = useMemo(() => {
    const _timestampMap: { [key: number]: string } = {};
    const _priceMap: { [key: number]: number } = {};
    const _dataset: any[] = [];

    if (!props.priceData) {
      // Return default values if priceData is not available
      return { dataset: _dataset, timestampMap: _timestampMap };
    }

    for (let i = 0; i < props.priceData.prices.length; i++) {
      const timestamp = props.priceData.timestamps[i];
      if (
        props.xContraints?.[0] &&
        props.xContraints?.[1] &&
        (timestamp < props.xContraints?.[0] || timestamp > props.xContraints[1])
      )
        continue;
      const timeString = toUTCTimestring(timestamp, props.candleSize ?? "1D");
      _timestampMap[timestamp] = timeString;
      _priceMap[timestamp] = props.priceData.prices[i];
      const price = props.priceData.prices[i];
      _dataset.push({
        x: timeString,
        timestamp: timestamp,
        price: price,
      });
    }
    setBaseLeft(_dataset[0]?.x);
    setBaseRight(_dataset[_dataset.length - 1]?.x);

    return {
      dataset: _dataset,
      timestampMap: _timestampMap,
      priceMap: _priceMap,
    };
  }, [props.priceData, props.candleSize, props.xContraints]);

  const CustomTooltip = memo(
    ({ active, payload, label }: TooltipProps<number, string>) => {
      if (active && label && payload) {
        const tooltipOrderLabel =
          tooltipOrderLabels && tooltipOrderLabels[label];
        return (
          <div className="custom-tooltip">
            <div className="price-and-date-container">
              <label>{label}</label>
              <label
                color={"dimmed"}
                style={{ fontFamily: "IBMPlexMono-Light" }}
              >
                <strong>{payload?.[0]?.value?.toFixed(decimalCount)} $</strong>
              </label>
              {tooltipOrderLabel && !props.microtester && (
                <label className="dimmed-label">Click to focus</label>
              )}
            </div>
            {tooltipOrderLabel}
          </div>
        );
      }

      return null;
    }
  );

  const tooltipOrderLabels = useMemo(() => {
    const labelMap: { [key: string]: ReactNode } = {};

    if (props.candleSize !== "1D" && props.filteredOrders) {
      // Use filteredOrders for detailed tooltip labels
      props.filteredOrders.forEach((order) => {
        const timestampKey = `candle_size_${props.candleSize ?? "1D"}`;
        const timestamp = order.timestamp_references[timestampKey];
        const timeString = toUTCTimestring(timestamp, props.candleSize ?? "1D");
        const orderType = order.id.includes("buy_") ? "buy" : "sell";
        const price = orderType === "buy" ? order.buy_price : order.sell_price!;

        const detailLabel = (
          <div key={timeString} className="order-tooltip-labels">
            <label className={`order-tooltip-label ${orderType}`}>
              {orderType}
            </label>
            <label>
              <strong>{price.toFixed(decimalCount)} $</strong>
            </label>
          </div>
        );
        labelMap[timeString] = detailLabel;
      });
    } else {
      if (!currencyOverviewOrders) return undefined;
      Object.entries(currencyOverviewOrders).forEach(
        ([timestampString, orderIds], idx) => {
          const timestamp = parseInt(timestampString);
          const timeString = toUTCTimestring(
            timestamp,
            props.candleSize ?? "1D"
          );
          const timestampLabel = (
            <div key={timeString} className="order-tooltip-labels">
              {orderIds.map((orderId, index) => {
                const orderType = orderId.includes("buy_") ? "buy" : "sell";
                return (
                  <div
                    key={orderId}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {orderIds.length > 3 ? (
                      <>
                        {index === 0 || index === orderIds.length - 1 ? (
                          <label
                            className={`order-tooltip-label ${orderType} small`}
                            key={orderId}
                          >
                            {orderType}
                          </label>
                        ) : (
                          <>
                            {index < itemCap && (
                              <div
                                className={`order-tooltip-dot ${orderType}`}
                              />
                            )}
                            {index === itemCap && (
                              <div className="order-tooltip-cap">
                                <label>
                                  + {orderIds.length - itemCap - 1} orders
                                </label>
                              </div>
                            )}
                            {index === itemCap && (
                              <IoMdArrowDropdown size={10} />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <label
                        className={`order-tooltip-label ${orderType}`}
                        key={orderId}
                      >
                        {orderType}
                      </label>
                    )}

                    {index < orderIds.length - 1 && index < itemCap && (
                      <IoMdArrowDropdown size={orderIds.length > 3 ? 10 : 15} />
                    )}
                  </div>
                );
              })}
            </div>
          );
          labelMap[timeString] = timestampLabel;
        }
      );
    }
    return labelMap;
  }, [
    currencyOverviewOrders,
    props.candleSize,
    itemCap,
    props.filteredOrders,
    decimalCount,
  ]);

  const firstOrderIdMap = useMemo(() => {
    const _firstOrderIdMap: { [key: number]: string } = {};
    if (!currencyOverviewOrders) return undefined;
    Object.entries(currencyOverviewOrders).forEach(
      ([timestampString, orderIds]) => {
        const timestamp = parseInt(timestampString);
        const firstOrderId = orderIds[0];
        _firstOrderIdMap[timestampMap[timestamp]] = firstOrderId;
      }
    );
    return _firstOrderIdMap;
  }, [currencyOverviewOrders, timestampMap]);

  //detect order type from list of order ids and determine whether it is a hybrid order(buy and sell), buy order or sell order
  //create a function the uses regex to detect the order type from the order id. The order id is a string such as 'bt.buy_1' or 'bt.sell_1'
  //the function should return a string that is either 'buy', 'sell' or 'hybrid'
  const getOrderType = (orderIds: string[]) => {
    if (orderIds.length > 1) {
      const lastOrderId = orderIds[orderIds.length - 1];
      return lastOrderId.includes("buy_") ? "buy" : "sell";
    }
    const firstOrderId = orderIds[0];
    return firstOrderId.includes("buy_") ? "buy" : "sell";
  };

  const { detailedOrderAnnotationDots, latestAcquisitionOrder } = useMemo<{
    detailedOrderAnnotationDots: React.ReactNode[] | undefined;
    latestAcquisitionOrder: Order | undefined;
  }>(() => {
    if (!props.filteredOrders || isRunning || props.candleSize === "1D")
      return {
        detailedOrderAnnotationDots: undefined,
        latestAcquisitionOrder: undefined,
      };
    let latestAcquisitionOrder: Order | undefined = undefined;
    const annotations: React.ReactNode[] = [];
    props.filteredOrders.forEach((order: Order, idx: number) => {
      if (filteredOrderIds.length > 0 && !filteredOrderIds.includes(order.id))
        return;
      if (idx === 0) latestAcquisitionOrder = order;
      const timestampKey = `candle_size_${props.candleSize ?? "1D"}`;
      const timestamp = order.timestamp_references[timestampKey];
      const orderType = order.id.includes("buy_") ? "buy" : "sell";
      if (
        orderType === "buy" &&
        latestAcquisitionOrder?.timestamp_references[timestampKey] < timestamp
      )
        latestAcquisitionOrder = order;
      const price = orderType === "buy" ? order.buy_price : order.sell_price!; // assuming priceMap is a map of timestamps to prices
      const xValue = toUTCTimestring(timestamp, props.candleSize ?? "1D");

      const strokeColor =
        orderType === "buy"
          ? theme.buyOrderStrokeHover
          : theme.sellOrderStrokeHover;
      const fillColor =
        orderType === "buy" ? theme.buyOrderStroke : theme.sellOrderStroke;
      annotations.push(
        <ReferenceDot
          key={`detail-dot-${order.id}`}
          x={xValue}
          y={price}
          strokeWidth={2}
          r={4}
          fill={fillColor}
          stroke={strokeColor}
          onClick={() => {
            setFocusedOrderId(order.id);
          }}
        />
      );
    });

    return {
      detailedOrderAnnotationDots: annotations,
      latestAcquisitionOrder: latestAcquisitionOrder,
    };
  }, [
    props.filteredOrders,
    isRunning,
    props.candleSize,

    theme,
    setFocusedOrderId,
    filteredOrderIds,
  ]);

  const overviewOrderAnnotationDots = useMemo(() => {
    if (!currencyOverviewOrders || !timestampMap || !priceMap) return undefined;

    const annotations: React.ReactNode[] = [];

    Object.entries(currencyOverviewOrders).forEach(
      ([timestampString, orderIds], idx) => {
        const timestamp = parseInt(timestampString);
        const xValue = timestampMap[timestamp];
        const yValue = priceMap[timestamp];
        if (orderIds.length === 1) {
          const orderTypeColor = getOrderType(orderIds);
          const strokeColor =
            orderTypeColor === "buy"
              ? theme.buyOrderStrokeHover
              : theme.sellOrderStrokeHover;
          const fillColor =
            orderTypeColor === "buy"
              ? theme.buyOrderStrokeHover
              : theme.sellOrderStrokeHover;

          annotations.push(
            <ReferenceDot
              key={`dot-${timestamp}-${idx}`}
              onClick={(e: any) => {
                setFocusedOrderId(orderIds[0]);
              }}
              x={xValue}
              y={yValue}
              strokeWidth={2}
              r={4}
              fill={fillColor}
              stroke={strokeColor}
            />
          );
        } else {
          const ogYValue = priceMap[timestamp];

          const lastOrderId = orderIds[orderIds.length - 1];
          for (let i = 0; i < orderIds.length; i++) {
            // we adjust the y value of the order annotation to get some vertical spacing between the orders
            // first order is the ogYValue, then we subtract n% of the ogYValue for each subsequent order
            const iModifier = i <= itemCap ? i : itemCap + 1;
            const adjustedYValue =
              i === 0 ? ogYValue : ogYValue - ogYValue * 0.015 * iModifier;
            const rValue = i === 0 ? 4 : 3;
            const orderId = orderIds[i];
            const strokeColor = orderId.includes("buy")
              ? theme.buyOrderStrokeHover
              : theme.sellOrderStrokeHover;
            const fillColor = orderId.includes("buy")
              ? theme.buyOrderStroke
              : theme.sellOrderStroke;
            if (i !== orderIds.length - 1 && i >= itemCap) {
              if (i === itemCap) {
                annotations.push(
                  <ReferenceDot
                    key={`dot-${timestamp}-${idx}-${i}`}
                    onClick={(e: any) => {
                      setFocusedOrderId(orderId);
                    }}
                    x={xValue}
                    y={adjustedYValue}
                    strokeWidth={1}
                    r={2}
                    fill={theme.text}
                    stroke={theme.text}
                  />
                );
              } else {
                continue;
              }
            } else {
              annotations.push(
                <ReferenceDot
                  key={`dot-${timestamp}-${idx}-${i}`}
                  onClick={(e: any) => {
                    setFocusedOrderId(orderId);
                  }}
                  x={xValue}
                  y={adjustedYValue}
                  strokeWidth={i === 0 || orderId === lastOrderId ? 1 : 0}
                  r={rValue}
                  fill={fillColor}
                  stroke={strokeColor}
                />
              );
            }
          }
        }
      }
    );

    return annotations;
  }, [
    currencyOverviewOrders,
    timestampMap,
    priceMap,
    theme,
    setFocusedOrderId,
    itemCap,
  ]);

  const axisYDomain = useMemo(() => {
    if (!dataset) return undefined;
    const prices = dataset.map((entry) => entry.price);
    let min = Math.min(...prices);
    let max = Math.max(...prices);
    if (props.holding?.take_profit_target_price && props.showHoldingDetails) {
      if (
        props.holding.take_profit_target_price > max &&
        props.holdingDetailsToggle?.tpTarget
      )
        max = props.holding.take_profit_target_price;
    }
    if (props.holding?.stoploss_target_price && props.showHoldingDetails) {
      if (
        props.holding.stoploss_target_price < min &&
        props.holdingDetailsToggle?.slTarget
      )
        min = props.holding.stoploss_target_price;
    }
    const padding = (max - min) * 0.1;
    return [min - padding, max + padding];
  }, [
    dataset,
    props.holding,
    props.showHoldingDetails,
    props.holdingDetailsToggle,
  ]);

  const handleMouseUp = useCallback(
    (e) => {
      if (!zoomLeft || !zoomRight) return;
      try {
        const date1 = new Date(zoomLeft);
        const date2 = new Date(zoomRight);
        const left = date1 < date2 ? date1 : date2;
        const right = date1 < date2 ? date2 : date1;
        if (left === right) return;
        props.setXContraints &&
          props.setXContraints([left.getTime(), right.getTime()]);
      } finally {
        setZoomLeft(undefined);
        setZoomRight(undefined);
      }
    },
    [setZoomLeft, setZoomRight, zoomLeft, zoomRight, props]
  );

  interface CurrentHoldingAnnotationData {
    x1: string;
    x2: string;
    y1?: number;
    y2?: number;
  }

  const currentHoldingAnnotationData = useMemo(():
    | CurrentHoldingAnnotationData
    | undefined => {
    if (!props.isHolding || !currencyOverviewOrders) return undefined;

    if (props.candleSize !== "1D" && latestAcquisitionOrder) {
      const timestampKey = `candle_size_${props.candleSize ?? "1D"}`;
      const acquisitionTimestamp =
        latestAcquisitionOrder.timestamp_references[timestampKey];
      const acquisitionTimeString = toUTCTimestring(
        acquisitionTimestamp,
        props.candleSize ?? "1D"
      );
      const acquisitionPrice = latestAcquisitionOrder.id.includes("buy_")
        ? latestAcquisitionOrder.buy_price
        : latestAcquisitionOrder.sell_price!;
      const lastPrice =
        props.priceData?.prices[props.priceData.prices.length - 1];
      const lastTimestamp =
        props.priceData?.timestamps[props.priceData.timestamps.length - 1];
      const x1 = acquisitionTimeString;
      const x2 = toUTCTimestring(lastTimestamp, props.candleSize ?? "1D");
      return {
        x1: x1,
        x2: x2,
        y1: acquisitionPrice,
        y2: lastPrice,
      };
    }
    // get last entry of currencyOverviewOrders
    const lastHoldingTimestamp = Object.keys(currencyOverviewOrders)[
      Object.keys(currencyOverviewOrders).length - 1
    ];
    const timestamps = Object.keys(timestampMap);
    const lastTimestamp = timestamps[timestamps.length - 1];

    const orderIds = currencyOverviewOrders?.[lastHoldingTimestamp];
    const hasBuyOrder = orderIds?.some((orderId) => orderId.includes("buy_"));
    if (!hasBuyOrder) return undefined;
    if (!priceMap) return undefined;
    const x1 = timestampMap[lastHoldingTimestamp];
    // get last entry of timestampMap
    const x2 = props.currentOverviewTimestamp
      ? timestampMap[props.currentOverviewTimestamp]
      : timestampMap[lastTimestamp];
    const y1 = priceMap[lastHoldingTimestamp] as number | undefined;
    const y2 = priceMap[props.currentOverviewTimestamp ?? lastTimestamp] as
      | number
      | undefined;
    return { x1, x2, y1, y2 } as CurrentHoldingAnnotationData;
  }, [
    props.isHolding,
    props.currentOverviewTimestamp,
    priceMap,
    currencyOverviewOrders,
    timestampMap,
    latestAcquisitionOrder,
    props.candleSize,
    props.priceData,
  ]);

  const renderHoldingArea = useMemo(() => {
    if (
      props.isHolding &&
      props.showHoldingDetails &&
      currentHoldingAnnotationData &&
      currentHoldingAnnotationData.y1 &&
      currentHoldingAnnotationData.y2 &&
      props.holdingDetailsToggle?.holdingArea
    ) {
      return (
        <ReferenceArea
          x1={currentHoldingAnnotationData.x1}
          x2={currentHoldingAnnotationData.x2}
          y1={currentHoldingAnnotationData.y1}
          y2={currentHoldingAnnotationData.y2}
          stroke={
            currentHoldingAnnotationData.y1 < currentHoldingAnnotationData.y2
              ? theme.buyOrderStroke
              : theme.sellOrderStroke
          }
          fill={"transparent"}
          strokeWidth={1}
        />
      );
    }
  }, [
    theme.sellOrderStroke,
    theme.buyOrderStroke,
    props.isHolding,
    props.showHoldingDetails,
    currentHoldingAnnotationData,
    props.holdingDetailsToggle,
  ]);

  const renderStoplossTarget = useMemo(() => {
    if (
      props.holding &&
      props.showHoldingDetails &&
      currentHoldingAnnotationData &&
      props.holdingDetailsToggle?.slTarget
    ) {
      return (
        <ReferenceArea
          x2={currentHoldingAnnotationData.x1}
          x1={currentHoldingAnnotationData.x2}
          y1={(props.holding.stoploss_target_price ?? 0) * 0.9999}
          y2={props.holding.stoploss_target_price}
          stroke={theme.sellOrderStrokeHover}
          strokeWidth={2}
        />
      );
    }
  }, [
    theme.sellOrderStrokeHover,
    props.holding,
    props.showHoldingDetails,
    currentHoldingAnnotationData,
    props.holdingDetailsToggle,
  ]);

  const renderTakeProfitTarget = useMemo(() => {
    if (
      props.holding &&
      props.showHoldingDetails &&
      currentHoldingAnnotationData &&
      props.holdingDetailsToggle?.tpTarget
    ) {
      return (
        <ReferenceArea
          x2={currentHoldingAnnotationData.x1}
          x1={currentHoldingAnnotationData.x2}
          y1={(props.holding.take_profit_target_price ?? 0) * 0.9999}
          y2={props.holding.take_profit_target_price}
          stroke={theme.buyOrderStrokeHover}
          strokeWidth={2}
        />
      );
    }
  }, [
    theme.buyOrderStrokeHover,
    props.holding,
    props.showHoldingDetails,
    currentHoldingAnnotationData,
    props.holdingDetailsToggle,
  ]);

  const renderDetailedOrderAnnotations = useMemo(() => {
    if (enableAnnotation && !props.hideOrders && detailedOrderAnnotationDots)
      return detailedOrderAnnotationDots;
  }, [enableAnnotation, props.hideOrders, detailedOrderAnnotationDots]);

  const renderOverviewOrderAnnotations = useMemo(() => {
    if (enableAnnotation && !props.hideOrders && overviewOrderAnnotationDots)
      return overviewOrderAnnotationDots;
  }, [enableAnnotation, props.hideOrders, overviewOrderAnnotationDots]);

  const renderMicrotestResults = useMemo(() => {
    if (!props.microtester || !props.microtestResults) return null;
    const annotations: React.ReactNode[] = [];
    props.microtestResults.entry_timestamps.forEach((timestamp, idx) => {
      const xValue = toUTCTimestring(timestamp, props.candleSize ?? "1D");
      annotations.push(
        <ReferenceLine
          key={`dot-${timestamp}-${idx}`}
          x={xValue}
          stroke={theme.buyOrderStroke}
          strokeWidth={2}
          strokeDasharray="6 6"
        />
      );
    });
    props.microtestResults.exit_timestamps.forEach((timestamp, idx) => {
      const xValue = toUTCTimestring(timestamp, props.candleSize ?? "1D");
      annotations.push(
        <ReferenceLine
          key={`dot-${timestamp}-${idx}`}
          x={xValue}
          stroke={theme.sellOrderStroke}
          strokeWidth={2}
          strokeDasharray="6 6"
        />
      );
    });
    return annotations;
  }, [props.microtester, props.microtestResults, theme]);

  const renderLineChart = useMemo(() => {
    return (
      <Line
        onAnimationEnd={() => setEnableAnnotation(true)}
        type="monotone"
        dataKey="price"
        stroke={theme.text}
        strokeWidth={2}
        activeDot={{ r: 3 }}
        dot={{ r: 0 }}
      />
    );
  }, [theme.text]);

  const bottomDateLabels = useMemo(() => {
    if (!timestampMap || !props.showFirstAndLastDate) return undefined;
    return {
      first: toUTCTimestring(
        parseInt(Object.keys(timestampMap)[0]),
        props.candleSize
      ),
      last: toUTCTimestring(
        parseInt(
          Object.keys(timestampMap)[Object.keys(timestampMap).length - 1]
        ),
        props.candleSize
      ),
    };
  }, [timestampMap, props.showFirstAndLastDate, props.candleSize]);

  return (
    <>
      {props.priceData && props.priceData.prices.length > 1 ? (
        <>
          <ResponsiveContainer width="99.9%" height="99%">
            <LineChart
              width={300}
              height={100}
              data={dataset}
              onClick={(e: any) => {
                const activeLabel = e.activeLabel;
                if (activeLabel && firstOrderIdMap) {
                  setZoomLeft(undefined);
                  setZoomRight(undefined);
                  const firstOrderId =
                    firstOrderIdMap[activeLabel] ?? undefined;
                  if ((props.candleSize ?? "1D") === "1D") return;
                  setFocusedOrderId(firstOrderId);
                }
              }}
              onMouseDown={(e: any) => {
                if (e.button === 2) return;
                setZoomLeft(e.activeLabel);
                setZoomRight(undefined);
              }}
              onMouseMove={(e: any) => {
                if (!zoomLeft) return;
                if (zoomLeft !== e.activeLabel) {
                  setZoomRight(e.activeLabel);
                }
              }}
              onMouseUp={handleMouseUp}
              onMouseLeave={(e: any) => {
                setZoomLeft(undefined);
                setZoomRight(undefined);
              }}
            >
              {axisYDomain && (
                <YAxis domain={[axisYDomain[0], axisYDomain[1]]} hide />
              )}
              {baseLeft && baseRight && (
                <XAxis dataKey="x" hide domain={[baseLeft, baseRight]} />
              )}

              {renderLineChart}

              {!isRunning && <Tooltip content={<CustomTooltip />} />}

              {zoomLeft && zoomRight && (
                <ReferenceArea
                  x1={zoomLeft}
                  x2={zoomRight}
                  stroke={theme.cardInteriorAlt}
                  fill={theme.cardInteriorAlt}
                  strokeWidth={2}
                />
              )}

              {renderMicrotestResults}
              {renderHoldingArea}

              {renderStoplossTarget}

              {renderTakeProfitTarget}
              {props.microtester ? (
                <>{renderOverviewOrderAnnotations}</>
              ) : (
                <>
                  {props.candleSize !== "1D" && renderDetailedOrderAnnotations}

                  {props.candleSize === "1D" && renderOverviewOrderAnnotations}
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
          {bottomDateLabels && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.4 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.5 }}
              className="trading-card-chart-date-label-container"
            >
              <label className="dimmed-label">
                {bottomDateLabels.first} GMT
              </label>
              <label className="dimmed-label">
                {bottomDateLabels.last} GMT
              </label>
            </motion.div>
          )}
        </>
      ) : (
        <>
          {props.loading ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.5 }}
              className="tradingcard-chart-no-data-container"
            >
              <NeotonSpinner activeTheme={props.activeTheme} />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.5 }}
              className="tradingcard-chart-no-data-container"
            >
              <AiOutlineLineChart size={26} />
              <label>Nothing to visualize yet</label>
            </motion.div>
          )}
        </>
      )}
    </>
  );
}
