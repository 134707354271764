import "./color-picker.scss";
import { ColorPicker, ColorSwatch, Space, TextInput } from "@mantine/core";
import { useState } from "react";
import IconButton from "../../buttons/icon-button/IconButton";
import { FiCheck } from "react-icons/fi";

interface Props {
  currentColor: string;
  handleColorChange: (color: string) => void;
}
export function CustomColorPicker(props: React.PropsWithChildren<Props>) {
  const [color, setColor] = useState(props.currentColor);

  return (
    <div className="color-picker-container">
      <div className="color-picker-container-row">
        <ColorPicker
          format="hex"
          value={color}
          onChange={(col) => setColor(col)}
        />
      </div>
      <Space h={"xl"} />
      <div className="color-picker-container-row">
        <ColorSwatch color={color} size={40} radius="xs" />
      </div>
      <div className="color-picker-container-row">
        <IconButton
          icon={<FiCheck />}
          iconSize={4}
          onClick={() => props.handleColorChange(color)}
        />
        <TextInput value={color} onChange={(e) => setColor(e.target.value)} />
      </div>
    </div>
  );
}
