import "./membership-card.scss";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  RefreshUserPermissionsContext,
  ToastContext,
  UserInfoContext,
  UserPermissionsContext,
} from "../../../App";
import { Modal, TextInput } from "@mantine/core";
import {
  BetaCodeSubmitForm,
  UserSubscriptionTier,
  UsermanagerService,
} from "../../../services/UserManagerService";
import { useAuth0 } from "@auth0/auth0-react";
import { BetaSignupForm } from "../BetaSignupForm";
import { CommonButton } from "../../../components/buttons/neoton-common-button/CommonButton";
import { useQuery } from "react-query";
import { ProfileEditor } from "./ProfileEditor";
import { UserProfileComponent } from "../../../components/community/user-profile-component/UserProfileComponent";
import { FaUserCheck, FaUsers } from "react-icons/fa6";
import { IoAlert } from "react-icons/io5";
import { SubscriptionsModal } from "./SubscriptionsModal";
import { CommonUserStats } from "../../../components/common/common-user-stats/CommonUserStats";

interface Props {
  activeTheme: string;
  setDefaultDashboardState: () => void;
  isAnonymous: boolean;
}
export function MembershipCard(props: React.PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const userPermissions = useContext(UserPermissionsContext);
  const refreshPermissions = useContext(RefreshUserPermissionsContext);
  const triggerToast = useContext(ToastContext);
  const userInfo = useContext(UserInfoContext);
  const [codeForm, setCodeForm] = useState<BetaCodeSubmitForm>({
    code: "",
    nonce: "",
  });
  const [loading, setLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalMode, setModalMode] = useState<
    "Beta signup" | "Profile" | "Violation" | "Subscription"
  >(isAuthenticated ? "Profile" : "Beta signup");

  const isAdmin = useMemo(() => {
    if (!userPermissions || !userPermissions.includedPermissions) return false;
    return userPermissions.includedPermissions.includes("admin");
  }, [userPermissions]);

  const validInput = codeForm.code.length === 24;
  const [loadingSubscription, setLoadingSubscription] = useState(false);

  const fetchSubscriptionTier = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingSubscription(true);
      const response = await UsermanagerService.GetSubscriptionTier(token);
      return response.data.payload;
    } finally {
      setLoadingSubscription(false);
    }
  }, [getAccessTokenSilently, setLoadingSubscription]);

  const userSubscriptionTierQuery = useQuery(
    "userRole",
    fetchSubscriptionTier,
    {
      cacheTime: 60000,
      staleTime: 0,
      enabled: !props.isAnonymous,
    }
  );

  const userSubscriptionTier = useMemo(() => {
    if (!userSubscriptionTierQuery.data) return undefined;
    return userSubscriptionTierQuery.data;
  }, [userSubscriptionTierQuery]);

  const handleSubmit = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!validInput) return;
    try {
      setLoading(true);
      // get Nonce
      // artificial timeout to prevent spamming
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const nonceResponse = await UsermanagerService.getNonce();
      const nonce = nonceResponse.data.nonce;
      const submitResponse = await UsermanagerService.submitBetaCode(token, {
        ...codeForm,
        nonce: nonce,
      });
      triggerToast(submitResponse.data.message, "success", null);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      refreshPermissions();
      props.setDefaultDashboardState();
    } catch (error: any) {
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setCodeForm({
        nonce: "",
        code: "",
      });
      setLoading(false);
    }
  }, [
    validInput,
    setCodeForm,
    codeForm,
    props,
    setLoading,
    refreshPermissions,
    triggerToast,
    getAccessTokenSilently,
  ]);

  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContainerHeight(ref.current.clientHeight);
    }
  }, [setContainerHeight, ref]);

  const renderSubscriptionTierButton = useCallback(
    (subscriptionTier: UserSubscriptionTier) => {
      if (subscriptionTier.plan === "Pro plan") {
        return (
          <CommonButton
            onClick={() => {
              setModalMode("Subscription");
              setModalOpened(true);
            }}
            leftIcon={
              <span
                className={
                  "neoton-icon " +
                  subscriptionTier.plan.replace(" ", "-").toLowerCase()
                }
              >
                E
              </span>
            }
            compact
            activeTheme={props.activeTheme}
            label={subscriptionTier.plan}
            borderTheme="neoton-accent"
            disabled={!isAdmin}
          />
        );
      }
      if (subscriptionTier.plan === "Basic plan") {
        return (
          <CommonButton
            onClick={() => {
              setModalMode("Subscription");
              setModalOpened(true);
            }}
            leftIcon={
              <span
                className={
                  "neoton-icon " +
                  subscriptionTier.plan.replace(" ", "-").toLowerCase()
                }
              >
                E
              </span>
            }
            compact
            activeTheme={props.activeTheme}
            label={subscriptionTier.plan}
            borderTheme="blue-accent"
            disabled={!isAdmin}
          />
        );
      }

      return (
        <CommonButton
          onClick={() => {
            setModalMode("Subscription");
            setModalOpened(true);
          }}
          leftIcon={
            <span
              className={
                "neoton-icon " +
                subscriptionTier.plan.replace(" ", "-").toLowerCase()
              }
            >
              E
            </span>
          }
          compact
          activeTheme={props.activeTheme}
          label="Free plan"
          borderTheme="gray-accent"
          disabled={!isAdmin}
        />
      );
    },
    [props.activeTheme, setModalMode, setModalOpened, isAdmin]
  );

  const ProfileTabView = useCallback(() => {
    return (
      <div className="profile-container">
        {userInfo && userInfo.user_hash !== null && (
          <UserProfileComponent
            style={{
              margin: 20,
            }}
            activeTheme={props.activeTheme}
            userHash={userInfo.user_hash}
            username={userInfo.username ?? "Unknown"}
            userTitle={userInfo.title ?? undefined}
            size={containerHeight > 201 ? 130 : 60}
            nocache
            clickable
            onClick={() => {
              setModalMode("Profile");
              setModalOpened(true);
            }}
          />
        )}
        <CommonUserStats userInfo={userInfo} activeTheme={props.activeTheme} />
      </div>
    );
  }, [
    userInfo,
    props.activeTheme,
    containerHeight,
    setModalMode,
    setModalOpened,
  ]);

  const requestReportResolve = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!userInfo?.violation || !userInfo.violation_reason) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.RequestResolveReport(
        token,
        userInfo.violation_reason
      );
      triggerToast(response.data.message, "success", null);
    } catch (error: any) {
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setLoading(false);
      setModalMode("Profile");
      setModalOpened(false);
    }
  }, [
    userInfo,
    getAccessTokenSilently,
    triggerToast,
    setLoading,
    setModalOpened,
    setModalMode,
  ]);

  return (
    <div className="membership-card-container">
      {props.isAnonymous && (
        <div className="beta-activation-container">
          <div className="beta-activation-column">
            <label className="dimmed-label">
              Enter your beta access code to activate your account
            </label>
            <TextInput
              size="lg"
              maxLength={24}
              placeholder="Enter your access code"
              value={codeForm.code}
              onChange={(event) =>
                setCodeForm({ ...codeForm, code: event.currentTarget.value })
              }
            />
          </div>
          <div className="beta-activation-column">
            <CommonButton
              activeTheme={props.activeTheme}
              label="Activate beta"
              onClick={handleSubmit}
              disabled={!validInput}
              loading={loading}
              leftIcon={<FaUserCheck />}
              borderTheme={validInput ? "neoton-accent" : undefined}
            />
            <label>or</label>
            <CommonButton
              activeTheme={props.activeTheme}
              label="Enlist for beta"
              onClick={() => {
                setModalOpened(true);
                setModalMode("Beta signup");
              }}
              borderTheme="blue-accent"
              leftIcon={<FaUsers />}
              keepWidth={true}
            />
          </div>
        </div>
      )}
      {!props.isAnonymous && (
        <div ref={ref} className="account-outer-container">
          <div className="common-header-panel">
            {userSubscriptionTier ? (
              renderSubscriptionTierButton(userSubscriptionTier)
            ) : (
              <CommonButton
                onClick={() => {
                  setModalMode("Subscription");
                  setModalOpened(true);
                }}
                leftIcon={<span className="neoton-icon free-plan">E</span>}
                compact
                activeTheme={props.activeTheme}
                label="Free plan"
                borderTheme="gray-accent"
                loading={loadingSubscription}
                disabled={!isAdmin}
              />
            )}

            {userInfo?.violation && (
              <CommonButton
                style={{
                  marginLeft: "auto",
                  marginRight: "5px",
                }}
                onClick={() => {
                  setModalMode("Violation");
                  setModalOpened(true);
                }}
                compact
                activeTheme={props.activeTheme}
                label="Violation report"
                leftIcon={<IoAlert />}
                borderTheme="red-accent"
              />
            )}
          </div>
          {ProfileTabView()}
        </div>
      )}
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        size="xl"
        title={modalMode}
      >
        {modalMode === "Violation" && userInfo?.violation && (
          <div className="violation-info-container">
            <label>Your account is currently flagged due to a violation</label>

            {userInfo.violation_reason && (
              <label>Reason for violation: {userInfo.violation_reason}</label>
            )}

            <label className="dimmed-label">
              While your account is flagged, you will be excluded from certain
              features, such as the Papertrader leaderboard.
            </label>

            <label
              className="dimmed-label"
              style={{
                whiteSpace: "wrap",
              }}
            >
              Change your content to comply with the rules to remove the flag.
              Press the button below to submit a evalution request. You can only
              submit one request per week.
            </label>

            <CommonButton
              style={{
                alignSelf: "center",
              }}
              onClick={requestReportResolve}
              compact
              borderTheme="gray-accent"
              activeTheme={props.activeTheme}
              label="Evaluate my profile"
            />
          </div>
        )}
        {modalMode === "Beta signup" && (
          <BetaSignupForm
            email={userInfo?.email}
            closeModal={() => setModalOpened(false)}
          />
        )}

        {modalMode === "Profile" && (
          <ProfileEditor
            activeTheme={props.activeTheme}
            setModalOpened={setModalOpened}
            existingUsername={userInfo?.username ?? undefined}
          />
        )}
        {modalMode === "Subscription" && (
          <SubscriptionsModal
            activeTheme={props.activeTheme}
            activeSubscription={userSubscriptionTier}
            refetchSubscription={userSubscriptionTierQuery.refetch}
          />
        )}
      </Modal>
    </div>
  );
}
