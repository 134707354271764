import "./calculation-profile-menu.scss";
import { Badge } from "@mantine/core";
import { getTierColors } from "../../utils/ParseCriteriaUtil";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { useContext } from "react";
import { UserPermissionsContext } from "../../App";

interface Props {
  activeTheme: string;
  selectedCalculationProfile: CalculationProfile;
  setSelectedCalculationProfile: (profile: CalculationProfile) => void;
}
export function CalculationProfileMenu(props: React.PropsWithChildren<Props>) {
  const sharedStyles = { minWidth: "100px", maxWidth: "120px" };
  const userPermissions = useContext(UserPermissionsContext);
  return (
    <div className="calculation-profile-menu-container">
      <CommonButton
        leftIcon={
          <Badge
            radius={"xs"}
            variant="outline"
            color={getTierColors("TA")}
            size={"xs"}
            styles={{
              inner: {
                fontFamily: "Montserrat-LightAlt",
              },
            }}
          >
            TA
          </Badge>
        }
        labelStyle={{
          fontFamily: "Montserrat-LightAlt",
          opacity: 0.9,
          fontWeight: 600,
          whiteSpace: "wrap",
        }}
        dimmedLabel
        style={sharedStyles}
        compact
        noMinWidth
        activeTheme={props.activeTheme}
        label="Technical Analysis"
        onClick={() => props.setSelectedCalculationProfile("TA")}
        borderTheme={
          props.selectedCalculationProfile === "TA" ? "blue-accent" : undefined
        }
      />
      <CommonButton
        leftIcon={
          <Badge
            radius={"xs"}
            variant="outline"
            color={getTierColors("PT")}
            size={"xs"}
            styles={{
              inner: {
                fontFamily: "Montserrat-LightAlt",
              },
            }}
          >
            PT
          </Badge>
        }
        labelStyle={{
          fontFamily: "Montserrat-LightAlt",
          opacity: 0.9,
          fontWeight: 600,
          whiteSpace: "wrap",
        }}
        dimmedLabel
        style={sharedStyles}
        compact
        noMinWidth
        activeTheme={props.activeTheme}
        label="Pattern Tracking"
        onClick={() => props.setSelectedCalculationProfile("PT")}
        borderTheme={
          props.selectedCalculationProfile === "PT"
            ? "purple-accent"
            : undefined
        }
      />
      <CommonButton
        disabled={!userPermissions?.includedPermissions.includes("pro")}
        leftIcon={
          <Badge
            radius={"xs"}
            variant="outline"
            color={getTierColors("AI")}
            size={"xs"}
            styles={{
              inner: {
                fontFamily: "Montserrat-LightAlt",
              },
            }}
          >
            AI
          </Badge>
        }
        labelStyle={{
          fontFamily: "Montserrat-LightAlt",
          opacity: 0.9,
          fontWeight: 600,
          whiteSpace: "wrap",
        }}
        dimmedLabel
        style={sharedStyles}
        compact
        noMinWidth
        activeTheme={props.activeTheme}
        label="AI Signals"
        onClick={() => props.setSelectedCalculationProfile("AI")}
        borderTheme={
          props.selectedCalculationProfile === "AI"
            ? "neoton-accent"
            : undefined
        }
      />
      <CommonButton
        disabled
        leftIcon={
          <Badge
            radius={"xs"}
            variant="outline"
            color={getTierColors("CI")}
            size={"xs"}
            styles={{
              inner: {
                fontFamily: "Montserrat-LightAlt",
              },
            }}
          >
            CI
          </Badge>
        }
        labelStyle={{
          fontFamily: "Montserrat-LightAlt",
          opacity: 0.9,
          fontWeight: 600,
          whiteSpace: "wrap",
        }}
        dimmedLabel
        style={sharedStyles}
        compact
        noMinWidth
        activeTheme={props.activeTheme}
        label="Community Indicators"
        onClick={() => props.setSelectedCalculationProfile("CI")}
        borderTheme={
          props.selectedCalculationProfile === "CI"
            ? "orange-accent"
            : undefined
        }
      />
    </div>
  );
}
export type CalculationProfile = "TA" | "AI" | "PT" | "CI";
