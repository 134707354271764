import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Candles } from "../../interfaces/Candle";
import { Currency } from "../../interfaces/Currency";
import { toTimestring } from "../../utils/FormattingUtils";
import { StonkChart } from "../chart-components/financial-chart/StonkChart";
import { IOHLCData } from "../chart-components/financial-chart/iOHLCData";
import useForceUpdate from "use-force-update";
import { motion } from "framer-motion";
import {
  persistChartSettings,
  tryGetStoredChartSettings,
} from "../../utils/CachedDataUtil";
import { ChartSettings } from "../../interfaces/chartIndicators/ChartSettings";
import {
  Case,
  StrategyVersion,
} from "../../interfaces/strategyInterfaces/Strategy";
import { ChartControls } from "./ChartControls";
import { ChartNavigation } from "./ChartNavigation";
import { NeotonLoader } from "../custom-loaders/NeotonLoader";
import { CommonCandleSizeControl } from "../common/common-candle-size-control/CommonCandleSizeControl";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { getTheme } from "../../utils/themeUtil";
import { CommonIconButton } from "../buttons/neoton-common-button/CommonIconButton";
import { FiPlayCircle, FiX } from "react-icons/fi";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { Modal } from "@mantine/core";
import { CandleSize, StrategyHelperModal } from "./StrategyHelperModal";
import { RiAiGenerate } from "react-icons/ri";
import {
  CriteriaTestResourceContext,
  SetCriteriaTestResourceContext,
} from "../../pages/strategy-page/StrategyPage";
import { ParsedCriteria } from "./ParsedCriteria";
import { IndicatorInfosContext } from "../../shared-service-contexts/IndicatorsInfoContext";
import { CriteriaTesterModal } from "./CriteriaTesterModal";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  candleSize: string;
  handleCandleSizeChange: (
    candleSize: string,
    centerTimestamp: number | null
  ) => void;
  candles: Candles | undefined;
  selectedCase: Case | undefined;
  timeInterval: number[];
  selectedCurrency: Currency;
  availableCurrencies: Currency[];
  loadingCandles: boolean;
}

export function StrategyChartContainer(props: React.PropsWithChildren<Props>) {
  const forceUpdate = useForceUpdate();
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const criteriaTestResource = useContext(CriteriaTestResourceContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const setCriteriaTestResource = useContext(SetCriteriaTestResourceContext);
  const storedChartSettings = tryGetStoredChartSettings();
  const [stagedCriteriaGenerationPoint, setStagedCriteriaGenerationPoint] =
    useState<StagedCriteriaGenerationPoint | undefined>();
  const [criteriaGenerationPoints, setCriteriaGenerationPoints] = useState<
    CriteriaGenerationPoint[]
  >([]);

  const [chartSettings, setChartSettings] =
    useState<ChartSettings>(storedChartSettings);

  const persistChartSettingsInLocalStorage = useCallback(
    (_chartSettings: ChartSettings) => {
      persistChartSettings(_chartSettings);
    },
    []
  );

  const [modalMode, setModalMode] = useState<
    "Criteria Generator" | "Criteria Tester" | undefined
  >();

  const handleChartClick = useCallback(
    (_dateDataPoint: number) => {
      if (
        criteriaGenerationPoints.some((x) => x.timestamp === _dateDataPoint)
      ) {
        // If the point is already set, remove it
        const dropIndex = criteriaGenerationPoints.findIndex(
          (x) => x.timestamp === _dateDataPoint
        );
        const newPoints = [...criteriaGenerationPoints];
        newPoints.splice(dropIndex, 1);
        setCriteriaGenerationPoints(newPoints);
        return;
      }
      setStagedCriteriaGenerationPoint({
        timestamp: _dateDataPoint,
        candle_size: props.candleSize,
      });
    },
    [
      props.candleSize,
      setStagedCriteriaGenerationPoint,
      setCriteriaGenerationPoints,
      criteriaGenerationPoints,
    ]
  );

  const stonkDataSeries = useMemo(() => {
    if (!props.candles) return;
    const _stonkDataSeries: IOHLCData[] = [];
    for (let i = 0; i < props.candles?.candleData.timestamps.length; i++) {
      const dataItem: IOHLCData = {
        close: props.candles?.candleData.closePrice[i],
        high: props.candles?.candleData.highPrice[i],
        low: props.candles?.candleData.lowPrice[i],
        open: props.candles?.candleData.openPrice[i],
        volume: props.candles?.candleData.volume[i],
        ts: props.candles?.candleData.timestamps[i],
        date: new Date(props.candles?.candleData.timestamps[i]),
      };

      _stonkDataSeries.push(dataItem);
    }
    return _stonkDataSeries;
  }, [props.candles]);

  const stonkChartContainerRef = useRef<HTMLDivElement>(null);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResizing(true);
      setTimeout(() => {
        setResizing(false);
      }, 100);
    });
  }, [setResizing]);

  const buyPointIcon = (
    <HiOutlineChevronDown color={theme.buyOrderStrokeHover} />
  );
  const sellPointIcon = (
    <HiOutlineChevronUp color={theme.sellOrderStrokeHover} />
  );

  useEffect(() => {
    if (stagedCriteriaGenerationPoint) {
      // If keyboard event is pressed
      // If key is "B" or "S" (Buy or Sell) add the point to the criteriaGenerationPoints
      // If key is "B" set the side to "entry"
      // If key is "S" set the side to "exit"
      // and remove the stagedCriteriaGenerationPoint
      // go
      window.addEventListener("keydown", (e) => {
        if (e.key === "b" || e.key === "B") {
          setCriteriaGenerationPoints([
            ...criteriaGenerationPoints,
            {
              timestamp: stagedCriteriaGenerationPoint.timestamp,
              candle_size: stagedCriteriaGenerationPoint.candle_size,
              side: "entry",
            },
          ]);
          setStagedCriteriaGenerationPoint(undefined);
        } else if (e.key === "s" || e.key === "S") {
          setCriteriaGenerationPoints([
            ...criteriaGenerationPoints,
            {
              timestamp: stagedCriteriaGenerationPoint.timestamp,
              candle_size: stagedCriteriaGenerationPoint.candle_size,
              side: "exit",
            },
          ]);
          setStagedCriteriaGenerationPoint(undefined);
        }
      });
    }
  }, [
    stagedCriteriaGenerationPoint,
    criteriaGenerationPoints,
    setCriteriaGenerationPoints,
    setStagedCriteriaGenerationPoint,
  ]);

  return (
    <div className="strategy-chart-container">
      <div className="chart-controls-container">
        <ChartNavigation
          candleSize={props.candleSize}
          activeTheme={props.activeTheme}
          selectedCurrency={props.selectedCurrency}
          handleNavigationChartClick={props.handleCandleSizeChange}
          currentInterval={[props.timeInterval[0], props.timeInterval[1]]}
        />
        <CommonCandleSizeControl
          id="strategy-creator-candle-size-control"
          selectedCandleSize={props.candleSize}
          onClick={(candleSize) =>
            props.handleCandleSizeChange(candleSize, null)
          }
        />

        <motion.div layout className="chart-controls-button">
          <ChartControls
            hideOrders
            forceUpdate={forceUpdate}
            persistChartSettingsInLocalStorage={
              persistChartSettingsInLocalStorage
            }
            activeTheme={props.activeTheme}
            chartSettings={chartSettings}
            setChartSettings={setChartSettings}
          />
        </motion.div>
        <div className="criteria-generator-outer-container">
          {criteriaGenerationPoints.length > 0 && (
            <CommonButton
              compact
              id="criteria-generator-button"
              activeTheme={props.activeTheme}
              borderTheme="gray-accent"
              leftIcon={<RiAiGenerate />}
              label="Generate criteria"
              dimmedLabel
              labelStyle={{
                fontSize: "12px",
                whiteSpace: "wrap",
              }}
              noMinWidth
              onClick={() => {
                setModalMode("Criteria Generator");
              }}
            />
          )}
          <motion.div layout className="criteria-generator-container">
            {criteriaGenerationPoints.length === 0 ? (
              <label className="dimmed-label">
                'Double-click' the chart to set buy/sell points
              </label>
            ) : (
              <>
                <motion.div layout className="criteria-generator-row buy">
                  {criteriaGenerationPoints
                    .filter((x) => x.side === "entry")
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map((point, idx) => {
                      return (
                        <div
                          className="criteria-generator-chip buy"
                          onClick={() => {
                            const dropIndex =
                              criteriaGenerationPoints.findIndex(
                                (x) =>
                                  x.timestamp === point.timestamp &&
                                  x.side === point.side
                              );
                            const newPoints = [...criteriaGenerationPoints];
                            newPoints.splice(dropIndex, 1);
                            setCriteriaGenerationPoints(newPoints);
                          }}
                          key={`buy-${idx}`}
                        >
                          {buyPointIcon}
                          <label className="dimmed-label">
                            {toTimestring(point.timestamp, point.candle_size)}
                          </label>
                        </div>
                      );
                    })}
                </motion.div>
                <motion.div layout className="criteria-generator-row sell">
                  {criteriaGenerationPoints
                    .filter((x) => x.side === "exit")
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map((point, idx) => {
                      return (
                        <div
                          className="criteria-generator-chip sell"
                          key={`sell-${idx}`}
                          onClick={() => {
                            const dropIndex =
                              criteriaGenerationPoints.findIndex(
                                (x) =>
                                  x.timestamp === point.timestamp &&
                                  x.side === point.side
                              );
                            const newPoints = [...criteriaGenerationPoints];
                            newPoints.splice(dropIndex, 1);
                            setCriteriaGenerationPoints(newPoints);
                          }}
                        >
                          {sellPointIcon}
                          <label className="dimmed-label">
                            {toTimestring(point.timestamp, point.candle_size)}
                          </label>
                        </div>
                      );
                    })}
                </motion.div>
              </>
            )}
            {stagedCriteriaGenerationPoint && (
              <div className="criteria-generator-popup">
                <div className="criteria-generator-popup-row">
                  <label className="dimmed-label">
                    {toTimestring(
                      stagedCriteriaGenerationPoint.timestamp,
                      stagedCriteriaGenerationPoint.candle_size
                    )}
                  </label>
                  <CommonIconButton
                    icon={<FiX />}
                    activeTheme={props.activeTheme}
                    onClick={() => setStagedCriteriaGenerationPoint(undefined)}
                  />
                </div>
                <label className="dimmed-label">
                  Press <strong>B</strong> or click below
                </label>
                <CommonButton
                  activeTheme={props.activeTheme}
                  borderTheme="green-accent"
                  leftIcon={buyPointIcon}
                  label="Buy point"
                  onClick={() => {
                    setCriteriaGenerationPoints([
                      ...criteriaGenerationPoints,
                      {
                        timestamp: stagedCriteriaGenerationPoint.timestamp,
                        candle_size: stagedCriteriaGenerationPoint.candle_size,
                        side: "entry",
                      },
                    ]);
                    setStagedCriteriaGenerationPoint(undefined);
                  }}
                />
                <label className="dimmed-label">
                  Press <strong>S</strong> or click below
                </label>
                <CommonButton
                  activeTheme={props.activeTheme}
                  borderTheme="red-accent"
                  label="Sell point"
                  leftIcon={sellPointIcon}
                  onClick={() => {
                    setCriteriaGenerationPoints([
                      ...criteriaGenerationPoints,
                      {
                        timestamp: stagedCriteriaGenerationPoint.timestamp,
                        candle_size: stagedCriteriaGenerationPoint.candle_size,
                        side: "exit",
                      },
                    ]);
                    setStagedCriteriaGenerationPoint(undefined);
                  }}
                />
              </div>
            )}
          </motion.div>
        </div>
      </div>
      <Modal
        centered
        opened={modalMode !== undefined}
        onClose={() => setModalMode(undefined)}
        title={modalMode}
        size="xl"
      >
        {modalMode === "Criteria Generator" &&
          criteriaGenerationPoints.length > 0 && (
            <StrategyHelperModal
              strategy={props.strategy}
              activeTheme={props.activeTheme}
              criteriaGenerationPoints={criteriaGenerationPoints}
              buyPointIcon={buyPointIcon}
              sellPointIcon={sellPointIcon}
              currency={props.selectedCurrency}
              preSelectedCandleSize={props.candleSize as CandleSize}
            />
          )}
        {modalMode === "Criteria Tester" && criteriaTestResource && (
          <CriteriaTesterModal
            selectedCurrency={props.selectedCurrency}
            activeTheme={props.activeTheme}
            criteriaTestResource={criteriaTestResource}
          />
        )}
      </Modal>

      {!stonkDataSeries || props.loadingCandles ? (
        <motion.div
          className="strategy-body-loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, delay: 0.5 }}
        >
          <NeotonLoader />
        </motion.div>
      ) : (
        <div className="stonk-chart-container" ref={stonkChartContainerRef}>
          {stonkChartContainerRef.current && !resizing && (
            <StonkChart
              activeTheme={props.activeTheme}
              candleSize={props.candleSize}
              data={stonkDataSeries}
              height={stonkChartContainerRef.current.clientHeight}
              width={stonkChartContainerRef.current.clientWidth}
              ratio={1}
              handleChartClick={handleChartClick}
              chartSettings={chartSettings}
              criteriaGenerationPoints={criteriaGenerationPoints}
            />
          )}
          {criteriaTestResource && (
            <motion.div
              initial={{ scale: 0, bottom: "2%", left: "5%" }}
              animate={{ scale: 1, bottom: "7%", left: "5%" }}
              transition={{ duration: 0.2, delay: 0.3 }}
              className="criteria-test-resource-container"
            >
              <div className="criteria-resource-row">
                <label>Entry criteria</label>
                {criteriaTestResource.entryCriteria && (
                  <CommonIconButton
                    icon={<FiX />}
                    label="Remove criteria"
                    style={{ fontSize: "smaller" }}
                    onClick={() => {
                      if (!criteriaTestResource.exitCriteria) {
                        setCriteriaTestResource(undefined);
                        return;
                      }
                      // remove the last element from the array
                      setCriteriaTestResource({
                        ...criteriaTestResource,
                        entryCriteria: undefined,
                      });
                    }}
                    activeTheme={props.activeTheme}
                  />
                )}
              </div>
              {criteriaTestResource.entryCriteria ? (
                <>
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    className="criteria-result-container entry"
                  >
                    <ParsedCriteria
                      indicatorsInfo={indicatorsInfo}
                      activeTheme={props.activeTheme}
                      criteria={criteriaTestResource.entryCriteria}
                    />
                  </motion.div>
                </>
              ) : (
                <label className="dimmed-label">
                  You can add a {criteriaTestResource.candleSize} entry criteria
                  to test along with the exit criteria
                </label>
              )}
              <div className="criteria-resource-row">
                <label>Exit criteria</label>
                {criteriaTestResource.exitCriteria && (
                  <CommonIconButton
                    icon={<FiX />}
                    label="Remove criteria"
                    style={{ fontSize: "smaller" }}
                    onClick={() => {
                      if (!criteriaTestResource.entryCriteria) {
                        setCriteriaTestResource(undefined);
                        return;
                      }
                      // remove the last element from the array
                      setCriteriaTestResource({
                        ...criteriaTestResource,
                        exitCriteria: undefined,
                      });
                    }}
                    activeTheme={props.activeTheme}
                  />
                )}
              </div>
              {criteriaTestResource.exitCriteria ? (
                <>
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: 0.3,
                    }}
                    className="criteria-result-container exit"
                  >
                    <ParsedCriteria
                      indicatorsInfo={indicatorsInfo}
                      activeTheme={props.activeTheme}
                      criteria={criteriaTestResource.exitCriteria}
                    />
                  </motion.div>
                </>
              ) : (
                <label className="dimmed-label">
                  You can add a {criteriaTestResource.candleSize} exit criteria
                  to test along with the entry criteria
                </label>
              )}
              <div
                className="criteria-resource-row"
                style={{
                  justifyContent: "space-evenly",
                }}
              >
                <CommonButton
                  style={{
                    maxHeight: 20,
                    alignSelf: "center",
                  }}
                  compact
                  activeTheme={props.activeTheme}
                  borderTheme="green-accent"
                  label={"Test criteria"}
                  leftIcon={<FiPlayCircle />}
                  onClick={() => setModalMode("Criteria Tester")}
                />
                <CommonButton
                  style={{
                    maxHeight: 20,
                    alignSelf: "center",
                  }}
                  compact
                  activeTheme={props.activeTheme}
                  label="Clear"
                  leftIcon={<FiX />}
                  onClick={() => setCriteriaTestResource(undefined)}
                />
              </div>
            </motion.div>
          )}
        </div>
      )}
    </div>
  );
}

export interface CriteriaGenerationPoint {
  timestamp: number;
  candle_size: string;
  side: "entry" | "exit";
}
export interface StagedCriteriaGenerationPoint {
  timestamp: number;
  candle_size: string;
}
