import { Space, Tooltip } from "@mantine/core";
import { AiOutlinePercentage } from "react-icons/ai";
import {
  Case,
  StoplossType,
  TakeProfitType,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { useCallback, useContext, useMemo, useState } from "react";
import { UpdateSelectedCaseContext } from "../../../pages/strategy-page/StrategyPage";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { BiSolidDownArrow } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { EquationContext } from "react-equation";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { motion } from "framer-motion";
import { CommonCandleSizeControl } from "../../common/common-candle-size-control/CommonCandleSizeControl";

interface Props {
  activeTheme: string;
  case: Case;
  readonly?: boolean;
}
export function CaseExtras(props: React.PropsWithChildren<Props>) {
  const updateCase = useContext(UpdateSelectedCaseContext);

  const [takeProfitMenuOpened, setTakeProfitMenuOpened] = useState(false);
  const [stoplossMenuOpened, setStoplossMenuOpened] = useState(false);

  const handleTakeProfitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      updateCase({
        ...props.case,
        stop_profit:
          value && value !== "" ? Math.abs(Number(value)) : undefined,
      });
    },
    [props.case, updateCase]
  );

  const handleStoplossChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        stoploss: value && value !== "" ? -Math.abs(Number(value)) : undefined,
      });
    },
    [props.case, updateCase]
  );

  const handleAtrStoplossMultiplierChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        atr_stoploss_multiplier: value && value !== "" ? Number(value) : 2,
      });
    },
    [props.case, updateCase]
  );

  const handleAtrTakeProfitMultiplierChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      updateCase({
        ...props.case,
        atr_take_profit_multiplier: value && value !== "" ? Number(value) : 2,
      });
    },
    [props.case, updateCase]
  );

  const handleTakeProfitCandleSizeChange = useCallback(
    (value: string) => {
      updateCase({
        ...props.case,
        stop_profit_candle_size: value,
      });
    },
    [props.case, updateCase]
  );

  const handleStoplossCandleSizeChange = useCallback(
    (value: string) => {
      updateCase({
        ...props.case,
        stoploss_candle_size: value,
      });
    },
    [props.case, updateCase]
  );

  const enforceOrderWeightRules = useCallback((value: number) => {
    if (value < 0.5) return 0.5;
    if (value > 1) return 1;
    return value;
  }, []);

  const enforceOrderFrequencyRules = useCallback((value: number) => {
    if (value < 1) return 1;
    if (value > 24) return 24;
    return value;
  }, []);

  const getReactionTimeLabels = useCallback(
    (candleSizeString: string | undefined) => {
      if (!candleSizeString) return "None";
      if (candleSizeString === "disabled") return "Never";
      if (candleSizeString.includes("1D")) {
        return "Every 24 hours";
      }
      if (candleSizeString.includes("1h")) {
        return "Every hour";
      }
      if (candleSizeString.includes("5m")) {
        return "Every 5 minutes";
      }
    },
    []
  );

  const orderWeightPctValue = (props.case.weight * 100).toFixed(0);

  const getTakeProfitLabel = useCallback(
    (_takeProfitType: TakeProfitType | undefined) => {
      switch (_takeProfitType) {
        case "static":
          return "Static take profit";
        case "atr":
          return "ATR take profit";
        case "disabled":
          return "No take profit";
        default:
          return "No take profit";
      }
    },
    []
  );

  const getStoplossLabel = useCallback(
    (_stoplossType: StoplossType | undefined) => {
      switch (_stoplossType) {
        case "static":
          return "Static stoploss";
        case "atr":
          return "ATR stoploss";
        case "trailing_atr":
          return "Trailing ATR";
        case "trailing":
          return "Trailing stoploss";
        case "disabled":
          return "No stoploss";
        default:
          return "No stoploss";
      }
    },
    []
  );

  const getOrderWeightNumberInput = useCallback(() => {
    return (
      <div className="number-input-container" id="order-weight-input">
        <Tooltip
          label={
            <label>
              This case will use <strong>{orderWeightPctValue}%</strong> of the
              funds in the market cap pool
            </label>
          }
        >
          <label className="dimmed-label">Order weight:</label>
        </Tooltip>
        <Space w={"xs"} />

        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <>
              {props.case.weight && props.case.weight < 1 && (
                <div
                  className={"immediate-action-input-button arrow-up"}
                  onClick={() => {
                    if (props.readonly) return;

                    let nextWeight = props.case.weight
                      ? props.case.weight + 0.05
                      : 1;
                    if (nextWeight > 1) nextWeight = 1;
                    if (nextWeight < 0.5) nextWeight = 0.5;

                    updateCase({
                      ...props.case,
                      weight: nextWeight,
                    });
                  }}
                >
                  <IoIosArrowUp />
                </div>
              )}
            </>
          )}
          <input
            disabled={true}
            value={orderWeightPctValue ?? 1}
            className="extras-number-input order-options"
            type="number"
            placeholder="1"
            max={1}
            min={1.5}
            step={0.05}
          />
          {!props.readonly && (
            <>
              {props.case.weight && props.case.weight > 0.5 && (
                <div
                  className={"immediate-action-input-button arrow-down"}
                  onClick={() => {
                    if (props.readonly) return;

                    let nextWeight = props.case.weight
                      ? props.case.weight - 0.05
                      : 1;
                    nextWeight = enforceOrderWeightRules(nextWeight);

                    updateCase({
                      ...props.case,
                      weight: nextWeight,
                    });
                  }}
                >
                  <IoIosArrowDown />
                </div>
              )}
            </>
          )}
        </div>
        <AiOutlinePercentage />
      </div>
    );
  }, [
    props.case,
    props.readonly,
    updateCase,
    orderWeightPctValue,
    enforceOrderWeightRules,
  ]);

  const getOrderFrequencyNumberInput = useCallback(() => {
    return (
      <div className="number-input-container" id="order-frequency-input">
        <Tooltip
          label={
            <label>
              This case can execute new buy orders
              <strong> {props.case.daily_frequency_limit}</strong> times per day
            </label>
          }
        >
          <label className="dimmed-label">Order frequency:</label>
        </Tooltip>
        <Space w={"xs"} />

        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <>
              {props.case.daily_frequency_limit &&
                props.case.daily_frequency_limit < 24 && (
                  <div
                    className={"immediate-action-input-button arrow-up"}
                    onClick={() => {
                      if (props.readonly) return;

                      let nextFreq = props.case.daily_frequency_limit
                        ? props.case.daily_frequency_limit + 1
                        : 1;
                      nextFreq = enforceOrderFrequencyRules(nextFreq);

                      updateCase({
                        ...props.case,
                        daily_frequency_limit: nextFreq,
                      });
                    }}
                  >
                    <IoIosArrowUp />
                  </div>
                )}
            </>
          )}
          <input
            disabled={true}
            value={props.case.daily_frequency_limit ?? 1}
            className="extras-number-input order-options"
            type="number"
            placeholder="1"
            max={24}
            min={1}
          />
          {!props.readonly && (
            <>
              {props.case.daily_frequency_limit &&
                props.case.daily_frequency_limit > 1 && (
                  <div
                    className={"immediate-action-input-button arrow-down"}
                    onClick={() => {
                      if (props.readonly) return;
                      let nextFreq = props.case.daily_frequency_limit
                        ? props.case.daily_frequency_limit - 1
                        : 1;
                      nextFreq = enforceOrderFrequencyRules(nextFreq);

                      updateCase({
                        ...props.case,
                        daily_frequency_limit: nextFreq,
                      });
                    }}
                  >
                    <IoIosArrowDown />
                  </div>
                )}
            </>
          )}
        </div>
        <label>/ day</label>
      </div>
    );
  }, [props.case, props.readonly, updateCase, enforceOrderFrequencyRules]);

  const getTakeProfitNumberInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stop_profit && props.case.stop_profit > 0
            ? "stop-profit-input"
            : ""
        }
      >
        <label>Change:</label>
        <Space w={"xs"} />

        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                if (props.readonly) return;

                const nextTakeProfit = props.case.stop_profit
                  ? props.case.stop_profit + 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stop_profit: Math.abs(nextTakeProfit),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleTakeProfitChange}
            value={props.case.stop_profit}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={
              props.case.stop_profit && props.case.stop_profit > 0
                ? "stop-profit-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                if (props.readonly) return;
                const nextTakeProfit = props.case.stop_profit
                  ? props.case.stop_profit - 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stop_profit: Math.abs(nextTakeProfit),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>

        <AiOutlinePercentage />
      </div>
    );
  }, [props.case, props.readonly, handleTakeProfitChange, updateCase]);

  const getTakeProfitAtrInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stop_profit && props.case.stop_profit > 0
            ? "stop-profit-input"
            : ""
        }
      >
        <label>ATR Multiplier:</label>
        <Space w={"xs"} />

        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                if (props.readonly) return;

                const nextMultiplier = props.case.atr_take_profit_multiplier
                  ? props.case.atr_take_profit_multiplier + 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_take_profit_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleAtrTakeProfitMultiplierChange}
            value={parseFloat(
              props.case.atr_take_profit_multiplier?.toFixed(1) ?? "2.0"
            )}
            className="extras-number-input"
            type="number"
            min={0.1}
            max={100}
            placeholder="0"
            id={
              props.case.stop_profit && props.case.stop_profit > 0
                ? "stop-profit-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                if (props.readonly) return;

                const nextMultiplier = props.case.atr_take_profit_multiplier
                  ? props.case.atr_take_profit_multiplier - 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_take_profit_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
      </div>
    );
  }, [
    props.case,
    props.readonly,
    handleAtrTakeProfitMultiplierChange,
    updateCase,
  ]);

  const getStoplossInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stoploss && props.case.stoploss < 0 ? "stoploss-input" : ""
        }
      >
        <label>Change: </label>
        <Space w={"xs"} />
        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                const nextStoploss = props.case.stoploss
                  ? props.case.stoploss - 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stoploss: -Math.abs(nextStoploss),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleStoplossChange}
            value={props.case.stoploss}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={
              props.case.stoploss && props.case.stoploss < 0
                ? "stoploss-input"
                : ""
            }
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                const nextStoploss = props.case.stoploss
                  ? props.case.stoploss + 0.5
                  : 10;
                updateCase({
                  ...props.case,
                  stoploss: -Math.abs(nextStoploss),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
        <AiOutlinePercentage />
      </div>
    );
  }, [props.case, props.readonly, handleStoplossChange, updateCase]);

  const getStoplossAtrInput = useCallback(() => {
    return (
      <div
        className="number-input-container"
        id={
          props.case.stoploss && props.case.stoploss < 0 ? "stoploss-input" : ""
        }
      >
        <label>ATR Multiplier:</label>
        <Space w={"xs"} />

        <div className="immediate-action-input-controls">
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-up"}
              onClick={() => {
                const nextMultiplier = props.case.atr_stoploss_multiplier
                  ? props.case.atr_stoploss_multiplier + 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_stoploss_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowUp />
            </div>
          )}
          <input
            disabled={props.readonly}
            onChange={handleAtrStoplossMultiplierChange}
            value={parseFloat(
              props.case.atr_stoploss_multiplier?.toFixed(1) ?? "2.0"
            )}
            min={0.1}
            max={100}
            className="extras-number-input"
            type="number"
            placeholder="0"
            id={"stoploss-input"}
          />
          {!props.readonly && (
            <div
              className={"immediate-action-input-button arrow-down"}
              onClick={() => {
                const nextMultiplier = props.case.atr_stoploss_multiplier
                  ? props.case.atr_stoploss_multiplier - 0.1
                  : 2;
                updateCase({
                  ...props.case,
                  atr_stoploss_multiplier: parseFloat(
                    nextMultiplier.toFixed(1)
                  ),
                });
              }}
            >
              <IoIosArrowDown />
            </div>
          )}
        </div>
      </div>
    );
  }, [
    props.case,
    props.readonly,
    handleAtrStoplossMultiplierChange,
    updateCase,
  ]);

  const stoplossEquations = useMemo(() => {
    const multiplierValue =
      props.case.atr_stoploss_multiplier?.toFixed(1) ?? "2";
    const _stoploss_equations: { [key: string]: string } = {
      atr: `Current Price < Buy Price - (ATR * ${multiplierValue})`,
      trailing_atr: `Current Price < Peak Price - (ATR * ${multiplierValue})`,
    };
    return _stoploss_equations;
  }, [props.case]);

  const takeProfitEquations = useMemo(() => {
    const multiplierValue =
      props.case.atr_take_profit_multiplier?.toFixed(1) ?? "2";
    const _take_profit_equations: { [key: string]: string } = {
      atr: `Current Price > Buy Price + (ATR * ${multiplierValue})`,
    };
    return _take_profit_equations;
  }, [props.case]);

  return (
    <div className="case-extras-outer-container">
      <label className="dimmed-label">
        Immediate actions. Triggers regardless of criterias.
      </label>
      <div className="case-extras-container">
        <div className={"extras-column take-profit"}>
          <div className="extras-column-header">
            <CommonButton
              activeTheme={props.activeTheme}
              label={getTakeProfitLabel(props.case.take_profit_type)}
              onClick={() => setTakeProfitMenuOpened(!takeProfitMenuOpened)}
              compact
              disabled={props.readonly}
              flat={!takeProfitMenuOpened}
              leftIcon={<AiOutlinePercentage />}
              borderTheme={
                !props.case.take_profit_type ||
                props.case.take_profit_type === "disabled"
                  ? "gray-accent"
                  : "green-accent"
              }
            />
          </div>
          <div className="extras-column-body">
            {takeProfitMenuOpened ? (
              <>
                <BiSolidDownArrow size={8} />

                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("static")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "static",
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"green-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "atr",
                      atr_take_profit_multiplier:
                        props.case.atr_take_profit_multiplier ?? 2,
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"green-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getTakeProfitLabel("disabled")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      take_profit_type: "disabled",
                    });
                    setTakeProfitMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"gray-accent"}
                />
              </>
            ) : (
              <>
                {!props.case.take_profit_type ||
                props.case.take_profit_type === "disabled" ? (
                  <>
                    <label className="dimmed-label">No take profit</label>
                  </>
                ) : (
                  <>
                    {props.case.take_profit_type === "static" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since buy
                          </label>
                          {getTakeProfitNumberInput()}
                        </div>
                      </div>
                    )}
                    {props.case.take_profit_type === "atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{
                                  opacity: 0.5,
                                }}
                              >
                                {renderEquation(takeProfitEquations["atr"])}
                              </div>
                            )}
                          />
                          {getTakeProfitAtrInput()}
                        </div>
                      </div>
                    )}
                    <div className="reaction-time-container">
                      <label className="dimmed-label">
                        Reaction time:{" "}
                        {getReactionTimeLabels(
                          props.case.stop_profit_candle_size
                        )}
                      </label>
                      {!props.readonly && (
                        <CommonCandleSizeControl
                          selectedCandleSize={
                            props.case.stop_profit_candle_size?.replace(
                              "candle_size_",
                              ""
                            ) ?? "1D"
                          }
                          onClick={(candleSize) =>
                            handleTakeProfitCandleSizeChange(
                              `candle_size_${candleSize}`
                            )
                          }
                          themeColor="buy"
                          hideLabel
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className={"extras-column stoploss"}>
          <div className="extras-column-header">
            <CommonButton
              activeTheme={props.activeTheme}
              label={getStoplossLabel(props.case.stoploss_type)}
              onClick={() => setStoplossMenuOpened(!stoplossMenuOpened)}
              compact
              disabled={props.readonly}
              flat={!stoplossMenuOpened}
              leftIcon={<AiOutlinePercentage />}
              borderTheme={
                !props.case.stoploss_type ||
                props.case.stoploss_type === "disabled"
                  ? "gray-accent"
                  : "red-accent"
              }
            />
          </div>
          <div className="extras-column-body">
            {stoplossMenuOpened ? (
              <>
                <BiSolidDownArrow size={8} />

                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("static")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "static",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("trailing")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "trailing",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "atr",
                      atr_stoploss_multiplier:
                        props.case.atr_stoploss_multiplier ?? 2,
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("trailing_atr")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "trailing_atr",
                      atr_stoploss_multiplier:
                        props.case.atr_stoploss_multiplier ?? 2,
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"red-accent"}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label={getStoplossLabel("disabled")}
                  onClick={() => {
                    updateCase({
                      ...props.case,
                      stoploss_type: "disabled",
                    });
                    setStoplossMenuOpened(false);
                  }}
                  compact
                  flat
                  leftIcon={<AiOutlinePercentage />}
                  borderTheme={"gray-accent"}
                />
              </>
            ) : (
              <>
                {!props.case.stoploss_type ||
                props.case.stoploss_type === "disabled" ? (
                  <>
                    <label className="dimmed-label">No stoploss</label>
                  </>
                ) : (
                  <>
                    {props.case.stoploss_type === "trailing" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since peak holding price
                          </label>
                          {getStoplossInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "static" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <label className="dimmed-label">
                            % Change since buy
                          </label>
                          {getStoplossInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{
                                  opacity: 0.5,
                                }}
                              >
                                {renderEquation(stoplossEquations["atr"])}
                              </div>
                            )}
                          />
                          {getStoplossAtrInput()}
                        </div>
                      </div>
                    )}
                    {props.case.stoploss_type === "trailing_atr" && (
                      <div className="immediate-action-input-container">
                        <div className="immediate-action-column">
                          <EquationContext
                            render={(renderEquation) => (
                              <div
                                className="equation-wrapper"
                                style={{
                                  opacity: 0.5,
                                }}
                              >
                                {renderEquation(
                                  stoplossEquations["trailing_atr"]
                                )}
                              </div>
                            )}
                          />
                          {getStoplossAtrInput()}
                        </div>
                      </div>
                    )}

                    <div className="reaction-time-container">
                      <label className="dimmed-label">
                        Reaction time:{" "}
                        {getReactionTimeLabels(props.case.stoploss_candle_size)}
                      </label>
                      {!props.readonly && (
                        <CommonCandleSizeControl
                          selectedCandleSize={
                            props.case.stoploss_candle_size?.replace(
                              "candle_size_",
                              ""
                            ) ?? "1D"
                          }
                          onClick={(candleSize) =>
                            handleStoplossCandleSizeChange(
                              `candle_size_${candleSize}`
                            )
                          }
                          themeColor="sell"
                          hideLabel
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <label className="dimmed-label">Order options</label>
      <div className={"case-extras-container order-options"}>
        <div className={"extras-column order-options"}>
          <div className="extras-column-body order-options">
            {getOrderWeightNumberInput()}
            {getOrderFrequencyNumberInput()}
          </div>
        </div>
      </div>
    </div>
  );
}
