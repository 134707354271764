import { createContext, useCallback, useContext, useState } from "react";
import { PapertraderService } from "../../services/PapertraderService";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { ApplicationIndicators } from "../../interfaces/Indicators";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { IndicatorsContext } from "../../shared-service-contexts/IndicatorContext";
import { TradingDashboard } from "../common/TradingDashboard";
import { ToastContext, UpdateCurrencyVibranceContext } from "../../App";
import { NeotonLoader } from "../../components/custom-loaders/NeotonLoader";
import { PapertraderLeaderboard } from "../../components/paperwallet-center/PapertraderLeaderboard";
import { UsermanagerService } from "../../services/UserManagerService";
import { Pagination } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { DateInterval } from "../../interfaces/DateInterval";
import { PapertraderControl } from "../../components/common/common-dashboard/trader-controls/PapertraderControl";
import { useMediaQuery } from "@mantine/hooks";
import {
  IndicatorInfosContext,
  IndicatorsInfo,
} from "../../shared-service-contexts/IndicatorsInfoContext";
import { SetListChildToggledContext } from "../../components/shared-context/ListChildToggledContext";

interface Props {
  activeTheme: string;
}
export default function PapertraderPage(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const triggerToast = useContext(ToastContext);
  const [selectedSeasonKey, setSelectedSeasonKey] = useState<
    string | undefined
  >();
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [loadingInitialReport, setLoadingInitalReport] = useState(false);
  const [listChildToggled, setListChildToggled] = useState(
    location.state !== null ? false : true
  );

  const pageLimit = 25;

  const [page, setPage] = useState(1);
  const [paginationChunks, setPaginationChunks] = useState<{
    start: number;
    stop: number;
  }>({ start: 0, stop: pageLimit });

  const [strategyId, setStrategyId] = useState<string | undefined>(
    location.state?.strategyId
  );
  const [versionId, setVersionId] = useState<string | undefined>(
    location.state?.versionId
  );

  const handlePaginationChange = useCallback(
    (page: number) => {
      const start = Math.abs(page - 1) * pageLimit;
      const stop = start + pageLimit;
      setPage(page);
      setPaginationChunks({ start, stop });
    },
    [setPage, setPaginationChunks]
  );

  const fetchCurrencies = useCallback(async () => {
    const _token = await getAccessTokenSilently();
    try {
      return await PapertraderService.blueprint.getCurrencies(_token);
    } catch (error) {
      console.log("error", error);
    }
  }, [getAccessTokenSilently]);

  const updateCurrencyVibrances = useContext(UpdateCurrencyVibranceContext);

  const currenciesQuery = useQuery("Currencies", fetchCurrencies, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retryDelay: 4000,
    onSuccess: (data) => {
      if (!data || !updateCurrencyVibrances) return;
      updateCurrencyVibrances(data);
    },
  });

  const fetchIndicators = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await PapertraderService.blueprint.getIndicators(token);
      return response.data as ApplicationIndicators;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const indicatorsQuery = useQuery(["Indicators"], fetchIndicators, {
    keepPreviousData: true,
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const fetchIndicatorInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await PapertraderService.blueprint.getIndicatorsInfo(
        token
      );
      return response.data as IndicatorsInfo;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const indicatorInfoQuery = useQuery(["IndicatorsInfo"], fetchIndicatorInfo, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const getSeasons = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await PapertraderService.blueprint.getSeasons(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently]);

  const seasonsQuery = useQuery(["Seasons"], getSeasons, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const fetchLeaderboard = useCallback(async () => {
    const _selectedSeasonKey = selectedSeasonKey
      ? selectedSeasonKey
      : seasonsQuery.data?.current_season ?? "season_0";
    setSelectedSeasonKey(_selectedSeasonKey);
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingLeaderboard(true);
      const response = await PapertraderService.getPapertraderLeaderboard(
        token,
        _selectedSeasonKey
      );
      handlePaginationChange(1);
      return response.data;
    } catch (error) {
      console.log("error", error);
    } finally {
      setTimeout(() => {
        setLoadingLeaderboard(false);
      }, 1500);
    }
  }, [
    getAccessTokenSilently,
    setSelectedSeasonKey,
    setLoadingLeaderboard,
    selectedSeasonKey,
    handlePaginationChange,
    seasonsQuery.data,
  ]);

  const leaderboardQuery = useQuery(
    ["Leaderboard", setSelectedSeasonKey, selectedSeasonKey],
    fetchLeaderboard,
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retryDelay: 4000,
    }
  );

  const pageCount = leaderboardQuery.data?.payload
    ? Math.ceil(leaderboardQuery.data.payload.strategy_count / pageLimit)
    : 0;

  const fetchUserInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.getUserInfo(token);
    return response.data.payload;
  }, [getAccessTokenSilently]);

  const userInfoQuery = useQuery("userInfo", fetchUserInfo, {
    cacheTime: 60000,
    staleTime: 0,
    enabled: true,
  });

  const loadStrategy = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!versionId || !strategyId) return;
    try {
      const response = await PapertraderService.blueprint.loadStrategy(
        token,
        strategyId,
        versionId
      );
      return response.payload;
    } catch (error) {}
  }, [getAccessTokenSilently, strategyId, versionId]);

  const loadStrategyQuery = useQuery(
    ["LoadStrategy", versionId, strategyId],
    loadStrategy,
    {
      enabled: strategyId !== undefined && versionId !== undefined,
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retryDelay: 4000,
    }
  );

  const tryFetchFullTradingReport = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    if (!versionId || !strategyId) return;
    try {
      setLoadingInitalReport(true);
      const response = await PapertraderService.getPapertraderReport(
        token,
        versionId,
        selectedSeasonKey === seasonsQuery.data?.current_season
          ? undefined
          : selectedSeasonKey
      );
      return response.data.payload;
    } finally {
      setLoadingInitalReport(false);
    }
  }, [
    getAccessTokenSilently,
    setLoadingInitalReport,
    selectedSeasonKey,
    strategyId,
    versionId,
    seasonsQuery.data?.current_season,
  ]);

  const fullTradingReportQuery = useQuery(
    ["FullTradingReport", versionId, strategyId, selectedSeasonKey],
    tryFetchFullTradingReport,
    {
      enabled: seasonsQuery.data !== undefined,
      cacheTime: 60000,
      staleTime: 0,
      onError(error: any) {
        const errorMessage = error?.response?.data?.error;
        if (errorMessage) {
          triggerToast(errorMessage, "error", null);
        }
      },
    }
  );

  const getIntervalFromReport = useCallback((): DateInterval | undefined => {
    if (!fullTradingReportQuery.data) return undefined;
    const report = fullTradingReportQuery.data;
    const startTimestamp = report.report?.wallet_value_snapshots.timestamps[0];
    const stopTimestamp =
      report.report?.wallet_value_snapshots.timestamps[
        report.report?.wallet_value_snapshots.timestamps.length - 1
      ];
    if (!startTimestamp || !stopTimestamp) return undefined;
    return {
      startTimestamp: parseInt(startTimestamp),
      stopTimestamp: parseInt(stopTimestamp),
    };
  }, [fullTradingReportQuery.data]);

  const dateInterval = getIntervalFromReport();

  const handleStrategyAttach = useCallback(
    (_strategyId: string, _versionId: string) => {
      setStrategyId(_strategyId);
      setVersionId(_versionId);
      setListChildToggled(false);
    },
    [setStrategyId, setVersionId, setListChildToggled]
  );

  return (
    <>
      <CurrencyContext.Provider value={currenciesQuery.data}>
        <IndicatorsContext.Provider value={indicatorsQuery.data}>
          <IndicatorInfosContext.Provider value={indicatorInfoQuery.data}>
            <SetPapertraderSeasonContext.Provider value={setSelectedSeasonKey}>
              <GetPapertraderSeasonContext.Provider value={selectedSeasonKey}>
                <SetListChildToggledContext.Provider
                  value={setListChildToggled}
                >
                  <TradingDashboard
                    activeTheme={props.activeTheme}
                    traderType={{
                      traderType: "papertrader",
                    }}
                    commonWalletReport={
                      fullTradingReportQuery.data ?? undefined
                    }
                    attachedStrategy={loadStrategyQuery.data ?? undefined}
                    dateInterval={dateInterval}
                    listChildToggledOverride={listChildToggled}
                    controlContainerChild={
                      <PapertraderControl activeTheme={props.activeTheme} />
                    }
                    leaderboardContainerChild={LeaderboardComponent()}
                    loadingInitialReport={loadingInitialReport}
                    handleAttachStrategy={
                      compact ? handleStrategyAttach : undefined
                    }
                    featuredButton={{
                      label: `${selectedSeasonKey} report`,
                      onClick: handleStrategyAttach,
                      mode: "papertrader",
                    }}
                  />
                </SetListChildToggledContext.Provider>
              </GetPapertraderSeasonContext.Provider>
            </SetPapertraderSeasonContext.Provider>
          </IndicatorInfosContext.Provider>
        </IndicatorsContext.Provider>
      </CurrencyContext.Provider>
    </>
  );

  function LeaderboardComponent() {
    return (
      <div className="papertrader-leaderboard-parent">
        <div className="papertrader-leaderboard-parent-header">
          <label>Leaderboard: {selectedSeasonKey}</label>
          {leaderboardQuery.data?.payload &&
          !loadingLeaderboard &&
          leaderboardQuery.data.payload.strategy_count > 10 ? (
            <div className="pagination-bar">
              <Pagination
                onChange={handlePaginationChange}
                page={page}
                total={pageCount}
                color={props.activeTheme === "light" ? "cyan" : "cyan"}
                radius={"xs"}
                size={"xs"}
              />
            </div>
          ) : undefined}
        </div>
        {loadingLeaderboard ? (
          <div
            className={
              compact
                ? "compact-loader-container leaderboard-loader"
                : "desktop-leaderboard-loader-container"
            }
          >
            <NeotonLoader />
            {!compact && (
              <label className="dimmed-label loading-label">
                Loading {selectedSeasonKey} leaderboard...
              </label>
            )}
          </div>
        ) : (
          <>
            {leaderboardQuery.data && !loadingLeaderboard && (
              <PapertraderLeaderboard
                compact={compact}
                paginationChunks={paginationChunks}
                activeTheme={props.activeTheme}
                leaderboard={
                  leaderboardQuery.data.payload?.strategy_leaderboard &&
                  leaderboardQuery.data.payload?.strategy_leaderboard.slice(
                    paginationChunks.start,
                    paginationChunks.stop
                  )
                }
                userInfo={userInfoQuery.data}
                attachStrategy={(_strategyId, _versionId) => {
                  setStrategyId(_strategyId);
                  setVersionId(_versionId);
                  setListChildToggled(false);
                }}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export const GetPapertraderSeasonContext = createContext<string | undefined>(
  undefined
);
export const SetPapertraderSeasonContext = createContext<
  React.Dispatch<React.SetStateAction<string | undefined>> | undefined
>(undefined);
export const GetCurrentSeasonContext = createContext<string | undefined>(
  undefined
);
