import { motion } from "framer-motion";
import { useState, useEffect } from "react";

interface Props {}
export function CommonClock(props: React.PropsWithChildren<Props>) {
  const [time, setTime] = useState<Date>(new Date());

  // add ticking utc clock
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <motion.label
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
      className={"header-button utc-clock-container"}
    >
      <div className="utc-clock">{time.toUTCString()}</div>
    </motion.label>
  );
}
