import React, { useRef } from "react";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { FaCheck } from "react-icons/fa";

interface Props {
  activeTheme: string;
  onAccept: () => void;
  loading: boolean;
  children: React.ReactNode; // Allow passing of children components
}

export function AcceptModalContent({
  children,
  activeTheme,
  onAccept,
  loading,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="accept-modal">
      <div
        className="accept-modal-content"
        ref={contentRef}
        style={{ overflowY: "scroll", minHeight: "400px", maxHeight: "70vh" }}
      >
        {children}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px 0",
        }}
      >
        <CommonButton
          activeTheme={activeTheme}
          onClick={onAccept}
          loading={loading}
          label="Accept"
          leftIcon={<FaCheck />}
        />
      </div>
    </div>
  );
}
