import { motion } from "framer-motion";
import { Currency } from "../../../interfaces/Currency";
import { Avatar } from "@mantine/core";
import { getCurrencyUrl } from "../../../utils/cdnUtil";

interface Props {
  includedCurrencies: string[];
  currencies: Currency[];
  allowedCurrencies: string[];
  handleUpdateIncludedCurrencies: (currencyName: string) => void;
}
export function NewCurrencySelection(props: React.PropsWithChildren<Props>) {
  return (
    <div className="new-currency-selection-container">
      {props.currencies.map((currency, idx) => {
        return (
          <motion.div
            key={currency.currency_name}
            initial={{ opacity: 0, scale: 1.3 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.25, delay: idx * 0.01 }}
            className="new-currency-selection-item"
            id={
              !props.allowedCurrencies.includes(currency.currency_name)
                ? "disabled-currency-selection-item"
                : props.includedCurrencies.includes(currency.currency_name)
                ? "selected-currency-selection-item"
                : ""
            }
            onClick={() => {
              if (!props.allowedCurrencies.includes(currency.currency_name))
                return;
              props.handleUpdateIncludedCurrencies(currency.currency_name);
            }}
          >
            <Avatar src={getCurrencyUrl(currency)} size={26} radius={"lg"} />
            <label>{currency.currency_name}</label>
          </motion.div>
        );
      })}
    </div>
  );
}
