import { roundToTwo } from "../../../utils/FormattingUtils";

interface Props {
  currentValue: number | undefined;
  initialValue: number;
  interactive?: boolean;
}
export function WalletTooltipLabel(props: React.PropsWithChildren<Props>) {
  const walletPL =
    props.currentValue && props.currentValue - props.initialValue;

  return (
    <div className="wallet-amount-label-container">
      {props.currentValue ? (
        <label>
          Current value: <strong>$ {roundToTwo(props.currentValue)}</strong>
        </label>
      ) : undefined}
      <label>
        Initial value: <strong>$ {roundToTwo(props.initialValue)}</strong>
      </label>
      {walletPL ? (
        <label>
          Profit/Loss: <strong>{`$ ${roundToTwo(walletPL)}`}</strong>
        </label>
      ) : undefined}

      {props.interactive && props.interactive && (
        <label>
          <strong>Click to change wallet value</strong>
        </label>
      )}
    </div>
  );
}
