import { RSISettings } from "../../../interfaces/chartIndicators/RSISettings";
import { MotionIndicatorButton } from "./MotionButton";
import { useCallback, useState } from "react";
import { Chip, ColorSwatch, Modal, Popover, Space, Text } from "@mantine/core";
import { VscSettings } from "react-icons/vsc";
import { CustomColorPicker } from "./CustomColorPicker";

interface Props {
  activeTheme: string;
  rsiSettings: RSISettings;
  handleRsiSettingsChange: (settings: RSISettings) => void;
}
export function RSIControls(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);
  const [colorPickerOpened, setColorPickerOpened] = useState(false);
  const color = props.activeTheme === "light" ? "dark" : "gray";

  const [currentColorToChangeValue, setCurrentColorToChangeValue] =
    useState<string>(props.rsiSettings.insideThresholdColor);

  const [currentFieldToChange, setCurrentFieldToChange] = useState<
    "inside" | "outside"
  >("inside");

  const handleColorToChange = useCallback(
    (color: string) => {
      switch (currentFieldToChange) {
        case "inside":
          props.rsiSettings.insideThresholdColor = color;
          break;
        case "outside":
          props.rsiSettings.outsideThresholdColor = color;
          break;

        default:
          break;
      }
      setColorPickerOpened(false);
      props.handleRsiSettingsChange(props.rsiSettings);
    },
    [currentFieldToChange, props, setColorPickerOpened]
  );

  const handleColorPickerOpen = useCallback(
    (fieldToUpdate: "inside" | "outside") => {
      setColorPickerOpened(true);
      setCurrentFieldToChange(fieldToUpdate);
      switch (fieldToUpdate) {
        case "inside":
          setCurrentColorToChangeValue(props.rsiSettings.insideThresholdColor);
          break;
        case "outside":
          setCurrentColorToChangeValue(props.rsiSettings.outsideThresholdColor);
          break;

        default:
          break;
      }
    },
    [props.rsiSettings, setColorPickerOpened, setCurrentFieldToChange]
  );

  return (
    <>
      <Popover
        opened={opened}
        clickOutsideEvents={colorPickerOpened ? ["onClick"] : undefined}
        onClose={() => setOpened(false)}
        transition="slide-up"
        position="bottom"
        target={
          <MotionIndicatorButton
            onClick={(e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            }}
          >
            <VscSettings size={12} />
            <label>RSI</label>
          </MotionIndicatorButton>
        }
        className="indicator-popover"
      >
        <div className="indicator-popover-outer-container">
          <Text className="header" color={"dimmed"}>
            Relative Strength Index
          </Text>
          <div className="indicator-popover-container">
            <Space h={"xs"} />
            <div className="indicator-popover-row">
              <Chip
                checked={props.rsiSettings.rsiEnabled}
                color={color}
                variant="outline"
                value="RSI Enabled"
                onChange={() => {
                  const newSettings = props.rsiSettings;
                  newSettings.rsiEnabled = !newSettings.rsiEnabled;
                  props.handleRsiSettingsChange(newSettings);
                }}
              >
                toggled
              </Chip>
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                Inside threshold
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("inside")}
                style={{ cursor: "pointer" }}
                color={props.rsiSettings.insideThresholdColor}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Text className="label" color={"dimmed"}>
                Outside threshold
              </Text>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("outside")}
                style={{ cursor: "pointer" }}
                color={props.rsiSettings.outsideThresholdColor}
                size={15}
                radius="sm"
              />
            </div>
          </div>
        </div>
      </Popover>
      <Modal
        opened={colorPickerOpened}
        onClose={() => setColorPickerOpened(false)}
      >
        <CustomColorPicker
          currentColor={currentColorToChangeValue}
          handleColorChange={handleColorToChange}
        />
      </Modal>
    </>
  );
}
