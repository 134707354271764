import { useCallback, useState } from "react";
import { LivetraderUserLogItem } from "../../../interfaces/livetrader/LivewalletReport";
import { toTimestring } from "../../../utils/FormattingUtils";
import "./livetrader-logs.scss";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { MdErrorOutline } from "react-icons/md";
import { Modal } from "@mantine/core";
import { LivetraderErrorDetailsWindow } from "./LivetraderErrorDetailsWindow";
import { useAuth0 } from "@auth0/auth0-react";
import { UsermanagerService } from "../../../services/UserManagerService";
import { LivetraderService } from "../../../services/LivetraderService";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { LiaTimesSolid } from "react-icons/lia";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { FaRegTrashCan } from "react-icons/fa6";
interface Props {
  activeTheme: string;
  livetraderLogs: LivetraderUserLogItem[] | undefined;
  refetchLogs: () => void;
}
export function LivetraderLogs(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();

  const [sortMethod, setSortMethod] = useState<"Oldest" | "Newest">("Newest");
  const [selectedLivetraderLog, setSelectedLivetraderLog] = useState<
    LivetraderUserLogItem | undefined
  >();
  const [submittingBug, setSubmittingBug] = useState(false);

  const submitLivetraderBug = useCallback(async () => {
    if (!selectedLivetraderLog) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setSubmittingBug(true);
      await UsermanagerService.submitLivetraderBugReport(
        token,
        selectedLivetraderLog.id
      );
      setSelectedLivetraderLog({
        ...selectedLivetraderLog,
        bug_submitted: true,
      });
      props.refetchLogs();
    } finally {
      setSubmittingBug(false);
    }
  }, [
    selectedLivetraderLog,
    setSelectedLivetraderLog,
    setSubmittingBug,
    setSelectedLivetraderLog,
    selectedLivetraderLog,
    props.refetchLogs,
  ]);

  const handleDeleteLog = useCallback(
    async (livewalletId: string, logId: string) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      await LivetraderService.deleteLivetraderLog(token, livewalletId, logId);
      props.refetchLogs();
    },
    [
      getAccessTokenSilently,
      LivetraderService,
      props.refetchLogs,
      getAccessTokenSilently,
    ]
  );

  return (
    <div className="livetrader-logs">
      <div className="livetrader-logs-header">
        <label
          className="sort-method-label"
          onClick={(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
            setSortMethod(sortMethod === "Oldest" ? "Newest" : "Oldest");
          }}
        >
          Sort by: {sortMethod}
        </label>
        <label className="dimmed-label">
          Logs will only be available for the last 48 hours
        </label>
      </div>
      <div className="livetrader-log-container">
        {props.livetraderLogs && props.livetraderLogs.length > 0 ? (
          <>
            {props.livetraderLogs
              .sort((a, b) => {
                if (sortMethod === "Oldest") {
                  return a.timestamp - b.timestamp;
                } else {
                  return b.timestamp - a.timestamp;
                }
              })
              .map((log, index) => {
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4, delay: index * 0.02 }}
                    key={index}
                    className={"log-item " + log.type}
                  >
                    <div className="log-item-header">
                      <label
                        style={{
                          marginLeft: "5x",
                        }}
                        className="dimmed-label"
                      >
                        {toTimestring(log.timestamp)}
                      </label>
                      {log.type === "error" ? (
                        <CommonButton
                          style={{
                            marginLeft: "auto",
                            marginRight: "5px",
                          }}
                          compact
                          leftIcon={<MdErrorOutline />}
                          activeTheme={props.activeTheme}
                          label="Error details"
                          borderTheme="red-accent"
                          onClick={() => setSelectedLivetraderLog(log)}
                        />
                      ) : (
                        <label
                          style={{
                            marginLeft: "auto",
                            marginRight: "5px",
                          }}
                        >
                          {log.type}
                        </label>
                      )}
                      <CommonIconButton
                        flat
                        icon={<LiaTimesSolid />}
                        onClick={() =>
                          handleDeleteLog(log.livewallet_id, log.id)
                        }
                        activeTheme={props.activeTheme}
                      />
                    </div>
                    <label className="log-message">{log.message}</label>
                  </motion.div>
                );
              })}
          </>
        ) : (
          <label className="dimmed-label">No logs available</label>
        )}
      </div>
      <Modal
        title="Error details"
        opened={selectedLivetraderLog !== undefined}
        onClose={() => setSelectedLivetraderLog(undefined)}
        centered
        size={"lg"}
      >
        {selectedLivetraderLog && (
          <LivetraderErrorDetailsWindow
            activeTheme={props.activeTheme}
            logItem={selectedLivetraderLog}
            submittingBug={submittingBug}
            submitBug={submitLivetraderBug}
          />
        )}
      </Modal>
    </div>
  );
}
