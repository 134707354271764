import { motion } from "framer-motion";
import { UserStrategyItem } from "../../../interfaces/common/UserStrategies";
import { Identicon } from "../identicon/Identicon";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useState } from "react";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { QueryObserverResult, useQuery } from "react-query";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";
import { Tooltip } from "@mantine/core";
import { toUTCTimestring } from "../../../utils/FormattingUtils";
import { FiBarChart2, FiCode, FiTrash2, FiX, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineCode } from "react-icons/ai";
import { VscHistory } from "react-icons/vsc";
import { ToastContext } from "../../../App";
import { StrategyManagerService } from "../../../services/StrategyManagerService";
import { CustomResponse } from "../../../interfaces/Response";
import { useMediaQuery } from "@mantine/hooks";
import { PaperwalletStatsContainer } from "./PaperwalletStatsContainer";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { Limitation } from "../../../interfaces/common/UserLimitations";
import { HiSaveAs } from "react-icons/hi";
import { IoCodeSlashSharp } from "react-icons/io5";
import { FaFileCode } from "react-icons/fa";

interface Props {
  activeTheme: string;
  strategyId: string;
  strategyName: string;
  versionItems: UserStrategyItem[];
  traderType: TraderType;
  selectedVersionId?: string | undefined;
  setSelectedVersionId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  refetch: () => Promise<
    QueryObserverResult<
      | CustomResponse<{
          [key: string]: UserStrategyItem[];
        }>
      | undefined,
      unknown
    >
  >;
  livetradingVersionIds: string[];
  strategyIsLive: boolean;
  attachStrategy: (strategyId: string, versionId: string) => void;
  tradingAccountSetup?: boolean;
  limitations?: Limitation;
  strategyManagerMenu?: boolean;
  newStrategyPopupMode?: boolean;
  attachedVersionId?: string;
  updateStrategy?: (overwrite_version_id?: string) => Promise<void>;
  closePopup?: () => void;
}
export function CommonVersionsSelect(props: React.PropsWithChildren<Props>) {
  const compact = useMediaQuery("(max-width: 768px)");
  const iconSize = compact ? 13 : 20;

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletedVersions, setDeletedVersions] = useState<string[]>([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [renameMode, setRenameMode] = useState(false);
  const [inputText, setInputText] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);

  const handleStrategyDelete = useCallback(async () => {
    if (deleting) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setDeleting(true);
      props.setSelectedVersionId && props.setSelectedVersionId(undefined);
      const response = await StrategyManagerService.deleteStrategy(
        token,
        props.strategyId
      );
      triggerToast(response.message, "success", null);
      props.refetch();
    } finally {
      setDeleting(false);
      setDeleteMode(false);
    }
  }, [getAccessTokenSilently, triggerToast, props, setDeleting, deleting]);

  const fetchPaperwalletStats = useCallback(async () => {
    if (!props.strategyId) return;
    if (!props.versionItems) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingStats(true);
      const blueprintService = getBlueprintService(props.traderType);
      const versionIds: string[] = props.versionItems.map(
        (versionItem) => versionItem.version_id
      );
      const response = await blueprintService.getStrategyPaperwallets(
        token,
        versionIds
      );
      return response.data;
    } finally {
      setLoadingStats(false);
    }
  }, [
    getAccessTokenSilently,
    props.traderType,
    props.strategyId,
    props.versionItems,
    setLoadingStats,
  ]);

  const paperwalletStatsQuery = useQuery(
    ["PaperwalletStats"],
    fetchPaperwalletStats,
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDeleteVersion = useCallback(
    async (versionId: string) => {
      const token = await getAccessTokenSilently();
      if (!token) return;
      if (props.strategyManagerMenu && props.versionItems.length === 1) return;
      const response = await StrategyManagerService.deleteStrategyVersion(
        token,
        props.strategyId,
        versionId
      );
      triggerToast(response.message, "success", null);
      setDeletedVersions([...deletedVersions, versionId]);
      props.refetch();
      if (
        props.strategyManagerMenu &&
        props.attachedVersionId &&
        props.attachedVersionId === versionId
      ) {
        const fallbackVersion = props.versionItems.find(
          (item) => item.version_id !== versionId
        );
        if (!fallbackVersion) return;
        navigate(`/strategy/${props.strategyId}/${fallbackVersion.version_id}`);
      }
    },
    [
      getAccessTokenSilently,
      triggerToast,
      props,
      setDeletedVersions,
      deletedVersions,
      navigate,
    ]
  );

  const isLive = useCallback(
    (versionId: string) => {
      return props.livetradingVersionIds.includes(versionId);
    },
    [props]
  );

  const versionItemLoader = () => {
    return (
      <div className="versions-popup-select-item-loader-container">
        <label className="dimmed-label">Loading...</label>
        <NeotonSpinner activeTheme={props.activeTheme} />
      </div>
    );
  };

  const versionIdIcon = (strategyItem: UserStrategyItem) => {
    return (
      <Tooltip
        position="bottom"
        className="common-strategy-select-version-id-tooltip"
        withArrow
        radius={"xs"}
        onClick={(e: any) => {
          e.stopPropagation();
          if (strategyItem.version_id === props.selectedVersionId) {
            props.setSelectedVersionId && props.setSelectedVersionId(undefined);
            return;
          }
          props.setSelectedVersionId &&
            props.setSelectedVersionId(strategyItem.version_id);
        }}
        label={
          <>
            <label>
              Version id: <strong>{strategyItem.version_id}</strong>
            </label>
            <br />
            <label>
              Created on:{" "}
              <strong>{toUTCTimestring(strategyItem.created_on, "1h")}</strong>
            </label>
            {isLive(strategyItem.version_id) && (
              <>
                <br />
                <label>
                  <strong>
                    This version is currently being used for live trading and
                    cannot be deleted
                  </strong>
                </label>
              </>
            )}
          </>
        }
      >
        {isLive(strategyItem.version_id) && (
          <div className="common-strategy-live-trading-indicator absolute" />
        )}
        <Identicon guid={strategyItem.version_id} size={compact ? 34 : 44} />
      </Tooltip>
    );
  };

  const hideButton = useCallback(() => {
    return (
      <motion.div
        className="versions-popup-select-item-button"
        onClick={(e: any) => {
          e.stopPropagation();
          props.setSelectedVersionId && props.setSelectedVersionId(undefined);
        }}
      >
        <label>Hide</label>
        <FiX size={iconSize} />
      </motion.div>
    );
  }, [iconSize, props]);

  const strategyManagerMenu = useCallback(
    (strategyId: string, versionId: string) => {
      if (props.updateStrategy) {
        return (
          <>
            <motion.div
              className={
                "versions-popup-select-item-button trading-account-setup" +
                (isLive(versionId) ? " disabled" : "")
              }
              onClick={async (e: any) => {
                if (isLive(versionId)) return;
                e.stopPropagation();
                props.closePopup && props.closePopup();
                props.updateStrategy && (await props.updateStrategy(versionId));
                props.refetch();
              }}
            >
              <label>Overwrite</label>
              <HiSaveAs size={iconSize} />
            </motion.div>
            {hideButton()}
          </>
        );
      }
      return (
        <>
          {props.attachedVersionId !== versionId && (
            <motion.div
              className="versions-popup-select-item-button trading-account-setup"
              onClick={(e: any) => {
                e.stopPropagation();
                navigate(`/strategy/${strategyId}/${versionId}`);
              }}
            >
              <label>Load</label>
              <IoCodeSlashSharp size={iconSize} />
            </motion.div>
          )}
          {hideButton()}
          {props.versionItems.length > 1 && (
            <motion.div
              className={
                "versions-popup-select-item-button delete" +
                (isLive(versionId) ? " disabled" : "")
              }
              onClick={(e: any) => {
                e.stopPropagation();
                if (isLive(versionId)) return;
                setShowDeleteConfirmation(true);
              }}
            >
              <label>Delete</label>
              <FiTrash2 size={iconSize} />
            </motion.div>
          )}
        </>
      );
    },
    [hideButton, iconSize, props, props.attachedVersionId, navigate]
  );
  const tradingAccountSetupMenu = useCallback(
    (strategyId: string, versionId: string) => {
      return (
        <>
          <motion.div
            className="versions-popup-select-item-button trading-account-setup"
            onClick={(e: any) => {
              e.stopPropagation();
              props.attachStrategy(strategyId, versionId);
              props.setSelectedVersionId &&
                props.setSelectedVersionId(undefined);
            }}
          >
            <label>Select for trading</label>
            <CgArrowsExchangeAlt size={iconSize} />
          </motion.div>
          {hideButton()}
        </>
      );
    },
    [hideButton, iconSize, props]
  );
  const newStrategyPopupMode = useCallback(
    (strategyId: string, versionId: string) => {
      return (
        <>
          <motion.div
            className="versions-popup-select-item-button trading-account-setup"
            onClick={(e: any) => {
              e.stopPropagation();
              props.attachStrategy(strategyId, versionId);
              props.setSelectedVersionId &&
                props.setSelectedVersionId(undefined);
            }}
          >
            <label>Select</label>
            <FaFileCode size={iconSize} />
          </motion.div>
          {hideButton()}
        </>
      );
    },
    [hideButton, iconSize, props]
  );

  const defaultVersionMenu = useCallback(
    (strategyId: string, versionId: string) => {
      return (
        <>
          {props.traderType.traderType !== "backtester" && (
            <motion.div
              className="versions-popup-select-item-button"
              onClick={(e: any) => {
                e.stopPropagation();
                navigate("/backtester", {
                  state: {
                    strategyId: strategyId,
                    versionId: versionId,
                  },
                });
              }}
            >
              <label>Backtest</label>
              <VscHistory size={iconSize} />
            </motion.div>
          )}
          <motion.div
            className="versions-popup-select-item-button"
            onClick={(e: any) => {
              e.stopPropagation();
              navigate(`/strategy/${strategyId}/${versionId}`);
              props.setSelectedVersionId &&
                props.setSelectedVersionId(undefined);
            }}
          >
            <label>Edit</label>
            <AiOutlineCode size={iconSize} />
          </motion.div>
          {hideButton()}
          {props.traderType.traderType === "backtester" && (
            <motion.div
              className="versions-popup-select-item-button"
              onClick={(e: any) => {
                e.stopPropagation();
                props.setSelectedVersionId &&
                  props.setSelectedVersionId(undefined);
                props.attachStrategy(strategyId, versionId);
              }}
            >
              <label>Attach</label>
              <FiCode size={iconSize} />
            </motion.div>
          )}
          {props.traderType.traderType === "papertrader" && (
            <motion.div
              className="versions-popup-select-item-button"
              onClick={(e: any) => {
                e.stopPropagation();
                props.setSelectedVersionId &&
                  props.setSelectedVersionId(undefined);
                props.attachStrategy(strategyId, versionId);
              }}
            >
              <label>Report</label>
              <FiBarChart2 size={iconSize} />
            </motion.div>
          )}
          <motion.div
            className={
              "versions-popup-select-item-button delete" +
              (isLive(versionId) ? " disabled" : "")
            }
            onClick={(e: any) => {
              e.stopPropagation();
              if (isLive(versionId)) return;
              setShowDeleteConfirmation(true);
            }}
          >
            <label>Delete</label>
            <FiTrash2 size={iconSize} />
          </motion.div>
        </>
      );
    },
    [hideButton, iconSize, isLive, navigate, props]
  );

  const deleteConfirmation = (versionId: string) => {
    return (
      <>
        <motion.div
          className="versions-popup-select-item-button delete"
          onClick={(e: any) => {
            e.stopPropagation();
            setShowDeleteConfirmation(false);
            props.setSelectedVersionId && props.setSelectedVersionId(undefined);
            handleDeleteVersion(versionId);
          }}
        >
          <label>Delete version</label>
          <FiTrash2 size={iconSize} />
        </motion.div>
        <motion.div
          className="versions-popup-select-item-button"
          onClick={(e: any) => {
            e.stopPropagation();
            setShowDeleteConfirmation(false);
          }}
        >
          <label>Cancel</label>
          <FiXCircle size={iconSize} />
        </motion.div>
      </>
    );
  };

  return (
    <>
      <div className="versions-popup-header-menu">
        <Tooltip
          withArrow
          opened={deleteMode}
          disabled={!deleteMode}
          label={
            <>
              <label>
                <strong>Confirm deletion by entering the strategy name.</strong>
              </label>
              <br />
              <label>
                <strong>This action cannot be undone.</strong>
              </label>
              <br />
              <label>
                Enter: <strong>{props.strategyName}</strong>
              </label>
            </>
          }
        >
          <input
            autoFocus={true}
            className={
              "versions-popup-header-menu-input" +
              (deleteMode
                ? " active-delete"
                : renameMode
                ? " active-rename"
                : "")
            }
            placeholder={props.strategyName}
            disabled={!deleteMode}
            value={inputText}
            onChange={(e: any) => {
              const value = e.target.value;
              setInputText(value);
            }}
          />
        </Tooltip>
        {props.strategyName === inputText && deleteMode && (
          <motion.div
            //Delete entire strategy
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={"delete-strategy-button"}
            onClick={handleStrategyDelete}
          >
            <label>{deleting ? "deleting..." : "delete"}</label>
            <FiTrash2 />
          </motion.div>
        )}

        {!props.strategyIsLive &&
          !props.tradingAccountSetup &&
          !props.strategyManagerMenu && (
            <motion.div
              className={
                "delete-strategy-button" + (deleteMode ? " active" : "")
              }
              onClick={(e: any) => {
                e.stopPropagation();
                setDeleteMode(!deleteMode);
                setInputText("");
              }}
            >
              {deleteMode ? <FiX /> : <FiTrash2 />}
            </motion.div>
          )}
      </div>
      <motion.div
        className="versions-popup-select-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
      >
        <div className="popup-subheader">
          {props.limitations && (
            <label className="dimmed-label">
              Versions: {props.versionItems.length} /{" "}
              {props.limitations.strategy_versions}
            </label>
          )}
          <Tooltip
            label={
              <label>Papertrader performance for the current season</label>
            }
          >
            <label className="dimmed-label">Papertrader performance</label>
          </Tooltip>
        </div>
        {props.versionItems.map((strategyItem, index) => {
          if (deletedVersions.includes(strategyItem.version_id)) return null;
          const paperwalletItem =
            paperwalletStatsQuery.data?.payload?.[strategyItem.version_id] ??
            undefined;
          if (props.selectedVersionId === strategyItem.version_id) {
            return (
              <motion.div
                key={`${index}-menu`}
                className={
                  "versions-popup-select-item" +
                  (props.selectedVersionId === strategyItem.version_id
                    ? " selected"
                    : "")
                }
              >
                {versionIdIcon(strategyItem)}
                {props.strategyManagerMenu ? (
                  <>
                    {showDeleteConfirmation
                      ? deleteConfirmation(strategyItem.version_id)
                      : strategyManagerMenu(
                          strategyItem.id,
                          strategyItem.version_id
                        )}
                  </>
                ) : props.tradingAccountSetup ? (
                  <>
                    {tradingAccountSetupMenu(
                      strategyItem.id,
                      strategyItem.version_id
                    )}
                  </>
                ) : props.newStrategyPopupMode ? (
                  <>
                    {newStrategyPopupMode(
                      strategyItem.id,
                      strategyItem.version_id
                    )}
                  </>
                ) : (
                  <>
                    {showDeleteConfirmation
                      ? deleteConfirmation(strategyItem.version_id)
                      : defaultVersionMenu(
                          strategyItem.id,
                          strategyItem.version_id
                        )}
                  </>
                )}
              </motion.div>
            );
          }
          return (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key={index}
              className={
                "versions-popup-select-item" +
                (props.selectedVersionId === strategyItem.version_id
                  ? " selected"
                  : "")
              }
              onClick={(e: any) => {
                e.stopPropagation();
                if (strategyItem.version_id === props.selectedVersionId) {
                  props.setSelectedVersionId &&
                    props.setSelectedVersionId(undefined);
                  return;
                }
                props.setSelectedVersionId &&
                  props.setSelectedVersionId(strategyItem.version_id);
              }}
            >
              {versionIdIcon(strategyItem)}
              <PaperwalletStatsContainer
                key={index}
                activeTheme={props.activeTheme}
                paperwalletStats={paperwalletItem}
                tier={strategyItem.tier}
                strategyId={strategyItem.id}
                isLive={props.livetradingVersionIds.includes(
                  strategyItem.version_id
                )}
                loading={loadingStats}
                versionLoader={loadingStats && versionItemLoader()}
              />
            </motion.div>
          );
        })}
      </motion.div>
    </>
  );
}
