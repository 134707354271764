import { CriteriaSubMenu } from "../CriteriaSubMenu";
import { ParsedCriteria } from "../ParsedCriteria";
import { IndicatorsInfo } from "../../../shared-service-contexts/IndicatorsInfoContext";
import { Indicators } from "../../../interfaces/Indicators";
import { motion } from "framer-motion";

interface Props {
  activeTheme: string;
  candleSize: string;
  criterias: string[];
  criteriaType: "entry" | "exit";
  handleDeleteCriteria: (
    candleSize: string,
    criteriaType: string,
    index: number
  ) => void;
  readonly?: boolean;
  indicators: Indicators | undefined;
  indicatorsInfo: IndicatorsInfo | undefined;
}
export function CandleSizeCriterias(props: React.PropsWithChildren<Props>) {
  return (
    <div className="candle-size-criterias-container">
      {props.criterias.length > 1 && (
        <motion.label
          initial={{ opacity: 0, x: 10 }}
          animate={{ opacity: .4, x: 0, skewX: -4 }}
          transition={{ duration: 0.3, delay: 0.5 }}
          className="dimmed-label"
          style={{
            alignSelf: "center",
            marginTop: "5px",
            // cursive
          }}
        >
          At least 1 of the below criterias must be filled to trigger an {props.criteriaType}
        </motion.label>
      )}
      {props.criterias.map((criteria, index) => {
        return (
          <div
            className="criteria-sub-menu-container"
            key={`${props.criteriaType}-${props.candleSize}-${index}-container`}
          >
            <div
              id={
                props.criteriaType === "entry"
                  ? "entry-line-criteria-menu-container"
                  : "exit-line-criteria-menu-container"
              }
              className="line"
            />
            {!props.readonly ? (
              <CriteriaSubMenu
                isAi={false}
                type={props.criteriaType}
                candleSize={props.candleSize}
                criteria={criteria}
                index={index}
                openMicrotest={() => {}}
                deleteCriteria={() =>
                  props.handleDeleteCriteria(
                    props.candleSize,
                    props.criteriaType,
                    index
                  )
                }
                criteriaListLength={props.criterias.length}
                control={
                  <div
                    className="parsed-criteria-menu-container"
                    id={
                      props.criteriaType === "entry"
                        ? "entry-criteria-menu-container"
                        : "exit-criteria-menu-container"
                    }
                    key={`${props.criteriaType}-${props.candleSize}-${index}-container`}
                  >
                    <ParsedCriteria
                      key={`${props.criteriaType}-${props.candleSize}-${index}`}
                      activeTheme={props.activeTheme}
                      criteria={criteria}
                      indicatorsInfo={props.indicatorsInfo}
                    />
                  </div>
                }
              />
            ) : (
              <div
                className="parsed-criteria-menu-container"
                id={
                  props.criteriaType === "entry"
                    ? "entry-criteria-menu-container"
                    : "exit-criteria-menu-container"
                }
                key={`${props.criteriaType}-${props.candleSize}-${index}-container`}
              >
                <ParsedCriteria
                  key={`${props.criteriaType}-${props.candleSize}-${index}`}
                  activeTheme={props.activeTheme}
                  criteria={criteria}
                  indicatorsInfo={props.indicatorsInfo}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
