import "./papertrader-control-compact.scss";
import { motion } from "framer-motion";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";

interface Props {
  activeTheme: string;
  attachedStrategy: StrategyVersion | undefined;
  rightSlotOnClicked?: () => void;
  leftSlotOnClicked?: () => void;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
}
export function PapertraderControlCompact(
  props: React.PropsWithChildren<Props>
) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0, y: 10 }}
      className="papertrader-control-bottom-container"
    >
      {props.leftSlot && (
        <motion.div
          onClick={props.leftSlotOnClicked}
          className="papertrader-control-slot left"
        >
          {props.leftSlot}
        </motion.div>
      )}

      {props.rightSlot && (
        <motion.div
          onClick={props.rightSlotOnClicked}
          className="papertrader-control-slot right"
        >
          {props.rightSlot}
        </motion.div>
      )}
    </motion.div>
  );
}
