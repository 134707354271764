import "./user-profile.scss";
import { Avatar, Modal } from "@mantine/core";
import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { PublicUserProfileComponent } from "../public-user-profile/PublicUserProfileComponent";
import { usePalette } from "react-palette";
import { getTheme } from "../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  userHash: string;
  username: string;
  userTitle?: string;
  enableMenu?: boolean;
  tempUrl?: string;
  size?: number;
  clickable?: boolean;
  onClick?: () => void;
  tiny?: boolean;
  nocache?: boolean;
  style?: React.CSSProperties;
}
export function UserProfileComponent(props: React.PropsWithChildren<Props>) {
  const image_path = props.tempUrl ? props.tempUrl : `${props.userHash}.jpg`;

  const showTitle =
    props.size && props.size > 120 && props.userTitle ? true : false;

  const imageUrl = useMemo(() => {
    return props.nocache
      ? `https://neoton-media.azureedge.net/profile-pics/${image_path}?ts=${new Date().getTime()}`
      : `https://neoton-media.azureedge.net/profile-pics/${image_path}`;
  }, [props.nocache, image_path]);
  const [showModal, setShowModal] = useState(false);

  const ownerTitles = ["CTO", "CEO", "CDO", "Owner", "Founder", "Co-Founder"];
  const getTitleClass = (title: string) => {
    if (ownerTitles.includes(title)) return "owner";
    return "user";
  };
  const { data } = usePalette(imageUrl);
  const theme = useMemo(() => {
    return getTheme(props.activeTheme);
  }, [props.activeTheme]);

  const marqueeEnabled = props.tiny && props.username?.length > 8;

  const marqueeVariants = {
    animate: marqueeEnabled
      ? {
          x: [
            props.username.length + 25,
            -Math.abs(props.username.length + 25),
          ], // Move from 0 to -100% of its own width
          transition: {
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 5, // Adjust duration for speed
              ease: "linear", // Smooth animation
            },
          },
        }
      : {
          x: 0,
        },
  };

  return (
    <>
      <div
        className="outer-user-profile-container"
        style={props.style}
        onClick={(e: any) => {
          if (!props.clickable) return;
          e.stopPropagation();
          if (props.enableMenu) {
            setShowModal(true);
            return;
          }
          props.onClick && props.onClick();
        }}
      >
        <motion.div
          className={
            "user-profile-container" + (props.clickable ? " clickable" : "")
          }
          id={props.tiny ? "tiny-profile" : ""}
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            width: props.size ? props.size : "auto",
            height: props.size
              ? props.tiny
                ? props.size
                : props.size * 1.4
              : "auto",
          }}
          transition={{ duration: 0.2 }}
        >
          <Avatar src={imageUrl} alt={props.username} size={props.size} radius="xl" />
          {!props.tiny && (
            <>
              {showTitle && <div className="neoton-divider" />}
              {!props.enableMenu ? (
                <label className="user-menu-label">{props.username ?? "Unknown"}</label>
              ) : (
                <label
                  className={
                    "username-label" +
                    (props.userTitle ? " prominent" : " dimmed")
                  }
                >
                  {props.username}
                </label>
              )}
              {showTitle && <div className="neoton-divider" />}
              {showTitle && (
                <label
                  className={`user-menu-label ${getTitleClass(
                    props.userTitle!
                  )}`}
                >
                  {props.userTitle}
                </label>
              )}
              {showTitle && <div className="neoton-divider" />}
            </>
          )}
          {props.tiny && (
            <div
              className="tiny-user-label-container"
              style={{
                transform: `translateY(${props.size ? props.size * 1 : 0}px)`,
                paddingLeft: '5px',
                paddingRight: '5px',
                border: data.darkVibrant
                  ? `1px solid ${data.darkVibrant}`
                  : `1px solid ${theme.text}`,
              }}
            >
              <motion.label
                className="tiny-user-label"
                variants={marqueeVariants}
                initial="hidden"
                animate="animate"
              >
                {props.username}
              </motion.label>
            </div>
          )}
        </motion.div>
      </div>
      <Modal
        radius={"xl"}
        centered
        onClose={() => setShowModal(false)}
        opened={showModal}
      >
        <PublicUserProfileComponent
          activeTheme={props.activeTheme}
          userHash={props.userHash}
        />
      </Modal>
    </>
  );
}
