import { Chip, Text, ColorSwatch, Popover, Modal } from "@mantine/core";
import { useCallback, useState } from "react";
import { EMASettings } from "../../../interfaces/chartIndicators/EMASettings";
import { CustomColorPicker } from "./CustomColorPicker";
import { VscSettings } from "react-icons/vsc";
import { MotionIndicatorButton } from "./MotionButton";

interface Props {
  activeTheme: string;
  emaSettings: EMASettings;
  handleEmaSettingsChange: (settings: EMASettings) => void;
}
export function EMAControls(props: React.PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);
  const [colorPickerOpened, setColorPickerOpened] = useState(false);

  const [currentColorToChange, setCurrentColorToChange] = useState<
    "20" | "50" | "100" | "200"
  >();
  const [currentColorToChangeValue, setCurrentColorToChangeValue] =
    useState<string>(props.emaSettings.ema20Color);

  const color = props.activeTheme === "light" ? "dark" : "gray";

  const unchecked =
    !props.emaSettings.ema20enabled &&
    !props.emaSettings.ema50enabled &&
    !props.emaSettings.ema100enabled &&
    !props.emaSettings.ema200enabled;

  const handleEmaSettingsChange = useCallback(
    (checked: boolean, size: "20" | "50" | "100" | "200") => {
      switch (size) {
        case "20":
          props.emaSettings.ema20enabled = checked;
          break;
        case "50":
          props.emaSettings.ema50enabled = checked;
          break;
        case "100":
          props.emaSettings.ema100enabled = checked;
          break;
        case "200":
          props.emaSettings.ema200enabled = checked;
          break;
        default:
          break;
      }

      props.handleEmaSettingsChange(props.emaSettings);
    },
    [props]
  );

  const handleColorPickerOpen = useCallback(
    (size: "20" | "50" | "100" | "200") => {
      setCurrentColorToChange(size);
      setColorPickerOpened(true);
      switch (size) {
        case "20":
          setCurrentColorToChangeValue(props.emaSettings.ema20Color);
          break;
        case "50":
          setCurrentColorToChangeValue(props.emaSettings.ema50Color);
          break;
        case "100":
          setCurrentColorToChangeValue(props.emaSettings.ema100Color);
          break;
        case "200":
          setCurrentColorToChangeValue(props.emaSettings.ema200Color);
          break;
        default:
          break;
      }
    },
    [setCurrentColorToChange, setColorPickerOpened, props.emaSettings]
  );

  const handleColorChange = useCallback(
    (color: string) => {
      switch (currentColorToChange) {
        case "20":
          props.emaSettings.ema20Color = color;
          break;
        case "50":
          props.emaSettings.ema50Color = color;
          break;
        case "100":
          props.emaSettings.ema100Color = color;
          break;
        case "200":
          props.emaSettings.ema200Color = color;
          break;
        default:
          break;
      }
      setColorPickerOpened(false);
      props.handleEmaSettingsChange(props.emaSettings);
    },
    [props, currentColorToChange, setColorPickerOpened]
  );

  return (
    <>
      <Popover
        opened={opened}
        clickOutsideEvents={colorPickerOpened ? ["onClick"] : undefined}
        onClose={() => setOpened(false)}
        transition="slide-left"
        position="right"
        target={
          <MotionIndicatorButton
            onClick={(e: any) => {
              e.stopPropagation();
              setOpened(!opened);
            }}
          >
            <VscSettings size={12} />

            <label>EMA</label>
          </MotionIndicatorButton>
        }
        className="indicator-popover"
      >
        <div className="indicator-popover-outer-container">
          <Text className="header" color={"dimmed"}>
            Exponential Moving Average
          </Text>
          <div className="indicator-popover-container">
            <div className="indicator-popover-row">
              <Chip
                checked={props.emaSettings.ema20enabled}
                color={color}
                variant="outline"
                value="EMA20"
                onChange={(checked) => handleEmaSettingsChange(checked, "20")}
              >
                EMA20
              </Chip>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("20")}
                style={{ cursor: "pointer" }}
                color={props.emaSettings.ema20Color}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Chip
                checked={props.emaSettings.ema50enabled}
                color={color}
                variant="outline"
                value="EMA50"
                onChange={(checked) => handleEmaSettingsChange(checked, "50")}
              >
                EMA50
              </Chip>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("50")}
                style={{ cursor: "pointer" }}
                color={props.emaSettings.ema50Color}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Chip
                checked={props.emaSettings.ema100enabled}
                color={color}
                variant="outline"
                value="EMA100"
                onChange={(checked) => handleEmaSettingsChange(checked, "100")}
              >
                EMA100
              </Chip>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("100")}
                style={{ cursor: "pointer" }}
                color={props.emaSettings.ema100Color}
                size={15}
                radius="sm"
              />
            </div>
            <div className="indicator-popover-row">
              <Chip
                checked={props.emaSettings.ema200enabled}
                color={color}
                variant="outline"
                value="EMA200"
                onChange={(checked) => handleEmaSettingsChange(checked, "200")}
              >
                EMA200
              </Chip>
              <ColorSwatch
                onClick={() => handleColorPickerOpen("200")}
                style={{ cursor: "pointer" }}
                color={props.emaSettings.ema200Color}
                size={15}
                radius="sm"
              />
            </div>
          </div>
        </div>
      </Popover>
      <Modal
        opened={colorPickerOpened}
        onClose={() => setColorPickerOpened(false)}
      >
        <CustomColorPicker
          currentColor={currentColorToChangeValue}
          handleColorChange={handleColorChange}
        />
      </Modal>
    </>
  );
}
