/**
 *
 *
 * @param {string} string
 * @return {*}  {string}
 */
export const capitalize = (string: string): string => {
  return `${string.substring(0, 1).toUpperCase()}${string.substring(
    1,
    string.length
  )}`;
};

export const labelizeSeason = (season: string) => {
  const seasonSplit = season.split("_");
  return `${capitalize(seasonSplit[0])} ${seasonSplit[1]}`;
};