import { motion } from "framer-motion";
import { StrategyLeaderboardItem } from "../../interfaces/season/Leaderboard";
import { WalletValueChart } from "../chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import CountUp from "react-countup";
import { calcPctChange } from "../../utils/FormattingUtils";
import { useContext, useEffect, useMemo, useState } from "react";
import { TierBadges } from "../strategy-body/case-control/TierBadges";
import { FiBarChart2 } from "react-icons/fi";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { UserProfileComponent } from "../community/user-profile-component/UserProfileComponent";
import { CurrencyLogoArray } from "../common/common-currencies/CurrencyLogoArray";
import { StrategyVersionIcon } from "../common/identicon/StrategyVersionIcon";
import { Menu } from "@mantine/core";
import { FaRankingStar } from "react-icons/fa6";

interface Props {
  activeTheme: string;
  index: number;
  leaderboardItem: StrategyLeaderboardItem;
  owned: boolean;
  attachStrategy: (strategyId: string, versionId: string) => void;
  homepage?: boolean;
  compact?: boolean;
}
export function PapertraderLeaderboardItem(
  props: React.PropsWithChildren<Props>
) {
  const currencies = useContext(CurrencyContext);

  const includedCurrencies = useMemo(() => {
    return currencies?.filter((currency) =>
      props.leaderboardItem.currencies.includes(currency.currency_name)
    );
  }, [currencies, props.leaderboardItem.currencies]);

  const pctChange = useMemo(
    () =>
      calcPctChange(
        props.leaderboardItem.initial_balance,
        props.leaderboardItem.current_balance
      ),
    [
      props.leaderboardItem.initial_balance,
      props.leaderboardItem.current_balance,
    ]
  );

  const isWinning = useMemo(
    () =>
      props.leaderboardItem.current_balance >
      props.leaderboardItem.initial_balance,
    [
      props.leaderboardItem.current_balance,
      props.leaderboardItem.initial_balance,
    ]
  );

  const [showGraph, setShowGraph] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  // create a event listener for window resize, and set the showGraph state to false
  // this will cause the graph to re-render, and animate again
  // add it to the useEffect hook, and it will cause the graph to re-render on every render

  // this is a hacky solution, but it works
  useEffect(() => {
    let isMounted = true; // add this line

    const handleResize = () => {
      setShowGraph(false);
      setResizing(true);
      setInitialRender(false);
    };

    window.addEventListener("resize", handleResize);

    if (!showGraph && !resizing) {
      setTimeout(() => {
        if (isMounted) {
          // check if component is still mounted
          setShowGraph(true);
          setResizing(false);
        }
      }, 500);
    }
    // Proper cleanup function
    return () => {
      isMounted = false; // set to false when unmounting
      window.removeEventListener("resize", handleResize);
    };
  }, [
    showGraph,
    setShowGraph,
    setResizing,
    resizing,
    initialRender,
    setInitialRender,
  ]);

  return (
    <div
      className={
        "papertrader-leaderboard-item-container" +
        (props.homepage ? " homepage" : "") +
        (props.compact ? " compact" : "")
      }
    >
      <div className="leaderboard-profile-container">
        <div
          className={`leaderboard-item-block rank rank-${props.leaderboardItem.rank}`}
          style={{
            fontSize: "larger",
            opacity: props.leaderboardItem.rank > 3 ? 0.7 : 1,
          }}
        >
          <div className={`rank-item-inner`}>
            <FaRankingStar />
            <label className={"rank-label"}>
              {props.leaderboardItem.rank}{" "}
            </label>
          </div>
        </div>
        <div className="leaderboard-profile-picture-container">
          <UserProfileComponent
            activeTheme={props.activeTheme}
            userHash={props.leaderboardItem.user_hash}
            username={props.leaderboardItem.strategy_author}
            size={props.compact ? 40 : 60}
            tiny
            enableMenu
            clickable
          />
        </div>
      </div>
      <div className="leaderboard-item-middle-section">
        <div className="leaderboard-strategy-header">
          <div className="leaderboard-item-block">
            <StrategyVersionIcon
              activeTheme={props.activeTheme}
              name={props.leaderboardItem.strategy_name}
              strategy_id={props.leaderboardItem.strategy_id}
              version_id={props.leaderboardItem.version_id}
              disableMenu={!props.owned}
              included_currencies={
                includedCurrencies?.map((x) => x.currency_name) ?? []
              }
              hideLabel
              size={props.compact ? 20 : 25}
              compact={props.compact}
              primaryMenuButton={
                <Menu.Item
                  icon={<FiBarChart2 />}
                  onClick={() => {
                    props.attachStrategy(
                      props.leaderboardItem.strategy_id,
                      props.leaderboardItem.version_id
                    );
                  }}
                >
                  Papertrader report
                </Menu.Item>
              }
            />
            <label
              className={
                "leaderboard-item-strategy-name-label" +
                (props.owned ? " owned" : "")
              }
            >
              {props.leaderboardItem.strategy_name}
            </label>
          </div>

          <div
            style={{
              marginLeft: "auto",
            }}
            className="leaderboard-item-block"
          >
            <CountUp
              className={
                props.leaderboardItem.current_balance ===
                props.leaderboardItem.initial_balance
                  ? "wallet-stat-label"
                  : isWinning
                  ? "wallet-stat-label-profit"
                  : "wallet-stat-label-deficit"
              }
              decimals={2}
              start={0}
              end={pctChange}
              duration={1}
              delay={props.index * 0.05 + 0.5}
              suffix="%"
              prefix={isWinning ? "+" : ""}
            />
          </div>
        </div>

        <motion.div
          className="leaderboard-wallet-value-container"
          initial={{ opacity: 0, y: !props.compact ? 50 : 0 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: !props.compact ? props.index * 0.05 + 0.5 : 0 }}
          onAnimationComplete={() => setShowGraph(true)}
        >
          <div className="leaderboard-item-chart-container">
            {!props.compact && showGraph && (
              <WalletValueChart
                currencyPair={props.leaderboardItem.currency_pair}
                isWinning={isWinning}
                walletValueSnapshots={props.leaderboardItem.wallet_snapshots}
                activeTheme={props.activeTheme}
                marketStatistics={undefined}
                compact
                animate={false}
                disableTooltip={!props.owned}
                animationDuration={1000}
              />
            )}
            {props.compact && (
              <WalletValueChart
                currencyPair={props.leaderboardItem.currency_pair}
                isWinning={isWinning}
                walletValueSnapshots={props.leaderboardItem.wallet_snapshots}
                activeTheme={props.activeTheme}
                marketStatistics={undefined}
                animate={false}
                disableTooltip={!props.owned}
                compact
              />
            )}
          </div>
        </motion.div>
        {!props.compact && (
          <div className="leaderboard-item-currency-array">
            <CurrencyLogoArray
              currencies={includedCurrencies ?? []}
              previewAmount={10}
              expandable={true}
              size={"xs"}
            />

            {props.leaderboardItem.tier && (
              <TierBadges
                size="xs"
                compact
                collectedCriterias={[]}
                tiersList={props.leaderboardItem.tier.split("/")}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
