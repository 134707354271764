import { User } from "@auth0/auth0-react";
import { UserID } from "../interfaces/UserId";

export const getUserId = (user: User): UserID | undefined => {
  const sub = user.sub;
  if (sub === null || sub === undefined) {
    return undefined
  }
  return {
    value: sub.split("|")[1],
  } as UserID;
};
