import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useMemo, useState } from "react";
import { UsermanagerService } from "../../../services/UserManagerService";
import { useQuery } from "react-query";
import { motion } from "framer-motion";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { LivetraderUserLogItem } from "../../../interfaces/livetrader/LivewalletReport";
import { Paragraph } from "../../../pages/legal/Typegraphy";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { MdReport } from "react-icons/md";

interface Props {
  activeTheme: string;
  logItem: LivetraderUserLogItem;
  submitBug: () => void;
  submittingBug: boolean;
}
export function LivetraderErrorDetailsWindow(
  props: React.PropsWithChildren<Props>
) {
  const { getAccessTokenSilently } = useAuth0();
  const [loadingEvaluation, setLoadingEvaluation] = useState(false);

  const fetchLogEvaluation = useCallback(async () => {
    const token = await getAccessTokenSilently();
    try {
      setLoadingEvaluation(true);
      const response = await UsermanagerService.getLivetraderLogEvaluation(
        token,
        props.logItem.id
      );
      return response.data.payload;
    } finally {
      setLoadingEvaluation(false);
    }
  }, [getAccessTokenSilently, props.logItem]);

  const logEvaluationQuery = useQuery(
    ["logEvaluation", props.logItem],
    fetchLogEvaluation,
    {
      cacheTime: 60 * 60,
      retryDelay: 5000,
    }
  );

  const formattedExplanation = useMemo(() => {
    if (!logEvaluationQuery.data?.explanation) return;
    return logEvaluationQuery.data.explanation;
  }, [logEvaluationQuery.data?.explanation]);

  return (
    <div className="livetrader-error-details-container">
      {loadingEvaluation ? (
        <div className="neoton-loader-container">
          <NeotonLoader />
        </div>
      ) : (
        <motion.div
          className="log-evaluation-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paragraph>{formattedExplanation}</Paragraph>
        </motion.div>
      )}
      {props.logItem.bug_submitted ? (
        <label>Bug submitted</label>
      ) : (
        <CommonButton
          activeTheme={props.activeTheme}
          label="Report error"
          borderTheme="red-accent"
          loading={props.submittingBug}
          onClick={props.submitBug}
          leftIcon={<MdReport />}
        />
      )}
    </div>
  );
}
