import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useState } from "react";
import { UsermanagerService } from "../../services/UserManagerService";
import { ToastContext } from "../../App";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { TfiEmail } from "react-icons/tfi";

interface Props {
  activeTheme: string;
}
export function EmailVerificationPopup(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently } = useAuth0();
  const triggerToast = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [didRequestNewEmail, setDidRequestNewEmail] = useState(false);

  const handleResendEmail = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.ResendActivationEmail(token);
      triggerToast(response.data.payload, "success", null);
      setDidRequestNewEmail(true);
    } catch (error: any) {
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, setDidRequestNewEmail, triggerToast]);

  return (
    <div className="email-verification-container">
      <label>
        A confirmation email has been sent to your email address.
      </label>
      <label>
        Please verify your email to continue.
      </label>

      {!didRequestNewEmail && (
        <div className="button-row">
          <label className="dimmed-label">Didn't receive the email?</label>
          <CommonButton
            compact
            leftIcon={<TfiEmail />}
            borderTheme="blue-accent"
            activeTheme={props.activeTheme}
            onClick={handleResendEmail}
            loading={loading}
            label="Resend Email"
          />
        </div>
      )}
    </div>
  );
}
