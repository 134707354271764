import {
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { DateInterval } from "../../../../interfaces/DateInterval";
import { CurrencyPriceData } from "../../../../interfaces/PricesOverview";
import { toUTCTimestring } from "../../../../utils/FormattingUtils";
import { getTheme } from "../../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  dateInterval: DateInterval;
  priceData: CurrencyPriceData | undefined;
}
export function RangePreviewChart(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);
  const createData = () => {
    if (!props.priceData) return undefined;
    const dataset: any[] = [];
    for (let i = 0; i < props.priceData?.prices.length; i++) {
      dataset.push({
        x: toUTCTimestring(props.priceData?.timestamps[i], "1D"),
        USD: props.priceData?.prices[i],
      });
    }
    return dataset;
  };

  const data = createData();

  const minY = data && Math.min(...data.map((d) => d.USD));
  const maxY = data && Math.max(...data.map((d) => d.USD));

  // Add some padding to the min and max values
  const padding = minY && maxY && (maxY - minY) * 0.1;
  const paddedMinY = padding && minY - padding;
  const paddedMaxY = padding && maxY + padding;

  return (
    <>
      <ResponsiveContainer width="99%" height="99%">
        <LineChart width={300} height={100} data={data}>
          {paddedMinY && paddedMaxY && (
            <YAxis domain={[paddedMinY, paddedMaxY]} hide />
          )}
          <XAxis dataKey="x" hide />
          <Line
            animationDuration={0}
            type="monotone"
            dataKey="USD"
            stroke={props.activeTheme === "light" ? "#333234" : "#DACDE9"}
            strokeOpacity={0.4}
            strokeWidth={2}
            activeDot={{ r: 0 }}
            dot={{ r: 0 }}
          />
          <ReferenceArea
            x1={toUTCTimestring(props.dateInterval.stopTimestamp, "1D")}
            x2={toUTCTimestring(props.dateInterval.startTimestamp, "1D")}
            y1={minY}
            y2={maxY}
            stroke={theme.buyOrderStrokeHover}
            fill={theme.neoton}
            fillOpacity={0.2}
            strokeOpacity={0.7}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
