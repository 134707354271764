import { motion } from "framer-motion";
import { DateInterval } from "../../../interfaces/DateInterval";
import { useCallback } from "react";

interface Props {
  dateInterval: DateInterval;
  currentInterval: DateInterval | undefined;
  quarterLabel: string;
  seasonLabel?: string;
  handleDateIntervalExtend: (_dateInteval: DateInterval) => void;
}
export function CommonDateSubChip(props: React.PropsWithChildren<Props>) {
  const checkIfActive = useCallback(() => {
    if (!props.currentInterval) return false;
    // props.currentInterval is a quarter
    // check if the quarter is in the currentInterval
    const currentIntervalStart = new Date(props.currentInterval.startTimestamp);
    const currentIntervalStop = new Date(props.currentInterval.stopTimestamp);
    const quarterStart = new Date(props.dateInterval.startTimestamp);
    const quarterStop = new Date(props.dateInterval.stopTimestamp);

    return (
      quarterStart >= currentIntervalStart && quarterStop <= currentIntervalStop
    );
  }, [props.currentInterval, props.dateInterval]);

  const isActive = checkIfActive();

  return (
    <motion.div
      id={
        props.dateInterval.stopTimestamp > new Date().getTime()
          ? "future-quarter-chip"
          : ""
      }
      className={"subchip-container" + (isActive ? " active" : "")}
      onClick={(e: any) => {
        e.stopPropagation();
        props.handleDateIntervalExtend(props.dateInterval);
      }}
    >
      <label>{props.quarterLabel}</label>
      {props.seasonLabel && <label>{props.seasonLabel}</label>}
    </motion.div>
  );
}
