import "./common-market-cap-pool-header.scss";
import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import { Holdings } from "../../../interfaces/backtester/BacktestTradingReport";
import { Currency } from "../../../interfaces/Currency";
import { MarketCapPoolFunds } from "./CommonMarketCapPoolFundsContainer";
import { useState, useEffect } from "react";
import { Tooltip } from "@mantine/core";

interface Props {
  activeTheme: string;
  label: string;
  poolFunds: PoolFunds | undefined;
  holdings: Holdings | undefined;
  poolCurrencies: Currency[] | undefined;
  poolCompositionType: string;
  dynamicPoolExplanation: React.ReactNode | undefined;
}
export function CommonMarketCapPoolHeader(
  props: React.PropsWithChildren<Props>
) {
  const [showMarketCapPoolFunds, setShowMarketCapPoolFunds] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMarketCapPoolFunds(true);
    }, 2000); // Delay of 2000 milliseconds

    return () => clearTimeout(timer); // Cleanup to avoid memory leaks
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="common-market-cap-pool-header-container">
      <div className={"pool-type-indicator " + props.poolCompositionType} />

      <Tooltip
        wrapLines
        label={
          <div className="market-cap-pool-header-tooltip-container">
            <label>
              The {props.label} funds are the total funds available in this pool
              for trading.
            </label>
            {props.dynamicPoolExplanation}
          </div>
        }
        position="right"
        withArrow
      >
        <label className="dimmed-label market-cap-pool-header-label">
          {props.label}
        </label>
      </Tooltip>
      {props.holdings && showMarketCapPoolFunds && (
        <MarketCapPoolFunds
          activeTheme={props.activeTheme}
          holdings={props.holdings}
          label={props.label}
          poolCurrencies={props.poolCurrencies}
          poolFunds={props.poolFunds}
        />
      )}
    </div>
  );
}
