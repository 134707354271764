import { Currency } from "../interfaces/Currency";
import { IndicatorSuggestionMenuItem } from "../interfaces/strategyInterfaces/IndicatorSuggestionMenuItem";
import { PublicStrategyItem, Version } from "../interfaces/strategyInterfaces/StrategiesOverview";

export function compareCurrencyRank(a: Currency, b: Currency) {
  if (a.cmc_rank < b.cmc_rank) {
    return -1;
  }
  if (a.cmc_rank > b.cmc_rank) {
    return 1;
  }
  return 0;
}

export function compareIndicatorSuggestionValue(
  a: IndicatorSuggestionMenuItem,
  b: IndicatorSuggestionMenuItem
) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

export const sortVersionsByDate = (versions: Version[]) => {
  return versions.sort((a, b) => {
    return (
      new Date(b.created_on).getTime() - new Date(a.created_on).getTime()
    );
  });
};

export const sortByDate = (
  a: Version | PublicStrategyItem,
  b: Version | PublicStrategyItem
) => {
  return a.created_on > b.created_on ? -1 : 1;
};
