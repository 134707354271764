import { useCallback } from "react";
import {
  TooltipProps,
  LineChart,
  YAxis,
  XAxis,
  Line,
  Tooltip,
  ReferenceArea,
} from "recharts";
import { CurrencyPriceData } from "../../../interfaces/PricesOverview";
import { toUTCTimestring } from "../../../utils/FormattingUtils";
import { getTheme } from "../../../utils/themeUtil";

interface Props {
  activeTheme: string;
  candleSize: string;
  priceData: CurrencyPriceData | undefined;
  handleNavigationChartClick: (
    candleSize: string,
    centerTimestamp: number | null
  ) => void;
  currentInterval: [number, number];
  sessionInterval?: [number, number];
  enable1DChartClick?: boolean;
}
export function NavigationChart(props: React.PropsWithChildren<Props>) {
  const theme = getTheme(props.activeTheme);

  const createData = () => {
    if (!props.priceData) return undefined;
    const dataset: any[] = [];
    for (let i = 0; i < props.priceData?.prices.length; i++) {
      dataset.push({
        x: toUTCTimestring(props.priceData?.timestamps[i], "1D"),
        USD: props.priceData?.prices[i],
        USDSession:
          props.sessionInterval &&
          props.priceData?.timestamps[i] >= props.sessionInterval[0] &&
          props.priceData?.timestamps[i] <= props.sessionInterval[1]
            ? props.priceData?.prices[i]
            : undefined,
      });
    }
    return dataset;
  };

  const data = createData();
  const sessionData = data?.filter((d) => d.USDSession);

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && label && payload) {
      return (
        <div className="custom-tooltip">
          <div className="price-and-date-container">
            <label>{label}</label>
          </div>
        </div>
      );
    }

    return null;
  };

  const minY =
    sessionData && props.candleSize !== "1D"
      ? Math.min(...sessionData.map((d) => d.USDSession))
      : data && Math.min(...data.map((d) => d.USD));
  const maxY =
    sessionData && props.candleSize !== "1D"
      ? Math.max(...sessionData.map((d) => d.USDSession))
      : data && Math.max(...data.map((d) => d.USD));

  // Add some padding to the min and max values
  const padding = minY && maxY && (maxY - minY) * 0.1;
  const paddedMinY = padding && minY - padding;
  const paddedMaxY = padding && maxY + padding;

  const findNearestTimestamp = useCallback(
    (timestamp: number) => {
      const timestamps = props.priceData?.timestamps;
      if (!timestamps) return undefined;
      let minDiff = 0;
      const diffMap: { [key: number]: number } = {};
      for (let i = 0; i < timestamps.length; i++) {
        const diff = Math.abs(timestamps[i] - timestamp);
        diffMap[Math.abs(diff)] = timestamps[i];
      }
      minDiff = Math.min(...Object.keys(diffMap).map((d) => parseInt(d)));

      return diffMap[minDiff];
    },
    [props.priceData?.timestamps]
  );

  const getUTCDateFromStr = (dateStr: string) => {
    const date = new Date(dateStr);
    const utcDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
    return utcDate;
  };

  const _areaX1 =
    props.priceData &&
    props.currentInterval[0] < props.priceData.timestamps?.[0]
      ? toUTCTimestring(props.priceData.timestamps[0], "1D")
      : toUTCTimestring(props.currentInterval[0], "1D");

  const _areaX2 =
    props.priceData &&
    props.currentInterval[1] >
      props.priceData.timestamps?.[props.priceData.timestamps?.length - 1]
      ? toUTCTimestring(
          props.priceData.timestamps[props.priceData.timestamps?.length - 1],
          "1D"
        )
      : toUTCTimestring(props.currentInterval[1], "1D");

  const areaX1 = props.priceData?.timestamps.includes(
    getUTCDateFromStr(_areaX1)
  )
    ? _areaX1
    : toUTCTimestring(findNearestTimestamp(getUTCDateFromStr(_areaX1)), "1D");

  const areaX2 = props.priceData?.timestamps.includes(
    getUTCDateFromStr(_areaX2)
  )
    ? _areaX2
    : toUTCTimestring(findNearestTimestamp(getUTCDateFromStr(_areaX2)), "1D");

  return (
    <>
      {data && (
        <LineChart
          width={250}
          height={55}
          data={
            props.candleSize !== "1D" && props.sessionInterval
              ? sessionData
              : data
          }
          onClick={(e) => {
            if (!props.enable1DChartClick && props.candleSize === "1D") return;
            const dateLabel = e.activeLabel;
            if (!dateLabel || dateLabel === null) return;
            const timestamp = new Date(dateLabel).getTime();
            props.handleNavigationChartClick(props.candleSize, timestamp);
          }}
        >
          {paddedMinY && paddedMaxY && (
            <YAxis domain={[paddedMinY, paddedMaxY]} hide />
          )}
          <XAxis dataKey="x" hide />
          <Line
            type="monotone"
            dataKey="USD"
            stroke={props.activeTheme === "light" ? "#333234" : "#DACDE9"}
            opacity={props.sessionInterval ? 0.3 : 1}
            strokeWidth={2}
            activeDot={{ r: 0 }}
            dot={{ r: 0 }}
            animationDuration={0}
          />
          <Line
            type="monotone"
            dataKey="USDSession"
            stroke={props.activeTheme === "light" ? "#333234" : "#DACDE9"}
            opacity={1}
            strokeWidth={2}
            activeDot={{ r: 0 }}
            dot={{ r: 0 }}
            animationDuration={0}
          />

          <ReferenceArea
            x1={areaX1}
            x2={areaX2}
            strokeWidth={1}
            fill={
              !props.enable1DChartClick && props.candleSize === "1D"
                ? "transparent"
                : theme.cardInterior
            }
            fillOpacity={0.5}
            stroke={
              !props.enable1DChartClick && props.candleSize === "1D"
                ? "transparent"
                : theme.cardInterior
            }
          />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      )}
    </>
  );
}
