import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  TooltipProps,
  TextProps,
} from "recharts";
import { getTheme } from "../../../utils/themeUtil";
import {  calcPctOfTotal } from "../../../utils/FormattingUtils";
import { useMemo, useState } from "react";

interface Props {
  activeTheme: string;
  wins: number;
  losses: number;
  trades: number;
  fillColor?: string;
  totalTrades?: number;
  hideTotal?: boolean;
  disableTooltip?: boolean;
}
export function MetricsPieChart(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const data = [
    {
      name: "Wins",
      value: props.wins,
    },
    {
      name: "Losses",
      value: props.losses,
    },
  ];

  const data2 = props.totalTrades
    ? [
        {
          name: "Other trades",
          value: props.totalTrades - props.trades,
        },
        {
          name: "Trades",
          value: props.trades,
        },
      ]
    : [
        {
          name: "Trades",
          value: props.trades,
        },
      ];

  const COLORS = {
    Wins: theme.buyOrderStrokeHover,
    Losses: theme.sellOrderStrokeHover,
    "Total trades": theme.cardInterior,
    "Other trades": theme.cardInterior,
    Trades: props.fillColor ?? theme.text,
  };
  const COLORSFill = {
    Wins: props.wins > props.losses ? theme.buyOrderAreaY : 'transparent',
    Losses: props.wins < props.losses ? theme.sellOrderStroke : 'transparent',
    "Total trades": theme.cardInterior,
    "Other trades": theme.cardInterior,
    Trades: props.fillColor ?? theme.text,
  };

  const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip pie-chart">
          <div className="price-and-date-container">
            <label>
              {payload[0].name}: {payload[0].value}
            </label>
          </div>
        </div>
      );
    }

    return null;
  };

  const [hoverMode, setHoverMode] = useState<"w/l" | "trades" | undefined>(
    props.totalTrades ? "w/l" : undefined
  );

  const CustomLabel = (labelProps: TextProps & { value: number }) => {
    const { x, y, value } = labelProps;
    const invertPad = !isNaN(y as any) && (y as number) > 90 ? 15 : -3;
    if (props.totalTrades && value === props.totalTrades - props.trades)
      return null;
    if (props.totalTrades && props.totalTrades === value) {
      return (
        <text
          className="wallet-stat-label pie-chart"
          x={x}
          y={y}
          dy={invertPad - 3}
          dx={-25}
          fill={COLORS[labelProps.name ?? "Trades"]}
          stroke={COLORS[labelProps.name ?? "Trades"]}

        >
          {calcPctOfTotal(props.trades, props.totalTrades).toFixed(2)}%
        </text>
      );
    }

    return (
      <text
        className="wallet-stat-label pie-chart"
        x={x}
        y={y}
        dy={invertPad}
        dx={labelProps.name === "Losses" ? -10 : -3}
        fill={COLORS[labelProps.name ?? "Trades"]}
        stroke={COLORS[labelProps.name ?? "Trades"]}
        
      >
        {value}
        {labelProps.name === "Wins"
          ? " Wins"
          : labelProps.name === "Losses"
          ? " Losses"
          : ""}
      </text>
    );
  };

  return (
    <>
      <PieChart height={200} width={200} className="pie-chart">
        {!props.disableTooltip && <Tooltip content={<CustomTooltip />} />}
        {!props.hideTotal && (
          <Pie
            data={data2}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={36}
            animationEasing="ease-in-out"
            stroke={theme.card}
            label={
              hoverMode === "w/l" ? undefined : (
                <CustomLabel value={data2[0].value} />
              )
            }
            labelLine={props.totalTrades ? false : true}
            opacity={hoverMode === "w/l" ? 0.35 : 1}
        
          >
            {data2.map((entry, index) => (
              <Cell
                stroke={theme.card}
                key={`cell-${index}`}
                fill={COLORS[entry.name]}
              />
            ))}
          </Pie>
        )}
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={30}
          label={<CustomLabel value={data2[0].value} />}
          labelLine={false}
          opacity={1}
        >
          {data.map((entry, index) => (
            <Cell
              stroke={COLORS[entry.name]}
              key={`cell-${index}`}
              strokeWidth={2}
              fill={COLORSFill[entry.name]}
            />
          ))}
        </Pie>
      </PieChart>
    </>
  );
}
