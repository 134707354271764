import "./icon-button.scss";

import React from "react";

interface Props {
  onClick: () => void;
  icon: any;
  iconSize: number;
  disabled?: boolean;
}

export default function IconButton(props: React.PropsWithChildren<Props>) {
  if (props.disabled) {
    return (
      <div
        className="icon-button-disabled"
        style={{
          fontSize: `${props.iconSize}rem`,
        }}
      >
        {props.icon}
      </div>
    );
  }
  return (
    <div
      className="icon-button"
      onClick={props.onClick}
      style={{
        fontSize: `${props.iconSize}rem`,
      }}
    >
      {props.icon}
    </div>
  );
}
