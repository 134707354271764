import { useCallback, useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getTheme } from "../../utils/themeUtil";
import { toLocalTimeString } from "../../utils/FormattingUtils";
import { ExecutionStatusMessages } from "../../interfaces/livetrader/LivewalletReport";
import { motion } from "framer-motion";
import { FiCheck } from "react-icons/fi";
import { RiErrorWarningLine } from "react-icons/ri";
import { CgArrowsExchange } from "react-icons/cg";

interface Props {
  activeTheme: string;
  executionTimestamp: number;
  compact?: boolean;
  executionStatusMessage?: ExecutionStatusMessages;
}
export function ExecutionUpdateCircularProgressBar(
  props: React.PropsWithChildren<Props>
) {
  const theme = getTheme(props.activeTheme);
  const totalSeconds = 5 * 60; // 5 minutes
  const [percentage, setPercentage] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);

  const timestamp =
    props.executionStatusMessage?.timestamp ?? props.executionTimestamp;

  const createMessageArray = useCallback(
    (messages: ExecutionStatusMessages) => {
      const _messageArray: {
        message: string;
        messageType: "sync" | "order" | "warning";
      }[] = [];
      if (messages.sync) {
        _messageArray.push({ message: messages.sync, messageType: "sync" });
      }
      if (messages.orders) {
        messages.orders.forEach((orderMessage) => {
          _messageArray.push({ message: orderMessage, messageType: "order" });
        });
      }
      if (messages.missing_currencies) {
        _messageArray.push({
          message: messages.missing_currencies,
          messageType: "warning",
        });
      }

      return _messageArray;
    },
    []
  );

  const messageTypeIcons = {
    sync: (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="circular-progress-icon-container"
      >
        <FiCheck className="dimmed-label" />
      </motion.div>
    ),
    order: (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="circular-progress-icon-container"
      >
        <CgArrowsExchange size={20} color={theme.orderOptionsTheme} />
      </motion.div>
    ),
    warning: (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="circular-progress-icon-container"
      >
        <RiErrorWarningLine
          size={20}
          className="dimmed-label"
          color={theme.mediumWarningColor}
        />
      </motion.div>
    ),
  };

  const messages =
    props.executionStatusMessage &&
    createMessageArray(props.executionStatusMessage);

  useEffect(() => {
    if (!messages) return;
    const messageShowDuration = props.compact ? 30 : 20;

    const interval = setInterval(() => {
      const now = new Date();
      const elapsedSeconds = Math.floor((now.getTime() - timestamp) / 1000);
      const newPercentage =
        ((totalSeconds - elapsedSeconds) / totalSeconds) * 100;
      setPercentage(newPercentage > 0 ? newPercentage : 0);

      if (elapsedSeconds % messageShowDuration !== 0) return;

      setMessageIndex((prevIndex) => {
        return (prevIndex + 1) % messages.length; // Cycle through indexes
      });
    }, 1000); // Update every second

    return () => {
      clearInterval(interval);
    };
  }, [timestamp, totalSeconds, props.compact]);

  return (
    <div
      className={
        "livetrader-circular-progress-bar-outer-container" +
        (props.compact ? " compact" : "")
      }
    >
      <div className="execution-label-container">
        {percentage > 80 ? (
          <label className="dimmed-label">Just now</label>
        ) : (
          <label className="dimmed-label">
            {toLocalTimeString(props.executionTimestamp, "1h")}
          </label>
        )}
      </div>

      <div className="livetrader-circular-progress-bar-container">
        <CircularProgressbarWithChildren
          value={percentage}
          counterClockwise={true}
          styles={buildStyles({
            strokeLinecap: theme.card,
            trailColor: theme.cardInterior,
            pathColor: theme.cardInteriorHover,
          })}
          children={
            (messages &&
              messages.length > 0 &&
              messageTypeIcons[messages[messageIndex]?.messageType]) ||
            undefined
          }
        />
      </div>
      {messages && messages.length > 0 && (
        <motion.div className="execution-message-container">
          {messages.map((messageItem, index) => {
            if (index !== messageIndex) return null;
            return (
              <motion.label
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                exit={{ opacity: 0 }}
                key={index}
                className="execution-message"
                id={messageItem.message.length > 30 ? "marquee-message" : ""}
              >
                {messageItem.message}
              </motion.label>
            );
          })}
        </motion.div>
      )}
    </div>
  );
}
