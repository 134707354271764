import { useContext } from "react";
import { ApplicationIndicators } from "../../../../interfaces/Indicators";
import "./code-fragments-overview.scss";
import { CriteriaFragment } from "../../criteria-fragments/CriteriaFragment";
import { Badge } from "@mantine/core";
import { FragmentType } from "../../../../utils/ParseCriteriaUtil";
import { UserPermissionsContext } from "../../../../App";
import { AICurrenciesContext } from "../CaseControl";
import { IndicatorInfosContext } from "../../../../shared-service-contexts/IndicatorsInfoContext";
import { motion } from "framer-motion";
import { FiX } from "react-icons/fi";
import { EditedCriteriaContext } from "../NewCriteriaEditor";
interface Props {
  activeTheme: string;
  applicationIndicators: ApplicationIndicators;
  criteriaType: string;
  allowedFragmentTypes: string[];
  focusedFragmentIndex: number | undefined;
  addFragment: (fragment: string) => void;
  candleSize: string;
  tradingCurrencies: string[];
  removeCurrency: (currency: string) => void;
}
export function AICodeFragmentsOverview(props: React.PropsWithChildren<Props>) {
  const editedCriteria = useContext(EditedCriteriaContext);
  const aiCurrencies = useContext(AICurrenciesContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const userPermissions = useContext(UserPermissionsContext);
  const hasPermissions = userPermissions?.includedPermissions.includes("pro");

  // get currencies not supoorted by AI
  const illegalCurrencies = props.tradingCurrencies.filter(
    (currency) => !aiCurrencies.includes(currency)
  );

  const candleSizeSupportedAIIndicators = Object.keys(
    props.applicationIndicators.ai_supported_candle_sizes
  ).filter((key) =>
    props.applicationIndicators.ai_supported_candle_sizes[key].includes(
      props.candleSize
    )
  );

  const aiIndicatorFragmentsEnabled =
    hasPermissions && illegalCurrencies.length === 0;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      exit={{ opacity: 0 }}
      className="code-fragments-overview-container"
    >
      <>
        {!hasPermissions && (
          <label className="dimmed-label">Requires PRO subscription</label>
        )}
        {illegalCurrencies.length > 0 && (
          <div className="error-text-container">
            <label>The following currencies are not supported by AI:</label>
            <label className="dimmed-label">Click to remove</label>
            <div className="error-text">
              {illegalCurrencies.map((currencyName) => (
                <Badge
                  leftSection={<FiX />}
                  onClick={() => props.removeCurrency(currencyName)}
                  key={currencyName}
                  style={{ cursor: "pointer" }}
                  variant="outline"
                  color="red"
                  size="sm"
                  radius="xs"
                >
                  {currencyName}
                </Badge>
              ))}
            </div>
          </div>
        )}

        {candleSizeSupportedAIIndicators.length === 0 && (
          <label className="dimmed-label">
            There are currently no AI signals supported on candle size:{" "}
            {props.candleSize}
          </label>
        )}

        {indicatorsInfo && (
          <>
            {Object.keys(indicatorsInfo.ai_indicator_series).map((key, idx) => {
              const indicator_info = indicatorsInfo.ai_indicator_series[key];
              if (
                !indicator_info.candle_size_support.includes(props.candleSize)
              )
                return;
              return (
                <div className="ai-series-container" key={key + "-" + idx}>
                  <label className="series-name">
                    {indicator_info.ai_alias}
                  </label>
                  <label className="dimmed-label">
                    {indicator_info.alias_description}
                  </label>
                  <div className="code-fragments-container">
                    {Object.keys(
                      props.applicationIndicators.ai_translations
                    ).map((aiAlias, index) => {
                      if (
                        props.criteriaType === "entry" &&
                        !aiAlias.includes("ENTRY")
                      )
                        return null;
                      if (
                        props.criteriaType === "exit" &&
                        !aiAlias.includes("EXIT")
                      )
                        return null;
                      const fragmentType: FragmentType = {
                        fragment: aiAlias,
                        type: "AI_VARIABLE",
                        className: "fragment-badge-variable-ai",
                      };
                      const enabled =
                        aiIndicatorFragmentsEnabled &&
                        !editedCriteria.includes(aiAlias) &&
                        props.allowedFragmentTypes.includes(fragmentType.type);
                      if (
                        !props.applicationIndicators.ai_translations[
                          aiAlias
                        ].includes(key)
                      )
                        return null;

                      return (
                        <div
                          key={aiAlias + "-" + index}
                          className="code-fragment-container"
                          id={enabled ? "" : "disabled-code-fragment"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            if (!enabled) return;
                            if (
                              !props.allowedFragmentTypes.includes(
                                fragmentType.type
                              )
                            )
                              return;
                            props.addFragment(aiAlias);
                          }}
                        >
                          <CriteriaFragment
                            indicators={props.applicationIndicators.indicators}
                            activeTheme={props.activeTheme}
                            fragmentType={fragmentType}
                            index={index}
                            small
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    </motion.div>
  );
}
