import { FiArrowUpRight, FiArrowDownRight, FiArrowRight } from "react-icons/fi";
import { ApplicationIndicators, Indicators } from "../interfaces/Indicators";
import { MantineColor } from "@mantine/core";

export const isOperator = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;

  return (
    fragment.includes(">") || fragment.includes("<") || fragment.includes("=")
  );
};

export const isAdvancedOperator = (fragment: string | undefined) => {
  const directions_values = ["1.0", "-1.0"];
  if (fragment === "" || !fragment || directions_values.includes(fragment))
    return false;
  return (
    fragment.includes("*") ||
    fragment.includes("/") ||
    fragment.includes("+") ||
    fragment.includes("-")
  );
};

export const createDirectionsTooltip = (fragment: number) => {
  if (fragment > 0) {
    return <FiArrowUpRight />;
  }
  if (fragment < 0) {
    return <FiArrowDownRight />;
  }
  return <FiArrowRight />;
};

export const isConnector = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;
  return fragment.includes(";");
};

export const isNumeric = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;
  return !isNaN(parseFloat(fragment.replace("-", "")));
};

export const isPriceHead = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;

  return fragment === "PRICE";
};
export const isCandle = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;

  return ["OPEN", "CLOSE", "HIGH", "LOW"].includes(fragment.toUpperCase());
};
export const isDirection = (fragment: string | undefined) => {
  if (fragment === "" || !fragment) return false;
  return fragment.toUpperCase().includes("DIRECTIONS");
};

const isTAIndicatorVariable = (
  fragment: string | undefined,
  indicators: Indicators
) => {
  if (fragment === "" || !fragment) return false;

  const indicatorVariables: string[] = [];
  Object.keys(indicators.ta_indicators).map((indicatorKey) => {
    indicators.ta_indicators[indicatorKey].map((item: string) => {
      indicatorVariables.push(item);
    });
  });
  return indicatorVariables.includes(fragment);
};
const isAiIndicatorVariable = (
  fragment: string | undefined,
  applicationIndicators: ApplicationIndicators
) => {
  if (fragment === "" || !fragment) return false;
  return Object.keys(applicationIndicators.ai_translations).includes(fragment);
};
const isPTIndicatorVariable = (
  fragment: string | undefined,
  patternCriteriaMap: { [key: string]: string }
) => {
  if (fragment === "" || !fragment) return false;
  return Object.keys(patternCriteriaMap).includes(fragment);
};

export const isVariable = (
  fragment: string | undefined,
  indicators: Indicators
) => {
  if (fragment === "" || !fragment) return false;

  return (
    isTAIndicatorVariable(fragment, indicators) ||
    isNumeric(fragment) ||
    isCandle(fragment) ||
    isDirection(fragment) ||
    isPriceHead(fragment)
  );
};

export interface FragmentType {
  fragment: string;
  type:
    | "OPERATOR"
    | "ADVANCED_OPERATOR"
    | "DIRECTION"
    | "VALUE"
    | "CONNECTOR"
    | "PRICE_HEAD"
    | "CANDLE"
    | "VARIABLE"
    | "AI_VARIABLE"
    | "PATTERN";
  className:
    | "fragment-badge-operator"
    | "fragment-badge-variable"
    | "fragment-badge-number"
    | "fragment-badge-connector"
    | "fragment-badge-price-head"
    | "fragment-badge-candle"
    | "fragment-badge-variable-ai"
    | "fragment-badge-variable-ta"
    | "fragment-badge-pattern";
}

export const getFragmentType = (
  fragment: string,
  applicationIndicators: ApplicationIndicators,
  patternCriteriaMap: { [key: string]: string },
  forceDirection: boolean = false
): FragmentType => {
  if (forceDirection) {
    return {
      fragment,
      type: "DIRECTION",
      className: "fragment-badge-variable-ta",
    };
  }
  switch (true) {
    case isConnector(fragment):
      return {
        fragment,
        type: "CONNECTOR",
        className: "fragment-badge-connector",
      };
    case isDirection(fragment):
      return {
        fragment,
        type: "DIRECTION",
        className: "fragment-badge-variable-ta",
      };
    case isOperator(fragment):
      return {
        fragment,
        type: "OPERATOR",
        className: "fragment-badge-operator",
      };
    case isNumeric(fragment):
      return { fragment, type: "VALUE", className: "fragment-badge-number" };
    case isAdvancedOperator(fragment):
      return {
        fragment,
        type: "OPERATOR",
        className: "fragment-badge-operator",
      };
    case isPriceHead(fragment):
      return {
        fragment,
        type: "PRICE_HEAD",
        className: "fragment-badge-price-head",
      };
    case isCandle(fragment):
      return { fragment, type: "CANDLE", className: "fragment-badge-candle" };
    case isTAIndicatorVariable(fragment, applicationIndicators.indicators):
      return {
        fragment,
        type: "VARIABLE",
        className: "fragment-badge-variable-ta",
      };
    case isAiIndicatorVariable(fragment, applicationIndicators):
      return {
        fragment,
        type: "AI_VARIABLE",
        className: "fragment-badge-variable-ai",
      };
    case isPTIndicatorVariable(fragment, patternCriteriaMap):
      return {
        fragment,
        type: "PATTERN",
        className: "fragment-badge-pattern",
      };
    default:
      return {
        fragment,
        type: "VARIABLE",
        className: "fragment-badge-variable",
      };
  }
};

export const getTierColors = (tier: string): MantineColor | undefined => {
  switch (tier.toLowerCase()) {
    case "ai":
      return "cyan";
    case "ta":
      return "indigo";
    case "pt":
      return "grape";
    case "ci":
      return "orange";
    default:
      return "gray";
  }
};

export const getCriteriaDependencies = (
  criteria: string,
  applicationIndicators: ApplicationIndicators
) => {
  const taIndicatorDependencies: string[] = [];
  const ptIndicatorDependencies: string[] = [];
  const aiIndicatorDependencies: string[] = [];
  criteria.split(" ").forEach((element) => {
    // get the ta indicator dependencies
    Object.keys(applicationIndicators.indicators.ta_indicators).forEach(
      (key) => {
        if (
          applicationIndicators.indicators.ta_indicators[key].includes(element.replace('_DIRECTIONS', ''))
        ) {
          taIndicatorDependencies.push(`TA.${key}`);
        }
      }
    );
    // get the ai indicator dependencies
    const possibleAiElement = element
      .replace("_ENTRY", "")
      .replace("_EXIT", "");
    if (
      Object.keys(applicationIndicators.ai_alias_translations).includes(
        possibleAiElement
      )
    ) {
      aiIndicatorDependencies.push(
        `AI.${applicationIndicators.ai_alias_translations[possibleAiElement]}`
      );
    }
    // get the pt indicator dependencies
    if (Object.keys(applicationIndicators.pt_translations).includes(element)) {
      const elementTranslation = applicationIndicators.pt_translations[element];
      ptIndicatorDependencies.push(`PT.${elementTranslation.split(" ")[0]}`);
    }
  });
  const allDependencies = [
    ...taIndicatorDependencies,
    ...ptIndicatorDependencies,
    ...aiIndicatorDependencies,
  ].filter((value, index, self) => self.indexOf(value) === index);
  return allDependencies;
};

export const getTranslatedCriteriaDependencies = (
  criteria: string,
  applicationIndicators: ApplicationIndicators
): TranslatedCriteriaDependencies => {
  const dependencies = getCriteriaDependencies(criteria, applicationIndicators);
  const aiAliasTranslations = {};
  Object.keys(applicationIndicators.ai_alias_translations).forEach((key) => {
    const value = applicationIndicators.ai_alias_translations[key];
    aiAliasTranslations[value] = key;
  });
  const taDeps = dependencies
    .filter((dep) => dep.startsWith("TA."))
    .map((dep) => dep.replace("TA.", ""));
  const aiDeps = dependencies
    .filter((dep) => dep.startsWith("AI."))
    .map((dep) => aiAliasTranslations[dep.replace("AI.", "")]);
  const ptDeps = dependencies
    .filter((dep) => dep.startsWith("PT."))
    .map((dep) => dep.replace("PT.", "").replace("CDL", ""));
  const calculationProfiles: string[] = [];
  if (taDeps.length > 0) {
    calculationProfiles.push("TA");
  }
  if (aiDeps.length > 0) {
    calculationProfiles.push("AI");
  }
  if (ptDeps.length > 0) {
    calculationProfiles.push("PT");
  }
  return {
    calculationProfiles: calculationProfiles,
    taIndicators: taDeps,
    aiIndicators: aiDeps,
    ptIndicators: ptDeps,
    length: dependencies.length,
  };
};

export interface TranslatedCriteriaDependencies {
  calculationProfiles: string[];
  taIndicators: string[];
  aiIndicators: string[];
  ptIndicators: string[];
  length: number;
}
