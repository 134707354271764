import "./tier-badges.scss";

import { useCallback, useContext } from "react";
import { IndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";
import { Badge, MantineSize } from "@mantine/core";
import { getTierColors } from "../../../utils/ParseCriteriaUtil";

interface Props {
  collectedCriterias: string[];
  tiersList?: string[];
  size?: MantineSize;
  compact?: boolean;
  noMargin?: boolean;
  style?: React.CSSProperties;
}
export function TierBadges(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(IndicatorsContext);

  const hasIndicators = useCallback(
    (indicatorKeys: string[], criterias: string[]) => {
      const hasIndicator = indicatorKeys.some((key) =>
        criterias.some((str) => str.includes(key))
      );
      return hasIndicator;
    },
    []
  );

  const createTiers = useCallback(() => {
    const _tiers: string[] = [];
    if (!applicationIndicators) return [];

    if (
      hasIndicators(
        Object.keys(applicationIndicators.ai_translations),
        props.collectedCriterias
      )
    ) {
      _tiers.push("ai");
    }
    if (
      hasIndicators(
        Object.keys(applicationIndicators.indicators.ta_indicators),
        props.collectedCriterias
      )
    ) {
      _tiers.push("ta");
    }
    if (
      hasIndicators(
        Object.keys(applicationIndicators.pt_translations),
        props.collectedCriterias
      )
    ) {
      _tiers.push("pt");
    }
    return _tiers;
  }, [props.collectedCriterias, hasIndicators, applicationIndicators]);

  const tiers = createTiers();

  return (
    <>
      {!props.tiersList ? (
        <div
          style={props.style}
          className={
            "tier-badges-container" +
            (props.noMargin ? " no-margin" : "") +
            (props.compact ? " compact" : "")
          }
        >
          {tiers.map((tier, idx) => {
            if (tier.length < 1) return null;
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                key={idx}
              >
                <Badge
                  radius={"xs"}
                  key={`${tier}-${idx}`}
                  variant="outline"
                  color={getTierColors(tier)}
                  size={props.size ?? "sm"}
                  styles={{
                    inner: {
                      fontFamily: "Montserrat-LightAlt",
                    },
                  }}
                >
                  {tier}
                </Badge>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={props.style}
          className={
            "tier-badges-container" +
            (props.noMargin ? " no-margin" : "") +
            (props.compact ? " compact" : "")
          }
        >
          {props.tiersList.length === 0 ? undefined : (
            <>
              {props.tiersList.map((tier, idx) => {
                if (tier.length < 1) return null;
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    key={idx}
                  >
                    <Badge
                      radius={"xs"}
                      key={`${tier}-${idx}`}
                      variant="outline"
                      color={getTierColors(tier)}
                      size={props.size ?? "sm"}
                      styles={{
                        inner: {
                          fontFamily: "Montserrat-LightAlt",
                        },
                      }}
                    >
                      {tier}
                    </Badge>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
}
