import "./common-strategy-overview.scss";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback, useMemo, useContext } from "react";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { useQuery } from "react-query";
import {
  CommonStrategyItem,
  CommonStrategyVersionItem,
  StrategySearchResultComponent,
} from "../../sidemenu/search-results/StrategySearchResultComponent";
import {
  StrategyVersionPaperwalletItem,
  UserStrategyItem,
} from "../../../interfaces/common/UserStrategies";
import { UsermanagerService } from "../../../services/UserManagerService";
import { UserLimitationsContext, UserPermissionsContext } from "../../../App";
import { FeaturedButton } from "../../sidemenu/search-results/StrategyOwnerMenu";
import { GetPapertraderSeasonContext } from "../../../pages/papertrader-page/PapertraderPage";
import { motion } from "framer-motion";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { NewStrategyPopupContent } from "../../sidemenu/NewStrategyPopupContent";
import { Modal } from "@mantine/core";
import { CgCodeSlash } from "react-icons/cg";

interface Props {
  activeTheme: string;
  traderType: TraderType;
  featuredButton?: FeaturedButton;
  maxHeight?: number;
}
export function CommonStrategyOverview(props: React.PropsWithChildren<Props>) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const userPermissions = useContext(UserPermissionsContext);
  const limitations = useContext(UserLimitationsContext);

  const fetchUserInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.getUserInfo(token);
    return response.data.payload;
  }, [getAccessTokenSilently]);

  const selectedSeasonKey = useContext(GetPapertraderSeasonContext);

  const [newStrategyPopupOpened, setNewStrategyPopupOpened] = useState(false);

  const getSeasons = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getSeasons(token);
      return response.data;
    } catch (error) {}
  }, [getAccessTokenSilently, props.traderType]);

  const seasonsQuery = useQuery(["Seasons"], getSeasons, {
    cacheTime: 60000,
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const userInfoQuery = useQuery("userInfo", fetchUserInfo, {
    cacheTime: 60000,
    staleTime: 0,
    enabled:
      isAuthenticated &&
      userPermissions &&
      userPermissions.includedPermissions &&
      userPermissions.includedPermissions.includes("free")
        ? true
        : false,
  });

  const [loading, setLoading] = useState(false);
  const [loadingPaperwalletStats, setLoadingPaperwalletStats] = useState(false);
  const [selectedStrategyId, setSelectedStrategyId] = useState<string>();

  const fetchUserStrategies = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      // delay loading to prevent flickering
      setLoading(true);
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getUserStrategies(token);
      return response.data;
    } finally {
      setLoading(false);
    }
  }, [setLoading, getAccessTokenSilently, props.traderType]);

  const strategiesQuery = useQuery(["Strategies"], fetchUserStrategies, {
    keepPreviousData: false,
    cacheTime: 60, // 60 seconds
    staleTime: 0,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const versionIds = useMemo(() => {
    const _versionIds: string[] = [];
    if (!strategiesQuery.data?.payload) return _versionIds;
    Object.keys(strategiesQuery.data?.payload).forEach((strategyId) => {
      const _strategy = strategiesQuery!.data!.payload![strategyId];
      _strategy.forEach((item) => {
        _versionIds.push(item.version_id);
      });
    });
    return _versionIds;
  }, [strategiesQuery]);

  const fetchPaperwalletStats = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      // delay loading to prevent flickering
      setLoadingPaperwalletStats(true);
      const blueprintService = getBlueprintService(props.traderType);
      const response = await blueprintService.getStrategyPaperwallets(
        token,
        versionIds,
        !seasonsQuery.data
          ? undefined
          : seasonsQuery.data.current_season === selectedSeasonKey
          ? undefined
          : selectedSeasonKey
      );
      return response.data;
    } finally {
      setLoadingPaperwalletStats(false);
    }
  }, [
    getAccessTokenSilently,
    props.traderType,
    versionIds,
    selectedSeasonKey,
    seasonsQuery,
    setLoadingPaperwalletStats,
  ]);

  const paperwalletStatsQuery = useQuery(
    ["PaperwalletStats", selectedSeasonKey],
    fetchPaperwalletStats,
    {
      keepPreviousData: true,
      cacheTime: 6000,
      staleTime: 0,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: versionIds.length > 0,
    }
  );

  const toCommonStrategyVersionItem = (
    strategyItem: UserStrategyItem,
    _strategyVersionPaperwalletItem: StrategyVersionPaperwalletItem | undefined
  ): CommonStrategyVersionItem | undefined => {
    const tier = strategyItem.tier.split("/");

    if (strategyItem && !_strategyVersionPaperwalletItem) {
      return {
        created_on: strategyItem.created_on,
        currencies: strategyItem.included_currencies,
        currency_pair: strategyItem.currency_pair,
        current_balance: 1000,
        initial_balance: 1000,
        min_permission: tier.includes("AI") ? "free" : "pro",
        tags: strategyItem.tags,
        tier: tier,
        version_id: strategyItem.version_id,
      };
    }
    if (!_strategyVersionPaperwalletItem) return undefined;
    return {
      created_on: strategyItem.created_on,
      currencies: _strategyVersionPaperwalletItem.currencies,
      currency_pair: _strategyVersionPaperwalletItem.currency_pair,
      current_balance: _strategyVersionPaperwalletItem.wallet_value_snapshots
        ? _strategyVersionPaperwalletItem.wallet_value_snapshots.valueSnapshots[
            _strategyVersionPaperwalletItem.wallet_value_snapshots
              .valueSnapshots.length - 1
          ]
        : 1000,
      initial_balance: _strategyVersionPaperwalletItem.wallet_value_snapshots
        ? _strategyVersionPaperwalletItem.wallet_value_snapshots
            .valueSnapshots[0]
        : 1000,
      min_permission: tier.includes("AI") ? "free" : "pro",
      tags: strategyItem.tags,
      tier: tier,
      version_id: strategyItem.version_id,
    };
  };

  const sortedStrategyIdsByRecent = useMemo(() => {
    if (!strategiesQuery.data?.payload) return [];
    return Object.keys(strategiesQuery.data.payload).sort((a, b) => {
      const aTs = strategiesQuery.data?.payload?.[a][0]._ts ?? 0;
      const bTs = strategiesQuery.data?.payload?.[b][0]._ts ?? 0;
      return bTs - aTs;
    });
  }, [strategiesQuery.data]);

  useMemo(() => {
    if (!selectedStrategyId && sortedStrategyIdsByRecent.length > 0) {
      setSelectedStrategyId(sortedStrategyIdsByRecent[0]);
    }
  }, [sortedStrategyIdsByRecent]);

  const commonStrategyItems = useMemo(() => {
    // Early return if data is not available
    if (
      !strategiesQuery.data ||
      !strategiesQuery.data?.payload ||
      !userInfoQuery.data
    )
      return;

    const _commonStrategyItems: { [key: string]: CommonStrategyItem } = {};

    // Sort the keys based on _ts in descending order

    // Populate _commonStrategyItems using sorted keys
    sortedStrategyIdsByRecent.forEach((strategyId) => {
      const _userStrategyItems: UserStrategyItem[] =
        strategiesQuery.data!.payload![strategyId];

      const commonStrategy: CommonStrategyItem = {
        isOwner: true,
        name: _userStrategyItems[0].name,
        user: {
          user_hash: userInfoQuery.data?.user_hash ?? "",
          username: userInfoQuery.data?.username ?? "",
          title: userInfoQuery.data?.title ?? "",
        },
        match: 0,
        versions: paperwalletStatsQuery.data?.payload
          ? _userStrategyItems.map(
              (strategyItem) =>
                toCommonStrategyVersionItem(
                  strategyItem,
                  paperwalletStatsQuery.data?.payload![
                    strategyItem.version_id
                  ] ?? undefined
                ) as CommonStrategyVersionItem
            )
          : undefined,
      };

      _commonStrategyItems[strategyId] = commonStrategy;
    });

    return _commonStrategyItems;
  }, [
    strategiesQuery.data,
    paperwalletStatsQuery.data,
    userInfoQuery.data,
    sortedStrategyIdsByRecent,
  ]);

  return (
    <div className="common-strategy-overview-outer-container">
      <div className="common-strategy-overview-header-container">
        {loading ? (
          <label className="dimmed-label">Loading strategies...</label>
        ) : (
          <>
            <label className="dimmed-label">Your Strategies {limitations && commonStrategyItems ?  `${Object.keys(commonStrategyItems).length} / ${limitations.strategies}` : null}</label>
            <CommonButton
              label="New strategy"
              activeTheme={props.activeTheme}
              onClick={() => setNewStrategyPopupOpened(true)}
              compact={true}
              leftIcon={<CgCodeSlash />}
            />
          </>
        )}
      </div>
      <motion.div
        className="common-strategy-overview-container"
        animate={{
          minHeight: `${props.maxHeight}px` ?? "none",
          height: `${props.maxHeight}px` ?? "100%",
          maxHeight: `${props.maxHeight}px` ?? "100%",
        }}
      >
        <>
          {commonStrategyItems &&
            Object.keys(commonStrategyItems).map((strategyId, idx) => {
              return (
                <StrategySearchResultComponent
                  key={idx}
                  strategyResult={commonStrategyItems[strategyId]}
                  expanded={selectedStrategyId === strategyId}
                  index={idx}
                  strategyId={strategyId}
                  setExpandedId={(id) => {
                    setSelectedStrategyId(id);
                  }}
                  paperwalletStats={paperwalletStatsQuery.data?.payload}
                  refetchStrategies={() => {
                    paperwalletStatsQuery.remove();
                    strategiesQuery.remove();
                    strategiesQuery.refetch();
                    paperwalletStatsQuery.refetch();
                  }}
                  featuredButton={props.featuredButton}
                  traderType={props.traderType}
                  loadingPaperwalletStats={loadingPaperwalletStats}
                />
              );
            })}
          {commonStrategyItems &&
            Object.keys(commonStrategyItems).length === 0 && (
              <div className="common-empty-strategies-container">
                <label className="dimmed-label">
                  You dont have any strategies
                </label>
                <CommonButton
                  label="New strategy"
                  activeTheme={props.activeTheme}
                  onClick={() => setNewStrategyPopupOpened(true)}
                  leftIcon={<CgCodeSlash />}
                />
              </div>
            )}
        </>
      </motion.div>
      <Modal
        opened={newStrategyPopupOpened}
        onClose={() => setNewStrategyPopupOpened(false)}
        centered
        size={'lg'}
        title={<label>Create a new strategy</label>}
      >
        <NewStrategyPopupContent
          activeTheme={props.activeTheme}
          setNewStrategyDrawerOpened={setNewStrategyPopupOpened}
        />
      </Modal>
    </div>
  );
}
