import "./common-button.scss";
import { motion } from "framer-motion";
import { NeotonSpinner } from "../../custom-loaders/PixelLoader";

interface Props {
  activeTheme: string;
  label?: string | React.ReactNode;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick?: (e?: any) => void;
  loading?: boolean;
  customLoadingText?: string;
  tooltipAbove?: boolean;
  borderTheme?:
    | "red-accent"
    | "green-accent"
    | "blue-accent"
    | "yellow-accent"
    | "purple-accent"
    | "gray-accent"
    | "neoton-accent";
  keepWidth?: boolean;
  flat?: boolean;
  style?: React.CSSProperties;
}
export function CommonIconButton(props: React.PropsWithChildren<Props>) {
  return (
    <div
      style={props.style}
      className={
        "common-icon-button-container" +
        (props.disabled ? " disabled" : "") +
        (props.borderTheme ? " " + props.borderTheme : "") +
        (props.flat ? " flat" : "")
      }
      id={props.disabled ? "disabled-common-icon-button" : ""}
      onClick={(e: any) => {
        e.stopPropagation();
        !props.disabled && !props.loading && props.onClick && props.onClick();
      }}
    >
      {!props.loading ? (
        <motion.div
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.2 }}
        >
          {props.icon}
          {props.label && !props.disabled && (
            <div
              className={
                "icon-button-label" + (props.tooltipAbove ? " above" : "")
              }
            >
              {props.label}
            </div>
          )}
        </motion.div>
      ) : (
        <NeotonSpinner activeTheme={props.activeTheme} gray />
      )}
    </div>
  );
}
