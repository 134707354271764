import { motion } from "framer-motion";
import CountUp from "react-countup";
import {
  HoldingItem,
  Holdings,
} from "../../../interfaces/backtester/BacktestTradingReport";
import { PoolFunds } from "../../../interfaces/backtester/WalletFunds";
import { Currency } from "../../../interfaces/Currency";
import { useCallback, useMemo, useState } from "react";
import CommonMarketCapPoolHeaderProgress from "./CommonMarketCapPoolHeaderProgress";

interface Props {
  activeTheme: string;
  poolFunds: PoolFunds | undefined;
  label: string;
  holdings: Holdings;
  poolCurrencies: Currency[] | undefined;
}

export function MarketCapPoolFunds(props: React.PropsWithChildren<Props>) {
  const poolCurrencyNames = useMemo(() => {
    return (
      props.poolCurrencies?.map((currency) => currency.currency_name) ?? []
    );
  }, [props.poolCurrencies]);

  const holdings = useMemo(() => {
    if (!props.holdings || !props.poolFunds) return [];
    return props.holdings?.holding_list?.filter((holding) =>
      poolCurrencyNames.includes(holding.currency_name)
    );
  }, [props.holdings, props.poolFunds, poolCurrencyNames]);

  const holdingsValue = useMemo(() => {
    return holdings?.reduce((acc, holding) => acc + holding.current_value, 0);
  }, [holdings]);

  const poolValue = useMemo(() => {
    if (!props.poolFunds) {
      return undefined;
    }
    // Calculate the pool value based on holdings and available amount
    const totalHoldingsValue = holdingsValue ?? 0;
    return totalHoldingsValue + props.poolFunds.available_amount;
  }, [props.poolFunds, holdingsValue]);

  const getPctWidthForHolding = useCallback(
    (_holding: HoldingItem) => {
      if (!props.poolFunds) return 0;
      const pctWidth =
        (_holding.current_value / props.poolFunds?.initial_amount) * 100;
      return pctWidth;
    },
    [props.poolFunds]
  );

  const [showMarketCapPoolFunds, setShowMarketCapPoolFunds] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      onAnimationComplete={() => {
        setShowMarketCapPoolFunds(true);
      }}
      className="funds-pool-progress-container"
    >
      {props.poolFunds && poolValue ? (
        <CommonMarketCapPoolHeaderProgress
          activeTheme={props.activeTheme}
          pctWidth={
            props.poolFunds
              ? (props.poolFunds.available_amount /
                  props.poolFunds.initial_amount) *
                100
              : 0
          }
          poolValue={poolValue}
          poolFunds={props.poolFunds}
          label={props.label}
        >
          {props.poolFunds.available_amount > 0 && (
            <>
              <CountUp
                className={"pool-funds-label value"}
                end={props.poolFunds.available_amount}
                start={0}
                duration={1}
                prefix="$ "
                decimals={2}
              />

              <motion.label
                className="pool-funds-label"
                initial={{ x: -10 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.2, delay: 0.2 }}
              >
                /
              </motion.label>
            </>
          )}
          <CountUp
            className={"pool-funds-label value dimmed"}
            end={poolValue}
            start={0}
            duration={1}
            prefix="$ "
            decimals={2}
          />
        </CommonMarketCapPoolHeaderProgress>
      ) : undefined}

      {holdings.length > 0 &&
        showMarketCapPoolFunds &&
        holdings.map((holding, idx) => {
          return (
            <CommonMarketCapPoolHeaderProgress
              key={`${props.label}-${idx}`}
              activeTheme={props.activeTheme}
              pctWidth={getPctWidthForHolding(holding)}
              holdingItem={holding}
            />
          );
        })}
    </motion.div>
  );
}
