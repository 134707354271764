import "./papertrader-leaderboard.scss";
import { motion } from "framer-motion";
import { StrategyLeaderboardItem } from "../../interfaces/season/Leaderboard";
import { PapertraderLeaderboardItem } from "./PapertraderLeaderboardItem";
import { UserInfo } from "../../interfaces/user/UserInfo";

interface Props {
  activeTheme: string;
  leaderboard: StrategyLeaderboardItem[] | undefined;
  userInfo: UserInfo | undefined;
  paginationChunks: {
    start: number;
    stop: number;
  };
  attachStrategy: (strategyId: string, versionId: string) => void;
  homepage?: boolean;
  maxHeight?: number;
  ferrisWheelMode?: boolean;
  previewMode?: boolean;
  compact?: boolean;
}
export function PapertraderLeaderboard(props: React.PropsWithChildren<Props>) {
  const userHash = props.userInfo?.user_hash;

  return (
    <motion.div
      className="papertrader-leaderboard-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, maxHeight: props.maxHeight }}
      transition={{ duration: 0.2, delay: 1, delayChildren: 1 }}
    >
      <>
        {props.ferrisWheelMode ? (
          <>
            {props.leaderboard &&
              props.leaderboard
                .slice(0, props.previewMode ? 10 : 1)
                .map((leaderboardItem, index) => {
                  return (
                    <PapertraderLeaderboardItem
                      compact
                      homepage={props.homepage}
                      owned={leaderboardItem.user_hash === userHash}
                      index={index}
                      key={index}
                      leaderboardItem={leaderboardItem}
                      activeTheme={props.activeTheme}
                      attachStrategy={props.attachStrategy}
                    />
                  );
                })}
          </>
        ) : (
          <>
            {props.leaderboard &&
              props.leaderboard.map((leaderboardItem, index) => {
                return (
                  <PapertraderLeaderboardItem
                    homepage={props.homepage}
                    owned={leaderboardItem.user_hash === userHash}
                    index={index}
                    key={index}
                    leaderboardItem={leaderboardItem}
                    activeTheme={props.activeTheme}
                    attachStrategy={props.attachStrategy}
                  />
                );
              })}
          </>
        )}
      </>
    </motion.div>
  );
}
