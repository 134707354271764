import { Menu } from "@mantine/core";
import { FiPlay, FiTrash2 } from "react-icons/fi";
import { useContext, useMemo } from "react";
import { SetEditModeContext } from "./case-control/CaseControl";
import { AiOutlineCode } from "react-icons/ai";
import {
  SetCriteriaTestResourceContext,
  CriteriaTestResourceContext,
} from "../../pages/strategy-page/StrategyPage";
import { CandleSize } from "./StrategyHelperModal";

interface Props {
  type: "exit" | "entry";
  candleSize: string;
  control: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  index: number;
  criteria: string;
  criteriaListLength: number;
  openMicrotest: (
    _criteria: string,
    criteriaType: string,
    _candleSize: string
  ) => void;
  deleteCriteria: (
    _criteria: string,
    _criteriaType: string,
    _candleSize: string
  ) => void;
  isAi: boolean;
}
export function CriteriaSubMenu(props: React.PropsWithChildren<Props>) {
  const setEditMode = useContext(SetEditModeContext);
  const setCriteriaTestResource = useContext(SetCriteriaTestResourceContext);
  const criteriaTestResource = useContext(CriteriaTestResourceContext);

  const testableCriteria = useMemo(() => {
    if (props.type === "entry") return true;
    const untestableVariables = ["PROFIT_PCT", "BUY_PRICE", "PEAK_PRICE"];
    for (const variable of untestableVariables) {
      if (props.criteria.includes(variable)) return false;
    }
    return true;
  }, [props.criteria, props.type]);
  const iconSize = 20;
  return (
    <Menu
      key={`${props.type}-${props.candleSize}-menu`}
      control={props.control}
      className="criteria-menu"
      withArrow
    >
      <Menu.Item
        color={"indigo"}
        icon={<AiOutlineCode size={iconSize} />}
        onClick={(e: any) => {
          e.stopPropagation();
          setEditMode(props.criteria, props.type, props.candleSize);
        }}
        disabled={props.isAi}
      >
        Edit criteria
      </Menu.Item>
      <Menu.Item
        color={"cyan"}
        icon={<FiPlay size={iconSize} />}
        onClick={(e: any) => {
          e.stopPropagation();
          setCriteriaTestResource({
            ...criteriaTestResource,
            candleSize: props.candleSize as CandleSize,
            [`${props.type}Criteria`]: props.criteria,
          });
        }}
        disabled={
          !testableCriteria ||
          (criteriaTestResource !== undefined &&
            props.candleSize !== criteriaTestResource?.candleSize)
        }
      >
        {!testableCriteria ? "Not testable" : "Test criteria"}
      </Menu.Item>
      <Menu.Item
        color="red"
        icon={<FiTrash2 size={iconSize} />}
        onClick={(e: any) => {
          e.stopPropagation();
          props.deleteCriteria(props.criteria, props.type, props.candleSize);
        }}
        disabled={props.isAi}
      >
        Delete criteria
      </Menu.Item>
    </Menu>
  );
}
