import "./common-news-marquee.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { memo, useCallback, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { NewsMarqueeItem } from "./NewsMaqueeItem";
import { Avatar, Tooltip } from "@mantine/core";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import CountUp from "react-countup";
import { PiChartLineDown, PiChartLineUp } from "react-icons/pi";
import { useLocation } from "react-router-dom";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import { determineDecimals } from "../../../utils/FormattingUtils";

interface Props {
  activeTheme: string;
  traderType: TraderType;
}

const CommonNewsMarquee = memo(function CommonNewsMarquee(
  props: React.PropsWithChildren<Props>
) {
  //export function CommonNewsMarquee(props: React.PropsWithChildren<Props>) {
  const location = useLocation();
  const currencies = useContext(CurrencyContext);
  const { getAccessTokenSilently } = useAuth0();
  const tickerIconSize = 18;
  const fetchTickers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const blueprint = getBlueprintService(props.traderType);
    const response = await blueprint.getTickers(token);
    return response.data;
  }, [getAccessTokenSilently, props.traderType]);

  const tickersQuery = useQuery("tickers", fetchTickers, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 15 * 60 * 1000, // 15 minutes
    refetchInterval: 60 * 1000 * 2, // 2 minutes
  });

  const fetchFeed = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const blueprint = getBlueprintService(props.traderType);
    const response = await blueprint.getFeed(token);
    return response.data;
  }, [getAccessTokenSilently, props.traderType]);

  const feedQuery = useQuery("feed", fetchFeed, {
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 15 * 60 * 1000, // 15 minutes
    refetchInterval: 60 * 1000 * 5, // 2 minutes
  });

  const neotonGlobalNews = useMemo(() => {
    return (
      feedQuery.data?.filter((feedItem) => {
        return feedItem.feed === "global";
      }) ?? []
    );
  }, [feedQuery.data]);

  const locationSpecificNews = useMemo(() => {
    return (
      feedQuery.data?.filter((feedItem) => {
        return feedItem.feed === location.pathname.replace("/", "");
      }) ?? []
    );
  }, [feedQuery.data, location.pathname]);

  const news = useMemo(
    () => [...neotonGlobalNews, ...locationSpecificNews],
    [neotonGlobalNews, locationSpecificNews]
  );

  // Memoize the calculation for tickers
  const memoizedTickers = useMemo(() => {
    return (
      tickersQuery.data &&
      Object.keys(tickersQuery.data).map(
        (currencyName: string, idx: number) => {
          const currency = currencies?.find(
            (currency) => currency.currency_name === currencyName
          );
          if (!currency) return null;
          const ticker = tickersQuery.data?.[currencyName];
          if (!ticker) return null;
          const changePct = ticker.daily_change_relative * 100;
          const decimalCount = determineDecimals(ticker.last_price);

          return (
            <Tooltip
              key={idx}
              label={
                <label className="news-timestamp">
                  <strong>{currency.currency_name}</strong>
                  {" | "}
                  {new Date(ticker.timestamp).toUTCString()}
                </label>
              }
            >
              <NewsMarqueeItem
                index={idx}
                leftChild={
                  <Avatar
                    size="xs"
                    src={getCurrencyUrl(currency)}
                    radius={"lg"}
                  />
                }
                rightChild={
                  <div className="news-marquee-ticker">
                    <CountUp
                      className={"wallet-stat-label"}
                      end={ticker.last_price}
                      start={0}
                      preserveValue
                      duration={1}
                      prefix="$ "
                      decimals={decimalCount}
                    />
                    <label
                      className={
                        "marquee-ticker-icon" +
                        (changePct > 0 ? " up" : " down")
                      }
                    >
                      {changePct > 0 ? (
                        <PiChartLineUp size={tickerIconSize} />
                      ) : (
                        <PiChartLineDown size={tickerIconSize} />
                      )}
                    </label>
                    <CountUp
                      className={
                        !changePct
                          ? "wallet-stat-label"
                          : changePct > 0
                          ? "wallet-stat-label-profit"
                          : "wallet-stat-label-deficit"
                      }
                      end={changePct ?? 0}
                      start={0}
                      preserveValue
                      suffix=" %"
                      duration={1}
                      decimals={2}
                    />
                  </div>
                }
              />
            </Tooltip>
          );
        }
      )
    );
  }, [tickersQuery.data, currencies]);

  // Memoize the news items
  const memoizedNewsItems = useMemo(() => {
    return news.map((feedItem, idx) => {
      return (
        <Tooltip
          key={`feed-${idx}`}
          label={
            <label className="news-timestamp">
              {new Date(parseInt(feedItem.id)).toUTCString()}
            </label>
          }
        >
          <NewsMarqueeItem
            index={idx}
            leftChild={<label className={`news-e ${feedItem.feed}`}>E</label>}
            rightChild={
              <label className="news-message">{feedItem.message}</label>
            }
          />
        </Tooltip>
      );
    });
  }, [news]);

  return (
    <div className="news-marquee-container">
      <div className="news-marquee-mover">
        {memoizedTickers}
        {memoizedNewsItems}
      </div>
    </div>
  );
});

export default CommonNewsMarquee;
