import { createContext } from "react";

export interface IndicatorsInfo {
  ai_indicators: IndicatorsInfoItem;
  ai_indicator_series: {
    [key: string]: {
      ai_alias: string;
      alias_description: string;
      origin: string;
      candle_size_support: string[];
    };
  };
}

export interface IndicatorsInfoItem {
  [key: string]: {
    ai_name: string;
    ai_alias: string;
    description: string;
    origin: string;
  };
}

export const IndicatorInfosContext = createContext<IndicatorsInfo | undefined>(
  undefined
);
