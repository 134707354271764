import { createContext } from "react";
import { BacktestStageRequest } from "../../../interfaces/backtester/BacktestStageRequest";

export const SetBacktestStageRequestContext = createContext<
  (_stageRequest: BacktestStageRequest) => void
>(() => {});

export const GetBacktestStageRequestContext = createContext<
  BacktestStageRequest | undefined
>(undefined);
