import CountUp from "react-countup";
import React, { useContext } from "react";
import { StrategyVersionPaperwalletItem } from "../../../interfaces/common/UserStrategies";
import { calcPctChange, roundToTwo } from "../../../utils/FormattingUtils";
import { WalletValueChart } from "../../chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import { TierBadges } from "../../strategy-body/case-control/TierBadges";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mantine/hooks";
import { AiOutlineLineChart } from "react-icons/ai";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";

interface Props {
  activeTheme: string;
  paperwalletStats: StrategyVersionPaperwalletItem | undefined;
  strategyId: string | undefined;
  tier: string | undefined;
  isLive: boolean;
  loading: boolean;
  versionLoader?: React.ReactNode;
}

export function PaperwalletStatsContainer(
  props: React.PropsWithChildren<Props>
) {
  const emptyText = props.loading ? "" : "No data available";

  const compact = useMediaQuery("(max-width: 768px)");
  const currencies = useContext(CurrencyContext);

  const initialBalance =
    props.paperwalletStats?.wallet_value_snapshots?.valueSnapshots?.[0];
  const currentBalance =
    props.paperwalletStats?.wallet_value_snapshots?.valueSnapshots?.[
      props.paperwalletStats?.wallet_value_snapshots?.valueSnapshots?.length - 1
    ];

  const isWinning =
    initialBalance && currentBalance ? initialBalance < currentBalance : false;

  const pctChange =
    initialBalance && currentBalance
      ? calcPctChange(initialBalance, currentBalance)
      : 0;

  const maxCurrencyDisplay = compact ? 3 : 6;

  const versionCurrencies = currencies
    ?.filter((currency) =>
      props.paperwalletStats?.currencies.includes(currency.currency_name)
    )
    .slice(0, maxCurrencyDisplay);

  return (
    <>
      {props.paperwalletStats && props.strategyId && props.tier ? (
        <div className="version-paperwallet-stats-container">
          <div className="tier-currencies-container">
            <div className="upper-badges">
              <TierBadges
                tiersList={props.tier.split("/")}
                collectedCriterias={[]}
                size="xs"
              />
            </div>
            <CurrencyLogoArray
              currencies={versionCurrencies ?? []}
              previewAmount={maxCurrencyDisplay}
              size={"xs"}
              expandable
            />
          </div>

          {props.paperwalletStats?.wallet_value_snapshots?.valueSnapshots
            .length > 1 ? (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="version-paperwallet-chart-container"
              >
                <WalletValueChart
                  currencyPair={props.paperwalletStats.currency_pair}
                  isWinning={isWinning}
                  walletValueSnapshots={
                    props.paperwalletStats.wallet_value_snapshots
                  }
                  activeTheme={props.activeTheme}
                  marketStatistics={undefined}
                  animate={false}
                  compact
                  disableTooltip
                />
              </motion.div>
              <div className="change-container">
                <CountUp
                  className={
                    roundToTwo(pctChange) === 0.0
                      ? "wallet-stat-label"
                      : pctChange > 0
                      ? "wallet-stat-label-profit"
                      : "wallet-stat-label-deficit"
                  }
                  decimals={pctChange < 100 ? 2 : 0}
                  suffix="%"
                  preserveValue
                  duration={0}
                  end={pctChange}
                  start={0}
                />
              </div>
            </>
          ) : (
            <div className="data-unavailable-container">
              <AiOutlineLineChart size={25} />
              <label className="dimmed-label">Report unavailable</label>
            </div>
          )}
        </div>
      ) : (
        <>
          {props.versionLoader && <>{props.versionLoader}</>}
          <label>{emptyText}</label>
        </>
      )}
    </>
  );
}
