import { motion } from "framer-motion";
import { useCallback, useContext, useMemo } from "react";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";

interface Props {
  orderCount: number;
  wins: number;
  losses: number;
  toggle?: () => void;
  addOrRemoveFilter: (filter: string) => void;
  orderFilters: string[] | undefined;
  disabled?: boolean;
  compact?: boolean;
  toggled?: boolean;
}
export function CommonOrdersHeader(props: React.PropsWithChildren<Props>) {
  const isRunning = useContext(IsBacktestingContext);

  const winLossRatio = useMemo(() => {
    return props.wins / (props.wins + props.losses);
  }, [props.wins, props.losses]);

  const winPct = useMemo(() => {
    const pctValue = winLossRatio * 100;
    return pctValue.toFixed(2);
  }, [winLossRatio]);

  const lossPct = useMemo(() => {
    const pctValue = 100 - winLossRatio * 100;
    return pctValue.toFixed(2);
  }, [winLossRatio]);

  const shouldShowLossPct = useCallback(() => {
    if (props.disabled) return false;
    if (props.orderFilters?.includes("wins")) return false;
    if (props.orderFilters?.includes("losses")) return true;
    return (
      !props.orderFilters?.includes("losses") &&
      !props.orderFilters?.includes("wins") &&
      lossPct > winPct
    );
  }, [props.orderFilters, props.disabled, lossPct, winPct]);

  const shouldShowWinPct = useCallback(() => {
    if (props.disabled) return false;
    if (props.orderFilters?.includes("losses")) return false;
    if (props.orderFilters?.includes("wins")) return true;
    return (
      !props.orderFilters?.includes("losses") &&
      !props.orderFilters?.includes("wins") &&
      winPct > lossPct
    );
  }, [props.orderFilters, props.disabled, lossPct, winPct]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, delay: 0.2 }}
      onClick={() => {
        if (props.disabled && props.toggle) props.toggle();
      }}
      className="common-orders-header-stats-container"
    >
      {isRunning ? (
        <motion.label
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 1 }}
          className="dimmed-label"
        >
          Your orders will be listed here once the backtest has finished
        </motion.label>
      ) : props.orderCount === 0 &&
        props.orderFilters &&
        props.orderFilters.length === 0 ? (
        <motion.label
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, delay: 0.5 }}
          className="dimmed-label"
        >
          No orders
        </motion.label>
      ) : (
        <>
          {props.compact && !props.toggled && (
            <label onClick={props.toggle} className="orders-stats-main-header">
              {props.orderCount > 0
                ? `Orders (${props.orderCount})`
                : "No orders yet"}
            </label>
          )}
          <label
            onClick={() => {
              if (props.disabled) return;
              props.addOrRemoveFilter("wins");
            }}
            className={
              "orders-stats-header wins" +
              (props.orderFilters && props.orderFilters.includes("losses")
                ? " disabled"
                : "")
            }
          >
            {props.wins} wins
          </label>

          {props.losses > 0 || props.wins > 0 ? (
            <motion.div layout className="win-loss-bar-container">
              <motion.div
                className="win-bar"
                onClick={() => {
                  if (props.disabled) return;
                  props.addOrRemoveFilter("wins");
                }}
                initial={{ width: 0 }}
                animate={{
                  y: props.disabled ? 0 : 5,
                  width:
                    props.orderFilters && props.orderFilters.includes("losses")
                      ? `0%`
                      : props.orderFilters &&
                        props.orderFilters.includes("wins")
                      ? `100%`
                      : `${winPct}%`,
                }}
                transition={{
                  duration: 0.15,
                  delay: 0.3,
                  type: "tween",
                  easings: ["easeInOut"],
                }}
              >
                {shouldShowWinPct() ? (
                  <motion.label
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, y: -15 }}
                    transition={{ duration: 0.2, delay: 0.7 }}
                  >
                    {winPct} %
                  </motion.label>
                ) : undefined}
              </motion.div>
              <motion.div
                className="loss-bar"
                onClick={() => {
                  if (props.disabled) return;
                  props.addOrRemoveFilter("losses");
                }}
                initial={{ width: 0 }}
                animate={{
                  y: props.disabled ? 0 : -5,
                  width:
                    props.orderFilters && props.orderFilters.includes("wins")
                      ? `0%`
                      : props.orderFilters &&
                        props.orderFilters.includes("losses")
                      ? `100%`
                      : `${lossPct}%`,
                }}
                transition={{
                  duration: 0.15,
                  delay: 0.3,
                  type: "tween",
                  easings: ["easeInOut"],
                }}
              >
                {shouldShowLossPct() ? (
                  <motion.label
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, y: 9 }}
                    transition={{ duration: 0.2, delay: 0.7 }}
                  >
                    {lossPct} %
                  </motion.label>
                ) : undefined}
              </motion.div>
            </motion.div>
          ) : undefined}

          <label
            onClick={() => {
              if (props.disabled) return;
              props.addOrRemoveFilter("losses");
            }}
            className={
              "orders-stats-header losses" +
              (props.orderFilters && props.orderFilters.includes("wins")
                ? " disabled"
                : "")
            }
          >
            {props.losses} losses
          </label>
        </>
      )}
    </motion.div>
  );
}
