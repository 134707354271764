import Lottie from "react-lottie";
import { useState, useCallback, useEffect } from "react";

interface Props {
  animationData: any;
  introAnimationData: any;
}
export function IconMenuButton(props: React.PropsWithChildren<Props>) {
  const [didRender, setDidRender] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: props.animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const introAnimationOptions = { 
    loop: false,
    autoplay: true,
    animationData: props.introAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const changeDidRender = useCallback(() => {
    setTimeout(() => {
      setDidRender(false);
      setIsPaused(true);
    }, 1000);
  }, [setDidRender, setIsPaused]);

  useEffect(() => {
    if (didRender) {
      changeDidRender();
    }

    setIsPaused(isPaused);
    // cleanup function
    return () => {};
  }, [isPaused, setIsPaused, didRender, changeDidRender]);
  return (
    <div
      className="lottie-container"
      onClick={() => setIsPaused(false)}
      onMouseEnter={() => setIsPaused(false)}
      onMouseLeave={() => setIsPaused(true)}
    >
      {didRender ? (
        <Lottie options={introAnimationOptions} />
      ) : (
        <Lottie options={animationOptions} isStopped={isPaused} />
      )}
    </div>
  );
}
