import { MembershipCard } from "../home-page/home-page-cards/MembershipCard";
import "./activation-page.scss";

interface Props {
  activeTheme: string;
}
export function ActivationPage(props: React.PropsWithChildren<Props>) {
  return (
    <div className="activation-page">
      <MembershipCard
        activeTheme={props.activeTheme}
        setDefaultDashboardState={() => {}}
        isAnonymous={true}
      />
    </div>
  );
}
