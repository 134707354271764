import { Badge } from "@mantine/core";
import { CriteriaFragment } from "./criteria-fragments/CriteriaFragment";
import { FragmentType, getFragmentType } from "../../utils/ParseCriteriaUtil";
import { IndicatorsInfo } from "../../shared-service-contexts/IndicatorsInfoContext";
import { useContext, useMemo } from "react";
import { IndicatorsContext } from "../../shared-service-contexts/IndicatorContext";

interface Props {
  activeTheme: string;
  criteria: string;
  editor?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fragmentType?: FragmentType,
    index?: number
  ) => void;
  focusedIndex?: number;
  indicatorsInfo: IndicatorsInfo | undefined;
}

export function ParsedCriteria(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(IndicatorsContext);

  const patternCriteriaMap = useMemo(() => {
    return applicationIndicators?.pt_translations ?? {};
  }, [applicationIndicators]);

  const fragments =
    props.criteria && props.criteria.length > 0
      ? props.criteria.trim().split(" ")
      : [];

  const isStopLoss = props.criteria.toLowerCase().includes("stoploss");
  const isTakeProfit = props.criteria.toLowerCase().includes("take_profit");

  if (props.editor) {
    return (
      <>
        <div
          className="editable-criteria-fragment-container"
          onClick={(e) => {
            e.stopPropagation();
            props.onClick!(e);
          }}
        >
          {fragments.length > 0 &&
            fragments.map((fragment, index) => {
              if (applicationIndicators) {
                let forceDirection = false;
                if (index > 1) {
                  const previousFragment = fragments[index - 1];
                  const previousFragmentType = getFragmentType(
                    previousFragment,
                    applicationIndicators,
                    patternCriteriaMap
                  );
                  if (previousFragmentType.type === "OPERATOR") {
                    const secondLastFragment = fragments[index - 2];
                    const secondLastFragmentType = getFragmentType(
                      secondLastFragment,
                      applicationIndicators,
                      patternCriteriaMap
                    );
                    if (secondLastFragmentType.type === "DIRECTION") {
                      forceDirection = true;
                    }
                  }
                }
                const fragmentType = getFragmentType(
                  fragment,
                  applicationIndicators,
                  patternCriteriaMap,
                  forceDirection
                );

                return (
                  <div key={`${props.criteria}-${fragment}-${index}`}>
                    <div
                      className="editable-criteria-fragment"
                      id={
                        props.focusedIndex === index ? "focused-fragment" : ""
                      }
                      onContextMenu={(e) => {
                        e.stopPropagation();
                        props.onClick!(e, fragmentType, index);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onClick!(e, fragmentType, index);
                      }}
                      key={`${props.criteria}-${fragment}-${index}`}
                    >
                      <CriteriaFragment
                        indicators={applicationIndicators.indicators}
                        key={`${fragment}-${index}`}
                        activeTheme={props.activeTheme}
                        fragmentType={fragmentType}
                        index={index}
                      />
                    </div>
                  </div>
                );
              }
            })}
          {props.focusedIndex !== 0 && !props.focusedIndex && (
            <div className="criteria-fragment-cursor" />
          )}
        </div>
      </>
    );
  }
  if (isStopLoss) {
    return (
      <label className="immediate-action-critera-label stoploss">{props.criteria}</label>
    );
  }
  if (isTakeProfit) {
    return (
      <label className="immediate-action-critera-label take-profit">{props.criteria}</label>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      {fragments?.map((fragment, index) => {
        if (applicationIndicators) {
          let forceDirection = false;
          if (index > 1) {
            const previousFragment = fragments[index - 1];
            const previousFragmentType = getFragmentType(
              previousFragment,
              applicationIndicators,
              patternCriteriaMap
            );
            if (previousFragmentType.type === "OPERATOR") {
              const secondLastFragment = fragments[index - 2];
              const secondLastFragmentType = getFragmentType(
                secondLastFragment,
                applicationIndicators,
                patternCriteriaMap
              );
              if (secondLastFragmentType.type === "DIRECTION") {
                forceDirection = true;
              }
            }
          }
          const fragmentType = getFragmentType(
            fragment,
            applicationIndicators,
            patternCriteriaMap,
            forceDirection
          );
          return (
            <CriteriaFragment
              key={`${fragment}-${index}`}
              indicators={applicationIndicators.indicators}
              activeTheme={props.activeTheme}
              fragmentType={fragmentType}
              index={index}
            />
          );
        }
      })}
    </div>
  );
}

export function OrBadge() {
  return (
    <Badge
      className="fragment-badge-or"
      color="gray"
      size="sm"
      radius={"xs"}
      style={{ width: "fit-content" }}
    >
      or
    </Badge>
  );
}
