import {
  CurrentCoordinate,
  LineSeries,
} from "react-financial-charts";

interface Props {
  yAccessor: (data: any) => number;
  stroke: string;
}
export function EMALine(props: React.PropsWithChildren<Props>) {
  return (
    <>
      <LineSeries yAccessor={props.yAccessor} strokeStyle={props.stroke} />

      <CurrentCoordinate yAccessor={props.yAccessor} fillStyle={props.stroke} />
    </>
  );
}

export interface EMAOptions {
  yAccessor: (data: any) => number;
  type: string;
  stroke: string;
  windowSize: number;
}
