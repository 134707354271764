import "./strategy-version-icon.scss";
import { motion } from "framer-motion";
import { Identicon } from "./Identicon";
import React, { useContext, useMemo, useState } from "react";
import { CurrencyVibranceContext } from "../../../App";
import { getTheme } from "../../../utils/themeUtil";
import { Menu } from "@mantine/core";
import { IoCodeSlash, IoCodeWorkingOutline, IoWallet } from "react-icons/io5";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";
import { useNavigate } from "react-router-dom";
import { useClipboard } from "@mantine/hooks";
import { toTimestring } from "../../../utils/FormattingUtils";
import { VscHistory } from "react-icons/vsc";

interface Props {
  activeTheme: string;
  included_currencies: string[];
  strategy_id: string;
  version_id: string;
  name?: string;
  created_on?: number;
  size?: number;
  hideLabel?: boolean;
  disableMenu?: boolean;
  livetraderId?: string;
  menuPosition?: "left" | "right";
  compact?: boolean;
  primaryMenuButton?: React.ReactNode;
}
export function StrategyVersionIcon(props: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const isRunning = useContext(IsBacktestingContext);
  const size = props.size ?? 40;
  const fallbackColor = useMemo(() => {
    const theme = getTheme(props.activeTheme);
    return theme.cardInteriorAlt;
  }, [props.activeTheme]);

  const currencyVibrances = useContext(CurrencyVibranceContext);
  const colors: string[] = useMemo(() => {
    const fallbackColors = [fallbackColor];
    if (!currencyVibrances) return fallbackColors;
    if (!props.included_currencies) return fallbackColors;
    return props.included_currencies.map((currency) => {
      const vibrance = currencyVibrances[currency];
      if (!vibrance) return fallbackColor;
      return vibrance.darkMuted ?? fallbackColor;
    });
  }, [props.included_currencies, currencyVibrances, fallbackColor]);

  const hoverGradient = useMemo(() => {
    const previewColors = colors.slice(0, 8);
    if (previewColors.length < 2)
      return `linear-gradient(270deg, ${fallbackColor}, ${previewColors.join(
        ","
      )})`;
    return `linear-gradient(270deg, ${previewColors.join(",")})`;
  }, [colors, fallbackColor]);

  const [menuOpened, setMenuOpened] = useState(false);
  const versionIdClipboard = useClipboard({ timeout: 500 });

  return (
    <Menu
      onScroll={() => setMenuOpened(false)}
      withArrow
      opened={menuOpened}
      position={props.menuPosition ?? "right"}
      styles={{
        root: {
          alignSelf: "center",
        },
      }}
      control={
        <motion.div
          onClick={(e: any) => {
            if (props.disableMenu) return;
            e.stopPropagation();
            setMenuOpened(!menuOpened);
          }}
          whileHover={
            !props.disableMenu
              ? {
                  scale: 1.1,
                  rotate: 0,
                  filter: "grayscale(0%)",
                }
              : {}
          }
          whileTap={{ scale: 0.9 }}
          className={
            "strategy-version-icon-container" +
            (props.disableMenu ? " no-menu" : "")
          }
          initial={{ scale: 0, height: `${size}px`, width: `${size}px` }}
          animate={{
            scale: 1,
            rotate: 180,
            height: `${size}px`,
            width: `${size}px`,
            backgroundImage: hoverGradient,
            filter: "grayscale(50%)",
          }}
          exit={{ scale: 0 }}
        >
          <Identicon guid={props.version_id} size={size * 1.3} />
          <div
            className="strategy-version-icon-container-inner"
            style={{
              width: size,
              height: size,
            }}
          ></div>
          {versionIdClipboard.copied && (
            <motion.label
              initial={{ opacity: 0, rotate: 180, x: -20, y: 10 }}
              animate={{ opacity: 0.8, rotate: 180, x: -35, y: 35 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="copied-label"
            >
              copied
            </motion.label>
          )}
          {!props.hideLabel && props.name && (
            <label
              style={{
                border: `1px solid ${fallbackColor}`,
              }}
              className="strategy-version-name-label"
            >
              {props.name}
            </label>
          )}
        </motion.div>
      }
      onClose={() => setMenuOpened(false)}
    >
      {props.name && (
        <Menu.Label>
          <label className="menu-version-name-label">{props.name}</label>
        </Menu.Label>
      )}
      {props.livetraderId && (
        <Menu.Item
          color="blue"
          icon={<IoWallet />}
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/livetrader`, {
              state: { tradingAccountId: props.livetraderId },
            });
          }}
        >
          <label>Go to livetrader</label>
        </Menu.Item>
      )}

      {props.primaryMenuButton && props.primaryMenuButton}

      <Menu.Item
        disabled={isRunning}
        icon={<VscHistory />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate("/backtester", {
            state: {
              strategyId: props.strategy_id,
              versionId: props.version_id,
            },
          });
        }}
      >
        <label>Backtest</label>
      </Menu.Item>
      <Menu.Item
        disabled={isRunning || props.compact}
        icon={<IoCodeSlash />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate(`/strategy/${props.strategy_id}/${props.version_id}`);
        }}
      >
        <label>Edit</label>
      </Menu.Item>
      <Menu.Item
        onClick={(e: any) => {
          e.stopPropagation();
          versionIdClipboard.copy(props.version_id);
        }}
        icon={<IoCodeWorkingOutline />}
      >
        <label>Id: </label>
        <label className="dimmed-label version-id">
          {props.version_id.slice(0, 18)}...
        </label>
      </Menu.Item>
      {props.created_on ? (
        <Menu.Label>
          <label>
            Created: <strong>{toTimestring(props.created_on, "1h")}</strong>
          </label>
        </Menu.Label>
      ) : undefined}
    </Menu>
  );
}
