import "./compact-wallet-value.scss";
import { motion } from "framer-motion";
import { CommonWalletReport } from "../../../interfaces/common-trading/CommonTradingReport";
import CountUp from "react-countup";
import { roundToTwo } from "../../../utils/FormattingUtils";
import { Divider } from "@mantine/core";
import { useEffect, useState } from "react";
import { TraderType } from "../../../pages/common/TradingDashboard";

interface Props {
  activeTheme: string;
  commonWalletReport: CommonWalletReport | undefined;
  traderType: TraderType
}
export function CompactWalletValue(props: React.PropsWithChildren<Props>) {
  const initialBalance =
    props.commonWalletReport?.report?.initial_balance ?? 1000;

  const currentBalance = props.commonWalletReport?.report?.current_balance;
  const growth =
    currentBalance &&
    ((currentBalance - initialBalance) / initialBalance) * 100;

  const [prevGrowth, setPrevGrowth] = useState(growth);

  const nothingAttachedLabel = props.traderType.traderType === 'livetrader' ? "No Trading account attached" : "No Strategy attached"

  useEffect(() => {
    setTimeout(() => {
      setPrevGrowth(growth);
    }, 500);
    return () => {};
  }, [growth, setPrevGrowth]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, delay: 0.2 }}
      className="compact-wallet-value-container"
    >
      {props.commonWalletReport ? (
        <>
          <div className="compact-wallet-value-column">
            {growth ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: 0.2 }}
                className="compact-wallet-change-container"
              >
                <CountUp
                  className={
                    !growth
                      ? "wallet-stat-label"
                      : growth > 0
                      ? "wallet-stat-label-profit"
                      : "wallet-stat-label-deficit"
                  }
                  end={growth}
                  start={prevGrowth}
                  preserveValue
                  delay={0.2}
                  suffix=" %"
                  duration={1}
                  decimals={2}
                />
              </motion.div>
            ) : undefined}
          </div>
          <div className="compact-wallet-value-column">
            <div className="compact-wallet-value-row">
              <label>Initial: </label>
              <label className="wallet-stat-label-minor">
                $ {roundToTwo(initialBalance)}
              </label>
            </div>
            {currentBalance && (
              <div className="compact-wallet-value-row">
                <label>Current: </label>
                <label className="wallet-stat-label">
                  $ {roundToTwo(currentBalance)}
                </label>
              </div>
            )}
          </div>
        </>
      ) : <div className="compact-wallet-value-column">
        <label className="dimmed-label">{nothingAttachedLabel}</label>
        </div>}
      <Divider orientation="vertical" />
    </motion.div>
  );
}
