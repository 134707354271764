import { IOHLCData } from "./iOHLCData";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import {
  MACDSeries,
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  BarSeries,
  MovingAverageTooltip,
  OHLCTooltip,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateY,
  RSITooltip,
  BollingerSeries,
  BollingerBandTooltip,
  HoverTooltip,
  MACDTooltip,
  Annotate,
  SvgPathAnnotation,
  LabelAnnotation,
  mouseBasedZoomAnchor,
  LineSeries,
  SingleValueTooltip,
  CandlestickSeries,
} from "react-financial-charts";
import {
  rsi,
  bollingerBand,
  ema,
  macd,
  atr,
} from "@react-financial-charts/indicators";
import { RSISeries } from "@react-financial-charts/series";
import { EMALine } from "./stonk-chart-indicators/EMALine";
import { useCallback, useContext, useMemo, useState } from "react";

import { ChartSettings } from "../../../interfaces/chartIndicators/ChartSettings";
import { getTheme } from "../../../utils/themeUtil";
import { Order } from "../../../interfaces/backtester/BacktestTradingReport";
import { CandleSizeContext } from "../../../pages/common/TradingDashboard";
import ErrorBoundary from "../../common/ErrorBoundary";
import { determineDecimals } from "../../../utils/FormattingUtils";
import { CriteriaGenerationPoint } from "../../strategy-body/StrategyChartContainer";

interface Props {
  activeTheme: string;
  candleSize?: string;
  data: IOHLCData[];
  height: number;
  dateTimeFormat?: string;
  width: number;
  ratio: number;
  handleChartClick?: (dateDataPoint: number) => void;
  chartSettings: ChartSettings;
  orders?: Order[];
  initialRunningTime?: number;
  criteriaGenerationPoints?: CriteriaGenerationPoint[];
}
export function StonkChart(props: React.PropsWithChildren<Props>) {
  const margin = { left: 0, right: 63, top: 0, bottom: 5 };
  const pricesDisplayFormat = useMemo(() => {
    // lets get the average close price
    const closePrices = props.data.map((d) => d.close);
    const sum = closePrices.reduce((a, b) => a + b, 0);
    const average = sum / closePrices.length;
    const decimalCount = determineDecimals(average);
    return format(`.${decimalCount}f`);
  }, [props.data]);

  const candleSizeContext = useContext(CandleSizeContext);
  const candleSize = props.candleSize ?? candleSizeContext ?? "1D";
  const theme = getTheme(props.activeTheme);

  const xScaleProvider =
    discontinuousTimeScaleProviderBuilder().inputDateAccessor(
      (d: IOHLCData | undefined) => (d ? d.date : new Date())
    );

  const initialData = props.data;

  const [dateDataPoint, setDateDataPoint] = useState<number>();

  const ema200Indicator = useMemo(() => {
    return ema()
      .id(1)
      .options({ windowSize: 200 })
      .merge((d: any, c: any) => {
        d.ema200 = c;
      })
      .accessor((d: any) => d?.ema200);
  }, []);

  const ema100Indicator = useMemo(() => {
    return ema()
      .id(2)
      .options({ windowSize: 100 })
      .merge((d: any, c: any) => {
        d.ema100 = c;
      })
      .accessor((d: any) => d?.ema100);
  }, []);

  const ema20Indicator = useMemo(() => {
    return ema()
      .id(2)
      .options({ windowSize: 20 })
      .merge((d: any, c: any) => {
        d.ema20 = c;
      })
      .accessor((d: any) => d?.ema20);
  }, []);

  const ema50Indicator = useMemo(() => {
    return ema()
      .id(2)
      .options({ windowSize: 50 })
      .merge((d: any, c: any) => {
        d.ema50 = c;
      })
      .accessor((d: any) => d?.ema50);
  }, []);

  const rsiIndicator = useMemo(() => {
    return rsi()
      .options({ windowSize: 14 })
      .id(0)
      .merge((d: any, c: any) => {
        d.rsi = c;
      })
      .accessor((d: any) => d?.rsi);
  }, []);

  const bbandIndicator = useMemo(() => {
    return bollingerBand()
      .merge((d: any, c: any) => {
        d.bb = c;
      })
      .accessor((d: any) => d?.bb);
  }, []);

  const macdIndicator = useMemo(() => {
    return macd()
      .options({
        fast: 12,
        signal: 9,
        slow: 26,
      })
      .merge((d: any, c: any) => {
        d.macd = c;
      })
      .accessor((d: any) => d?.macd);
  }, []);

  const atrIndicator = useMemo(() => {
    return atr()
      .options({ windowSize: 14 })
      .merge((d: any, c: any) => {
        d.atr = c;
      })
      .accessor((d: any) => d?.atr)
      .stroke(props.chartSettings.atrSettings.atrColor);
  }, [props.chartSettings.atrSettings]);

  const getEmaOptions = () => {
    const opts: any = [];
    if (props.chartSettings.emaSettings) {
      if (props.chartSettings.emaSettings.ema20enabled) {
        opts.push({
          yAccessor: ema20Indicator.accessor(),
          type: "EMA",
          stroke: props.chartSettings.emaSettings.ema20Color,
          windowSize: 20,
        });
      }
      if (props.chartSettings.emaSettings.ema50enabled) {
        opts.push({
          yAccessor: ema50Indicator.accessor(),
          type: "EMA",
          stroke: props.chartSettings.emaSettings.ema50Color,
          windowSize: 50,
        });
      }
      if (props.chartSettings.emaSettings.ema100enabled) {
        opts.push({
          yAccessor: ema100Indicator.accessor(),
          type: "EMA",
          stroke: props.chartSettings.emaSettings.ema100Color,
          windowSize: 100,
        });
      }
      if (props.chartSettings.emaSettings.ema200enabled) {
        opts.push({
          yAccessor: ema200Indicator.accessor(),
          type: "EMA",
          stroke: props.chartSettings.emaSettings.ema200Color,
          windowSize: 200,
        });
      }
    }
    return opts;
  };

  const emaTooltipOptions = getEmaOptions();

  const calculatedData = macdIndicator(
    bbandIndicator(
      rsiIndicator(
        ema50Indicator(
          ema20Indicator(
            ema100Indicator(ema200Indicator(atrIndicator(initialData)))
          )
        )
      )
    )
  );

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(calculatedData);

  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max + 5];

  const ChartAny = Chart as any;

  const candleChartExtents = (data: IOHLCData) => {
    return [data.high, data.low];
  };

  const yEdgeIndicator = (data: IOHLCData) => {
    return data.close;
  };

  const volumeColor = (data: IOHLCData) => {
    return data.close > data.open ? theme.buyOrderAreaY : theme.sellOrderAreaY;
  };

  const volumeSeries = (data: IOHLCData) => {
    return data.volume;
  };

  const openCloseColor = (data: IOHLCData) => {
    return data.close > data.open ? theme.buyOrderAreaY : theme.sellOrderAreaY;
  };

  const dateFormat =
    candleSize === "5m"
      ? timeFormat("%Y-%m-%d %H:%M")
      : candleSize === "1h"
      ? timeFormat("%Y-%m-%d %H:%M")
      : timeFormat("%Y-%m-%d");

  const setDateFormat = useCallback(
    (arg: Date) => {
      setDateDataPoint(arg.getTime());
      return dateFormat(arg);
    },
    [dateFormat, setDateDataPoint]
  );

  const buyOrders = useMemo(() => {
    if (!props.orders) return;
    return props.orders.filter((order) => order.id.includes("buy"));
  }, [props.orders]);

  const buyOrderDates = useMemo(() => {
    if (!buyOrders) return;
    const _candleSize = candleSize ?? "1D";
    return buyOrders.map((order) =>
      parseInt(order.timestamp_references[`candle_size_${_candleSize}`])
    );
  }, [buyOrders, candleSize]);

  const sellOrders = useMemo(() => {
    if (!props.orders) return;
    return props.orders.filter((order) => order.id.includes("sell"));
  }, [props.orders]);

  const sellOrderDates = useMemo(() => {
    if (!sellOrders) return;
    const _candleSize = candleSize ?? "1D";
    return sellOrders.map((order) =>
      parseInt(order.timestamp_references[`candle_size_${_candleSize}`])
    );
  }, [sellOrders, candleSize]);

  const buyCriteriaGenerationTimestamps = useMemo(() => {
    if (!props.criteriaGenerationPoints) return;
    return props.criteriaGenerationPoints
      .filter((point) => point.side === "entry")
      .map((point) => point.timestamp);
  }, [props.criteriaGenerationPoints]);

  const sellCriteriaGenerationTimestamps = useMemo(() => {
    if (!props.criteriaGenerationPoints) return;
    return props.criteriaGenerationPoints
      .filter((point) => point.side === "exit")
      .map((point) => point.timestamp);
  }, [props.criteriaGenerationPoints]);

  const candleOrderIdMap = useMemo(() => {
    const idMap: {
      [key: string]: { buyOrders?: Order[]; sellOrders?: Order[] };
    } = {};
    if (!buyOrderDates || !sellOrderDates) return idMap;
    data.forEach((candle) => {
      const candleKey = `${candle.ts}`;
      if (buyOrderDates.includes(candle.date.getTime()))
        idMap[candleKey] = {
          buyOrders: buyOrders?.filter(
            (order) =>
              parseInt(
                order.timestamp_references[`candle_size_${candleSize}`]
              ) === candle.date.getTime()
          ),
        };
      if (sellOrderDates.includes(candle.date.getTime()))
        idMap[candleKey] = {
          ...idMap[candleKey],
          sellOrders: sellOrders?.filter(
            (order) =>
              parseInt(
                order.timestamp_references[`candle_size_${candleSize}`]
              ) === candle.date.getTime()
          ),
        };
    });
    return idMap;
  }, [buyOrderDates, sellOrderDates, data, buyOrders, sellOrders, candleSize]);

  const getBuyOrdersFromCandleKey = useCallback(
    (candleKey: string) => {
      if (!candleOrderIdMap[candleKey]?.buyOrders) return;
      return candleOrderIdMap[candleKey]?.buyOrders;
    },
    [candleOrderIdMap]
  );

  const getSellOrdersFromCandleKey = useCallback(
    (candleKey: string) => {
      if (!candleOrderIdMap[candleKey]?.sellOrders) return;
      return candleOrderIdMap[candleKey]?.sellOrders;
    },
    [candleOrderIdMap]
  );

  const placeSellOrderAnnotations = useCallback(
    (data: IOHLCData) => {
      if (!sellOrders || !sellOrderDates) return false;
      return sellOrderDates!.includes(data.date.getTime());
    },
    [sellOrders, sellOrderDates]
  );

  const placeBuyOrderAnnotations = useCallback(
    (data: IOHLCData) => {
      if (!buyOrders || !buyOrderDates) return false;
      return buyOrderDates!.includes(data.date.getTime());
    },
    [buyOrders, buyOrderDates]
  );

  const placeBuyPointAnnotations = useCallback(
    (data: IOHLCData) => {
      if (!props.criteriaGenerationPoints || !buyCriteriaGenerationTimestamps)
        return false;

      if (
        props.criteriaGenerationPoints.some(
          (point) => point.side === "entry" && point.candle_size === candleSize
        )
      ) {
        return buyCriteriaGenerationTimestamps.includes(data.date.getTime());
      }
      return false;
    },
    [
      props.criteriaGenerationPoints,
      candleSize,
      buyCriteriaGenerationTimestamps,
    ]
  );

  const placeSellPointAnnotations = useCallback(
    (data: IOHLCData) => {
      if (!props.criteriaGenerationPoints || !sellCriteriaGenerationTimestamps)
        return false;

      if (
        props.criteriaGenerationPoints.some(
          (point) => point.side === "exit" && point.candle_size === candleSize
        )
      ) {
        return sellCriteriaGenerationTimestamps.includes(data.date.getTime());
      }
      return false;
    },
    [
      props.criteriaGenerationPoints,
      candleSize,
      sellCriteriaGenerationTimestamps,
    ]
  );

  const candlesColorMap = useMemo(() => {
    const colorMap: { [key: string]: string } = {};
    colorMap["default"] = theme.text;
    if (!props.data) return colorMap;
    props.data.forEach((candle) => {
      const keyValue = `${candle.ts}`;
      const subtleKeyValue = `subtle_${keyValue}`;
      if (
        props.initialRunningTime &&
        candle.date.getTime() < props.initialRunningTime
      ) {
        colorMap[keyValue] = theme.cardInterior;
        colorMap[subtleKeyValue] = theme.cardInterior;
        return;
      }

      colorMap[keyValue] =
        candle.close > candle.open
          ? theme.buyOrderStroke
          : theme.sellOrderStroke;
      colorMap[subtleKeyValue] =
        candle.close > candle.open
          ? theme.subtleBuyOrderStroke
          : theme.subtleSellOrderStroke;
    });
    return colorMap;
  }, [theme, props.data, props.initialRunningTime]);

  const candlesWickColorMap = useMemo(() => {
    const colorMap: { [key: string]: string } = {};
    colorMap["default"] = theme.text;
    if (!props.data) return colorMap;
    props.data.forEach((candle) => {
      const keyValue = `${candle.ts}`;
      const subtleKeyValue = `subtle_${keyValue}`;
      if (
        props.initialRunningTime &&
        candle.date.getTime() < props.initialRunningTime
      ) {
        colorMap[keyValue] = theme.cardInterior;
        colorMap[subtleKeyValue] = theme.cardInterior;
        return;
      }
      if (
        (buyCriteriaGenerationTimestamps &&
          buyCriteriaGenerationTimestamps.includes(candle.ts)) ||
        (sellCriteriaGenerationTimestamps &&
          sellCriteriaGenerationTimestamps.includes(candle.ts))
      ) {
        colorMap[keyValue] = theme.text;
        colorMap[subtleKeyValue] = theme.text;
        return;
      }
      colorMap[keyValue] =
        candle.close > candle.open
          ? theme.buyOrderStroke
          : theme.sellOrderStroke;
      colorMap[subtleKeyValue] =
        candle.close > candle.open
          ? theme.subtleBuyOrderStroke
          : theme.subtleSellOrderStroke;
    });
    return colorMap;
  }, [
    theme,
    props.data,
    props.initialRunningTime,
    buyCriteriaGenerationTimestamps,
    sellCriteriaGenerationTimestamps,
  ]);

  const getCandleColor = useCallback(
    (candle: any) => {
      const keyValue = `${candle.ts}`;
      const subtleKeyValue = `subtle_${keyValue}`;
      return candlesColorMap[
        props.chartSettings.orderSettings?.enabled ||
        (props.criteriaGenerationPoints &&
          props.criteriaGenerationPoints.length > 0)
          ? subtleKeyValue
          : keyValue
      ];
    },
    [
      candlesColorMap,
      props.chartSettings.orderSettings,
      props.criteriaGenerationPoints,
    ]
  );

  const getCandleWickColor = useCallback(
    (candle: any) => {
      const keyValue = `${candle.ts}`;
      const subtleKeyValue = `subtle_${keyValue}`;
      return candlesWickColorMap[
        props.chartSettings.orderSettings?.enabled ? subtleKeyValue : keyValue
      ];
    },
    [candlesWickColorMap, props.chartSettings.orderSettings]
  );
  /** @type {*} */
  const sellLabelAnnotation = props.chartSettings.orderSettings?.enabled
    ? {
        y: ({ yScale, datum }: any) => {
          const candleKey = `${datum.ts}`;
          if (candleOrderIdMap[candleKey]?.sellOrders) {
            const sellOrders = candleOrderIdMap[candleKey]?.sellOrders;
            const sellOrder = sellOrders![0];
            if (sellOrder) {
              const yMultiplier =
                sellOrder.buy_price > sellOrder.sell_price! ? 0.99 : 1.001;
              return yScale(sellOrder.sell_price! * yMultiplier);
            }
          }

          yScale(datum.close);
        },
        text: (datum: any) => {
          const candleKey = `${datum.ts}`;
          if (candleOrderIdMap[candleKey]?.sellOrders) {
            const sellOrders = candleOrderIdMap[candleKey]?.sellOrders;
            const sellOrder = sellOrders![0];
            if (sellOrder) {
              const sellOrderPrice = sellOrder.sell_price!;
              const change =
                ((sellOrderPrice - sellOrder.buy_price) / sellOrder.buy_price) *
                100;
              const prefix = sellOrder.buy_price < sellOrderPrice ? "+" : "";
              return `${prefix}${change.toFixed(2)}%`;
            }
          }
        },
        fill: (datum: any) => {
          const candleKey = `${datum.ts}`;
          if (candleOrderIdMap[candleKey]?.sellOrders) {
            const sellOrders = candleOrderIdMap[candleKey]?.sellOrders;
            const sellOrder = sellOrders![0];
            if (sellOrder) {
              const sellOrderPrice = sellOrder.sell_price!;
              return sellOrder.buy_price > sellOrderPrice
                ? theme.sellOrderStrokeHover
                : theme.buyOrderStrokeHover;
            }
          }
        },
        textAnchor: "left",
        fontSize: 14,
        fontFamily: "IBMPlexMono-Medium",
      }
    : undefined;

  // source: https://www.svgrepo.com/collection/collections-icons/3
  // compressed with https://jakearchibald.github.io/svgomg/

  const singleEntrySvg = "M4.59 3.41 9.17 8l-4.58 4.59L6 14l6-6-6-6z";

  const sellCriteriaGenerationSvgValue =
    "M16 15a1 1 0 0 1-.707-.293L12 11.414l-3.293 3.293a1 1 0 1 1-1.414-1.414l4-4a1 1 0 0 1 1.414 0l4 4A1 1 0 0 1 16 15z";
  const buyCriteriaGenerationSvgValue =
    "M12 15a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L12 12.586l3.293-3.293a1 1 0 0 1 1.414 1.414l-4 4A1 1 0 0 1 12 15z";
  const entrySvg =
    "M6.59 3.41 11.17 8 6.6 12.59 8 14l6-6-6-6-1.41 1.41Zm2.24 4.42v.34l-.25.25v-.84l.25.25Zm-.5-.5v1.34l-.25.25V7.08l.25.25Zm-.5-.5v2.34l-.25.25V6.58l.25.25Zm-.5-.5v3.34l-.25.25V6.08l.25.25Zm-.5-.5v4.34l-.25.25V5.58l.25.25Zm-.5-.5v5.34l-.25.25V8.1l.1-.09-.1-.1V5.09l.25.25Zm-.5 3.01v2.83l-.25.25V8.6l.25-.25Zm0-3.51v2.83l-.25-.25V4.58l.25.25Zm-.5 4.01v2.83l-.25.25V9.1l.25-.25Zm0-4.51v2.83l-.25-.25V4.08l.25.25Zm-.5 5.01v2.83l-.25.25V9.6l.25-.25Zm0-5.51v2.83l-.25-.25V3.58l.25.25Zm-.5 6.01v2.83l-.25.25V10.1l.25-.25Zm0-6.51v2.83l-.25-.25V3.08l.25.25Zm-.5 7.01v2.83l-.25.25V10.6l.25-.25Zm0-7.51v2.83l-.25-.25V2.58l.25.25Zm-.5 8.01v2.83l-.25.25V11.1l.25-.25Zm0-8.51v2.83l-.25-.25V2.08l.25.25Zm-.5 9.01v2.49l-.25-.25v-1.99l.25-.25Zm0-9.17v2.49l-.25-.25V2.42l.25-.25Zm-.5 9.67v1.49l-.24-.24v-1l.24-.25Zm0-9.17v1.49l-.24-.25v-1l.24-.24Zm-.5 9.67v.5l-.24-.25.25-.25Zm0-9.18v.5l-.24-.25.25-.25Z";
  const singleExitWinSvg = "M3.41 11.41 8 6.83l4.59 4.58L14 10 8 4l-6 6z";
  const exitWinSvg =
    "M3.41 9.41 8 4.83l4.59 4.58L14 8 8 2 2 8l1.41 1.41Zm4.42-2.24h.34l.25.25h-.84l.25-.25Zm-.5.5h1.34l.25.25H7.08l.25-.25Zm-.5.5h2.34l.25.25H6.58l.25-.25Zm-.5.5h3.34l.25.25H6.08l.25-.25Zm-.5.5h4.34l.25.25H5.58l.25-.25Zm-.5.5h5.34l.25.25H8.1L8 9.82l-.1.1H5.09l.25-.25Zm3.01.5h2.83l.25.25H8.6l-.25-.25Zm-3.51 0h2.83l-.25.25H4.58l.25-.25Zm4.01.5h2.83l.25.25H9.1l-.25-.25Zm-4.51 0h2.83l-.25.25H4.08l.25-.25Zm5.01.5h2.83l.25.25H9.6l-.25-.25Zm-5.51 0h2.83l-.25.25H3.58l.25-.25Zm6.01.5h2.83l.25.25H10.1l-.25-.25Zm-6.51 0h2.83l-.25.25H3.08l.25-.25Zm7.01.5h2.83l.25.25H10.6l-.25-.25Zm-7.51 0h2.83l-.25.25H2.58l.25-.25Zm8.01.5h2.83l.25.25H11.1l-.25-.25Zm-8.51 0h2.83l-.25.25H2.08l.25-.25Zm9.01.5h2.49l-.25.25h-1.99l-.25-.25Zm-9.17 0h2.49l-.25.25H2.42l-.25-.25Zm9.67.5h1.49l-.24.24h-1l-.25-.24Zm-9.17 0h1.49l-.25.24h-1l-.24-.24Zm9.67.5h.5l-.25.24-.25-.25Zm-9.18 0h.5l-.25.24-.25-.25Z";
  const singleExitLossSvg = "M12.59 4.59 8 9.17 3.41 4.59 2 6l6 6 6-6z";
  const exitLossSvg =
    "M12.59 6.59 8 11.17 3.41 6.6 2 8l6 6 6-6-1.41-1.41ZM8.17 8.83h-.34l-.25-.25h.84l-.25.25Zm.5-.5H7.33l-.25-.25h1.84l-.25.25Zm.5-.5H6.83l-.25-.25h2.84l-.25.25Zm.5-.5H6.33l-.25-.25h3.84l-.25.25Zm.5-.5H5.83l-.25-.25h4.84l-.25.25Zm.5-.5H5.33l-.25-.25H7.9l.09.1.1-.1h2.82l-.25.25Zm-3.01-.5H4.83l-.25-.25H7.4l.25.25Zm3.51 0H8.34l.25-.25h2.83l-.25.25Zm-4.01-.5H4.33l-.25-.25H6.9l.25.25Zm4.51 0H8.84l.25-.25h2.83l-.25.25Zm-5.01-.5H3.83l-.25-.25H6.4l.25.25Zm5.51 0H9.34l.25-.25h2.83l-.25.25Zm-6.01-.5H3.33l-.25-.25H5.9l.25.25Zm6.51 0H9.84l.25-.25h2.83l-.25.25Zm-7.01-.5H2.83l-.25-.25H5.4l.25.25Zm7.51 0h-2.83l.25-.25h2.83l-.25.25Zm-8.01-.5H2.33l-.25-.25H4.9l.25.25Zm8.51 0h-2.83l.25-.25h2.83l-.25.25Zm-9.01-.5H2.17l.25-.25h1.99l.25.25Zm9.17 0h-2.49l.25-.25h1.99l.25.25Zm-9.67-.5H2.67l.24-.24h1l.25.24Zm9.17 0h-1.49l.25-.24h1l.24.24Zm-9.67-.5h-.5l.25-.24.25.25Zm9.18 0h-.5l.25-.24.25.25Z";

  const buyCriteriaGeneratorSvg = {
    fill: theme.buyOrderStrokeHover,

    path: (datum: any) => {
      return buyCriteriaGenerationSvgValue;
    },
    pathWidth: 12,
    pathHeight: 20,
    tooltip: "Buy Criteria Generation Point",
    y: ({ yScale, datum }: any) => {
      return yScale(datum.close);
    },
  };

  const sellCriteriaGeneratorSvg = {
    fill: theme.sellOrderStrokeHover,
    path: (datum: any) => {
      return sellCriteriaGenerationSvgValue;
    },
    pathWidth: 12,
    pathHeight: 2,
    tooltip: "Sell Criteria Generation Point",
    y: ({ yScale, datum }: any) => {
      return yScale(datum.close);
    },
  };

  const buySvgAnnotation = useMemo(() => {
    if (!props.chartSettings.orderSettings?.enabled) return undefined;

    return {
      fill: theme.buyOrderStrokeHover,
      path: (datum: any) => {
        const candleKey = `${datum.ts}`;
        const buyOrders = getBuyOrdersFromCandleKey(candleKey);
        const sellOrders = getSellOrdersFromCandleKey(candleKey);
        return (buyOrders && buyOrders.length > 1) || sellOrders
          ? entrySvg
          : singleEntrySvg;
      },
      pathWidth: 15,
      pathHeight: 0,
      tooltip: "Buy Order(s)",
      y: ({ yScale, datum }: any) => {
        const candleKey = `${datum.ts}`;
        const buyOrders = getBuyOrdersFromCandleKey(candleKey);
        const buyOrder = buyOrders![0];

        return buyOrder ? yScale(buyOrder.buy_price) : yScale(datum.close);
      },
    };
  }, [
    props.chartSettings.orderSettings,
    theme.buyOrderStrokeHover,
    getBuyOrdersFromCandleKey,
    getSellOrdersFromCandleKey,
    entrySvg,
    singleEntrySvg,
  ]);

  const sellSvgAnnotation = useMemo(() => {
    if (!props.chartSettings.orderSettings?.enabled) return undefined;

    return {
      fill: theme.sellOrderStrokeHover,
      path: (datum: any) => {
        const candleKey = `${datum.ts}`;
        const sellOrders = getSellOrdersFromCandleKey(candleKey);
        const sellOrder = sellOrders![0];
        if (sellOrders && sellOrders.length === 1 && sellOrder)
          return sellOrder.buy_price > sellOrder.sell_price!
            ? singleExitLossSvg
            : singleExitWinSvg;
        if (sellOrder) {
          return sellOrder.buy_price > sellOrder.sell_price!
            ? exitLossSvg
            : exitWinSvg;
        }
      },
      pathWidth: 8,
      pathHeight: 10,
      tooltip: "Sell Order(s)",
      y: ({ yScale, datum }: any) => {
        const candleKey = `${datum.ts}`;
        const sellOrders = getSellOrdersFromCandleKey(candleKey);
        const sellOrder = sellOrders![0];
        return sellOrder ? yScale(sellOrder.sell_price) : yScale(datum.close);
      },
    };
  }, [
    props.chartSettings.orderSettings?.enabled,
    theme.sellOrderStrokeHover,
    getSellOrdersFromCandleKey,
    singleExitLossSvg,
    singleExitWinSvg,
    exitLossSvg,
    exitWinSvg,
  ]);

  const chartXOrigin = 0;
  const chartWeights = {
    MACD: props.chartSettings.macdSettings?.macdEnabled ? 1.5 : 0, // Example weight
    VOLUME: props.chartSettings.showVolume ? 1 : 0,
    RSI: props.chartSettings.rsiSettings?.rsiEnabled ? 0.7 : 0,
    ATR: props.chartSettings.atrSettings?.atrEnabled ? 0.8 : 0,
    MAIN: 4,
  };

  const totalWeight = Object.values(chartWeights).reduce(
    (acc, weight) => acc + weight,
    0
  );

  const calculateHeight = (chartType: keyof typeof chartWeights) => {
    return (props.height * chartWeights[chartType]) / totalWeight;
  };

  const calculateOrigin = (chartsAbove: string[]) => {
    let yOffset = 0;
    chartsAbove.forEach((chartId) => {
      yOffset += calculateHeight(chartId as any);
    });
    return [chartXOrigin, yOffset]; // Assuming chartXOrigin is your horizontal origin
  };

  const rsiChartOrigin = calculateOrigin([]);
  const atrChartOrigin = calculateOrigin(
    props.chartSettings.rsiSettings?.rsiEnabled ? ["RSI"] : []
  );
  const mainChartHeight = calculateHeight("MAIN");
  const mainChartOrigin = calculateOrigin(
    [
      ...(props.chartSettings.atrSettings?.atrEnabled ? ["ATR"] : []),
      ...(props.chartSettings.rsiSettings?.rsiEnabled ? ["RSI"] : []),
    ].filter(Boolean) // Filter out any falsey values
  );
  const macdChartHeight = calculateHeight("MACD");
  const macdChartOrigin = calculateOrigin([
    "MAIN",
    ...(props.chartSettings.atrSettings?.atrEnabled ? ["ATR"] : []),
    ...(props.chartSettings.rsiSettings?.rsiEnabled ? ["RSI"] : []),
    // Add other charts that are between MAIN and VOLUME
  ]);

  const volumeChartOrigin = calculateOrigin(["RSI", "ATR", "MAIN", "MACD"]);

  function calculateTooltipOrigin(
    chartId: string,
    additionalOffset: number = 0
  ): [number, number] {
    const chartXOrigin = 8; // Replace with actual x origin for your charts
    let chartYOrigin = 0; // Replace with actual y origin for the specific chart

    // Example: Adjust chartYOrigin based on the chartId
    switch (chartId) {
      case "MAIN":
        chartYOrigin = mainChartOrigin[1];
        break;
      case "VOLUME":
        chartYOrigin = mainChartOrigin[1];
        break;
      case "MACD":
        chartYOrigin = macdChartOrigin[1];
        break;
      case "RSI":
        chartYOrigin = rsiChartOrigin[1];
        break;
      case "ATR":
        chartYOrigin = atrChartOrigin[1];
        break;
    }

    // Calculate the y position for the tooltip's origin
    const tooltipYOrigin = chartYOrigin + additionalOffset;

    return [chartXOrigin, tooltipYOrigin];
  }

  return (
    <>
      <ChartCanvas
        zoomMultiplier={1.5}
        maintainPointsPerPixelOnResize={true}
        clamp
        height={props.height - margin.top - margin.bottom} // Ensure total height minus margins is used
        ratio={props.ratio}
        width={props.width}
        margin={margin}
        data={data}
        displayXAccessor={displayXAccessor}
        seriesName={"Data"}
        xScale={xScale}
        xAccessor={xAccessor}
        xExtents={xExtents}
        zoomAnchor={mouseBasedZoomAnchor}
        onDoubleClick={() =>
          dateDataPoint ? props.handleChartClick?.(dateDataPoint) : undefined
        }
      >
        {props.chartSettings.macdSettings &&
        props.chartSettings.macdSettings.macdEnabled ? (
          //MACD
          <ChartAny
            id={"MACD"}
            height={macdChartHeight}
            origin={macdChartOrigin}
            yExtents={macdIndicator.accessor()}
          >
            <MACDTooltip
              origin={calculateTooltipOrigin("MAIN", 0)}
              appearance={props.chartSettings.macdSettings.appearance}
              options={macdIndicator.options()}
              yAccessor={macdIndicator.accessor()}
            />
            <YAxis
              showGridLines={props.chartSettings.showGrid}
              tickLabelFill={theme.text}
              gridLinesStrokeStyle={theme.text}
              strokeStyle={theme.cardInterior}
            />
            <MACDSeries
              yAccessor={macdIndicator.accessor()}
              {...props.chartSettings.macdSettings.appearance}
            />
          </ChartAny>
        ) : undefined}
        {props.chartSettings.showVolume && (
          <ChartAny
            id={"VOLUME"}
            height={calculateHeight("VOLUME")}
            origin={volumeChartOrigin}
            yExtents={volumeSeries}
          >
            <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
          </ChartAny>
        )}

        <ChartAny
          id={"MAIN"}
          height={mainChartHeight}
          origin={mainChartOrigin}
          yExtents={candleChartExtents}
        >
          <XAxis
            axisAt={"top"}
            showGridLines={props.chartSettings.showGrid}
            showTicks={true}
            showTickLabel
            gridLinesStrokeStyle={theme.cardInterior}
            tickLabelFill={theme.text}
            strokeStyle={theme.cardInterior}
          />

          <YAxis
            showGridLines={props.chartSettings.showGrid}
            gridLinesStrokeStyle={"gray"}
            tickLabelFill={theme.text}
            strokeStyle={theme.cardInterior}
          />
          {props.chartSettings.orderSettings?.enabled && (
            <>
              <Annotate
                with={LabelAnnotation}
                usingProps={sellLabelAnnotation}
                when={placeSellOrderAnnotations}
              />
              <Annotate
                with={SvgPathAnnotation}
                usingProps={buySvgAnnotation}
                when={placeBuyOrderAnnotations}
              />
              <Annotate
                with={SvgPathAnnotation}
                usingProps={sellSvgAnnotation}
                when={placeSellOrderAnnotations}
              />
            </>
          )}
          {props.criteriaGenerationPoints && (
            <>
              <Annotate
                with={SvgPathAnnotation}
                usingProps={buyCriteriaGeneratorSvg}
                when={placeBuyPointAnnotations}
              />
              <Annotate
                with={SvgPathAnnotation}
                usingProps={sellCriteriaGeneratorSvg}
                when={placeSellPointAnnotations}
              />
            </>
          )}
          <CandlestickSeries
            clip={true}
            candleStrokeWidth={2}
            fill={getCandleColor}
            stroke={getCandleWickColor}
            wickStroke={getCandleWickColor}
            yAccessor={(d) => ({
              open: d.open,
              high: d.high,
              low: d.low,
              close: d.close,
              ts: d.ts,
            })}
          />
          {props.chartSettings.emaSettings && (
            // EMA
            <>
              {props.chartSettings.emaSettings.ema20enabled && (
                <EMALine
                  yAccessor={ema20Indicator.accessor()}
                  stroke={props.chartSettings.emaSettings.ema20Color}
                />
              )}
              {props.chartSettings.emaSettings.ema50enabled && (
                <EMALine
                  yAccessor={ema50Indicator.accessor()}
                  stroke={props.chartSettings.emaSettings.ema50Color}
                />
              )}
              {props.chartSettings.emaSettings.ema100enabled && (
                <EMALine
                  yAccessor={ema100Indicator.accessor()}
                  stroke={props.chartSettings.emaSettings.ema100Color}
                />
              )}
              {props.chartSettings.emaSettings.ema200enabled && (
                <EMALine
                  yAccessor={ema200Indicator.accessor()}
                  stroke={props.chartSettings.emaSettings.ema200Color}
                />
              )}
              {props.chartSettings.emaSettings ? (
                <MovingAverageTooltip
                  origin={[8, 45]}
                  options={emaTooltipOptions}
                  textFill={theme.text}
                />
              ) : undefined}
            </>
          )}
          {props.chartSettings.bbSettings &&
          props.chartSettings.bbSettings.bbandEnabled ? (
            //BBAND
            <>
              <BollingerSeries />
              <BollingerBandTooltip
                origin={[8, 50]}
                options={bbandIndicator.options()}
                textFill={theme.text}
              />
            </>
          ) : undefined}
          <ErrorBoundary tryReload>
            <HoverTooltip
              yAccessor={ema20Indicator.accessor()}
              toolTipFillStyle={theme.PlayButtonbackground3}
              toolTipStrokeStyle={theme.PlayButtonbackground3}
              fontFill={theme.text}
              fontFamily="BalooBhaijaan2"
              background={{
                fillStyle: "transparent",
                strokeStyle: theme.neoton,
              }}
              fontSize={14}
              tooltip={{
                content: ({
                  currentItem,
                  xAccessor,
                }): {
                  x: string;
                  y: {
                    label: string;
                    value?: string | undefined;
                    stroke?: string | undefined;
                  }[];
                } => {
                  // Initialize default values
                  let x: string = "";
                  let y: {
                    label: string;
                    value?: string | undefined;
                    stroke?: string | undefined;
                  }[] = [];

                  if (currentItem && currentItem.open) {
                    const dateLabel = setDateFormat(xAccessor(currentItem));
                    const criteriaGenLabel =
                      "'Double-click' to place buy or sell point";
                    const criteriaRmLabel = "'Double-click' to remove point";
                    x = props.criteriaGenerationPoints
                      ? `${dateLabel} | ${
                          props.criteriaGenerationPoints.some(
                            (x) => x.timestamp === currentItem.ts
                          )
                            ? criteriaRmLabel
                            : criteriaGenLabel
                        }`
                      : dateLabel;
                    y = [
                      {
                        label: "open",
                        value: pricesDisplayFormat(currentItem.open),
                      },
                      {
                        label: "high",
                        value: currentItem.high
                          ? pricesDisplayFormat(currentItem.high)
                          : undefined,
                      },
                      {
                        label: "low",
                        value: currentItem.low
                          ? pricesDisplayFormat(currentItem.low)
                          : undefined,
                      },
                      {
                        label: "close",
                        value: currentItem.close
                          ? pricesDisplayFormat(currentItem.close)
                          : undefined,
                      },
                    ];

                    if (props.chartSettings.showVolume) {
                      y.push({
                        label: "volume",
                        value: currentItem.volume
                          ? currentItem.volume
                          : undefined,
                      });
                    }
                  }

                  return { x, y };
                },
              }}
            />
          </ErrorBoundary>

          <MouseCoordinateY
            rectWidth={margin.right}
            displayFormat={pricesDisplayFormat}
          />
          <EdgeIndicator
            itemType="last"
            rectWidth={margin.right + 10}
            fill={openCloseColor}
            lineStroke={openCloseColor}
            displayFormat={pricesDisplayFormat}
            yAccessor={yEdgeIndicator}
          />

          <OHLCTooltip
            origin={[5, 30]}
            textFill={theme.text}
            changeFormat={pricesDisplayFormat}
          />
        </ChartAny>

        {props.chartSettings.rsiSettings &&
        props.chartSettings.rsiSettings.rsiEnabled ? (
          //RSI
          <Chart
            id={"RSI"}
            height={calculateHeight("RSI")}
            origin={rsiChartOrigin}
            yExtents={[0, 100]}
          >
            <YAxis
              tickValues={[30, 50, 70]}
              tickLabelFill={theme.text}
              strokeStyle={props.chartSettings.rsiSettings.insideThresholdColor}
              tickStrokeStyle={theme.rsiLine}
            />
            <RSISeries
              yAccessor={rsiIndicator.accessor()}
              strokeStyle={{
                line: theme.rsiLine,
                top: "#808080",
                middle: "#808080",
                bottom: "#808080",
                outsideThreshold:
                  props.chartSettings.rsiSettings.outsideThresholdColor,
                insideThreshold:
                  props.chartSettings.rsiSettings.insideThresholdColor,
              }}
            />

            <RSITooltip
              origin={calculateTooltipOrigin("RSI", 20)}
              yAccessor={rsiIndicator.accessor()}
              options={{ windowSize: 50 }}
              textFill={theme.text}
              displayInit={theme.text}
            />
          </Chart>
        ) : undefined}
        {props.chartSettings.atrSettings &&
        props.chartSettings.atrSettings.atrEnabled ? (
          //ATR
          <Chart
            id={"ATR"}
            yExtents={atrIndicator.accessor()}
            origin={atrChartOrigin}
            height={calculateHeight("ATR")}
          >
            <YAxis
              tickLabelFill={theme.text}
              strokeStyle={props.chartSettings.atrSettings.atrColor}
              tickStrokeStyle={theme.text}
              ticks={2}
            />
            <LineSeries
              yAccessor={atrIndicator.accessor()}
              strokeStyle={props.chartSettings.atrSettings.atrColor}
            />

            <SingleValueTooltip
              yAccessor={atrIndicator.accessor()}
              yLabel={`ATR (${atrIndicator.options().windowSize})`}
              yDisplayFormat={format(".2f")}
              valueFill={theme.text}
              origin={calculateTooltipOrigin("ATR", 15)}
            />
          </Chart>
        ) : undefined}

        <CrossHairCursor
          strokeStyle={theme.neotonTransparent}
          strokeDasharray="LongDash"
        />
      </ChartCanvas>
    </>
  );
}
export interface BollingerBandOptionsCustom {
  windowSize: number;
  sourcePath: string;
  multiplier: number;
  movingAverageType: string;
}
